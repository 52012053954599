
const { default: strings } = require("./strings");

var stringMap = {};
stringMap[strings.About_App_Name] = "Mind+خريطة ذهنية";
stringMap[strings.TEMPLATE] = "قالب";
stringMap[strings.Mind_Edit_Main_Topic] = "موضوع الفرع";
stringMap[strings.Global_Add_Free_Node_Tips] = "انقر على الشاشة لاختيار موقع";
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = "انقر نقرًا مزدوجًا فوق الشاشة لتحديد الموقع المراد إضافته";
stringMap[strings.Global_Add_Relationship_Tips] = "حدد الهدف المراد ربطه";
stringMap[strings.Global_Add_Target_Tips] = "الرجاء تحديد عقدة لإضافة كائنات";
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = "هذا الكائن مرتبط بالفعل بخرائط أخرى";
stringMap[strings.Mind_Edit_Main_Idea] = "الموضوع الرئيسي";
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = "الخريطة المرتبطة غير موجودة";
stringMap[strings.Mind_Style_Add_Icon_Priority] = "أفضلية";
stringMap[strings.Mind_Style_Add_Icon_Progress] = "جدول";
stringMap[strings.Mind_Style_Add_Icon_Stars] = "نجمة";
stringMap[strings.Mind_Style_Add_Icon_Week] = "أسبوع";
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = "الصورة الرمزية";
stringMap[strings.Establish] = "خلق";
stringMap[strings.Create_From_Template] = "إنشاء من النموذج";
stringMap[strings.Common] = "عالمي";
stringMap[strings.Function_List] = "قائمة الوظائف";
stringMap[strings.Recent_Updates] = "تم تحديثه مؤخرا";
stringMap[strings.The_Latest_To_Delete] = "تم حذفه مؤخرًا";
stringMap[strings.Setting_Up] = "اتصل بنا";
stringMap[strings.Usinghelp] = "باستخدام المساعدة";
stringMap[strings.The_Download_Client] = "تنزيل نسخة العميل / الهاتف المحمول";
stringMap[strings.Global_Purchase] = "يشترى";
stringMap[strings.Global_Login] = "تسجيل الدخول";
stringMap[strings.My_map] = "خريطتي";
stringMap[strings.Create_Map] = "أنشئ خريطة";
stringMap[strings.Storm] = "عاصفه";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = "الميزات المشتركة \ n";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = "سمات";
stringMap[strings.Mind_Organization_Edit_Main_Idea] = "المركز التنظيمي";
stringMap[strings.Mind_Edit_Free_Topic] = "موضوع مجاني";
stringMap[strings.Mind_Edit_Sub_Topic] = "موضوع فرعي";
stringMap[strings.Mind_Organization_Main_Topic] = "قسم";
stringMap[strings.Mind_Edit_Main_Topic] = "موضوع الفرع";
stringMap[strings.Mind_Time_Edit_Main_Idea] = "إدارة الخطة";
stringMap[strings.Mind_Time_Edit_Head] = "زمن";
stringMap[strings.Mind_Time_Edit_Title] = "خطة";
stringMap[strings.Mind_Edit_Details] = "وصف مفصل";
stringMap[strings.Checking_Data] = "التحقق من البيانات";
stringMap[strings.Setting_Information] = "معلومات الإعداد";
stringMap[strings.Sign_Out] = "خروج";

//قائمة الوظائف
stringMap[strings.Create_a_Mind_Map] = "أنشئ خريطة ذهنية";
stringMap[strings.Create_Mind_Map_1] = "【1】قم بإنشاء أربعة عشر خريطة تخطيط (اتجاهين / يمين / يسار / هيكل تنظيمي / مخطط فقاعي فردي / مخطط فقاعي مزدوج / مخطط شجرة يسار / مخطط شجري يمين / مخطط شجري ثنائي الاتجاه / جدول / مخطط زمني أفقي / مخطط زمني عمودي / مخطط زمني S / هرم )";
stringMap[strings.Create_Mind_Map_2] = "【2】يمكن استخدام القوالب لإنشاء الخرائط بسرعة (عدد كبير من مراجع محتوى القوالب)";
stringMap[strings.Create_Mind_Map_3] = "【3】قم بتحرير تسعة أنواع من أنماط الخطوط على الخريطة";
stringMap[strings.Create_Mind_Map_4] = "【4】تحرير التغييرات التخطيط التسعة للخريطة";
stringMap[strings.Create_Mind_Map_5] = "【5】أضف العقد الفرعية إلى العقدة الهدف ، أضف العقد في نفس المستوى (العلوي والسفلي)";
stringMap[strings.Create_Mind_Map_6] = "【6】الملخص: شرح إضافة عقدة واحدة / عدة عقد [ملخص]";
stringMap[strings.Create_Mind_Map_7] = "【7】الحظر: أضف [حظر] إلى مربعات العقدة المفردة / العقدة المتعددة في الخريطة من أجل التهيئة";
stringMap[strings.Create_Mind_Map_8] = "【8】أضف عقدة مجانية";
stringMap[strings.Create_Mind_Map_9] = "【9】إنشاء اقترانات من أربعة أنماط خطوط بين أي عقد";
stringMap[strings.Create_Mind_Map_10] = "【10】قم بإدراج الصور (مكتبة المواد المحلية +) والرموز والملاحظات والروابط إلى العقد";
stringMap[strings.Create_Mind_Map_11] = "【11】نسخ / لصق العقدة ، نسخ / لصق النمط";
stringMap[strings.Create_Mind_Map_12] = "【12】إبطال";
stringMap[strings.Create_Mind_Map_13] = "【13】اختيار متعدد ، عملية متزامنة متعددة العقد";
stringMap[strings.Create_Mind_Map_14] = "【14】إدراج الرسم البياني الإحصائي";
stringMap[strings.Create_Mind_Map_15] = "【15】يمكن تسجيل الخريطة بأكملها";
stringMap[strings.Create_Mind_Map_16] = "【16】نسخ الخريطة";
stringMap[strings.Create_Mind_Map_17] = "【17】يمكنك نسخ الخريطة أو الخريطة بأكملها إلى خريطة الملفات الأخرى";
stringMap[strings.Create_Mind_Map_18] = "【18】يمكن إنشاء خريطة بعد الملخص";
stringMap[strings.Create_Mind_Map_19] = "【19】عند التحرير ، يمكنك تبديل تعديل الخريطة (جانب الكمبيوتر)";
stringMap[strings.Create_Mind_Map_20] = "【20】خريطة تحرير لوحة مفاتيح Bluetooth: \ n \ t [Command] + [a] حدد العقدة الرئيسية \ n \ t [Command] + [←] [↑] [→] [↓] قم بتغيير الهدف المحدد أو حرك الخريطة \ n \ t [Command] + [e] تحرير الهدف \ n \ t [Command] + [w] إنشاء عقدة فرعية \ n \ t [Command] + [s] إنشاء عقدة شقيقة الهدف (أعلى) \ n \ t [الأمر] + [d] إنشاء عقدة شقيقة مستهدفة (أدناه) \ n \ t [Command] + [q] الخروج من الهدف المحدد \ n \ t [Command] + [-] [+] تكبير الخريطة ";

stringMap[strings.Beautify_The_Mind_Map] = "تجميل الخريطة الذهنية";
stringMap[strings.Beautify_The_Mind_Map_1] = "【1】اضبط لون خلفية الخريطة الذهنية";
stringMap[strings.Beautify_The_Mind_Map_2] = "【2】اضبط سمك ولون خط الخريطة بالكامل ، سواء كان مدببًا أم لا";
stringMap[strings.Beautify_The_Mind_Map_3] = "【3】تعيين لون تعبئة النص ، لون الحدود ، شكل الحدود 13 ، عرض الحدود ، تأثير الظل";
stringMap[strings.Beautify_The_Mind_Map_4] = "【4】اضبط لون وعرض الخريطة بالكامل أو جزء منها";
stringMap[strings.Beautify_The_Mind_Map_5] = "【5】تعيين حجم خط النص ، غامق ، مائل ، حذف النمط";
stringMap[strings.Beautify_The_Mind_Map_6] = "【6】اضبط حجم الصورة وموضعها ونمط الشكل";
stringMap[strings.Beautify_The_Mind_Map_7] = "【7】أضف سبع فئات من الرموز (مشتركة / ثلاثية الأبعاد / دراسة / عمل / أشخاص / حيوانات / طعام) واضبط الحجم / الموضع";
stringMap[strings.Beautify_The_Mind_Map_8] = "【8】أضف مرجع نمط بنقرة واحدة إلى الخريطة بأكملها";
stringMap[strings.Beautify_The_Mind_Map_9] = "【9】خريطة إدارة المجموعة";
stringMap[strings.Beautify_The_Mind_Map_10] = "【10】يمكن إضافة الخلفية الشخصية (مكتبة المواد) إلى الخريطة بأكملها";
stringMap[strings.Beautify_The_Mind_Map_9] = "【11】يمكن إضافة حدود أو خلفيات فردية إلى عقدة واحدة (مكتبة المواد)";
stringMap[strings.Beautify_The_Mind_Map_9] = "【12】يمكن سحب طول العقدة";


stringMap[strings.Map_Output] = "إخراج الخريطة";
stringMap[strings.Map_Output_1] = "【1】احفظ الصورة";
stringMap[strings.Map_Output_2] = "【2】احفظ ملف PDF";
stringMap[strings.Map_Output_3] = "【3】رابط المشاركة";
stringMap[strings.Map_Output_4] = "【4】شارك في العاصفة";
stringMap[strings.Map_Output_4] = "【5】مطبعة";

stringMap[strings.Work_together] = "نعمل معا";
stringMap[strings.Work_together_1] = "【1】إنشاء مجموعة مشتركة (تسمية المجموعة ، إعلان المجموعة)";
stringMap[strings.Work_together_2] = "【2】إضافة أعضاء من المجموعة المشتركة";
stringMap[strings.Work_together_3] = "【3】يقوم العديد من الأعضاء في المجموعة بتحرير نفس الخريطة معًا";
stringMap[strings.Work_together_4] = "【4】تحميل الملفات المشتركة داخل المجموعة (word / excel / pdf)";
stringMap[strings.Work_together_5] = "【5】يمكنك ترك رسالة في المجموعة";
stringMap[strings.Work_together_6] = "【6】إعدادات أذونات إدارة المجموعة المشتركة";
stringMap[strings.Work_together_7] = "【7】مجموعة حل";

stringMap[strings.Work_together] = "تبادل المعرفة (العاصفة)";
stringMap[strings.Community_Sharing_1] = "【1】يمكنك مشاركة إبداعك مع العاصفة ، حتى يتمكن المزيد من الأشخاص من رؤيته";
stringMap[strings.Community_Sharing_1] = "【2】يمكنك تحديد العلامات لعرض محتوى العاصفة ذات الصلة";
stringMap[strings.Community_Sharing_1] = "【3】يمكنك الإعجاب والتعليق على أعمال العاصفة";

//تم تحديثه مؤخرا
stringMap[strings.Recently_Updated_Title_1] = "قم بإنشاء حساب شخصي";
stringMap[strings.Recently_Updated_Title_2] = "قاعدة معارف القالب الشامل";
stringMap[strings.Recently_Updated_Title_3] = "دعم منصات متعددة";
stringMap[strings.Recently_Updated_Title_4] = "خصص كل التفاصيل";
stringMap[strings.Recently_Updated_Title_5] = "مكتبة نمط شخصية";
stringMap[strings.Recently_Updated_Title_6] = "إخراج الخريطة";
stringMap[strings.Recently_Updated_Title_7] = "سحابة التخزين";
stringMap[strings.Recently_Updated_Title_8] = "خمسة عشر نوعا من الخرائط";


stringMap[strings.Recently_Updated_Content_1] = "قم بإنشاء حساب شخصي ، واحفظ الخريطة التي تم إنشاؤها مع الحساب";
stringMap[strings.Recently_Updated_Content_2] = "يتم اقتباس قاعدة معارف القالب الشامل مباشرة لتحسين كفاءة التعلم والعمل";
stringMap[strings.Recently_Updated_Content_3] = "دعم إنتاج إصدار الويب عبر الإنترنت ، محطة IOS المتنقلة ، محطة Mac ، محطة Android المحمولة ، استخدام نظام Android اللوحي متعدد المنصات";
stringMap[strings.Recently_Updated_Content_4] = "يمكن تجميل تصميم النص والخلفية والخطوط وأيقونات الصور وما إلى ذلك حسب التفضيلات الشخصية";
stringMap[strings.Recently_Updated_Content_5] = "يتم اقتباس مكتبة أنماط فردية مباشرة ، مما يلغي مشكلة مطابقة الألوان";
stringMap[strings.Recently_Updated_Content_6] = "يمكن حفظ الصور بتنسيق PDF ومشاركتها مباشرة مع الأصدقاء";
stringMap[strings.Recently_Updated_Content_7] = "دعم التخزين السحابي القوي ، لا داعي للقلق بشأن فقدان البيانات";
stringMap[strings.Recently_Updated_Content_8] = "اتجاهين ، يمين ، يسار ، هيكل تنظيمي ، مخطط فقاعي فردي ، مخطط فقاعي مزدوج ، مخطط شجري يسار ، مخطط شجري لليمين ، مخطط شجري ثنائي الاتجاه ، جدول ، مخطط زمني أفقي ، مخطط زمني عمودي ، مخطط زمني S ، ثمانية تخطيطات يمكن تغييرها في إرادة";

//تم حذفه مؤخرًا
stringMap[strings.Mind_Mindmap_Deleted_Title] = "تم حذفه مؤخرًا";
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = "يعيد";
stringMap[strings.Mind_Mindmap_Deleted_Forever] = "حذف";
stringMap[strings.Mind_Mindmap_Deleted_Filename] = "اسم الملف";
stringMap[strings.Mind_Mindmap_Deleted_Tips] = "بمجرد حذفه هنا ، ستتم إزالته نهائيًا";
stringMap[strings.Mind_Mindmap_Deleted_Operating] = "التشغيل";
stringMap[strings.Mind_Mindmap_Deleted_Selected] = "ابحث عن الخرائط المحذوفة مؤخرًا";
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = "حذف التاريخ";

//اتصل بنا
stringMap[strings.Contact_Us_No_Public] = "الجمهور";
stringMap[strings.Contact_Us_QQ_Group] = "مجموعة QQ";
stringMap[strings.Contact_Us_Message_Board] = "رسالة المجلس";
stringMap[strings.Contact_Us_Username] = "اسم";
stringMap[strings.Contact_Us_Email] = "صندوق بريد";
stringMap[strings.Contact_Us_Voicemail] = "يرجى ترك تعليقاتك القيمة";
stringMap[strings.Contact_Us_Send_Email] = "ارسل بريد الكتروني";

//التحقق من الرسالة
stringMap[strings.Input_Username_isNull] = "لا يمكن أن يكون الاسم فارغًا";
stringMap[strings.Input_Email_isNull] = "الرجاء إدخال البريد الإلكتروني الخاص بك";
stringMap[strings.Input_Voicemail_isNull] = "الرجاء إدخال المحتوى";
stringMap[strings.Input_Introduction_isNull] = "لا يمكن أن تكون المقدمة فارغة";
stringMap[strings.Input_Title_isNull] = "لا يمكن أن يكون العنوان فارغًا";

stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] = "يرجى إدخال رقم هاتف صالح";
stringMap[strings.Input_Please_Enter_Verification_Code] = "الرجاء إدخال رمز التحقق!";
stringMap[strings.Input_Please_Enter_The_Password] = "الرجاء إدخال كلمة المرور!";
stringMap[strings.Input_The_Password_Format_Is_Incorrect] = "تنسيق كلمة المرور غير صحيح!";
stringMap[strings.Input_Please_Enter_A_New_Password] = "الرجاء إدخال كلمة مرور جديدة!";
stringMap[strings.Input_Confirm_The_New_Password] = "الرجاء إدخال تأكيد كلمة المرور!";
stringMap[strings.Input_The_Passwords_Are_Inconsistent] = "كلمات المرور غير متسقة!";
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] = "الرجاء إدخال رمز التحقق الصحيح!";
stringMap[strings.Input_The_Verification_Code_Error] = "خطأ في رمز التحقق!";

//باستخدام المساعدة
stringMap[strings.Mind_Usinghelp_1] = "أنشئ خريطة ذهنية";
stringMap[strings.Mind_Usinghelp_2] = "كيفية إضافة العقد";
stringMap[strings.Mind_Usinghelp_3] = "إنشاء عقدة مجانية";
stringMap[strings.Mind_Usinghelp_4] = "قم بعمل ارتباط";
stringMap[strings.Mind_Usinghelp_5] = "تجميل القماش";
stringMap[strings.Mind_Usinghelp_6] = "تجميل النص";
stringMap[strings.Mind_Usinghelp_7] = "أدخل الملاحظات والروابط";
stringMap[strings.Mind_Usinghelp_8] = "إدراج رمز";
stringMap[strings.Mind_Usinghelp_9] = "أدخل الصورة";
stringMap[strings.Mind_Usinghelp_10] = "كيفية الحذف";
stringMap[strings.Mind_Usinghelp_11] = "تصدير الخريطة الذهنية";
stringMap[strings.Mind_Usinghelp_12] = "ترقية العضو";
stringMap[strings.Mind_Usinghelp_13] = "مفتاح التشغيل السريع";
stringMap[strings.Mind_Usinghelp_Understood] = "فهمتك";
stringMap[strings.Mind_Usinghelp_14] = "إدراج مكتبة المواد";
//استخدم المساعدة -2
stringMap[strings.Mind_Usinghelp_15] = "خريطة جديدة";
// stringMap[strings.Mind_Usinghelp_2] = "أضف العقدة";
// stringMap[strings.Mind_Usinghelp_4] = "قم بعمل ارتباط";
// stringMap[strings.Mind_Usinghelp_5] = "تجميل القماش";
// stringMap[strings.Mind_Usinghelp_6] = "تجميل النص";
// stringMap[strings.Mind_Usinghelp_7] = "أضف الملاحظات والروابط";
stringMap[strings.Mind_Usinghelp_17] = "تراجع عن العملية ، إلغاء";
stringMap[strings.Mind_Usinghelp_18] = "اسحب العقدة للتبديل";
stringMap[strings.Mind_Usinghelp_19] = "محاذاة العقدة";
stringMap[strings.Mind_Usinghelp_20] = "تغيير نوع الخريطة";
stringMap[strings.Mind_Usinghelp_21] = "نمط خط الخريطة";
stringMap[strings.Mind_Usinghelp_22] = "لون خلفية الخريطة";
stringMap[strings.Mind_Usinghelp_23] = "تعيين شكل العقدة";
stringMap[strings.Mind_Usinghelp_24] = "نمط خط العقدة";
stringMap[strings.Mind_Usinghelp_25] = "إعدادات صورة العقدة";
stringMap[strings.Mind_Usinghelp_26] = "إعدادات رمز العقدة";
stringMap[strings.Mind_Usinghelp_27] = "ملخص إضافة العقدة";
stringMap[strings.Mind_Usinghelp_28] = "اختيار إطار عقدة واحدة";
stringMap[strings.Mind_Usinghelp_29] = "كتلة متعددة العقدة";
stringMap[strings.Mind_Usinghelp_30] = "العقدة تضيف حدًا";
stringMap[strings.Mind_Usinghelp_31] = "أضف مخططًا";
stringMap[strings.Mind_Usinghelp_32] = "العقد المخفية والانتعاش";
stringMap[strings.Mind_Usinghelp_33] = "عقدة عمليات متعددة";
stringMap[strings.Mind_Usinghelp_34] = "تغيير النمط";
stringMap[strings.Mind_Usinghelp_35] = "أدخل الكلام والشرح";
stringMap[strings.Mind_Usinghelp_36] = "خريطة للصورة";
stringMap[strings.Mind_Usinghelp_37] = "وثيقة خريطة PDF";
stringMap[strings.Mind_Usinghelp_38] = "طباعة الخريطة";
stringMap[strings.Mind_Usinghelp_39] = "مشاركة الرابط وعرضه";
stringMap[strings.Mind_Usinghelp_40] = "تقاسم الخريطة للعاصفة";
stringMap[strings.Mind_Usinghelp_41] = "استخدام المجلدات";
stringMap[strings.Mind_Usinghelp_42] = "حذف واستعادة الخرائط";
//استخدم المساعدة 3
stringMap[strings.Mind_Usinghelp_43] = "يتقدم الأصدقاء للانضمام إلى المجموعة";
stringMap[strings.Mind_Usinghelp_44] = "كيفية حل مجموعة";
stringMap[strings.Mind_Usinghelp_45] = "تحميل الوثائق";
stringMap[strings.Mind_Usinghelp_46] = "توافق على الانضمام إلى التطبيق";
stringMap[strings.Mind_Usinghelp_47] = "التحرير المشترك";
stringMap[strings.Mind_Usinghelp_48] = "مجموعة مشاركة جديدة";
stringMap[strings.Mind_Usinghelp_49] = "قم بدعوة أعضاء المجموعة";

//عاصفه
stringMap[strings.Mind_Storm_Recommend] = "نوصي";
stringMap[strings.Mind_Storm_Boilingpoint] = "نقطة الغليان";
stringMap[strings.Mind_Storm_Unfold] = "تتكشف";
stringMap[strings.Mind_Storm_Putaway] = "ضعه بعيدا";
stringMap[strings.Mind_Storm_Myclassification] = "تصنيفي";
stringMap[strings.Mind_Storm_Recommended_Classification] = "التصنيف الموصى به";
stringMap[strings.Mind_Storm_Click_Add_Category] = "انقر لإضافة فئة";
stringMap[strings.Mind_Storm_No_Classification] = "لا يوجد تصنيف";
stringMap[strings.Mind_Storm_No_Tinterested] = "غير مهتم";
stringMap[strings.Mind_Storm_Report] = "تقرير";
stringMap[strings.Mind_Storm_Views] = "الآراء";

stringMap[strings.Mind_Storm_Pornographic] = "الابتذال الإباحي";
stringMap[strings.Mind_Storm_Advertising_Harassment] = "المضايقات الإعلانية";
stringMap[strings.Mind_Storm_Political_Religion] = "الدين السياسي";
stringMap[strings.Mind_Storm_Infringement] = "التعدي (صورة شخصية ، تشهير ، انتحال ، استخدام احتيالي)";
stringMap[strings.Mind_Storm_Prohibited_Content] = "المحتوى المحظور";
stringMap[strings.Mind_Storm_Spread_Rumors] = "نشر الشائعات";
stringMap[strings.Mind_Storm_Report_Successful] = "تم الإبلاغ بنجاح";

//تعديل
stringMap[strings.Global_Edit] = "تعديل";
stringMap[strings.Global_Cancel] = "إلغاء";
stringMap[strings.Global_Ok] = "تحديد";
stringMap[strings.Global_Name] = "اسم الشهرة";
stringMap[strings.Global_Introduction] = "مقدمة";
stringMap[strings.Global_Change_Avatar] = "تغيير الصورة الرمزية";
stringMap[strings.Global_Local_Upload] = "تحميل محلي";

//معلومات شخصية
stringMap[strings.User_Personal_information] = "معلومات شخصية";
stringMap[strings.User_Member_Validity_Period] = "فترة صلاحية العضوية";
stringMap[strings.User_My_Share] = "حصتي";
stringMap[strings.User_My_Collection] = "مجموعتي";
stringMap[strings.User_More_Message] = "أكثر";
stringMap[strings.User_Deleted_Sotrm] = "حذف العاصفة";
stringMap[strings.User_Folder_Rename] = "إعادة تسمية";
stringMap[strings.User_Folder_Rename_Root] = "تعديل الأذونات";
stringMap[strings.User_Folder_Rename_Root_Title] = "تعديل الأذونات";
stringMap[strings.User_Folder_Root_Public] = "عامة بالكامل";
stringMap[strings.User_Folder_Root_Viewonly] = "المجلد العام يسمح فقط بالعرض";
stringMap[strings.User_Folder_Root_Private] = "خاص";
stringMap[strings.User_Folder_Root_Public_Introduction] = "يمكن للآخرين عرض المفضلة على صفحة ملفك الشخصي";
stringMap[strings.User_Message_Link] = "حلقة الوصل";
stringMap[strings.User_New_Group] = "مجموعة جديدة";
stringMap[strings.User_New_Group_Title] = "إنشاء مجموعة جديدة";
stringMap[strings.User_New_Group_Style_Title] = "نوع الملف";
stringMap[strings.User_Share_Is_Null] = "لا حصة بعد!";
stringMap[strings.User_Deleted_Storm_Tips] = "هل أنت متأكد من حذف هذه العاصفة؟";
stringMap[strings.User_Remove_From_List] = "ازله من القائمة",
    stringMap[strings.User_Move_To] = "الانتقال إلى",
    stringMap[strings.User_Deleted_Link] = "حذف الارتباط"
stringMap[strings.User_Collction_Is_Null] = "لا مجموعة"
stringMap[strings.User_Collection] = "المفضلة"

//登录框
stringMap[strings.User_Lolgin_Welcome_To_Login] = "مرحبا بكم في تسجيل الدخولMind+"
stringMap[strings.User_Lolgin_Free_Registration] = "تسجيل مجاني"
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] = "الرجاء إدخال رقم الهاتف"
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] = "الرجاء إدخال رمز التحقق"
stringMap[strings.User_Lolgin_Obtain] = "الحصول على"
stringMap[strings.User_Lolgin_Password_Login] = "تسجيل الدخول كلمة المرور"
stringMap[strings.User_Lolgin_Login] = "تسجيل الدخول"
stringMap[strings.User_Lolgin_Register_An_Account] = "تسجيل حساب"
stringMap[strings.User_Lolgin_Already_Have_An_Account] = "هل لديك حساب"
stringMap[strings.User_Lolgin_To_Log_In] = "لتسجيل الدخول"
stringMap[strings.User_Lolgin_Please_Enter_Password] = "الرجاء إدخال كلمة المرور"
stringMap[strings.User_Lolgin_Confirm_Password] = "تأكيد كلمة المرور"
stringMap[strings.User_Lolgin_Registered] = "مسجل"
stringMap[strings.User_Lolgin_Account_Login] = "تسجل الدخول"
stringMap[strings.User_Lolgin_Scan_Code_Login] = "مسح تسجيل الدخول إلى رمز"
stringMap[strings.User_Lolgin_Forgot_Password] = "هل نسيت كلمة السر"
stringMap[strings.User_Lolgin_Verification_Code_Login] = "تسجيل الدخول إلى رمز التحقق"
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = "افتح Mind + وقم بالمسح في الجزء العلوي من واجهة الإعداد"
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = "تنتهي صلاحية رمز الاستجابة السريعة هذا خلال خمس دقائق ، الرجاء النقر"
stringMap[strings.User_Lolgin_Refresh] = "تحديث"
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = "تم المسح بنجاح ، يرجى التأكيد على هاتفك"
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] = "ما إذا كان يجب تفويض تسجيل الدخول"
stringMap[strings.User_Lolgin_Please_Enter_New_Password] = "الرجاء إدخال كلمة مرور جديدة"
stringMap[strings.User_Lolgin_Reset] = "إعادة ضبط"

//خريطتي
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] = "نوع قالب البحث أو القالب"
stringMap[strings.My_Mind_Date] = "تاريخ"
stringMap[strings.My_Mind_Create_Folder] = "أنشئ مجلد"
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] = "الرجاء إدخال اسم المجلد"
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] = "الرجاء إدخال اسم مجلد جديد"
stringMap[strings.My_Mind__No_Map_Yet_Go] = "لا توجد خريطة بعد ، اذهب"
stringMap[strings.My_Mind_Create] = "خلق"
stringMap[strings.My_Mind_Right] = "حق!"
stringMap[strings.My_Mind_Open] = "شغله"
stringMap[strings.My_Mind_Type_Left_Right] = "مخطط دليل مزدوج"
stringMap[strings.My_Mind_Type_Unilateral] = "خريطة اتجاه واحد"
stringMap[strings.My_Mind_Type_Bottom] = "الهيكل التنظيمي"
stringMap[strings.My_Mind_Type_Bubble] = "مخطط فقاعي"
stringMap[strings.My_Mind_Type_Time_Vertical] = "جدول المخطط"
stringMap[strings.My_Mind_Blank_Mind_Map] = "خريطة ذهنية فارغة"
stringMap[strings.My_Mind_All] = "الجميع"
stringMap[strings.My_Mind_Views] = "الآراء："

//البحث عن العاصفة
stringMap[strings.Storm_Search_How_Title] = "الكلمات الشعبية"
stringMap[strings.Storm_History_Search] = "البحث في التاريخ"

//公共 header
stringMap[strings.Public_Header_Mind] = "خريطة"
stringMap[strings.Public_Header_Outline] = "الخطوط العريضة"
stringMap[strings.Public_Header_Download_Apps] = "تنزيل التطبيقات"
stringMap[strings.Public_Header_Mobile_Version] = "اصدار المحمول"
stringMap[strings.Public_Header_Creator] = "المنشئ："
stringMap[strings.Public_Header_View_Frequency] = "تردد العرض："
stringMap[strings.Public_Header_Save] = "حفظ"
stringMap[strings.Header_Mind_Tips_Saved] = "تم الحفظ"
stringMap[strings.Header_Mind_Tips_Edit] = "تم تحريره"
stringMap[strings.Header_Mind_Tips_Update_Saved] = "تم حفظ التحديث"

//导图编辑菜单
stringMap[strings.Mind_Edit_Revoke] = "سحب او إبطال"
stringMap[strings.Mind_Edit_Restore] = "يعيد"
stringMap[strings.Mind_Edit_Subtopic] = "موضوع فرعي"
stringMap[strings.Mind_Edit_Top_Subtopic] = "موضوع فرعي"
stringMap[strings.Mind_Edit_Bottom_Subtopic] = "الموضوع التالي"
stringMap[strings.Mind_Edit_Free_Node] = "عقدة حرة"
stringMap [strings.Mind_Edit_Summary] = "ملخص"
stringMap [strings.Mind_Edit_Block] = "في كتلة"
stringMap [strings.Mind_Edit_Insert] = "إدراج"
stringMap [strings.Mind_Edit_Related] = "ذو صلة"
stringMap [strings.Mind_Edit_Style] = "نمط"
stringMap [strings.Mind_Edit_Recording] = "تسجيل"
stringMap [strings.Mind_Edit_Insert_Picture] = "إدراج صورة"
stringMap [strings.Mind_Edit_Insert_Icon] = "إدراج رمز"
stringMap [strings.Mind_Edit_Insert_Remarks] = "إدراج ملاحظات"
stringMap [strings.Mind_Edit_Insert_Link] = "إدراج ارتباط"
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = "إدراج رسم بياني إحصائي"
stringMap [strings.Mind_Edit_Upload] = "تحميل"
stringMap [strings.Mind_Edit_Right_Icon] = "أيقونة"
stringMap [strings.Mind_Edit_Right_Style] = "نمط"
stringMap [strings.Mind_Edit_Audio_Decoding] = "فك التشفير "
stringMap [strings.Mind_Edit_Audio_Duration] = "المدة"
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = "هل أنت متأكد من حذف الصوت المسجل؟"
stringMap [strings.Mind_Edit_Join_Membership] = "الانضمام إلى العضوية"
stringMap [strings.Mind_Edit_Upgrade] = "ترقية"
stringMap[strings.Mind_Edit_Upgrade_Item] = "ترقية برو"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = "يمكن للأعضاء الحصول على وقت تسجيل أطول ، الآن اذهب للترقية إلى العضوية؟"

stringMap [strings.Mind_Edit_Choose_Material] = "حدد مادة"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = "اسحب الصور وأفلتها هنا"
stringMap [strings.Mind_Edit_Select_locally] = "اختر من محلياً"
stringMap [strings.Mind_Edit_Choose_From] = "التحديد من مكتبة المواد"
stringMap [strings.Mind_Edit_Mine] = "خاصتي"
stringMap [strings.Mind_Edit_My_Material] = "مادتي"
stringMap [strings.Mind_Edit_Recently_Used] = "تم استخدامه مؤخرًا"
stringMap [strings.Mind_Edit_Use] = "استخدام"
stringMap [strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = "لا توجد مادة في الوقت الحالي ، اذهب وأضفها!"
stringMap [strings.Mind_Edit_Select_Chart_Type] = "حدد نوع المخطط"
stringMap [strings.Mind_Edit_Remarks] = "ملاحظات"
stringMap [strings.Mind_Edit_Remarks_Tips] = "الرجاء إدخال محتوى الملاحظات"
stringMap [strings.Mind_Edit_Add] = "إضافة"
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = "الرجاء إدخال الرابط"

//编辑页左侧菜单
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = "حفظ الصورة"
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = "تصدير ملف PDF"
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = "مشاركة الروابط"
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = "مشاركة في العاصفة"
stringMap [strings.Mind_Edit_Left_Menu_Printing] = "طباعة"
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = "فتح الإصدار الكامل"
stringMap [strings.Mind_Edit_Left_Menu_Help] = "مساعدة"
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = "حفظ التنسيق"
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = "تنسيق png"
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = "تنسيق jpg"
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = "تنسيق ويب"
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = "مشاركة الخريطة"
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = "تبديل المشاركة"
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = "يمكن نسخ الرابط وإرساله"
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = "نسخ الرابط"
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = "إذا كنت تريد تغيير الارتباط ، فالرجاء النقر فوق"
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = "إعادة إنشاء"
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = "رابط كلمة المرور:"
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] = "السماح بالحفظ"
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = "الحد الأقصى لعدد المشاهدات"
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = "لانهائي"
stringMap [strings.Mind_Edit_Left_Menu_Limit] = "حد"
stringMap [strings.Mind_Edit_Left_Menu_Second] = "ثانية"
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = "فترة صلاحية الارتباط"
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = "يوم واحد"
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = "3 أيام"
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = "7 أيام"
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = "دائم"
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = "نسخ المحتوى والرابط"
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = "هل أنت متأكد أنك تريد التجديد؟ الارتباط السابق سيكون غير صالح!"
stringMap [strings.Mind_Edit_Left_Menu_Share] = "مشاركة"
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = "فئة مخصصة:"
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = "السماح بالتعليقات"
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = "تحرير إضافة فئة"
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = "حقوق العضوية"
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = "منصة الدعم"
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = "فتح العضوية"
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = "تبادل كود التنشيط"
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = "مزايا العضوية بعد الشراء"
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = "حدد طريقة الدفع"
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = "سعر النشاط"
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = "المبلغ المدفوع بالفعل"
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = "التجديد فورًا"
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = "شروط الخدمة" 
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = " سياسة الخصوصية "
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = "كود التفعيل:"
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = "الرجاء إدخال رمز التفعيل"
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = "تحقق من رمز التنشيط"
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = "حدد الفئات"
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = "لا يمكن أن يكون العنوان الرئيسي فارغًا"
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = "القسيمة:"
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = "الرجاء إدخال رمز القسيمة"
stringMap [strings.Mind_Confirm_Use_Coupons] = "تأكيد استخدام القسائم"
stringMap [strings.Mind_Edit_Left_Menu_Verification] = "التحقق"
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = "المبلغ التفضيلي"
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = "ادفع فورًا"
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = "WeChat Pay"
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = "تأكيد معلومات Exchange"
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = "كود الصرف:"
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = "تبادل"
//编辑页右侧菜单
stringMap [strings.Mind_Edit_Right_Menu_canvas] = "قماش"
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = "لون الخلفية"
stringMap [strings.Mind_Edit_Right_Menu_layout] = "تخطيط"
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = "نمط الخط"
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = "لون الخط"
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = "عرض الخط"
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = "الخطوط مدببة"
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = "خط الموضوع"
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = "خط الأطفال"
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = "لون النص"
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = "حجم الخط"
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = "نمط الخط"
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = "لون الحدود"
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = "لون التعبئة"
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = "شكل الحدود"
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = "عرض الحدود"
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = "إظهار تأثير الظل (وظيفة الاشتراك)"
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = "لون الخط"
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = "خط منقط"
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = "حجم الصورة"
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = "موقع الصورة"
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = "شكل الصورة"
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = "حجم الرمز"
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = "موضع الرمز"
stringMap [strings.Mind_Edit_Right_Menu_routine] = "عام"
stringMap [strings.Mind_Edit_Right_Menu_priority] = "الأولوية"
stringMap [strings.Mind_Edit_Right_Menu_Progress] = "التقدم"
stringMap [strings.Mind_Edit_Right_Menu_Stars] = "نجوم"
stringMap [strings.Mind_Edit_Right_Menu_Week] = "أسبوع"
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = "عمودي"
stringMap [strings.Mind_Edit_Right_Menu_3D] = "ثلاثي الأبعاد"
stringMap [strings.Mind_Edit_Right_Menu_Learning] = "التعلم"
stringMap [strings.Mind_Edit_Right_Menu_Work] = "العمل"
stringMap [strings.Mind_Edit_Right_Menu_Characters] = "أحرف"
stringMap [strings.Mind_Edit_Right_Menu_Animals] = "الحيوانات"
stringMap [strings.Mind_Edit_Right_Menu_Food] = "طعام"
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = "نسخ النمط"
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = "أسلوب الالتصاق"
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = "تعديل الشكل"
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = "عرض الصورة"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = "حذف الصورة"
stringMap [strings.Mind_Edit_Node_Menu_Copy] = "نسخ"
stringMap [strings.Mind_Edit_Node_Menu_Paste] = "لصق"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = "حذف السطر"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = "حذف"
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = "تحرير الملاحظات"
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = "فتح الارتباط"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = "حذف الملاحظات"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = "حذف الارتباط"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = "حذف الرمز"
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = "نمط الأيقونة"

//统计图编辑面板
stringMap [strings.Echart_Edit_Statistical] = "إحصائيات"
stringMap [strings.Echart_Edit_Value] = "القيمة"
stringMap [strings.Echart_Edit_Color] = "اللون"
stringMap [strings.Echart_Edit_Title] = "العنوان"
stringMap [strings.Echart_Edit_Detailed_Description] = "وصف مفصل"
stringMap [strings.Echart_Edit_Value_Sort] = "فرز"
stringMap [strings.Echart_Edit_Value_Display_Type] = "نوع العرض"
stringMap [strings.Echart_Edit_Value_Color_Scheme] = "نظام الألوان"
stringMap [strings.Echart_Edit_Value_Display_Size] = "حجم العرض"
stringMap [strings.Echart_Edit_Value_Title_Size] = "حجم العنوان"
stringMap [strings.Echart_Edit_Value_Explain_Size] = "شرح الحجم"
stringMap [strings.Echart_Edit_Value_Subscribe] = "اشتراك"

//风暴标签页
stringMap [strings.StormBookTag_Subscrib] = "مشترك"
stringMap [strings.StormBookTag_List] = "قائمة"

//风暴分享页
stringMap [strings.Storm_Share_Comment] = "تعليق"
stringMap [strings.Storm_Share_Reply] = "رد"
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = "اسمح لي أيضًا بالتعليق عليها ~"
stringMap [strings.Storm_Share_Release] = "إطلاق"
stringMap [strings.Storm_Share_Main_Title] = "العنوان الرئيسي"
stringMap [strings.Storm_Share_Subtitle] = "الترجمة"
stringMap [strings.Storm_Share_Author] = "المؤلف"
stringMap [strings.Storm_Share_Replies] = "الردود"
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = "لا توجد تعليقات في الوقت الحالي. كن الأول."
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = "الرجاء إدخال تعليق ~"
stringMap [strings.Storm_Share_Delete_Comment_Tips] = "هل أنت متأكد أنك تريد حذف هذا التعليق؟"

//导图内部使用帮助
stringMap [strings.Mind_Usinghelp_Btn_1] = "مفتاح الاختصار (windows)"
stringMap [strings.Mind_Usinghelp_Btn_2] = "مفتاح الاختصار (mac)"
stringMap [strings.Mind_Usinghelp_Btn_3] = "الوصف"
stringMap [strings.Mind_Usinghelp_Btn_4] = "[Ctrl] + [D]"
stringMap [strings.Mind_Usinghelp_Btn_5] = "[الأمر] + [D]"
stringMap [strings.Mind_Usinghelp_Btn_6] = "إضافة عقدة فرعية"
stringMap [strings.Mind_Usinghelp_Btn_7] = "[حذف]"
stringMap [strings.Mind_Usinghelp_Btn_8] = "احذف العقدة الحالية (لاحظ أنه لا يمكن حذفها إذا كانت في حالة التحرير)"
stringMap [strings.Mind_Usinghelp_Btn_9] = "[Ctrl] + [E]"
stringMap [strings.Mind_Usinghelp_Btn_10] = "[الأمر] + [E]"
stringMap [strings.Mind_Usinghelp_Btn_11] = "تعديل العقدة الحالية"
stringMap[strings.Mind_Usinghelp_Btn_12] = "[Ctrl] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_13] = "[Command] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_14] = "[Ctrl] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_15] = "[Command] + [+]"
stringMap [strings.Mind_Usinghelp_Btn_16] = "تكبير الخريطة"
stringMap[strings.Mind_Usinghelp_Btn_17] = "[Ctrl] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_18] = "[Command] + [-]"
stringMap [strings.Mind_Usinghelp_Btn_19] = "خريطة ذهنية"
stringMap[strings.Mind_Usinghelp_Btn_20] = "[Ctrl] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_21] = "[Command] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_22] = "[Ctrl] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_23] = "[Command] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_24] = "[Ctrl] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_25] = "[Command] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_26] = "[Ctrl] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_27] = "[Command] + [B]"
stringMap [strings.Mind_Usinghelp_Btn_28] = "الخروج من التحرير"
//提示文字
stringMap [strings.Message_Tips_Login_Success] = "تم تسجيل الدخول بنجاح"
stringMap [strings.Message_Tips_Dlt_Success] = "الحذف بنجاح"
stringMap [strings.Message_Tips_Report_Success] = "الإبلاغ عن النجاح"
stringMap [strings.Message_Tips_Copy_Success] = "نسخ ناجح"
stringMap [strings.Message_Tips_Collction_Success] = "نجاح المجموعة"
stringMap [strings.Message_Tips_Avatar_Modification_Success] = "تم تعديل الصورة الرمزية بنجاح"
stringMap [strings.Message_Tips_Signature_Modification_Success] = "نجاح تعديل التوقيع"
stringMap [strings.Message_Tips_Name_Modification_Success] = "تم تعديل اللقب بنجاح"
stringMap [strings.Message_Tips_Modification_Success] = "التعديل ناجح"
stringMap [strings.Message_Tips_New_Profile_Success] = "أنشئ مجلدًا جديدًا بنجاح"
stringMap [strings.Message_Tips_Mobile_Success] = "نجاح الجوال"
stringMap [strings.Message_Tips_Submit_Success] = "أرسل بنجاح"
stringMap [strings.Message_Tips_Exchange_Success] = "التبادل ناجح"
stringMap [strings.Message_Tips_Please_Code] = "الرجاء إدخال رمز التفعيل"
stringMap [strings.Message_Tips_Agree_To_Terms] = "الرجاء الموافقة على سياسة الخصوصية"
stringMap [strings.Message_Tips_Please_Sign_In] = "الرجاء تسجيل الدخول"
stringMap [strings.Message_Tips_Share_Success] = "مشاركة ناجحة"
stringMap [strings.Message_Tips_Create_Success] = "تم الإنشاء بنجاح"
stringMap [strings.Message_Tips_Profile_Name_No_Null] = "لا يمكن أن يكون اسم المجلد فارغًا"
stringMap [strings.Message_Tips_Selecte_Success] = "اكتمل البحث"
stringMap [strings.Message_Tips_Subscription_Success] = "الاشتراك ناجح"
stringMap [strings.Message_Tips_Unsubscribe_Success] = "تم إلغاء الاشتراك بنجاح"
stringMap [strings.Message_Tips_Related_Content_Not_Found] = "المحتوى المرتبط غير موجود"
stringMap [strings.Message_Tips_Max_Add_Class] = "يمكنك فقط الإضافة على الأكثر"
stringMap [strings.Message_Tips_Registered_Success] = "تم التسجيل بنجاح"
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = "فشل تسجيل الدخول لمسح الرمز"
stringMap [strings.Message_Tips_Share_Is_Null] = "رابط المشاركة غير موجود"
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = "الملف الذي تم تحميله لا يمكن أن يتجاوز 3 ميجابايت"
stringMap [strings.Message_Tips_Get_Permission_Successfully] = "احصل على الإذن بنجاح"
stringMap [strings.Message_Tips_Membership_Required] = "العضوية مطلوبة"
stringMap [strings.Message_Tips_Save_Success] = "حفظ بنجاح"
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = "نجاح تسجيل الدخول إلى رمز المسح"
stringMap [strings.Message_Tips_Style] = "فئة؟"

//大纲模式
stringMap [strings.Outline_Menu_Indent] = "مسافة بادئة"
stringMap [strings.Outline_Menu_Decrease_Indent] = "تقليل المسافة البادئة"

//帮助提示
stringMap [strings.Help_Background_Content] ="خلفية الخط ذات الصلة، خلفية الخط ذات الصلة، خلفية الخط ذات الصلة، خلفية الخط ذات الصلة، خلفية الخط ذات الصلة، خلفية الخط ذات الصلة، خلفية الخط ذات الصلة، خلفية الخط ذات الصلة، خلفية الخط ذات الصلة، خط الخلفية ذات الصلة،"
stringMap [strings.Help_Mind_Content] = "يمكنك جعل خرائط تخطيط مختلفة وفقا لتفضيلاتك: خريطة المعالج المزدوج، الخريطة اليسرى، الخريطة اليمنى، خريطة المنظمة، شجرة ثنائية الاتجاه، الشجرة اليسرى، الشجرة اليمنى، الجدول"
stringMap [strings.Help_Share_Link_Content] = "يمكن مشاركة روابط المشاركة في WeChat و QQ و Weibo والقنوات الأخرى في شكل روابط ، ويمكن للأصدقاء مشاهدة المحتوى"
stringMap [strings.Using_Templates] = "استخدام القوالب"

//邀请码
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = "الرجاء إدخال رمز الدعوة"
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = "الرجاء إدخال رمز الدعوة الصحيح"
stringMap [strings.User_Lolgin_Optional] = "اختياري"

//使用素材

stringMap [strings.Cannot_Add_To_Node_Background] = "لا ينطبق على خلفية العقدة"
stringMap [strings.Cannot_Add_To_Img_Background] = "لا ينطبق على خلفية النص"
stringMap [strings.Cannot_Add_To_Mind_Background] = "لا ينطبق على خلفية خط الجزء"
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = "إضافة صورة خلفية"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = "حذف صورة الخلفية"
stringMap [strings.Mind_Add_Background_Image] = "إدراج صورة الخلفية"
stringMap [strings.Mind_Delete_Background_Image] = "حذف صورة الخلفية"
stringMap [strings.Permanent_Member] = "عضو دائم"
stringMap [strings.Insufficient_Storage_Space] = "مساحة تخزين غير كافية ، قم بترقية العضو لزيادة المساحة."
stringMap [strings.Used_Space] = "مساحة التخزين"
stringMap [strings.Current_Storage] = "التخزين الحالي:"
stringMap [strings.Used_Space_Upgrade] = "ترقية مساحة التخزين"
stringMap [strings.My_Mind_Is_Null_Tips] = "العقل + الخريطة الذهنية والملاحظات فن الإستذكار الفائق ، أضف أجنحة للتفكير ، الإنشاء ، العرض ، التخطيط ، والملاحظة"
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = "مساحة تخزين غير كافية ، يمكنك استخدام القالب عند ترقية عضويتك."

stringMap [strings.Group_Management] = "مجموعة"
stringMap [strings.Save_Img_Fill_Full] = "تمهيد ممتلئ"
stringMap [strings.Save_Img_Center_Tilt] = "Center Tilt"
stringMap [strings.Save_Img_File_Full_Dark_Color] = "كامل (غامق)"
stringMap [strings.Save_Img_Mind_Map_Watermarking] = "علامة مائية على خريطة العقل + العقل"
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = "أضف علامة مائية لحماية إنشاء المعرفة:"
stringMap [strings.Save_Img_Watermark_Text] = "نص العلامة المائية:"
stringMap [strings.Save_Img_Watermark_Logo] = "شعار العلامة المائية:"
stringMap [strings.Save_Img_Watermark_Style] = "نمط العلامة المائية:"
stringMap [strings.Save_Img_Please_Input] = "الرجاء إدخال"
stringMap [strings.Save_Img_Chop_Tips] = "يمكنك التمتع بحقوق العلامة المائية عند فتح عضو"
stringMap [strings.Public_Header_Mindmap_Mind] = "وضع الخريطة"
stringMap [strings.Copy_Mind_Map] = "نسخ الخريطة الذهنية"
stringMap [strings.Font_Style_Bold] = "غامق"
stringMap [strings.Font_Style_Italics] = "مائل"
stringMap [strings.Font_Style_Delete_Line] = "يتوسطه خط"
stringMap [strings.Remove_Any] = "إزالة"

stringMap [strings.Mindmap_Review] = "مراجعة Mindmap"
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = "تعميق الانطباع وتحسين الذاكرة وفرز الأفكار والمعلومات بكفاءة"
stringMap [strings.Mindmap_Review_Content] = "تشير مراجعة الخريطة إلى تشغيل عملية التحرير ، ويتم تشغيل المحتوى بالتسلسل من السمة المركزية ؛ يمكن أن تساعدك مراجعة الخريطة في إجراء جرد النظام والتحليل والعثور على الأفكار والثغرات. أثناء عملية المراجعة ، قد تجد مصادر إلهام مختلفة "
stringMap [strings.Mindmap_Review_Speed] = "السرعة"
stringMap [strings.Mindmap_Review_Word_Speed] = "سرعة الكلمات"
stringMap [strings.Mindmap_Review_Background_Ratio] = "نسبة الخلفية"
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = "تكبير عقدة التشغيل الحالية"
stringMap [strings.Mindmap_Review_Node_Playback_Order] = "ترتيب تشغيل العقدة"


stringMap [strings.Mind_Group_Picture_Upload] = "تحميل الصورة"
stringMap [strings.Mind_Group_Upload_From_Local] = "تحميل محلي"
stringMap [strings.Mind_Group_Mind_Mapping] = "خريطة ذهنية"
stringMap [strings.Mind_Group_Information] = "بيانات"

stringMap [strings.Mind_Group_Invite_New_Members] = "دعوة أعضاء جدد"
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = "الرجاء إدخال رقم هاتفك"
stringMap [strings.Mind_Group_Cancel] = "إلغاء"
stringMap [strings.Mind_Group_Confirm] = "موافق"
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = "لا يمكن ترك رقم الهاتف المحمول فارغًا"
stringMap [strings.Mind_Group_Invite_The_Successful] = "دعوة ناجحة"

stringMap [strings.Mind_Group_Shared_Group_Membership] = "أعضاء المجموعة المشتركة"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "الحد الأقصى لعدد أعضاء المجموعة المشتركة"
stringMap [strings.Mind_Group_Membership_Limit] = "حد العضوية"
stringMap [strings.Mind_Group_Current_Member_Limit] = "الحد الأعلى للعضو الحالي"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "ترقية الحد الأعلى للعضو"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "البحث عن أعضاء"
stringMap [strings.Mind_Group_Creator] = "المنشئ"
stringMap [strings.Mind_Group_Administrator] = "مسؤول"
stringMap [strings.Mind_Group_Number_Of_People] = "الأشخاص"
stringMap [strings.Mind_Group_Selected] = "محدد"
stringMap [strings.Mind_Group_Member] = "عضو"
stringMap [strings.Mind_Group_Check_All] = "حدد الكل"
stringMap [strings.Mind_Group_Remove] = "إزالة"
stringMap [strings.Mind_Group_Invite_New_Members] = "دعوة أعضاء جدد"
stringMap [strings.Mind_Group_Delete_Members] = "حذف الأعضاء"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "الرجاء تحديد العضو المطلوب حذفه"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "هل أنت متأكد أنك تريد إزالة المستخدم من هذه المجموعة؟"
stringMap [strings.Mind_Group_Add_As_Administrator] = "إضافة مسؤول"
stringMap [strings.Mind_Group_Accomplish] = "مكتمل"
stringMap [strings.Mind_Group_Compile] = "تحرير"
stringMap [strings.Mind_Group_Group_Owner] = "مالك المجموعة"
stringMap [strings.Mind_Group_Add_Admin] = "إضافة مسؤول"
stringMap [strings.Mind_Group_Group_ID] = "معرف المجموعة"
stringMap [strings.Mind_Group_Group_Number] = "رقم المجموعة"
stringMap [strings.Mind_Group_Name] = "الاسم"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "الرجاء إدخال اسم المجموعة المشتركة"
stringMap [strings.Mind_Group_Introduce] = "تقديم"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "الرجاء تقديم المجموعات المشتركة باختصار"
stringMap [strings.Mind_Group_Announcement] = "إعلان"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "علامة المجموعة المشتركة"
stringMap [strings.Mind_Group_Custom_Tag] = "علامة مخصصة"
stringMap [strings.Mind_Group_Save] = "حفظ"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "لا يمكن أن يكون اسم المجموعة فارغًا"
stringMap [strings.Mind_Group_Establish] = "إنشاء"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "السماح بإنشاء خرائط / مجلدات"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "السماح بتحرير الخرائط"
stringMap [strings.Mind_Group_Holder] = "الجميع"
stringMap [strings.Mind_Group_Only_The_Administrator] = "المسؤول فقط"
stringMap [strings.Mind_Group_Not_Allow] = "غير مسموح به"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "السماح بحذف الخريطة"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "السماح للمنتديات بنشر الرسائل"
stringMap [strings.Mind_Group_Export] = "تصدير"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "السماح بحفظ الصور / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "فتح علامة مائية للمجموعة"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "بعد الفتح ، ستتم طباعة شعار المجموعة على الخريطة الذهنية"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "السماح بحفظ الخرائط"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "حفظ في خريطتي"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "ما إذا كان سيتم السماح بالمشاركة في العاصفة"
stringMap [strings.Mind_Group_Join_The_Way] = "كيفية الانضمام"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "ما إذا كان سيتم السماح بدعوة أعضاء جدد"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "ما إذا كان سيتم السماح بإضافة أرقام مجموعة البحث"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "ما إذا كانت الرسالة تحتاج إلى التحقق من صحتها عند الانضمام"
stringMap [strings.Mind_Group_Shared_Group_Data] = "بيانات المجموعة المشتركة"
stringMap [strings.Mind_Group_Authority_Management] = "إدارة الصلاحيات"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "مغادرة مجموعة المشاركة"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "هل أنت متأكد من الخروج من المجموعة المشتركة؟"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "مجموعة الحل"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "سيتم حذف الملفات بعد تفكيك المجموعة. هل تريد تأكيد التفكيك؟"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "العودة إلى الصفحة الرئيسية"
stringMap [strings.Mind_Group_Join_The_Group] = "الانضمام إلى المجموعة"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "الرجاء إدخال رقم المجموعة / اسم المجموعة للبحث"
stringMap [strings.Mind_Group_Apply_To_Join] = "التقدم للانضمام"
stringMap [strings.Mind_Group_Join] = "انضمام"
stringMap [strings.Mind_Group_Verification] = "التحقق"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "الرجاء إدخال رسالة التحقق"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "لا يمكن أن يكون المحتوى فارغًا"
stringMap [strings.Mind_Group_Name] = "اسم المجموعة المشتركة"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "الرجاء إدخال اسم المجموعة المشتركة"
stringMap [strings.Mind_Group_Introduction] = "مشاركة مقدمة المجموعة"
stringMap [strings.Mind_Group_Group_Indication] = "رقم المجموعة"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Intresent_Yet] = "المنشئ كسول جدًا ، لا توجد مقدمة بعد ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "إعلان المجموعة المشتركة"
stringMap [strings.Mind_Group_No_Announcement] = "لا يوجد إعلان حتى الآن"
stringMap [strings.Mind_Group_Click_On] = "انقر للتوسيع"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "انقر للإخفاء"
stringMap [strings.Mind_Group_Altogether] = "الإجمالي"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "تم نسخ رقم المجموعة بنجاح"
stringMap [strings.Mind_Group_Stick] = "التمسك بالأعلى"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "يوجد محتوى جديد"
stringMap [strings.Mind_Group_Cancel_The_Top] = "إلغاء القمة"
stringMap [strings.Mind_Group_Setting_Up] = "إعدادات"
stringMap [strings.Mind_Group_Usinghelp] = "استخدام المساعدة"
stringMap [strings.Mind_Group_Set_The_Message] = "رسالة جماعية"
stringMap [strings.Mind_Group_Apply_To_Join] = "التقدم للانضمام"
stringMap [strings.Mind_Group_Invite_To_Join] = "دعوة للانضمام"
stringMap [strings.Mind_Group_Consent] = "موافق"
stringMap [strings.Mind_Group_Turn_Down] = "رفض"
stringMap [strings.Mind_Group_Label] = "تسمية"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "الرجاء إدخال اسم تسمية المجموعة المشتركة"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleers_To_Create_And_Share_Community] = "دعوة الأصدقاء والزملاء للمشاركة في إنشاء محتوى المعرفة ومشاركته"
stringMap [strings.Mind_Group_Immediately_Create] = "أنشئ فورًا"
stringMap [strings.Mind_Group_Immediately_To_Join] = "انضم الآن"

stringMap [strings.Currently_Selected] = "متابع حاليًا"
stringMap [strings.LabelManagement] = "إدارة التسمية"
stringMap [strings.NoAttention] = "لا اهتمام"
stringMap [strings.Mindmap_Review_Play] = "تشغيل"

stringMap [strings.Mind_Group_Joined] = "انضم"
stringMap [strings.Mind_Group_Download_App] = "تنزيل التطبيق"
stringMap [strings.Mind_Group_No_Content] = "لا يوجد محتوى حتى الآن"
stringMap [strings.Mind_Group_Individual] = "القطع"
stringMap [strings.Mind_Group_Message_Area] = "منطقة الرسائل"
stringMap [strings.Mind_Group_No_Message] = "لا توجد رسالة ، اذهب الآن"
stringMap [strings.Mind_Group_Leave_Message] = "اترك رسالة"
stringMap [strings.Mind_Group_Bar] = "شريط"
stringMap [strings.Mind_Group_See_More] = "مشاهدة المزيد"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "أدخل رمز الدعوة"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "تقدم بطلب للانضمام إلى مجموعة المشاركة"
stringMap [strings.Reasons_For_Application_0_30_Words] = "أسباب التطبيق (0-30 كلمة)"
stringMap [strings.Mind_Group_Send_Out] = "إرسال"
stringMap [strings.Mind_Group_Join_Successfully] = "انضم بنجاح"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "تم إرسال التطبيق بنجاح"

stringMap [strings.Mind_Group_Save_The_Mapping] = "حفظ الخريطة"
stringMap [strings.Mind_Group_Update_Record] = "تعديل السجل"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "تبديل الخريطة"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "مراجعة الخريطة"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "يقوم مستخدم حالي بتحرير الخريطة"
stringMap [strings.Mind_Edit_Page_Password] = "كلمة المرور"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "مجموعة المشاركة"
stringMap [strings.Mind_MBean] = "ميغابايت"
stringMap [strings.Mind_My_MBean] = "مجموعتي MBean"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Alipay Payment"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "يرجى الذهاب إلى صفحة الدفع للدفع"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "إجمالي"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "تأكيد الدفع"
stringMap [strings.Mind_Shooping_Redeem_Now] = "استرداد الآن"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "ما إذا كان سيتم تأكيد الدفع مقابل الشراء"
stringMap [strings.Mind_Tips] = "تلميحات"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "حبوب M الخاصة بك غير كافية ، هل تريد شراء M Beans"

stringMap [strings.Mind_share_scan_code] = "Mind + Scan code"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "هل تريد الاستعادة إلى آخر مكان تم تشغيله؟"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "أوقات اللعب المتبقية"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "سيتم حذف الملف بالكامل ، هل أنت متأكد من حله؟"
stringMap [strings.Group_Mind_Up_One_Level] = "أعلى مستوى واحد"
stringMap [strings.Group_Mind_No_Documents] = "لا توجد ملفات بعد"
stringMap [strings.Group_Mind_PaymentSuccessful] = "تم الدفع بنجاح"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "التحرير التعاوني للأشخاص ، والمزامنة في الوقت الفعلي متعددة الأطراف ، ومشاركة المستندات ، وتحسين كفاءة تعاون الفريق"

stringMap [strings.Mind_Edit_Subscript] = "منخفض"
stringMap [strings.Mind_Edit_Text_Location] = "موقع النص"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "استيراد إلى مجموعة مشتركة"

stringMap [strings.Standard_Colors] = "ألوان قياسية"
stringMap [strings.More_Colors] = "المزيد من الألوان"
stringMap [strings.Reset_Password] = "إعادة تعيين كلمة المرور"
stringMap [strings.Account_Password_Login] = "تسجيل الدخول إلى كلمة مرور الحساب"
stringMap [strings.Authentication_code_Login_registration] = "تسجيل دخول / تسجيل رمز التحقق"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "إنشاء طرف واحد ، مزامنة متعددة الأطراف"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "بنية خرائط غنية ومتنوعة"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "مكتبة قوالب رائعة ضخمة"
stringMap [strings.Map_Review_Play] = "تشغيل مراجعة الخريطة"
stringMap [strings.CommunityLearningAndSharingPlatform] = "التعلم بالمعرفة ومشاركة النظام الأساسي للتبادل"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "ترقية مساحة التخزين"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "دعني أعلق عليها أيضًا"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Annopped_At_This_Time] = "لا يوجد إشعار برسالة حتى الآن"

stringMap [strings.Failed_To_Get_Recording_Device] = "فشل الحصول على جهاز التسجيل"

stringMap [strings.Search_Pagination] = "ترقيم صفحات البحث"
stringMap [strings.Please_Enter_A_Paging_Name] = "الرجاء إدخال اسم الصفحة"
stringMap [strings.Operation_Settings] = "إعدادات العملية"
stringMap [strings.Shortcut_key_Settings] = "إعدادات مفتاح الاختصار"
stringMap [strings.Press_Key_Combination] = "الرجاء الضغط على مجموعة المفاتيح المطلوبة"
stringMap [strings.Mind_Reduction] = "اختزال"
stringMap [strings.Double_Click_Add_Free_Node] = "انقر نقرًا مزدوجًا فوق المساحة الفارغة لإضافة عقدة مجانية"
stringMap [strings.Shortcut_Conflict] = "تعارض مفتاح الاختصار"
stringMap [strings.Select_Main_Node] = "حدد العقدة الرئيسية"
stringMap [strings.Telephone] = "الهاتف"
stringMap [strings.Microblog] = "مدونة مصغرة"
stringMap [strings.Address] = "العنوان"
stringMap [strings.Company_Address] = "Block B ، المبنى 21 ، رقم 1158 Zhongxin Road ، مقاطعة Songjiang ، شنغهاي (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "أرشيف"
stringMap [strings.Mind_Bookshelf] = "Bookshelf"
stringMap [strings.Search_Archive] = "بحث في Bookshelf"
stringMap [strings.Modify_Archive_Order] = "تعديل ترتيب رف الكتب"
stringMap [strings.Modification_Order] = "أمر التعديل"
stringMap [strings.Please_Enter_Archive_Name] = "الرجاء إدخال اسم رف الكتب"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "اسم رف الكتب لا يمكن أن يكون فارغًا"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "هل أنت متأكد أنك تريد حذف رف الكتب هذا؟"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "هل أنت متأكد أنك تريد حذف مكتبة الكتب هذه؟"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "هل أنت متأكد أنك تريد حذف هذه الخريطة؟"
stringMap [strings.Mind_Edit_Confirm] = "تأكيد"
stringMap [strings.Mind_Import_Map] = "خريطة الاستيراد"
stringMap [strings.Mind_Bookcase] = "مكتبة"
stringMap [strings. added_Successfully] = "تمت الإضافة بنجاح"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "اسم خزانة الكتب لا يمكن أن يكون فارغًا"

stringMap [strings.Mind_Association_Map] = "خريطة الاقتران"
stringMap [strings.Mind_confirm_Association_Map] = "هل أنت متأكد من إقران هذه الخريطة؟"
stringMap [strings.Mind_Association_Map_See] = "عرض خريطة الاقتران"
stringMap [strings.Mind_Association_Map_Delete] = "حذف خريطة الاقتران"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "بنية خط الارتباط"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "بداية خط الارتباط"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "نهاية خط الارتباط"

stringMap [strings.Share_Group_To_Storm] = "ما إذا كنت تريد مشاركة المجموعة في العاصفة"
stringMap [strings.Apply_To_Join_Storm_Platform] = "في منصة العاصفة ، يرى الأشخاص الآخرون ويتقدمون للانضمام ، وتبادل الأفكار مع المتحمسين ذوي المعرفة العامة"
stringMap [strings.Pay_To_Goin] = "ادفع للانضمام"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "تحتاج حبوب M إلى الدفع عند الانضمام"
stringMap [strings.Mbean_Can_Be_Realized] = "Mbean Realized"

stringMap [strings.Mind_Visit] = "زيارة"
stringMap [strings.Mind_Details] = "التفاصيل"
stringMap [strings.Mind_See] = "عرض"
stringMap [strings.Earn_Mbeans] = "كسب مبينز"
stringMap [strings.Mind_Explain] = "الوصف"
stringMap [strings.Mind_Parenting] = "الأبوة والأمومة"
stringMap [strings.Mind_Internet] = "الإنترنت"
stringMap [strings.Mind_Psychology] = "علم النفس"
stringMap [strings.Mind_literature] = "أدب"
stringMap [strings.Mind_Technology] = "تقنية"
stringMap [strings.Mind_Workplace] = "مكان العمل"
stringMap [strings.Mind_Life] = "الحياة"
stringMap [strings.Mind_Subject] = "الموضوع"
stringMap [strings.Hot_Group] = "مجموعة فعالة"
stringMap [strings.Open_Group] = "فتح المجموعة"
stringMap [strings.Mind_Group_Used] = "مستعملة"

stringMap [strings.Mind_Click_Select] = "انقر للتحديد"
stringMap [strings.Mind_Double_Click_Enter] = "انقر نقرًا مزدوجًا للدخول"
stringMap [strings.Search_Shared_Groups] = "بحث عن المجموعات المشتركة"
stringMap [strings.Search_Storm] = "عاصفة البحث"
stringMap [strings.Members_Save_Free] = "حفظ الأعضاء مجانًا"
stringMap [strings.Bank_Card_Account_Name] = "الرجاء إدخال اسم حساب بطاقتك المصرفية"
stringMap [strings.Bank_Card_No] = "رقم البطاقة المصرفية"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "الرجاء إدخال رقم بطاقتك المصرفية"
stringMap [strings.Branch_Sub_Branch_Information] = "معلومات الفرع الفرع"
stringMap [strings.Branch_Sub_Branch_Example] = "على سبيل المثال: فرع Hangzhou فرع Chengxi الفرعي"
stringMap [strings.Application_For_Withdrawal] = "طلب سحب"
stringMap [strings.Withdrawal_Amount] = "مبلغ السحب"
stringMap [strings.Withdrawal] = "سحب"
stringMap [strings.Current_Revenue] = "الإيرادات الحالية"
stringMap [strings.Recharge] = "إعادة الشحن"
stringMap [strings.Current_Total_MBeans] = "العدد الإجمالي الحالي للميغابايت"
stringMap [strings.Withdrawal_Status] = "حالة السحب"
stringMap [strings.Insufficient_Withdrawal_Amount] = "مبلغ السحب غير كافٍ"
stringMap [strings.Load_More] = "تحميل المزيد"
stringMap [strings.Loading] = "تحميل"
stringMap [strings.It_Is_Over] = "انتهى الأمر"
stringMap [strings.Default_Label] = "التسمية الافتراضية"
stringMap [strings.Selected_Label] = "التسمية المحددة"
stringMap [strings.Current_Location] = "الموقع الحالي"
stringMap [strings.MGold_Introduction] = "كسبت الذهب M من خلال العقل +"
stringMap [strings.MGold_Details] = "مجموعة السحابة المدفوعة الخاصة بك ، توجد خرائط معرفية ومواد تعليمية وما إلى ذلك في المجموعة. إذا انضم المستخدمون إلى وحدات M للدفع ، فسيتم تحويل 50٪ منهم إلى M ذهب و M ذهب أكبر يمكن سحب أكثر من 50 "
stringMap [strings.Cloud_Group_Introduction] = "شارك مجموعتك ، يمكن للآخرين مشاهدتها على صفحة العاصفة"
stringMap [strings.Cloud_Group_Details] = "مجموعة السحابة المدفوعة الخاصة بك. توجد خرائط معرفية ومواد تعليمية وما إلى ذلك في المجموعة. إذا انضم المستخدمون إلى حبوب M للدفع ، فسيتم تحويل 50٪ منهم إلى M ذهب و M ذهب أكبر يمكن استخراج أكثر من 50 "
stringMap [strings.Share_Storm_Introduction] = "شارك خريطتك مع مجتمع العاصفة ، حتى يتمكن المزيد من الأشخاص من التعلم واستكشاف المعرفة منك"
stringMap [strings.Share_Storm_Details] = "إذا قمت بتمكين استخدام حبوب M لحفظ الخرائط ، يمكن للمستخدمين الآخرين استخدام خريطة المعرفة الخاصة بك ، وستحصل أيضًا على 50٪ من حبوب M. يمكن استخدام حبوب M لشراء الأعضاء والانضمام إلى مدفوعات المجموعات ، يمكنك أيضًا الدفع مقابل استخدام خرائط الأشخاص الآخرين "

stringMap [strings.Linked_Successfully] = "تم الارتباط بنجاح"
stringMap [strings.Cancel_Archiving] = "إلغاء الأرشفة"
stringMap [strings.Please_Select_Archive_Location] = "الرجاء تحديد موقع الأرشيف"
stringMap [strings.The_Search_Result_Is_Empty] = "نتائج البحث فارغة"

stringMap [strings.Customize_A_Style] = "يمكن لغير الأعضاء تخصيص نمط واحد فقط"
stringMap [strings.Adds_Current_Guide_Style_Library] = "أضف نمط الخريطة الحالي إلى مكتبة الأنماط"
stringMap [strings.Trial_Version] = "إصدار تجريبي"
stringMap [strings.Not_Supported_At_The_Moment] = "غير مدعوم حاليًا"
stringMap [strings.Mind_File] = "ملف"
stringMap [strings.Mind_Import] = "استيراد"

stringMap [strings.Mind_Edit_Insert_Video] = "إدراج فيديو"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "اسحب الفيديو وأفلته هنا"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "مدة تحميل الفيديو لا يمكن أن تتجاوز 60 ثانية"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "عرض الفيديو"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "حذف الفيديو"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "الفيديو الذي تم تحميله لا يمكن أن يتجاوز 10 ميجابايت"
stringMap [strings.Mind_Upload_Video_Restrictions] = "يدعم فقط تحميل مقاطع الفيديو بتنسيق MP4 ، حتى 120 ثانية و 10 ميجا في الحجم"
stringMap [strings.Mind_Video_Size] = "حجم الفيديو"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "تصدير كلمة"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "تصدير TXT"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "سحابة التخزين"
stringMap[strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "غير متزامن"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_As] = "حفظ"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "مجموعة مشتركة لا تدعم حفظ باسم ، يمكنك حفظ الملف إلى [ خلق ] مؤخرا ، ثم حفظ إلى المحلية"
stringMap [strings.Export_Failed] = "فشل التصدير"
stringMap [strings.Add_File] = "إضافة ملف"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "اسحب الملف مباشرة لتحميله"
stringMap [strings.Supported_Imported_File_Types] = "أنواع الملفات المدعومة حاليًا للاستيراد: Word (docx. فقط) و XMind و .txt"

stringMap [strings.Upgrade_To_Professional] = "الترقية إلى الإصدار الاحترافي"
stringMap [strings.Non_Members_Insert_Videos] = "يمكن لغير الأعضاء إدراج مقاطع الفيديو فقط بعد 24 ساعة من التسجيل"
stringMap [strings.Upload_Failed] = "فشل التحميل"
stringMap [strings.Upload_Failed_Title_Message] = "فشل التحميل ، يرجى النقر للتأكيد ، وحذف الصور المتبقية التي فشل التحميل"

stringMap [strings.Mind_Edit_Members_Use] = "استخدام الأعضاء"
stringMap [strings.Invitation_Code] = "رمز الدعوة"
stringMap [strings.Student_Certification] = "شهادة الطالب"
stringMap [strings.I_Get_It] = "سأتخطى الدليل"
stringMap [strings.Welcome_To_Mind] = "مرحبًا بالسيد الشاب للانضمام إلى Mind + ، فلنبدأ رحلة التفكير!"
stringMap [strings.Mind_Before] = "الأول"
stringMap [strings.Try_A_Map] = "جرب الخريطة"
stringMap [strings.Click_Create] = "انقر للإنشاء"
stringMap [strings.Click_Create_A_Mind_Map] = "انقر لإنشاء خريطة ذهنية"
stringMap [strings.Mind_About] = "حول"
stringMap [strings.See_Checking_Data] = "عرض المعلومات الشخصية"
stringMap [strings.Modify_Checking_Data] = "تعديل المعلومات الشخصية"
stringMap [strings.Mind_Shear] = "قص"

stringMap [strings.Mind_Style_Empty] = "لا يوجد نمط مفضل أو مخصص"
stringMap [strings.Mind_Branch_Free_Layout] = " فرع الحري "
stringMap [strings.Mind_Branch_Free_Layout_explain] = "فرع يمكن سحبها بحرية"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "موضوع السحب والمفاجأة مجانا"

stringMap [strings.Select_A_Node] = "حدد موضوعًا"
stringMap [strings.Create_Child_Node] = "إنشاء مظهر فرعي"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "بعد ذلك سنضيف واحدة لطيفة إلى المظهر الفرعي"
stringMap [strings.Click_Icon_Panel] = "انقر فوق لوحة الرموز"
stringMap [strings.Select_An_Icon_You_Like] = "حدد أيقونة تريدها"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "بعد ذلك سنستبدل الخريطة الذهنية بخريطة جديدة"
stringMap [strings.Select_The_Central_Theme] = "حدد المظهر المركزي"
stringMap [strings.Switch_To_Canvas_Panel] = "قم بالتبديل إلى لوحة القماش"
stringMap [strings.Click_Layout] = "انقر للتخطيط"
stringMap [strings.Choose_A_Layout_You_Like] = "اختر تنسيقًا يعجبك"
stringMap [strings.Mind_Map_Save_Share] = "تهانينا ، لقد نجحت في إنشاء خريطة ذهنية جميلة! دعنا نحفظها ونشاركها مع أصدقائك ~"
stringMap [strings.Click_Save_Picture] = "انقر لحفظ الصورة"
stringMap [strings.Click_OK] = "انقر فوق موافق"
stringMap [strings.Condratulations_On_Completing_The_Novice_Guide] = "تهانينا على إكمال دليل المبتدئين ~"
stringMap [strings.Upload_Succeeded] = "تم التحميل بنجاح"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "أسلوب سريع"
stringMap [strings.Mind_Extremely_Important] = "مهم للغاية"
stringMap [strings.Mind_Important] = "هام"
stringMap [strings.Mind_Commonly] = "عام"
stringMap [strings.Mind_Ordinary] = "عادي"
stringMap [strings.Member_Exclusive] = "هذه الميزة خاصة بالأعضاء. هل تريد ترقية عضويتك؟"
stringMap [strings.Member_Exclusive_Color_Scheme] = "نظام الألوان هذا خاص بالأعضاء. هل تريد ترقية عضويتك؟"
stringMap [strings.Mind_Enlarge] = "تكبير"
stringMap [strings.Mind_Narrow] = "ضيق"
stringMap [strings.Mind_Center] = "توسيط"
stringMap [strings.Mind_Full_Screen] = "ملء الشاشة"

stringMap [strings.Mind_Association_Node] = "عقدة الاقتران"
stringMap [strings.Mind_Version_Number] = "رقم الإصدار"
stringMap [strings.Mind_Copy_Rights] = "حقوق النشر © لعام 2021 لشركة Shanghai Yushu Technology Co.، Ltd. جميع الحقوق محفوظة."

stringMap [strings.Global_Edit_Task] = "تحرير المهمة"
stringMap [strings.Global_Detele_Task] = "مهمة الكشف"
stringMap [strings.Mind_Extremely_Urgent] = "عاجل جدًا"
stringMap [strings.Mind_Urgent] = "عاجل"
stringMap [strings.Mind_Lower] = "أقل"
stringMap [strings.Mind_Task_Information] = "معلومات المهمة"
stringMap [strings.Mind_Start_Date] = "تاريخ البدء"
stringMap [strings.Mind_End_Date] = "تاريخ الانتهاء"
stringMap [strings.Mind_person_Charge] = "الشخص التنفيذي"
stringMap [strings.Mind_colse] = "إغلاق"
stringMap [strings.Mind_Enter_Names_Separated_By_Commas] = "أدخل الأسماء ، افصل الأسماء المتعددة بفاصلات"
stringMap [strings.Mind_Please_Select_An_Start_Date] = "الرجاء تحديد تاريخ البدء"
stringMap [strings.Mind_Please_Select_An_End_Date] = "الرجاء تحديد تاريخ الانتهاء"
stringMap [strings.Mind_The_Task_Leader_Cannot_Be_Empty] = "إضافة منفذ"
stringMap [strings.Mind_The_End_Time_Less_Start_Time] = "لا يمكن أن يكون وقت الانتهاء أقل من وقت البدء"
stringMap [strings.Mind_Are_You_Sure_To_Delete_This_Task] = "هل أنت متأكد من حذف هذه المهمة؟"
stringMap [strings.Mind_Start_Later] = "البدء لاحقًا"
stringMap [strings.Mind_Over] = "انتهى"
stringMap [strings.Mind_In_Progress_Remaining] = "قيد التقدم ... المتبقي"
stringMap [strings.Mind_In_Progress_Time] = "قيد التقدم ... في الوقت المناسب"
stringMap [strings.Mind_level] = "مستوى الإكمال"
stringMap [strings.Mind_Completed] = "مكتمل"
stringMap [strings.Display_Mode] = "وضع العرض"
stringMap [strings.Default_Sort] = "الترتيب الافتراضي"
stringMap [strings.Default_Sort] = "الترتيب الافتراضي"
stringMap [strings.Edit_Time] = "تعديل الوقت"

stringMap [strings.Mind_Year] = "."
stringMap [strings.Mind_Month] = "."
stringMap [strings.Mind_Day] = " "
stringMap [strings.Mind_Day_Day] = "اليوم."
stringMap [strings.Mind_Hours] = "ساعة ."
stringMap [strings.Mind_Minutes] = "دقيقة ."

stringMap[strings.Mind_SubjectLine_Space] = "عرضية فرع"
stringMap[strings.Mind_SonSubjectLine_Space] = "مستعر فرع"
stringMap [strings.Mind_SubjectLine_H] = "فرع عرضية"
stringMap [strings.Mind_SubjectLine_Vertical_Space] = "فرع عمودي"
stringMap [strings.Mind_SonSubjectLine_H] = "فرع مستعرض"
stringMap [strings.Mind_SonSubjectLine_Vertical_Space] = "فرع عمودي"


stringMap [strings.Mind_Edit_Latex] = "تحرير LaTeX"
stringMap [strings.Mind_Detele_Latex] = "حذف LaTeX"
stringMap [strings.Mind_Are_You_Sure_To_Delete_This_LaTeX] = "هل أنت متأكد أنك تريد حذف صيغة LaTeX"
stringMap [strings.Mind_LaTeX_Expression] = "تعبير LaTeX"
stringMap [strings.Mind_LaTeX_Formula_Display_Area] = "منطقة عرض الصيغة"

stringMap [strings.Mind_LaTeX_Add_Template] = "إضافة إلى نموذج"
stringMap [strings.Mind_LaTeX_Common_Template] = "قالب عام"
stringMap [strings.Mind_LaTeX_Used_Help_1] = "وظائف LaTeX مقسمة إلى أربع وحدات ، وهي"
stringMap [strings.Mind_LaTeX_Used_Help_1_1] = "إنشاء منطقة الصيغة رسومات الصيغة المقابلة بناءً على التعبيرات"
stringMap [strings.Mind_LaTeX_Used_Help_1_2] = "منطقة التعبير - أدخل تعبير LaTeX"
stringMap [strings.Mind_LaTeX_Used_Help_1_3] = "قيم سلسلة منطقة الرمز المقابلة للرموز في الحقول المشتركة"
stringMap [strings.Mind_LaTeX_Used_Help_1_4] = "القوالب شائعة الاستخدام - بعض الصيغ الافتراضية ، يمكنك تخصيص الصيغ من خلال' إضافة إلى القال'، ويمكنك حذف الصيغ المخصصة المحددة عن طريق تحريك الماوس."
stringMap [strings.Mind_LaTeX_Used_Help_2] = "يجب إنشاء رسومات الصيغة وحفظها واستخدامها وفقًا لقواعد القواعد النحوية الصحيحة لـ LaTeX"
stringMap [strings.Mind_LaTeX_Used_Help_3] = "كيف تعدل اللون؟"
stringMap [strings.Mind_LaTeX_Used_Help_3_1] = "حدد منطقة النص المحددة وانقر فوق اللون في رمز النمط لتعديل لون النص ،"
stringMap [strings.Mind_LaTeX_Used_Help_3_2] = "على سبيل المثال ، لتلوين a + b في x = a + b ، ما عليك سوى تحديد a + b والنقر فوق كتلة اللون في رمز النمط ،"
stringMap [strings.Mind_LaTeX_Used_Help_3_3] = "في هذا الوقت سيصبح التعبير x = {\\ color {# FF0000} a} يتغير a إلى a + b coloring"
stringMap [strings.Mind_LaTeX_Quick_Title_2] = "أحرف يونانية"
stringMap [strings.Mind_LaTeX_Quick_Title_3] = "الرياضيات"
stringMap [strings.Mind_LaTeX_Quick_Title_4] = "رمز النمط"
stringMap [strings.Mind_LaTeX_Quick_Title_5] = "فاصل"
stringMap [strings.Mind_LaTeX_Quick_Title_6] = "حرف متغير"
stringMap [strings.Mind_LaTeX_Quick_Title_7] = "الوظيفة"
stringMap [strings.Mind_LaTeX_Quick_Title_8] = "رمز العلاقة"
stringMap [strings.Mind_LaTeX_Quick_Title_9] = "رمز السهم"
stringMap [strings.Mind_LaTeX_Quick_Title_10] = "أحرف متنوعة"
stringMap [strings.Mind_LaTeX_Quick_Title_11] = "تمييز خطير"
stringMap [strings.Mind_LaTeX_Error] = "خطأ في صيغة لاتكس ، يرجى التأكد من صحة الصيغة"

stringMap [strings.Mind_Resource_See] = "عرض المرفق"
stringMap [strings.Mind_Resource_Delete] = "حذف المرفقات"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "اسحب الملفات وأفلتها هنا"
stringMap [strings.Mind_Edit_choose_explain] = "اختر ملفًا"
stringMap [strings.Message_Tips_Share_Explain_Does_Not_Exist] = "الملف الذي تم تحميله لا يمكن أن يتجاوز 20 ميجابايت"

stringMap[strings.Please_Enter_Mind_Password] = "الرجاء إدخال 4-8 بت كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "الرجاء إدخال كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "هذه الوظيفة هي وظيفة الأعضاء"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "ملف كلمة السر قد أزيلت"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "ليس هناك ما يكفي من أرقام كلمة السر ، الرجاء إدخال 4 إلى 8 أرقام كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "كلمة السر طويلة جدا ، الرجاء إدخال 4 عكس 8 بت كلمة السر"
stringMap[strings.Please_Enter_Mind_Password_Success] = "تعيين كلمة المرور بنجاح"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "شكل كلمة السر خطأ"
stringMap[strings.Mind_Load_Data_Error] = "تحميل البيانات خطأ"
stringMap[strings.Please_Enter_Mind_Password_Error] = "كلمة السر خطأ"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "خريطة الخالق فقط يمكن تعيين كلمة السر"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'

stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
stringMap[strings.Global_Text_Find] = "Find"
stringMap[strings.Global_Text_Replace] = "Replace"
stringMap[strings.Global_Text_Replace_All] = "Replace All"
stringMap[strings.Global_Text_Find_Empty] = "No Content"
stringMap[strings.Global_Text_Find_Result] = "Item & of @"
stringMap[strings.Mind_Fast_Style_Rainbow_Label] = "Life"
stringMap[strings.Mind_Fast_Style_Solid_Label] = "Pure"
stringMap[strings.Mind_Import_File] = "Import File"

stringMap[strings.Mind_Add_Font_Label] = "+ Add local font:ttf、woff..."
stringMap[strings.Mind_Import_Font_Web_Tips] = "Please download the computer client from the official website to use this feature，https://mindyushu.com"
stringMap[strings.Mind_Import_Font_Upgrade_Label] = "The free trial version can only add one external font. Upgrading to Pro users can add more external fonts and all professional version features."

stringMap[strings.Mind_Flow_Chart_Map] = "Flow Chart"
stringMap[strings.Mind_Shape_Corner_Text] = "Process"
stringMap[strings.Mind_Shape_Start_Text] = "Start"
stringMap[strings.Mind_Shape_End_Text] = "End"
stringMap[strings.Mind_Shape_Determine_Text] = "Judging"
stringMap[strings.Mind_Shape_Ellipse_3D_Text] = "Database"
stringMap[strings.Mind_Shape_Cube_Text] = "Science"
stringMap[strings.Mind_Shape_Form_Text] = "Document"
stringMap[strings.Mind_Shape_Corner_Right_Text] = "Card"
stringMap[strings.Mind_Shape_Book_Text] = "Book"
stringMap[strings.Mind_Shape_Text] = "Text"

module.exports = stringMap;
