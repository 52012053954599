const { default: strings } = require("./strings");

var stringMap={};
stringMap[strings.About_App_Name] = 'Mind+マインドマッピング';
stringMap[strings.TEMPLATE] = 'テンプレート';
stringMap[strings.Mind_Edit_Main_Topic] = 'ブランチトピック';
stringMap[strings.Global_Add_Free_Node_Tips] = '画面をクリックして、追加する場所を選択します';
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = '画面をダブルタップして、追加する場所を選択します';
stringMap[strings.Global_Add_Relationship_Tips] = '関連付ける必要のあるターゲットを選択します';
stringMap[strings.Global_Add_Target_Tips] = 'オブジェクトを追加するノードを選択してください';
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'このオブジェクトはすでに他のマップに関連付けられています';
stringMap[strings.Mind_Edit_Main_Idea] = '中心テーマ';
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = '関連するマップが存在しません';
stringMap[strings.Mind_Style_Add_Icon_Priority] = '優先';
stringMap[strings.Mind_Style_Add_Icon_Progress] = 'スケジュール';
stringMap[strings.Mind_Style_Add_Icon_Stars] = '星';
stringMap[strings.Mind_Style_Add_Icon_Week] = '週間';
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = 'アバター';
stringMap[strings.Establish] = '作成する';
stringMap[strings.Create_From_Template] = 'テンプレートから作成';
stringMap[strings.Common] = 'ユニバーサル';
stringMap[strings.Function_List] = '機能リスト';
stringMap[strings.Recent_Updates] = '最近の更新';
stringMap[strings.The_Latest_To_Delete] = '最近削除';
stringMap[strings.Setting_Up] = 'お問い合わせ';
stringMap[strings.Usinghelp] = '操作ガイド';
stringMap[strings.The_Download_Client] = 'クライアント/モバイル版をダウンロード';
stringMap[strings.Global_Purchase] = '購入';
stringMap[strings.Global_Login] = 'ログインする';
stringMap[strings.My_map] = '私の地図';
stringMap[strings.Create_Map] = '地図を作成する';
stringMap[strings.Storm] = '知識';
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = '共通の\ n機能';
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = '特徴';
stringMap[strings.Mind_Organization_Edit_Main_Idea] = '組織センター';
stringMap[strings.Mind_Edit_Free_Topic] = '無料テーマ';
stringMap[strings.Mind_Edit_Sub_Topic] = 'サブトピック';
stringMap[strings.Mind_Organization_Main_Topic] = '部門';
stringMap[strings.Mind_Edit_Main_Topic] = 'ブランチトピック';
stringMap[strings.Mind_Time_Edit_Main_Idea] = '計画管理';
stringMap[strings.Mind_Time_Edit_Head] = '時間';
stringMap[strings.Mind_Time_Edit_Title] = '予定';
stringMap[strings.Mind_Edit_Details] = '詳細な説明';
stringMap[strings.Checking_Data] = 'データの確認';
stringMap[strings.Setting_Information] = '設定情報';
stringMap[strings.Sign_Out] = 'サインアウト';

//功能列表
stringMap[strings.Create_a_Mind_Map] = 'マインドマップを作成する';
stringMap[strings.Create_Mind_Map_1] = '【1】14のレイアウトマップを作成します（双方向/右/左/組織構造/シングルバブルチャート/ダブルバブルチャート/左ツリーチャート/右ツリーチャート/双方向ツリーチャート/テーブル/水平タイムライン/垂直タイムライン/ Sタイムライン/ピラミッド）';
stringMap[strings.Create_Mind_Map_2] = '【2】テンプレートを使用して、マップをすばやく作成できます（多数のテンプレートコンテンツ参照）';
stringMap[strings.Create_Mind_Map_3] = '【3】マップ上の9種類の線種を編集する';
stringMap[strings.Create_Mind_Map_4] = '【4】マップへの9つのレイアウト変更を編集します';
stringMap[strings.Create_Mind_Map_5] = '【5】ターゲットノードに子ノードを追加し、同じレベル（上位と下位）にノードを追加します';
stringMap[strings.Create_Mind_Map_6] = '【6】概要：シングルノード/マルチノードの追加について説明する[概要]';
stringMap[strings.Create_Mind_Map_7] = '【7】ブロッキング：モジュール化のために、マップの単一ノード/複数ノードボックスに[ブロッキング]を追加します      ';
stringMap[strings.Create_Mind_Map_8] = '【8】フリーノードを追加する';
stringMap[strings.Create_Mind_Map_9] = '【9】任意のノード間に4つの線種の関連付けを作成します';
stringMap[strings.Create_Mind_Map_10] = '【10】写真（ローカル+マテリアルライブラリ）、アイコン、メモ、ノードへのリンクを挿入します';
stringMap[strings.Create_Mind_Map_11] = '【11】ノードのコピー/貼り付け、スタイルのコピー/貼り付け';
stringMap[strings.Create_Mind_Map_12] = '【12】取り消す，やり直し';
stringMap[strings.Create_Mind_Map_13] = '【13】複数選択、複数ノードの同期操作';
stringMap[strings.Create_Mind_Map_14] = '【14】挿入可能な統計グラフ';
stringMap[strings.Create_Mind_Map_15] = '【15】地図全体を記録することができます';
stringMap[strings.Create_Mind_Map_16] = '【16】地図をコピーする';
stringMap[strings.Create_Mind_Map_17] = '【17】マップまたはマップ全体を他のファイルのマップにコピーできます';
stringMap[strings.Create_Mind_Map_18] = '【18】要約後にマップを作成できます';
stringMap[strings.Create_Mind_Map_19] = '【19】編集中に地図編集を切り替えることができます（电脑端）';
stringMap[strings.Create_Mind_Map_20] = '【20】Bluetoothキーボード編集マップ：\ n \ t [コマンド] + [a]メインノードを選択\ n \ t [コマンド] + [←] [↑] [→] [↓]選択したターゲットを変更するか、マップを移動します\ n \ t [コマンド] + [e]ターゲットの編集\ n \ t [コマンド] + [w]子ノードの作成\ n \ t [コマンド] + [s]ターゲットの兄弟ノードの作成（上）\ n \ t [コマンド] + [d]ターゲット兄弟ノードを作成します（下）\ n \ t [コマンド] + [q]選択したターゲットを終了します\ n \ t [コマンド] + [-] [+]ズームマップ';

stringMap[strings.Beautify_The_Mind_Map] = 'マインドマップを美しくする';
stringMap[strings.Beautify_The_Mind_Map_1] = '【1】マインドマップの背景色を設定する';
stringMap[strings.Beautify_The_Mind_Map_2] = '【2】テーパーの有無にかかわらず、マップライン全体の太さと色を設定します';
stringMap[strings.Beautify_The_Mind_Map_3] = '【3】テキストの塗りつぶしの色、境界線の色、境界線の13の形状、境界線の幅、影の効果を設定します';
stringMap[strings.Beautify_The_Mind_Map_4] = '【4】マップの全部または一部の色と幅を設定します';
stringMap[strings.Beautify_The_Mind_Map_5] = '【5】テキストのフォントサイズを設定し、太字、斜体、スタイルを削除します';
stringMap[strings.Beautify_The_Mind_Map_6] = '【6】画像のサイズ、位置、形状のスタイルを設定します';
stringMap[strings.Beautify_The_Mind_Map_7] = '【7】アイコンの7つのカテゴリ（一般/ 3D /研究/仕事/人/動物/食べ物）を追加し、サイズ/位置を設定します';
stringMap[strings.Beautify_The_Mind_Map_8] = '【8】マップ全体にワンクリックスタイルの参照を追加する';
stringMap[strings.Beautify_The_Mind_Map_9] = '【9】グループ管理マップ';
stringMap[strings.Beautify_The_Mind_Map_10] = '【10】パーソナライズされた背景（マテリアルライブラリ）をマップ全体に追加できます';
stringMap[strings.Beautify_The_Mind_Map_9] = '【11】パーソナライズされた背景（マテリアルライブラリ）をマップ全体に追加できます';
stringMap[strings.Beautify_The_Mind_Map_9] = '【12】ノードの長さはドラッグできます';


stringMap[strings.Map_Output] = 'マップ出力';
stringMap[strings.Map_Output_1] = '【1】画像を保存する';
stringMap[strings.Map_Output_2] = '【2】PDFを保存';
stringMap[strings.Map_Output_3] = '【3】共有リンク';
stringMap[strings.Map_Output_4] = '【4】嵐に分かち合う';
stringMap[strings.Map_Output_4] = '【5】印刷';

stringMap[strings.Work_together] = '共に働く';
stringMap[strings.Work_together_1] = '【1】共有グループを作成する（グループラベル、グループアナウンス）';
stringMap[strings.Work_together_2] = '【2】共有グループのメンバーを追加する';
stringMap[strings.Work_together_3] = '【3】グループ内の複数のメンバーが同じマップを一緒に編集します';
stringMap[strings.Work_together_4] = '【4】グループ内の共有ファイルをアップロードする（word / excel / pdf）';
stringMap[strings.Work_together_5] = '【5】グループにメッセージを残すことができます';
stringMap[strings.Work_together_6] = '【6】共有グループ管理のアクセス許可の設定';
stringMap[strings.Work_together_7] = '【7】解散グループ';

stringMap[strings.Work_together] = '知識共有（ストーム）';
stringMap[strings.Community_Sharing_1] = '【1】より多くの人が見ることができるように、あなたは嵐にあなたの創造物を共有することができます';
stringMap[strings.Community_Sharing_1] = '【2】タグを選択して、関連するストームコンテンツを表示できます';
stringMap[strings.Community_Sharing_1] = '【3】ストームワークスにいいねとコメントができます';

//最近更新
stringMap[strings.Recently_Updated_Title_1] = '個人アカウントを作成する';
stringMap[strings.Recently_Updated_Title_2] = 'マステンプレートナレッジベース';
stringMap[strings.Recently_Updated_Title_3] = '複数のプラットフォームをサポート';
stringMap[strings.Recently_Updated_Title_4] = 'すべての詳細をカスタマイズする';
stringMap[strings.Recently_Updated_Title_5] = 'パーソナライズされたスタイルスタイルライブラリ';
stringMap[strings.Recently_Updated_Title_6] = 'マップ出力';
stringMap[strings.Recently_Updated_Title_7] = 'クラウドストレージ';
stringMap[strings.Recently_Updated_Title_8] = '15種類の地図';


stringMap[strings.Recently_Updated_Content_1] = '個人アカウントを作成し、作成した地図をそのアカウントで保存します';
stringMap[strings.Recently_Updated_Content_2] = 'マステンプレートナレッジベースは、学習と作業の効率を向上させるために直接引用されます';
stringMap[strings.Recently_Updated_Content_3] = 'ウェブ版オンライン制作、IOSモバイル端末、Mac端末、Androidモバイル端末、Androidタブレットマルチプラットフォームの使用をサポート';
stringMap[strings.Recently_Updated_Content_4] = '個人の好みに応じて、テキスト、背景、線、画像アイコンなどのデザインを美しくすることができます';
stringMap[strings.Recently_Updated_Content_5] = '個々のスタイルスタイルライブラリは直接引用され、カラーマッチングの問題を排除します';
stringMap[strings.Recently_Updated_Content_6] = '写真、PDF形式を保存でき、友達と直接共有できます';
stringMap[strings.Recently_Updated_Content_7] = '強力なクラウドストレージサポート、データ損失を心配する必要がなくなりました';
stringMap[strings.Recently_Updated_Content_8] = '双方向、右、左、組織構造、シングルバブルチャート、ダブルバブルチャート、左ツリーチャート、右ツリーチャート、双方向ツリーチャート、テーブル、水平タイムライン、垂直タイムライン、Sタイムライン、8つのレイアウトはで変更できます意志';

//最近删除
stringMap[strings.Mind_Mindmap_Deleted_Title] = '最近削除されました';
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = '戻す';
stringMap[strings.Mind_Mindmap_Deleted_Forever] = '削除';
stringMap[strings.Mind_Mindmap_Deleted_Filename] = 'ファイル名';
stringMap[strings.Mind_Mindmap_Deleted_Tips] = 'ここで削除すると、完全に削除されます';
stringMap[strings.Mind_Mindmap_Deleted_Operating] = 'オペレーティング';
stringMap[strings.Mind_Mindmap_Deleted_Selected] = '最近削除された地図を検索する';
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = '日付を削除';

//联系我们
stringMap[strings.Contact_Us_No_Public] = '公共';
stringMap[strings.Contact_Us_QQ_Group] = 'QQグループ';
stringMap[strings.Contact_Us_Message_Board] = '伝言板';
stringMap[strings.Contact_Us_Username] = '名前';
stringMap[strings.Contact_Us_Email] = 'メールボックス';
stringMap[strings.Contact_Us_Voicemail] = '貴重なコメントを残してください';
stringMap[strings.Contact_Us_Send_Email] = 'メールを送る';

//验证信息
stringMap[strings.Input_Username_isNull] = '名前を空にすることはできません';
stringMap[strings.Input_Email_isNull] = '有効なメールアドレスを入力してください';
stringMap[strings.Input_Voicemail_isNull] = '内容を入力してください';
stringMap[strings.Input_Introduction_isNull] = 'イントロダクションを空にすることはできません';
stringMap[strings.Input_Title_isNull] = 'タイトルを空白にすることはできません';

stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] = '有効な電話番号を入力して下さい';
stringMap[strings.Input_Please_Enter_Verification_Code] = '確認コードを入力してください！';
stringMap[strings.Input_Please_Enter_The_Password] = 'パスワードを入力してください！';
stringMap[strings.Input_The_Password_Format_Is_Incorrect] = 'パスワードの形式が正しくありません！';
stringMap[strings.Input_Please_Enter_A_New_Password] = '新しいパスワードを入力してください！';
stringMap[strings.Input_Confirm_The_New_Password] = '確認パスワードを入力してください！';
stringMap[strings.Input_The_Passwords_Are_Inconsistent] = 'パスワードに一貫性がありません！';
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] = '正しい確認コードを入力してください！';
stringMap[strings.Input_The_Verification_Code_Error] = '検証コードエラー！';

//使用帮助
stringMap[strings.Mind_Usinghelp_1] = 'マインドマップを作成する';
stringMap[strings.Mind_Usinghelp_2] = 'ノードを追加する方法';
stringMap[strings.Mind_Usinghelp_3] = 'フリーノードを作成する';
stringMap[strings.Mind_Usinghelp_4] = '関連付けを行う';
stringMap[strings.Mind_Usinghelp_5] = 'キャンバスを美しくする';
stringMap[strings.Mind_Usinghelp_6] = 'テキストを美しくする';
stringMap[strings.Mind_Usinghelp_7] = 'メモ、リンクを挿入';
stringMap[strings.Mind_Usinghelp_8] = 'アイコンを挿入';
stringMap[strings.Mind_Usinghelp_9] = '画像';
stringMap[strings.Mind_Usinghelp_10] = '削除を元に戻す方法';
stringMap[strings.Mind_Usinghelp_11] = 'マインドマップをエクスポートする';
stringMap[strings.Mind_Usinghelp_12] = 'メンバーのアップグレード';
stringMap[strings.Mind_Usinghelp_13] = 'ホットキー';
stringMap[strings.Mind_Usinghelp_Understood] = 'とった';
stringMap[strings.Mind_Usinghelp_14] = 'マテリアルライブラリを挿入';
//使用帮助-2
stringMap[strings.Mind_Usinghelp_15] = '新しい地図';
// stringMap[strings.Mind_Usinghelp_2] = 'ノードを追加';
// stringMap[strings.Mind_Usinghelp_4] = '関連付けを行う';
// stringMap[strings.Mind_Usinghelp_5] = 'キャンバスを美しくする';
// stringMap[strings.Mind_Usinghelp_6] = 'テキストを美しくする';
// stringMap[strings.Mind_Usinghelp_7] = 'メモ、リンクを追加する   ';
stringMap[strings.Mind_Usinghelp_17] = '操作を元に戻し、キャンセルします';
stringMap[strings.Mind_Usinghelp_18] = 'ノードをドラッグしてスワップします';
stringMap[strings.Mind_Usinghelp_19] = 'ノードの配置';
stringMap[strings.Mind_Usinghelp_20] = 'マップタイプの変更';
stringMap[strings.Mind_Usinghelp_21] = 'マップラインスタイル';
stringMap[strings.Mind_Usinghelp_22] = '地図の背景色';
stringMap[strings.Mind_Usinghelp_23] = 'ノード形状を設定する';
stringMap[strings.Mind_Usinghelp_24] = 'ノードラインスタイル';
stringMap[strings.Mind_Usinghelp_25] = 'ノード画像の設定';
stringMap[strings.Mind_Usinghelp_26] = 'ノードアイコンの設定';
stringMap[strings.Mind_Usinghelp_27] = 'ノード追加の概要';
stringMap[strings.Mind_Usinghelp_28] = '単一ノードフレームの選択';
stringMap[strings.Mind_Usinghelp_29] = 'マルチノードブロック';
stringMap[strings.Mind_Usinghelp_30] = 'ノード追加境界';
stringMap[strings.Mind_Usinghelp_31] = 'グラフを追加する';
stringMap[strings.Mind_Usinghelp_32] = '隠しノードとリカバリ';
stringMap[strings.Mind_Usinghelp_33] = 'ノードの複数の操作';
stringMap[strings.Mind_Usinghelp_34] = 'スタイルを変える';
stringMap[strings.Mind_Usinghelp_35] = 'スピーチと説明を挿入';
stringMap[strings.Mind_Usinghelp_36] = '写真への地図';
stringMap[strings.Mind_Usinghelp_37] = 'PDFドキュメントのマップ';
stringMap[strings.Mind_Usinghelp_38] = '地図を印刷する';
stringMap[strings.Mind_Usinghelp_39] = 'リンクの共有と表示';
stringMap[strings.Mind_Usinghelp_40] = '嵐への地図共有';
stringMap[strings.Mind_Usinghelp_41] = 'フォルダの使用';
stringMap[strings.Mind_Usinghelp_42] = 'マップの削除と復元';
//使用帮助3
stringMap[strings.Mind_Usinghelp_43] = '友達がグループへの参加を申し込む';
stringMap[strings.Mind_Usinghelp_44] = 'グループを解散する方法';
stringMap[strings.Mind_Usinghelp_45] = 'ドキュメントをアップロードする';
stringMap[strings.Mind_Usinghelp_46] = 'アプリケーションに参加することに同意します';
stringMap[strings.Mind_Usinghelp_47] = '共同編集';
stringMap[strings.Mind_Usinghelp_48] = '新しい共有グループ';
stringMap[strings.Mind_Usinghelp_49] = 'グループメンバーを招待する';

//风暴
stringMap[strings.Mind_Storm_Recommend] = 'おすすめ';
stringMap[strings.Mind_Storm_Boilingpoint] = '人気がある';
stringMap[strings.Mind_Storm_Unfold] = '展開する';
stringMap[strings.Mind_Storm_Putaway] = '片付け';
stringMap[strings.Mind_Storm_Myclassification] = '私の分類';
stringMap[strings.Mind_Storm_Recommended_Classification] = '推奨される分類';
stringMap[strings.Mind_Storm_Click_Add_Category] = 'クリックしてカテゴリを追加';
stringMap[strings.Mind_Storm_No_Classification] = '分類なし';
stringMap[strings.Mind_Storm_No_Tinterested] = '興味がない';
stringMap[strings.Mind_Storm_Report] = '報告書';
stringMap[strings.Mind_Storm_Views] = 'ビュー';

stringMap[strings.Mind_Storm_Pornographic] = 'ポルノの下品';
stringMap[strings.Mind_Storm_Advertising_Harassment] = '広告嫌がらせ';
stringMap[strings.Mind_Storm_Political_Religion] = '政治的宗教';
stringMap[strings.Mind_Storm_Infringement] = '侵害（肖像画、名誉毀損、盗用、不正使用）';
stringMap[strings.Mind_Storm_Prohibited_Content] = '禁止されているコンテンツ';
stringMap[strings.Mind_Storm_Spread_Rumors] = 'うわさを広め、うわさを広める';
stringMap[strings.Mind_Storm_Report_Successful] = 'レポートが成功しました';

//编辑
stringMap[strings.Global_Edit] = '編集';
stringMap[strings.Global_Cancel] = 'キャンセル';
stringMap[strings.Global_Ok] = 'OK';
stringMap[strings.Global_Name] = 'ニックネーム';
stringMap[strings.Global_Introduction] = 'はじめに';
stringMap[strings.Global_Change_Avatar] = 'アバターの変更';
stringMap[strings.Global_Local_Upload] = 'ローカルアップロード';

//个人资料
stringMap[strings.User_Personal_information] = '個人情報';
stringMap[strings.User_Member_Validity_Period] = 'メンバーの有効期間';
stringMap[strings.User_My_Share] = 'マイシェア';
stringMap[strings.User_My_Collection] = 'マイコレクション';
stringMap[strings.User_More_Message] = '詳細';
stringMap[strings.User_Deleted_Sotrm] = 'ストームの削除';
stringMap[strings.User_Folder_Rename] = '名前の変更';
stringMap[strings.User_Folder_Rename_Root] = '権限の変更';
stringMap[strings.User_Folder_Rename_Root_Title] = '権限の変更';
stringMap[strings.User_Folder_Root_Public] = '完全に公開';
stringMap[strings.User_Folder_Root_Viewonly] = 'パブリックフォルダーは表示のみが許可されています';
stringMap[strings.User_Folder_Root_Private] = 'プライベート';
stringMap[strings.User_Folder_Root_Public_Introduction] = '他の人はあなたのプロフィールページであなたのお気に入りを見ることができます';
stringMap[strings.User_Message_Link] = 'リンク';
stringMap[strings.User_New_Group] = '新しいグループ';
stringMap[strings.User_New_Group_Title] = '新しいグループを作成する';
stringMap[strings.User_New_Group_Style_Title] = 'ファイルタイプ';
stringMap[strings.User_Share_Is_Null] = 'まだ共有していません！ ';
// stringMap[strings.User_Deleted_Storm_Tip​​s] = 'このストームを削除してもよろしいですか？ ';
stringMap[strings.User_Remove_From_List] = 'リストから削除';
stringMap[strings.User_Move_To] = '移動先';
stringMap[strings.User_Deleted_Link] = 'リンクを削除';
stringMap[strings.User_Collction_Is_Null] = 'コレクションはまだありません';
stringMap[strings.User_Collection] = 'コレクション';

//登录框
stringMap[strings.User_Lolgin_Welcome_To_Login] = 'ログインへようこそMind+'
stringMap[strings.User_Lolgin_Free_Registration] = '無料登録'
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] = '電話番号を入力してください'
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] = '確認コードを入力してください'
stringMap[strings.User_Lolgin_Obtain] = 'Get'
stringMap[strings.User_Lolgin_Password_Login] = 'パスワードログイン'
stringMap[strings.User_Lolgin_Login] = 'ログイン'
stringMap[strings.User_Lolgin_Register_An_Account] = 'アカウント登録'
stringMap[strings.User_Lolgin_Already_Have_An_Account] = 'すでにアカウントを持っています'
stringMap[strings.User_Lolgin_To_Log_In] = 'ログインに移動'
stringMap[strings.User_Lolgin_Please_Enter_Password] = 'パスワードを入力してください'
stringMap[strings.User_Lolgin_Confirm_Password] = 'パスワードの確認'
stringMap[strings.User_Lolgin_Registered] = '登録済み'
stringMap[strings.User_Lolgin_Account_Login] = 'アカウントログイン'
stringMap[strings.User_Lolgin_Scan_Code_Login] = 'スキャンコードログイン'
stringMap[strings.User_Lolgin_Forgot_Password] = 'パスワードを忘れた'
stringMap[strings.User_Lolgin_Verification_Code_Login] = '検証コードログイン'
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = '設定インターフェイスの上部でMind + scanを開きます'
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'このQRコードは5分で期限切れになります。期限切れになったらクリックしてください'
stringMap[strings.User_Lolgin_Refresh] = '更新'
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'スキャンは成功しました。携帯電話で確認してください'
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'ログインを承認するかどうか'
stringMap[strings.User_Lolgin_Please_Enter_New_Password] = '新しいパスワードを入力してください'
stringMap[strings.User_Lolgin_Reset] = 'リセット'

//我的导图
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] = 'テンプレートタイプまたはテンプレートを検索する'
stringMap[strings.My_Mind_Date] = '日付'
stringMap[strings.My_Mind_Create_Folder] = 'フォルダーの作成'
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] = 'フォルダ名を入力してください'
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] = '新しいフォルダ名を入力してください'
stringMap[strings.My_Mind__No_Map_Yet_Go] = 'まだ地図がありません、すぐに行ってください'
stringMap[strings.My_Mind_Create] = '作成'
stringMap[strings.My_Mind_Right] = 'バー！'
stringMap[strings.My_Mind_Open] = '開く'
stringMap[strings.My_Mind_Type_Left_Right] = 'デュアルガイドマップ'
stringMap[strings.My_Mind_Type_Unilateral] = '片側マップ'
stringMap[strings.My_Mind_Type_Bottom] = '組織図'
stringMap[strings.My_Mind_Type_Bubble] = 'バブルチャート'
stringMap[strings.My_Mind_Type_Time_Vertical] = 'スケジュールマップ'
stringMap[strings.My_Mind_Blank_Mind_Map] = '空白のマインドマップ'
stringMap[strings.My_Mind_All] = 'すべて'
stringMap[strings.My_Mind_Views] = 'ビュー：'
//风暴搜索
stringMap[strings.Storm_Search_How_Title] = 'トップタグ'
stringMap[strings.Storm_History_Search] = '履歴検索'

//公共 header
stringMap[strings.Public_Header_Mind] = 'マップ'
stringMap[strings.Public_Header_Outline] = '概要'
stringMap[strings.Public_Header_Download_Apps] = 'クライアントのダウンロード'
stringMap[strings.Public_Header_Mobile_Version] = 'モバイルバージョン'
stringMap[strings.Public_Header_Creator] = '作成者：'
stringMap[strings.Public_Header_View_Frequency] = 'ビュー：'
stringMap[strings.Public_Header_Save] = '保存'
stringMap[strings.Header_Mind_Tips_Saved] = '保存済み'
stringMap[strings.Header_Mind_Tips_Edit] = '編集済み'
stringMap[strings.Header_Mind_Tips_Update_Saved] = '更新が保存されました'
//导图编辑菜单
stringMap[strings.Mind_Edit_Revoke] = '取り消し'
stringMap[strings.Mind_Edit_Restore] = '復元'
stringMap[strings.Mind_Edit_Subtopic] = 'サブテーマ'
stringMap[strings.Mind_Edit_Top_Subtopic] = 'テーマ'
stringMap[strings.Mind_Edit_Bottom_Subtopic] = 'テーマ'
stringMap[strings.Mind_Edit_Free_Node] = 'フリーノード'
stringMap[strings.Mind_Edit_Summary] = '概要'
stringMap[strings.Mind_Edit_Block] = 'ブロックに'
stringMap[strings.Mind_Edit_Insert] = '挿入'
stringMap[strings.Mind_Edit_Related] = '関連'
stringMap[strings.Mind_Edit_Style] = 'スタイル'
stringMap[strings.Mind_Edit_Recording] = '記録'
stringMap[strings.Mind_Edit_Insert_Picture] = '画像を挿入'
stringMap[strings.Mind_Edit_Insert_Icon] = 'アイコンを挿入'
stringMap[strings.Mind_Edit_Insert_Remarks] = '備考を挿入'
stringMap[strings.Mind_Edit_Insert_Link] = 'リンクを挿入'
stringMap[strings.Mind_Edit_Insert_Summary_Graph] = '統計グラフを挿入'
stringMap[strings.Mind_Edit_Upload] = 'アップロード'
stringMap[strings.Mind_Edit_Right_Icon] = 'アイコン'
stringMap[strings.Mind_Edit_Right_Style] = 'スタイル'
stringMap[strings.Mind_Edit_Audio_Decoding] = 'デコード...'
stringMap[strings.Mind_Edit_Audio_Duration] = '期間'
stringMap[strings.Mind_Edit_Delete_Audio_Tips] = '録音されたオーディオを削除してもよろしいですか？ '
stringMap[strings.Mind_Edit_Join_Membership] = 'VIPを開く'
stringMap[strings.Mind_Edit_Upgrade] = 'アップグレード'
stringMap[strings.Mind_Edit_Upgrade_Item] = "アップグレード"
stringMap[strings.Mind_Edit_Join_Membership_Tips] = 'メンバーはより多くの記録時間を得ることができます。今度はメンバーシップにアップグレードしますか？ '

stringMap[strings.Mind_Edit_Choose_Material] = 'マテリアルを選択'
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'ここに画像をドラッグアンドドロップします'
stringMap[strings.Mind_Edit_Select_locally] = 'ローカルから選択'
stringMap[strings.Mind_Edit_Choose_From] = 'マテリアルライブラリから選択'
stringMap[strings.Mind_Edit_Mine] = 'My'
stringMap[strings.Mind_Edit_My_Material] = 'マイマテリアル'
stringMap[strings.Mind_Edit_Recently_Used] = '最近使用されました'
stringMap[strings.Mind_Edit_Use] = '使用'
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = '現時点ではマテリアルがありません。移動して追加してください。 '
stringMap[strings.Mind_Edit_Select_Chart_Type] = 'チャートタイプを選択'
stringMap[strings.Mind_Edit_Remarks] = '備考'
stringMap[strings.Mind_Edit_Remarks_Tips] = 'コメントを入力してください'
stringMap[strings.Mind_Edit_Add] = '追加'
stringMap[strings.Mind_Edit_Please_Enter_The_Link] = 'リンクを入力してください'

//编辑页左侧菜单
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] = '画像を保存'
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] = 'PDFをエクスポート'
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] = 'リンクを共有する'
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] = '嵐に共有する'
stringMap[strings.Mind_Edit_Left_Menu_Printing] = '印刷'
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'フルバージョンのロックを解除する'
stringMap[strings.Mind_Edit_Left_Menu_Help] = 'ヘルプ'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'フォーマットを保存'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'png形式'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'jpg形式'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'webp形式'
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] = 'マップを共有する'
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] = '共有スイッチ'
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'リンクをコピーして送信できます'
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] = 'リンクをコピー'
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'リンクを変更する場合は、[]をクリックしてください'
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] = '再生成'
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] = 'リンクパスワード：'
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] = '保存を許可する'
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = '最大ビュー数'
stringMap[strings.Mind_Edit_Left_Menu_Infinite] = '無限'
stringMap[strings.Mind_Edit_Left_Menu_Limit] = '制限'
stringMap[strings.Mind_Edit_Left_Menu_Second] = 'Second'
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] = 'リンクの有効期間'
stringMap[strings.Mind_Edit_Left_Menu_1_Day] = '1日'
stringMap[strings.Mind_Edit_Left_Menu_3_Days] = '3日'
stringMap[strings.Mind_Edit_Left_Menu_7_Days] = '7日'
stringMap[strings.Mind_Edit_Left_Menu_Permanent] = '永続的'
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'コンテンツとリンクをコピー'
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] = '再生成してもよろしいですか？前のリンクは無効になります！ '
stringMap[strings.Mind_Edit_Left_Menu_Share] = '共有'
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] = 'カスタムカテゴリ：'
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] = 'コメントを許可するかどうか'
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] = '編集してカテゴリを追加'
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] = 'メンバーシップの権利'
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] = 'サポートプラットフォーム'
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] = 'VIPを開く'
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'アクティブコード交換'
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] = '購入後のメンバーシップ特典'
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] = '支払い方法を選択してください'
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] = 'アクティビティ価格'
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = '実際に支払われた金額'
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'すぐに更新'
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《利用規約》'
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《プライバシーポリシー》'
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] = 'アクティベーションコード：'
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'アクティベーションコードを入力してください'
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'アクティベーションコードを確認する'
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] = 'カテゴリを選択'
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'メインタイトルを空にすることはできません'
stringMap[strings.Mind_Edit_Left_Menu_Coupon] = 'クーポン：'
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'クーポンコードを入力してください'
stringMap[strings.Mind_Confirm_Use_Coupons] = 'クーポンの使用を確認する'
stringMap[strings.Mind_Edit_Left_Menu_Verification] = '検証'
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] = '優先金額'
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'すぐに支払う'
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = '交換情報の確認'
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] = '交換コード：'
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Exchange'

//编辑页右侧菜单
stringMap[strings.Mind_Edit_Right_Menu_canvas] = 'Canvas'
stringMap[strings.Mind_Edit_Right_Menu_background_Color] = '背景色'
stringMap[strings.Mind_Edit_Right_Menu_layout] = 'レイアウト'
stringMap[strings.Mind_Edit_Right_Menu_Layout_branch] = "ブランチ構造"
stringMap [strings.Mind_Edit_Right_Menu_Line_branch] = "支線"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "アソシエーションライン構造"
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] = 'ラインスタイル'
stringMap[strings.Mind_Edit_Right_Menu_line_Color] = '線の色'
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] = '線幅'
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] = '線は先細になっています'
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] = 'テーマフォント'
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] = '子フォント'
stringMap[strings.Mind_Edit_Right_Menu_text_Color] = 'テキストの色'
stringMap[strings.Mind_Edit_Right_Menu_font_Size] = 'サイズ'
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] = 'フォントスタイル'
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] = '境界線の色'
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] = '塗りつぶしの色'
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] = 'ボーダーシェイプ'
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] = '境界線の幅'
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'シャドウ効果を表示する（サブスクリプション関数）'
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] = '線の色'
stringMap[strings.Mind_Edit_Right_Menu_Dotted_Line] = '点線'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] = '画像サイズ'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] = '画像の位置'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] = '画像の形状'
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] = 'Iconサイズ'
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] = 'Iconの位置'
stringMap[strings.Mind_Edit_Right_Menu_routine] = '一般'
stringMap[strings.Mind_Edit_Right_Menu_priority] = '優先度'
stringMap[strings.Mind_Edit_Right_Menu_Progress] = '進捗状況'
stringMap[strings.Mind_Edit_Right_Menu_Stars] = 'スター'
stringMap[strings.Mind_Edit_Right_Menu_Week] = '週'
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] = 'ポートレート'
stringMap[strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap[strings.Mind_Edit_Right_Menu_Learning] = '学習'
stringMap[strings.Mind_Edit_Right_Menu_Work] = '仕事'
stringMap[strings.Mind_Edit_Right_Menu_Characters] = '人'
stringMap[strings.Mind_Edit_Right_Menu_Animals] = '動物'
stringMap[strings.Mind_Edit_Right_Menu_Food] = '食物'
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] = 'コピースタイル'
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] = 'スティッキングスタイル'
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] = '形状の変更'
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] = '画像を表示'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] = '画像を削除'
stringMap[strings.Mind_Edit_Node_Menu_Copy] = 'コピー'
stringMap[strings.Mind_Edit_Node_Menu_Paste] = '貼り付け'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] = '行を削除'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = '削除'
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] = '備考の編集'
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] = 'リンクを開く'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'コメントの削除'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] = 'リンクを削除'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] = 'アイコンの削除'
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] = 'アイコンスタイル'

//统计图编辑面板
stringMap[strings.Echart_Edit_Statistical] = '統計'
stringMap[strings.Echart_Edit_Value] = '値'
stringMap[strings.Echart_Edit_Color] = '色'
stringMap[strings.Echart_Edit_Title] = 'タイトル'
stringMap[strings.Echart_Edit_Detailed_Description] = '詳細な説明'
stringMap[strings.Echart_Edit_Value_Sort] = '並べ替え'
stringMap[strings.Echart_Edit_Value_Display_Type] = '表示タイプ'
stringMap[strings.Echart_Edit_Value_Color_Scheme] = '配色'
stringMap[strings.Echart_Edit_Value_Display_Size] = '表示サイズ'
stringMap[strings.Echart_Edit_Value_Title_Size] = 'タイトルサイズ'
stringMap[strings.Echart_Edit_Value_Explain_Size] = 'サイズの説明'
stringMap[strings.Echart_Edit_Value_Subscribe] = '購読'
//风暴标签页
stringMap[strings.StormBookTag_Subscribed] = '購読済み'
stringMap[strings.StormBookTag_List] = 'リスト'

//风暴分享页
stringMap[strings.Storm_Share_Comment] = 'コメント'
stringMap[strings.Storm_Share_Reply] = '返信'
stringMap[strings.Storm_Share_Let_Me_Comment_Too] = 'コメントさせてください~'
stringMap[strings.Storm_Share_Release] = '送信'
stringMap[strings.Storm_Share_Main_Title] = 'メインタイトル'
stringMap[strings.Storm_Share_Subtitle] = '字幕'
stringMap[strings.Storm_Share_Author] = '作成者'
stringMap[strings.Storm_Share_Replies] = '返信'
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] = 'コメントはまだありません。最初に来てください'
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'コメントを入力してください〜'
stringMap[strings.Storm_Share_Delete_Comment_Tips] = 'このコメントを削除してもよろしいですか？ '
//导图内部使用帮助
stringMap[strings.Mind_Usinghelp_Btn_1] = 'ショートカットキー（ウィンドウ）'
stringMap[strings.Mind_Usinghelp_Btn_2] = 'ショートカットキー（mac）'
stringMap[strings.Mind_Usinghelp_Btn_3] = '説明'
stringMap[strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap[strings.Mind_Usinghelp_Btn_5] = '[コマンド] + [D]'
stringMap[strings.Mind_Usinghelp_Btn_6] = '子ノードを追加'
stringMap[strings.Mind_Usinghelp_Btn_7] = '[削除]'
stringMap[strings.Mind_Usinghelp_Btn_8] = '現在のノードを削除します（編集状態の場合は削除できないことに注意してください）'
stringMap[strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap[strings.Mind_Usinghelp_Btn_10] = '[コマンド] + [E]'
stringMap[strings.Mind_Usinghelp_Btn_11] = '現在のノードを変更する'
stringMap[strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap[strings.Mind_Usinghelp_Btn_13] = '[コマンド] + [↑] [↓] [←] [→]'
stringMap[strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap[strings.Mind_Usinghelp_Btn_15] = '[コマンド] + [+]'
stringMap[strings.Mind_Usinghelp_Btn_16] = 'マップを拡大する'
stringMap[strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap[strings.Mind_Usinghelp_Btn_18] = '[コマンド] + [-]'
stringMap[strings.Mind_Usinghelp_Btn_19] = 'マップを気にする'
stringMap[strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap[strings.Mind_Usinghelp_Btn_21] = '[コマンド] + [S]'
stringMap[strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_23] = '[コマンド] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_25] = '[コマンド] + [シフト] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap[strings.Mind_Usinghelp_Btn_27] = '[コマンド] + [B]'
stringMap[strings.Mind_Usinghelp_Btn_28] = '編集を終了します'
//提示文字
stringMap[strings.Message_Tips_Login_Success] = 'ログインに成功しました'
stringMap[strings.Message_Tips_Dlt_Success] = '正常に削除します'
stringMap[strings.Message_Tips_Report_Success] = 'レポートが成功しました'
stringMap[strings.Message_Tips_Copy_Success] = 'コピーに成功しました'
stringMap[strings.Message_Tips_Collction_Success] = 'コレクションの成功'
stringMap[strings.Message_Tips_Avatar_Modification_Success] = 'アバターの変更に成功しました'
stringMap[strings.Message_Tips_Signature_Modification_Success] = '署名変更の成功'
stringMap[strings.Message_Tips_Name_Modification_Success] = 'ニックネームが正常に変更されました'
stringMap[strings.Message_Tips_Modification_Success] = '変更に成功しました'
stringMap[strings.Message_Tips_New_Profile_Success] = '新しいフォルダーの成功'
stringMap[strings.Message_Tips_Mobile_Success] = 'モバイルの成功'
stringMap[strings.Message_Tips_Submit_Success] = '正常に送信します'
stringMap[strings.Message_Tips_Exchange_Success] = '交換に成功しました'
stringMap[strings.Message_Tips_Please_Code] = 'アクティベーションコードを入力してください'
stringMap[strings.Message_Tips_Agree_To_Terms] = 'プライバシーポリシーに同意してください'
stringMap[strings.Message_Tips_Please_Sign_In] = 'サインインしてください'
stringMap[strings.Message_Tips_Share_Success] = '共有に成功しました'
stringMap[strings.Message_Tips_Create_Success] = '正常に作成されました'
stringMap[strings.Message_Tips_Profile_Name_No_Null] = 'フォルダー名を空にすることはできません'
stringMap[strings.Message_Tips_Selecte_Success] = '検索が完了しました'
stringMap[strings.Message_Tips_Subscription_Success] = 'サブスクリプションは成功しました'
stringMap[strings.Message_Tips_Unsubscribe_Success] = '購読解除に成功しました'
stringMap[strings.Message_Tips_Related_Content_Not_Found] = '関連コンテンツが見つかりません'
stringMap[strings.Message_Tips_Max_Add_Class] = '追加で​​きるのは最大で'
stringMap[strings.Message_Tips_Registered_Success] = '正常に登録されました'
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] = 'コードスキャンログインに失敗しました'
stringMap[strings.Message_Tips_Share_Is_Null] = '共有リンクが存在しません'
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] = 'アップロードされたファイルは3MBを超えることはできません'
stringMap[strings.Message_Tips_Get_Permission_Successfully] = 'アクセス許可を正常に取得します'
stringMap[strings.Message_Tips_Membership_Required] = 'メンバーシップが必要です'
stringMap[strings.Message_Tips_Save_Success] = '正常に保存'
stringMap[strings.Message_Tips_Scan_Code_Login_Success] = 'スキャンコードのログインに成功しました'
stringMap[strings.Message_Tips_Style] = 'カテゴリ？'
//大纲模式
stringMap[strings.Outline_Menu_Indent] = 'インデント'
stringMap[strings.Outline_Menu_Decrease_Indent] = 'インデントを減らす'

//帮助提示
stringMap[strings.Help_Background_Content] = 'ラインバックグラウンド関連、ラインバックグラウンド関連、ラインバックグラウンド関連、ラインバックグラウンド関連、ラインバックグラウンド関連、ラインバックグラウンド関連、ラインバックグラウンド関連、ラインバックグラウンド関連、ラインバックグラウンド関連、'
stringMap[strings.Help_Mind_Content] = '好みに応じて異なるレイアウトのマップを作成できます：デュアルガイドマップ、左マップ、右マップ、組織図、双方向ツリー、左ツリー、右ツリー、テーブル'
stringMap[strings.Help_Share_Link_Content] = '共有リンクはWeChat、QQ、Weibo、その他のチャネルでリンクの形で共有でき、友達はコンテンツを表示できます'
stringMap[strings.Using_Templates] = 'テンプレートの使用'
//邀请码
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] = '招待コードを入力してください'
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = '正しい招待コードを入力してください'
stringMap[strings.User_Lolgin_Optional] = 'オプション'

//使用素材

stringMap[strings.Cannot_Add_To_Node_Background] = 'ノードの背景には適用されません'
stringMap[strings.Cannot_Add_To_Img_Background] = 'テキストの背景には適用されません'
stringMap[strings.Cannot_Add_To_Mind_Background] = '小包ラインの背景には適用されません'
stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = '背景画像を追加'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = '背景画像を削除'
stringMap[strings.Mind_Add_Background_Image] = '背景画像を挿入'
stringMap[strings.Mind_Delete_Background_Image] = '背景画像を削除する'
stringMap[strings.Permanent_Member] = '常任理事国'
stringMap[strings.Insufficient_Storage_Space] = 'ストレージ容量が不足しています。メンバーシップをアップグレードして容量を増やしてください。 '
stringMap[strings.Used_Space] = 'ストレージスペース'
stringMap[strings.Current_Storage] = '現在のストレージ：'
stringMap[strings.Used_Space_Upgrade] = 'ストレージスペースのアップグレード'
stringMap[strings.My_Mind_Is_Null_Tips] = 'マインド+マインドマップとメモスーパーニーモニック、思考に翼を追加、作成、表示、計画、メモ'
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] = 'ストレージスペースが不足しています。メンバーシップをアップグレードするときにテンプレートを使用できます。 '

stringMap[strings.Group_Management] = 'グループ'
// stringMap[strings.Save_Img_Fill_Fu​​ll] = '完全に舗装する'
stringMap[strings.Save_Img_Center_Tilt] = 'センターチルト'
stringMap[strings.Save_Img_File_Full_Dark_Color] = '完全に（暗い）'
stringMap[strings.Save_Img_Mind_Map_Watermarking] = 'マインド+マインドマップ透かし'
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = '知識の作成を保護するために透かしを追加します：'
stringMap[strings.Save_Img_Watermark_Text] = '透かしテキスト：'
stringMap[strings.Save_Img_Watermark_Logo] = '透かしロゴ：'
stringMap[strings.Save_Img_Watermark_Style] = '透かしスタイル：'
stringMap[strings.Save_Img_Please_Input] = '入力してください'
stringMap[strings.Save_Img_Chop_Tips] = 'メンバーを開くと透かしの権利を楽しむことができます'
stringMap[strings.Public_Header_Mindmap_Mind] = 'Mindmap'
stringMap[strings.Copy_Mind_Map] = 'マインドマップのコピー'
stringMap[strings.Font_Style_Bold] = '太字'
stringMap[strings.Font_Style_Italics] = 'イタリック'
stringMap[strings.Font_Style_Delete_Line] = '取り消し線'
stringMap[strings.Remove_Any] = '削除'

stringMap[strings.Mindmap_Review] = 'リプレイ'
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] = '印象を深め、記憶力を高め、アイデアや情報を効率的に整理する'
stringMap[strings.Mindmap_Review_Content] = 'マップレビューは編集プロセスの再生を指し、コンテンツは中央のテーマから順番に再生されます。マップレビューは、システムインベントリ、分析の実行、アイデアや抜け穴の発見に役立ちます。レビュープロセス、あなたはさまざまなインスピレーションを見つけるかもしれません '
stringMap[strings.Mindmap_Review_Speed] = '速度'
stringMap[strings.Mindmap_Review_Word_Speed] = '単語の速度'
stringMap[strings.Mindmap_Review_Background_Ratio] = '背景比率'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] = '現在の再生ノードの倍率'
stringMap[strings.Mindmap_Review_Node_Playback_Order] = 'ノード再生順序'


stringMap[strings.Mind_Group_Picture_Upload] = '画像のアップロード'
stringMap[strings.Mind_Group_Upload_From_Local] = 'ローカルアップロード'
stringMap[strings.Mind_Group_Mind_Mapping] = 'Mindmap'
stringMap[strings.Mind_Group_Information] = 'データ'

stringMap[strings.Mind_Group_Invite_New_Members] = '新しいメンバーを招待する'
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = '電話番号を入力してください'
stringMap[strings.Mind_Group_Cancel] = 'キャンセル'
stringMap[strings.Mind_Group_Confirm] = 'OK'
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = '携帯電話番号を空にすることはできません'
stringMap[strings.Mind_Group_Invite_The_Successful] = '招待が成功しました'
stringMap[strings.Mind_Group_Shared_Group_Membership] = "共有グループメンバー"
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "共有グループメンバーの最大数"
stringMap[strings.Mind_Group_Membership_Limit] = "メンバーシップ制限"
stringMap[strings.Mind_Group_Current_Member_Limit] = "現在のメンバーの上限"
stringMap[strings.Mind_Group_Upgrade_Member_Limit] = "アップグレードメンバーの上限"
stringMap[strings.Mind_Group_Members_Of_The_Search] = "メンバーを検索"
stringMap[strings.Mind_Group_Creator] = "作成者"
stringMap[strings.Mind_Group_Administrator] = "管理者"
stringMap[strings.Mind_Group_Number_Of_People] = "人"
stringMap[strings.Mind_Group_Selected] = "選択済み"
stringMap[strings.Mind_Group_Member] = "メンバー"
stringMap[strings.Mind_Group_Check_All] = "すべて選択"
stringMap[strings.Mind_Group_Remove] = "削除"
stringMap[strings.Mind_Group_Invite_New_Members] = "新しいメンバーを招待する"
stringMap[strings.Mind_Group_Delete_Members] = "メンバーを削除"
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] = "削除するメンバーを選択してください"
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "このグループからユーザーを削除してもよろしいですか？"
stringMap[strings.Mind_Group_Add_As_Administrator] = "管理者を追加"
stringMap[strings.Mind_Group_Accomplish] = "完了"
stringMap[strings.Mind_Group_Compile] = "編集"
stringMap[strings.Mind_Group_Group_Owner] = "グループ所有者"
stringMap[strings.Mind_Group_Add_Admin] = "管理者を追加"
stringMap[strings.Mind_Group_Group_ID] = "グループID"
stringMap[strings.Mind_Group_Group_Number] = "グループ番号"
stringMap[strings.Mind_Group_Name] = "名前"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "共有グループの名前を入力してください"
stringMap[strings.Mind_Group_Introduce] = "紹介"
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "共有グループを簡単に紹介してください"
stringMap[strings.Mind_Group_Announcement] = "アナウンス"
stringMap[strings.Mind_Group_Shared_Group_Tag] = "ラベル"
stringMap[strings.Mind_Group_Custom_Tag] = "カスタムタグ"
stringMap[strings.Mind_Group_Save] = "保存"
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "グループ名を空にすることはできません"
stringMap[strings.Mind_Group_Establish] = "作成"
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] = "マップ/フォルダーの作成を許可する"
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] = "マップの編集を許可する"
stringMap[strings.Mind_Group_Holder] = "全員"
stringMap[strings.Mind_Group_Only_The_Administrator] = "管理者のみ"
stringMap[strings.Mind_Group_Not_Allow] = "許可され​​ていません"
stringMap[strings.Mind_Group_Allows_Map_Deletion] = "マップの削除を許可する"
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "フォーラムにメッセージの投稿を許可する"
stringMap[strings.Mind_Group_Export] = "エクスポート"
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "画像/ PDFの保存を許可する"
stringMap[strings.Mind_Group_Open_Group_Watermark] = "グループの透かしを開く"
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "開くと、グループのロゴがマインドマップに印刷されます"
stringMap[strings.Mind_Group_Allow_Saving_Maps] = "マップの保存を許可する"
stringMap[strings.Mind_Group_Save_It_To_My_Map] = "マイマップに保存"
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "ストームへの共有を許可するかどうか"
stringMap[strings.Mind_Group_Join_The_Way] = "参加方法"
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] = "新しいメンバーの招待を許可するかどうか"
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "検索グループ番号の追加を許可するかどうか"
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "参加時にメッセージを検証する必要があるかどうか"
stringMap[strings.Mind_Group_Shared_Group_Data] = "共有グループデータ"
stringMap[strings.Mind_Group_Authority_Management] = "権限管理"
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] = "共有グループを離れる"
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "共有グループを終了してもよろしいですか？"
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] = "解散グループ"
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "グループが解散した後、ファイルは削除されます。解散することを確認しますか？"
stringMap[strings.Mind_Group_Return_To_Home_Page] = "ホームページに戻る"
stringMap[strings.Mind_Group_Join_The_Group] = "グループに参加"
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] = "検索するグループ番号/グループ名を入力してください"
stringMap[strings.Mind_Group_Apply_To_Join] = "参加に申し込む"
stringMap[strings.Mind_Group_Join] = "参加"
stringMap[strings.Mind_Group_Verification] = "検証"
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] = "検証メッセージを入力してください"
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] = "コンテンツを空にすることはできません"
stringMap[strings.Mind_Group_Name] = "グループ名"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "共有グループの名前を入力してください"
stringMap[strings.Mind_Group_Introduction] = "前書き"
stringMap[strings.Mind_Group_Group_Indication] = "グループ番号"
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "作成者は怠け者です、まだ紹介していません〜"
stringMap[strings.Mind_Group_Shared_Group_Announcement] = "共有グループのお知らせ"
stringMap[strings.Mind_Group_No_Announcement] = "まだアナウンスはありません"
stringMap[strings.Mind_Group_Click_On] = "クリックして展開"
stringMap[strings.Mind_Group_Click_On_The_Hidden] = "クリックして非表示"
stringMap[strings.Mind_Group_Altogether] = "合計"
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "グループ番号を正常にコピーしています"
stringMap[strings.Mind_Group_Stick] = "上に固定"
stringMap[strings.Mind_Group_There_Is_A_New_Content] = "新しいコンテンツがあります"
stringMap[strings.Mind_Group_Cancel_The_Top] = "トップをキャンセル"
stringMap[strings.Mind_Group_Setting_Up] = "設定"
stringMap[strings.Mind_Group_Usinghelp] = "ヘルプの使用"
stringMap[strings.Mind_Group_Set_The_Message] = "グループメッセージ"
stringMap[strings.Mind_Group_Apply_To_Join] = "参加に申し込む"
stringMap[strings.Mind_Group_Invite_To_Join] = "参加を招待"
stringMap[strings.Mind_Group_Consent] = "同意する"
stringMap[strings.Mind_Group_Turn_Down] = "拒否"
stringMap[strings.Mind_Group_Label] = "ラベル"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "共有グループラベルの名前を入力してください"
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "知識コンテンツを共同作成して共有するように友人や同僚を招待する"
stringMap[strings.Mind_Group_Immediately_Create] = "すぐに作成"
stringMap[strings.Mind_Group_Immediately_To_Join] = "今すぐ参加"

stringMap[strings.Currently_Selected] = "現在フォロー中"
stringMap[strings.LabelManagement] = "ラベル管理"
stringMap[strings.NoAttention] = "注意なし"
stringMap[strings.Mindmap_Review_Play] = '再生'

stringMap[strings.Mind_Group_Joined] = "参加しました"
stringMap[strings.Mind_Group_Download_App] = "アプリをダウンロード"
stringMap[strings.Mind_Group_No_Content] = "コンテンツはまだありません"
stringMap[strings.Mind_Group_Individual] = "ピース"
stringMap[strings.Mind_Group_Message_Area] = "伝言"
stringMap[strings.Mind_Group_No_Message] = "メッセージはありません、今すぐ行ってください"
stringMap[strings.Mind_Group_Leave_Message] = "メッセージを残す"
stringMap[strings.Mind_Group_Bar] = "バー"
stringMap[strings.Mind_Group_See_More] = "もっと見る"
stringMap[strings.Mind_Group_Enter_Invitation_Code] = "招待コードを入力してください"
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] = "共有グループへの参加を申し込む"
stringMap[strings.Reasons_For_Application_0_30_Words] = "アプリケーションの理由（0〜30ワード）"
stringMap[strings.Mind_Group_Send_Out] = "送信"
stringMap[strings.Mind_Group_Join_Successfully] = "正常に参加します"
stringMap[strings.Mind_Group_Application_Sent_Successfully] = "アプリケーションは正常に送信されました"

stringMap[strings.Mind_Group_Save_The_Mapping] = "マップを保存"
stringMap[strings.Mind_Group_Update_Record] = "レコードの変更"

stringMap[strings.Mind_Edit_Page_Switch_Mapping] = "スイッチマップ"
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] = "マップレビュー"
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "既存のユーザーが地図を編集しています"
stringMap[strings.Mind_Edit_Page_Password] = "パスワード"

stringMap[strings.Mind_Edit_Page_Sharing_Group] = "グループ"
stringMap[strings.Mind_MBean] = "MBean"
stringMap[strings.Mind_My_MBean] = "My MBean"
stringMap[strings.Alipay] = "Alipay"
stringMap[strings.Alipay_Payment] = "Alipay支払い"
stringMap[strings.Please_Pay_On_The_Payment_Page] = "支払いページに移動して支払いを行ってください"
stringMap[strings.WeChat] = "WeChat"
stringMap[strings.Mind_Shooping_Total] = "合計"
stringMap[strings.Mind_Shooping_Confirm_Payment] = "支払いの確認"
stringMap[strings.Mind_Shooping_Redeem_Now] = "今すぐ利用"

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] = "購入の支払いを確認するかどうか"
stringMap[strings.Mind_Tips] = "ヒント"
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "Mビーンが不十分です。Mビーンを購入しますか？"

stringMap[strings.Mind_share_scan_code] = "マインド+スキャンコード"

stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "最後に再生した場所に復元するかどうか？"
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "残りの再生可能時間"

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] = "ファイルは完全に削除されます。解散してもよろしいですか？"
stringMap[strings.Group_Mind_Up_One_Level] = "アップワンレベル"
stringMap[strings.Group_Mind_No_Documents] = "ファイルはまだありません"
stringMap[strings.Group_Mind_PaymentSuccessful] = "支払いが成功しました"

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] = "人々の共同編集、マルチターミナルのリアルタイム同期、ドキュメントの共有、チームのコラボレーション効率の向上"

stringMap[strings.Mind_Edit_Subscript] = "下付き文字"
stringMap[strings.Mind_Edit_Text_Location] = "テキストの位置"
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] = "共有グループにインポートします"


stringMap[strings.Standard_Colors] = "標準色"
stringMap[strings.More_Colors] = "より多くの色"
stringMap[strings.Reset_Password] = "パスワードをリセットします"
stringMap[strings.Account_Password_Login] = "アカウントパスワードログイン"
stringMap[strings.Authentication_code_Login_registration] = "確認コードログイン/登録"
stringMap[strings.One_End_Creation_Multi_End_Synchronization] = "一方の端はマルチ端同期を作成します"
stringMap[strings.Rich_And_Diverse_Mapping_Structure] = "豊富で多様なガイド構造"
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] = "美しいテンプレートのライブラリのボリューム"
stringMap[strings.Map_Review_Play] = "ガイドチャートのレビュー - 再生"
stringMap[strings.CommunityLearningAndSharingPlatform] = "知識学習と共有のためのプラットフォーム"

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] = "ストレージをアップグレードします"
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] = "私もコメントします"
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "現時点では、メッセージ通知はありません"
stringMap[strings.Failed_To_Get_Recording_Device] = "録音デバイスの取得に失敗しました。"

stringMap[strings.Search_Pagination] = "ページを検索"
stringMap[strings.Please_Enter_A_Paging_Name] = "改ページ名を入力してください"

stringMap[strings.Mind_Group_Used] = "使用済み"

stringMap [strings.Mind_Click_Select] = "クリックして選択"
stringMap [strings.Mind_Double_Click_Enter] = "ダブルクリックして入力"
stringMap [strings.Search_Shared_Groups] = "共有グループを検索"
stringMap [strings.Search_Storm] = "ストームの検索"
stringMap [strings.Members_Save_Free] = "メンバーは無料で保存"
stringMap [strings.Bank_Card_Account_Name] = "銀行カードの口座名を入力してください"
stringMap [strings.Bank_Card_No] = "銀行カード番号"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "銀行カード番号を入力してください"
stringMap [strings.Branch_Sub_Branch_Information] = "ブランチブランチ情報"
stringMap [strings.Branch_Sub_Branch_Example] = "例：杭州支店Chengxiサブ支店"
stringMap [strings.Application_For_Withdrawal] = "撤回の申請"
stringMap [strings.Withdrawal_Amount] = "引き出し額"
stringMap [strings.Withdrawal] = "撤回"
stringMap [strings.Current_Revenue] = "現在の収益"
stringMap [strings.Recharge] = "リチャージ"
stringMap [strings.Current_Total_MBeans] = "現在のMBeanの総数"
stringMap [strings.Withdrawal_Status] = "撤回ステータス"
stringMap [strings.Insufficient_Withdrawal_Amount] = "不十分な引き出し額"
stringMap [strings.Load_More] = "さらに読み込む"
stringMap [strings.Loading] = "読み込み中"
stringMap [strings.It_Is_Over] = "終了しました"
stringMap [strings.Default_Label] = "デフォルトのラベル"
stringMap [strings.Selected_Label] = "選択されたラベル"
stringMap [strings.Current_Location] = "現在の場所"
stringMap [strings.MGold_Introduction] = "MゴールドはMind +を通じてあなたが獲得します"
stringMap [strings.MGold_Details] = "有料クラウドグループ、グループにはナレッジマップ、学習教材などがあります。ユーザーが有料M Beanに参加すると、それらの50％がMゴールドに変換され、Mゴールドが大きくなります。 50以上抽出可能"
stringMap [strings.Cloud_Group_Introduction] = "あなたのグループを共有してください。他の人はストームページでそれを見ることができます"
stringMap [strings.Cloud_Group_Details] = "有料クラウドグループ。グループにはナレッジマップや学習教材などがあります。ユーザーが有料MBeanに参加すると、それらの50％がMゴールドに変換され、Mゴールドが大きくなります。 50以上は撤回することができます。"
stringMap [strings.Share_Storm_Introduction] = "マップをストームコミュニティに共有して、より多くの人々があなたから知識を学び、探索できるようにします"
stringMap [strings.Share_Storm_Details] = "M Beanを使用してマップを保存できるようにすると、他のユーザーがナレッジマップを使用でき、M Beanの50％を取得できます。MBeanを使用して、メンバーを購入し、有料に参加できます。グループ、他の人の地図を使用するために支払うこともできます。"

stringMap [strings.Linked_Successfully] = "リンクに成功しました"
stringMap [strings.Cancel_Archiving] = "アーカイブのキャンセル"
stringMap [strings.Please_Select_Archive_Location] = "アーカイブの場所を選択してください"
stringMap [strings.The_Search_Result_Is_Empty] = "検索結果は空です"

stringMap [strings.Customize_A_Style] = "非メンバーは1つのスタイルしかカスタマイズできません"
stringMap [strings.Adds_Current_Guide_Style_Library] = "現在のマップスタイルをスタイルライブラリに追加する"
stringMap [strings.Trial_Version] = "試用版"
stringMap [strings.Not_Supported_At_The_Moment] = "現在サポートされていません"
stringMap [strings.Mind_File] = "ファイル"
stringMap [strings.Mind_Import] = "インポート"
stringMap [strings.Importing] = "インポート"

stringMap [strings.Mind_Edit_Insert_Video] = "ビデオを挿入"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "ここにビデオをドラッグアンドドロップ"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "アップロード動画の長さは60秒を超えることはできません"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "ビデオを見る"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "動画を削除"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "アップロードされた動画は10MBを超えることはできません"
stringMap [strings.Mind_Upload_Video_Restrictions] = "最大120秒および10MサイズのMP4形式の動画のアップロードのみをサポートします"
stringMap [strings.Mind_Video_Size] = "ビデオサイズ"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "Wordをエクスポート"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "送信TXT"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "クラウドセーブ"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "同期しない"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "保存する"
stringMap [strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "共有グループは別名保存をサポートしていません。ローカルに保存する前に、ファイルを「最近作成したもの」に保存することができます。"
stringMap [strings.Export_Failed] = "エクスポートに失敗しました"
stringMap [strings.Add_File] = "ファイルを追加"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "ファイルを直接ドラッグしてアップロードします"
stringMap [strings.Supported_Imported_File_Types] = "現在インポートがサポートされているファイルの種類：Word（.docxのみ）、XMind、.txt"
stringMap [strings.No_Permission_Do_This] = "この操作を実行する権限がありません"

stringMap [strings.Upgrade_To_Professional] = "Professionalエディションへのアップグレード"
stringMap [strings.Non_Members_Insert_Videos] = "非会員は登録から24時間後にのみ動画を挿入できます"
stringMap [strings.Upload_Failed] = "アップロードに失敗しました"
stringMap [strings.Upload_Failed_Title_Message] = "アップロードに失敗しました。クリックして確認し、アップロードに失敗した残りの画像を削除してください"

stringMap [strings.Mind_Edit_Members_Use] = "メンバーの使用"
stringMap [strings.Invitation_Code] = "招待コード"
stringMap [strings.Student_Certification] = "学生認定"
stringMap [strings.I_Get_It] = "ガイドをスキップします"
stringMap [strings.Welcome_To_Mind] = "マインド+に参加する若いマスターを歓迎します。思考の旅を始めましょう！"
stringMap [strings.Mind_Before] = "最初"
stringMap [strings.Try_A_Map] = "マップを試す"
stringMap [strings.Click_Create] = "クリックして作成"
stringMap [strings.Click_Create_A_Mind_Map] = "クリックしてマインドマップを作成"
stringMap [strings.Mind_About] = "概要"
stringMap [strings.See_Checking_Data] = "個人情報を表示する"
stringMap [strings.Modify_Checking_Data] = "個人情報の変更"
stringMap [strings.Mind_Shear] = "せん断"

stringMap [strings.Mind_Style_Empty] = "お気に入りまたはカスタムスタイルはありません"
stringMap [strings.Mind_Branch_Free_Layout] = "分岐の自由"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "メイン分岐を自由にドラッグします"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "無料のテーマドラッグスナップ"
stringMap [strings.Select_A_Node] = "トピックを選択"
stringMap [strings.Create_Child_Node] = "子テーマを作成する"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "次に、子テーマに素敵なものを追加します"
stringMap [strings.Click_Icon_Panel] = "アイコンパネルをクリックしてください"
stringMap [strings.Select_An_Icon_You_Like] = "好きなアイコンを選択してください"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "次に、マインドマップを新しいものに置き換えます"
stringMap [strings.Select_The_Central_Theme] = "中央のテーマを選択してください"
stringMap [strings.Switch_To_Canvas_Panel] = "キャンバスパネルに切り替え"
stringMap [strings.Click_Layout] = "クリックしてレイアウト"
stringMap [strings.Choose_A_Layout_You_Like] = "好きなレイアウトを選択してください"
stringMap [strings.Mind_Map_Save_Share] = "おめでとうございます、あなたは美しいマインドマップを作成しました！それを保存して友達と共有しましょう〜"
stringMap [strings.Click_Save_Picture] = "クリックして画像を保存"
stringMap [strings.Click_OK] = "クリックOK"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "初心者ガイドを完了しておめでとうございます〜"
stringMap [strings.Upload_Succeeded] = "正常にアップロードされました"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "クイックスタイル"
stringMap [strings.Mind_Extremely_Important] = "非常に重要"
stringMap [strings.Mind_Important] = "重要"
stringMap [strings.Mind_Commonly] = "一般"
stringMap [strings.Mind_Ordinary] = "普通"
stringMap [strings.Member_Exclusive] = "この機能はメンバー専用です。メンバーシップをアップグレードしますか？"
stringMap [strings.Member_Exclusive_Color_Scheme] = "この配色はメンバー専用です。メンバーシップをアップグレードしますか？"
stringMap [strings.Mind_Enlarge] = "拡大"
stringMap [strings.Mind_Narrow] = "Narrow"
stringMap [strings.Mind_Center] = "中央揃え"
stringMap [strings.Mind_Full_Screen] = "フルスクリーン"

stringMap [strings.Mind_Association_Node] = "関連付けノード"
stringMap [strings.Mind_Version_Number] = "バージョン番号"
stringMap [strings.Mind_Copy_Rights] = "Copyright©2021ShanghaiYushu Technology Co.、Ltd。All rightsreserved。"

stringMap [strings.Global_Edit_Task] = "タスク"
stringMap [strings.Global_Detele_Task] = "タスクを削除"
stringMap [strings.Mind_Extremely_Urgent] = "緊急です"
stringMap [strings.Mind_Urgent] = "緊急"
stringMap [strings.Mind_Lower] = "低いです"
stringMap [strings.Mind_Task_Information] = "タスク情報"
stringMap [strings.Mind_Start_Date] = "開始日"
stringMap [strings.Mind_End_Date] = "終了日"
stringMap [strings.Mind_person_Charge] = "執行人"
stringMap [strings.Mind_colse] = "閉じる"
stringMap [strings.Mind_Enter_Names_Separated_By_Commas] = "名前を入力し、複数の名前をカンマで区切ります"
stringMap [strings.Mind_Please_Select_An_Start_Date] = "開始日を選択してください"
stringMap [strings.Mind_Please_Select_An_End_Date] = "終了日を選択してください"
stringMap [strings.Mind_The_Task_Leader_Cannot_Be_Empty] = "エグゼキュータを追加"
stringMap [strings.Mind_The_End_Time_Less_Start_Time] = "終了時刻を開始時刻より小さくすることはできません"
stringMap [strings.Mind_Are_You_Sure_To_Delete_This_Task] = "このタスクを削除してもよろしいですか？"
stringMap [strings.Mind_Start_Later] = "後で開始"
stringMap [strings.Mind_Over] = "Over"
stringMap [strings.Mind_In_Progress_Remaining] = "進行中...残り"
stringMap [strings.Mind_In_Progress_Time] = "進行中...時間内"
stringMap [strings.Mind_level] = "完成度"
stringMap [strings.Mind_Completed] = "完了"
stringMap [strings.Display_Mode] = "表示モード"
stringMap [strings.Default_Sort] = "デフォルトの並べ替え"
stringMap [strings.Edit_Time] = "編集時間"

stringMap [strings.Mind_Year] = "年"
stringMap [strings.Mind_Month] = "月"
stringMap [strings.Mind_Day] = "日"
stringMap [strings.Mind_Day_Day] = "日"
stringMap [strings.Mind_Hours] = "時間"
stringMap [strings.Mind_Minutes] = "分間"

stringMap[strings.Mind_SubjectLine_Space] = "枝のき"
stringMap[strings.Mind_SonSubjectLine_Space] = "子供のき"
stringMap [strings.Mind_SubjectLine_H] = "枝の横向き"
stringMap [strings.Mind_SubjectLine_Vertical_Space] = "枝の縦向き"
stringMap [strings.Mind_SonSubjectLine_H] = "子供の横向き"
stringMap [strings.Mind_SonSubjectLine_Vertical_Space] = "子供の縦向き"

stringMap [strings.Mind_Edit_Latex] = "LaTeXを編集"
stringMap [strings.Mind_Detele_Latex] = "LaTeXを削除"
stringMap [strings.Mind_Are_You_Sure_To_Delete_This_LaTeX] = "LaTeX式を削除してもよろしいですか"
stringMap [strings.Mind_LaTeX_Expression] = "LaTeX式"
stringMap [strings.Mind_LaTeX_Formula_Display_Area] = "数式表示領域"

stringMap [strings.Mind_LaTeX_Add_Template] = "テンプレートに追加"
stringMap [strings.Mind_LaTeX_Common_Template] = "共通テンプレート"
stringMap [strings.Mind_LaTeX_Used_Help_1] = "LaTeX関数は4つのモジュールに分割されています。"
stringMap [strings.Mind_LaTeX_Used_Help_1_1] = "式領域-式に基づいて対応する式グラフィックを生成する"
stringMap [strings.Mind_LaTeX_Used_Help_1_2] = "式領域-LaTeX式を入力してください"
stringMap [strings.Mind_LaTeX_Used_Help_1_3] = "シンボル領域-共通フィールドのシンボルに対応する文字列値"
stringMap [strings.Mind_LaTeX_Used_Help_1_4] = "一般的に使用されるテンプレート-一部のデフォルトの数式。[テンプレートに追加]で数式をカスタマイズできます。また、マウスを動かすと、指定したカスタム数式を削除できます。"
stringMap [strings.Mind_LaTeX_Used_Help_2] = "数式グラフィックは、正しいLaTeX文法規則の下で生成および保存して使用する必要があります"
stringMap [strings.Mind_LaTeX_Used_Help_3] = "色を変更する方法は？"
stringMap [strings.Mind_LaTeX_Used_Help_3_1] = "指定されたテキスト領域を選択し、スタイルシンボルの色をクリックして、テキストの色を変更します。"
stringMap [strings.Mind_LaTeX_Used_Help_3_2] = "たとえば、x = a + bでa + bに色を付けるには、a + bを選択し、スタイルシンボルのカラーブロックをクリックするだけです。"
stringMap [strings.Mind_LaTeX_Used_Help_3_3] = "この時点で、式はx = {\\ color {＃FF0000} a}になります。aをa + bの色に変更します。"
stringMap [strings.Mind_LaTeX_Quick_Title_2] = "ギリシャ文字"
stringMap [strings.Mind_LaTeX_Quick_Title_3] = "数学"
stringMap [strings.Mind_LaTeX_Quick_Title_4] = "スタイルシンボル"
stringMap [strings.Mind_LaTeX_Quick_Title_5] = "セパレータ"
stringMap [strings.Mind_LaTeX_Quick_Title_6] = "可変文字"
stringMap [strings.Mind_LaTeX_Quick_Title_7] = "関数"
stringMap [strings.Mind_LaTeX_Quick_Title_8] = "関係記号"
stringMap [strings.Mind_LaTeX_Quick_Title_9] = "矢印記号"
stringMap [strings.Mind_LaTeX_Quick_Title_10] = "その他の文字"
stringMap [strings.Mind_LaTeX_Quick_Title_11] = "アクサングラーブ"
stringMap [strings.Mind_LaTeX_Error] = "ラテックス式エラー。式が正しいことを確認してください"

stringMap [strings.Mind_Resource_See] = "添付ファイルを表示"
stringMap [strings.Mind_Resource_Delete] = "添付ファイルを削除"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "ここにファイルをドラッグアンドドロップします"
stringMap [strings.Mind_Edit_choose_explain] = "ファイルを選択"
stringMap [strings.Message_Tips_Share_Explain_Does_Not_Exist] = "アップロードされたファイルは20MBを超えることはできません"

stringMap[strings.Please_Enter_Mind_Password] = "4～8桁のパスワードを入力してください"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "パスワードを入力してください"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "この機能は会員機能です"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "ファイルパスワードを削除しました"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "パスワードの桁数が足りない場合は、4から8桁のパスワードを入力してください"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "パスワードが長すぎますので、4から8桁のパスワードを入力してください"
stringMap[strings.Please_Enter_Mind_Password_Success] = "パスワードの設定に成功しました"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "パスワードフォーマットエラー"
stringMap[strings.Mind_Load_Data_Error] = "データロードエラー"
stringMap[strings.Please_Enter_Mind_Password_Error] = "パスワードエラー"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "このガイド作成者のみがパスワードを設定できます"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = '番号付け'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = 'レイヤー1'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = 'レイヤー2'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = 'レイヤー3'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = 'レイヤー4'
stringMap[strings.Mind_Edit_Order_Remove_Label] = '除去じょきょ'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'あなたが望む質問を入力して、Aiは結果を与えます'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Aiアシスタントを合理的に文明的に使用してください'
stringMap[strings.Mind_Ai_To_Map_Label] = '使用'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = '質問を入力してください、Aiは結果を出します...'
stringMap[strings.Mind_Ai_Submit_Label] = '送信'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = '申し訳ありませんが、一日の使用回数が多すぎますので、明日ご利用ください'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = '本日の使用回数は終了しており、永続会員は1日の使用回数を増やすことができます'
stringMap[strings.Mind_Ai_To_Member_Label] = '無料回数がなくなり、会員は1日の利用回数を増やすことができます'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = '手順に従ってください'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
stringMap[strings.Global_Text_Find] = "Find"
stringMap[strings.Global_Text_Replace] = "Replace"
stringMap[strings.Global_Text_Replace_All] = "Replace All"
stringMap[strings.Global_Text_Find_Empty] = "No Content"
stringMap[strings.Global_Text_Find_Result] = "Item & of @"

stringMap[strings.Mind_Fast_Style_Rainbow_Label] = "Life"
stringMap[strings.Mind_Fast_Style_Solid_Label] = "Pure"
stringMap[strings.Mind_Import_File] = "Import File"

stringMap[strings.Mind_Add_Font_Label] = "+ Add local font:ttf、woff..."
stringMap[strings.Mind_Import_Font_Web_Tips] = "Please download the computer client from the official website to use this feature，https://mindyushu.com"
stringMap[strings.Mind_Import_Font_Upgrade_Label] = "The free trial version can only add one external font. Upgrading to Pro users can add more external fonts and all professional version features."
stringMap[strings.Mind_Flow_Chart_Map] = "Flow Chart"
stringMap[strings.Mind_Shape_Corner_Text] = "Process"
stringMap[strings.Mind_Shape_Start_Text] = "Start"
stringMap[strings.Mind_Shape_End_Text] = "End"
stringMap[strings.Mind_Shape_Determine_Text] = "Judging"
stringMap[strings.Mind_Shape_Ellipse_3D_Text] = "Database"
stringMap[strings.Mind_Shape_Cube_Text] = "Science"
stringMap[strings.Mind_Shape_Form_Text] = "Document"
stringMap[strings.Mind_Shape_Corner_Right_Text] = "Card"
stringMap[strings.Mind_Shape_Book_Text] = "Book"
stringMap[strings.Mind_Shape_Text] = "Text"

module.exports = stringMap;