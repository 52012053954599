const { default: strings } = require("./strings");

var stringMap= {};
stringMap[strings.About_App_Name] = "माइंड+ माइंड मैप";
stringMap[strings.TEMPLATE] = "टेम्पलेट";
stringMap[strings.Mind_Edit_Main_Topic] = "शाखा विषय";
stringMap[strings.Global_Add_Free_Node_Tips] = "जोड़ने के लिए स्थान का चयन करने के लिए स्क्रीन पर क्लिक करें";
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = "जोड़ने के लिए स्थान का चयन करने के लिए स्क्रीन पर डबल-टैप करें";
stringMap[strings.Global_Add_Relationship_Tips] = "उस लक्ष्य का चयन करें जिसे संबद्ध करने की आवश्यकता है";
stringMap[strings.Global_Add_Target_Tips] = "कृपया ऑब्जेक्ट जोड़ने के लिए एक नोड चुनें select";
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = "यह वस्तु पहले से ही अन्य मानचित्रों से संबद्ध है";
stringMap[strings.Mind_Edit_Main_Idea] = "केंद्रीय विषय";
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = "संबद्ध नक्शा मौजूद नहीं है";
stringMap[strings.Mind_Style_Add_Icon_Priority] = "वरीयता";
stringMap[strings.Mind_Style_Add_Icon_Progress] = "अनुसूची";
stringMap[strings.Mind_Style_Add_Icon_Stars] = "सितारा";
stringMap[strings.Mind_Style_Add_Icon_Week] = "सप्ताह";
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = "अवतार";
stringMap[strings.Establish] = "सृजन करना";
stringMap[strings.Create_From_Template] = "टेम्पलेट से बनाएं";
stringMap[strings.Common] = "यूनिवर्सल";
stringMap[strings.Function_List] = "समारोह सूची";
stringMap[strings.Recent_Updates] = "हाल ही में अद्यतित";
stringMap[strings.The_Latest_To_Delete] = "हाल ही में हटाया गया";
stringMap[strings.Setting_Up] = "संपर्क करें";
stringMap[strings.Usinghelp] = "मदद का उपयोग करना";
stringMap[strings.The_Download_Client] = "क्लाइंट/मोबाइल संस्करण डाउनलोड करें";
stringMap[strings.Global_Purchase] = "खरीद";
stringMap[strings.Global_Login] = "लॉग इन करें";
stringMap[strings.My_map] = "मेरा नक्शा";
stringMap[strings.Create_Map] = "एक नक्शा बनाएं";
stringMap[strings.Storm] = "आंधी";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = "सामान्य\nविशेषताएं";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = "विशेषताएं";
stringMap[strings.Mind_Organization_Edit_Main_Idea] = "संगठनात्मक केंद्र";
stringMap[strings.Mind_Edit_Free_Topic] = "मुफ्त विषय";
stringMap[strings.Mind_Edit_Sub_Topic] = "उपविषय";
stringMap[strings.Mind_Organization_Main_Topic] = "विभाग";
stringMap[strings.Mind_Edit_Main_Topic] = "शाखा विषय";
stringMap[strings.Mind_Time_Edit_Main_Idea] = "योजना प्रबंधन";
stringMap[strings.Mind_Time_Edit_Head] = "समय";
stringMap[strings.Mind_Time_Edit_Title] = "योजना";
stringMap[strings.Mind_Edit_Details] = "विस्तृत विवरण";
stringMap[strings.Checking_Data] = "डेटा की जाँच";
stringMap[strings.Setting_Information] = "जानकारी सेट करना";
stringMap[strings.Sign_Out] = "प्रस्थान करें";

//समारोह सूची
stringMap[strings.Create_a_Mind_Map] = "माइंड मैप बनाएं";
stringMap[strings.Create_Mind_Map_1] = "【1】चौदह लेआउट मानचित्र बनाएं (दो-तरफा/दाएं/बाएं/संगठन संरचना/एकल बबल चार्ट/डबल बबल चार्ट/बाएं पेड़ चार्ट/दायां पेड़ चार्ट/दो-तरफा पेड़ चार्ट/टेबल/क्षैतिज समयरेखा/वर्टिकल टाइमलाइन/एस टाइमलाइन/पिरामिड )";
stringMap[strings.Create_Mind_Map_2] = "【2】टेम्प्लेट का उपयोग नक्शे को जल्दी से बनाने के लिए किया जा सकता है (बड़ी संख्या में टेम्प्लेट सामग्री संदर्भ)";
stringMap[strings.Create_Mind_Map_3] = "【3】मानचित्र पर नौ प्रकार की रेखा शैलियाँ संपादित करें";
stringMap[strings.Create_Mind_Map_4] = "【4】मानचित्र में नौ लेआउट परिवर्तन संपादित करें";
stringMap[strings.Create_Mind_Map_5] = "【5】लक्ष्य नोड में चाइल्ड नोड्स जोड़ें, समान स्तर पर नोड्स जोड़ें (ऊपरी और निचले)";
stringMap[strings.Create_Mind_Map_6] = "【6】सारांश: सिंगल नोड/मल्टी-नोड को जोड़ने की व्याख्या करें [सारांश]";
stringMap[strings.Create_Mind_Map_7] = "【7】ब्लॉकिंग: मॉडर्नाइजेशन के लिए मैप के सिंगल नोड/मल्टीपल नोड बॉक्स में [ब्लॉकिंग] जोड़ें";
stringMap[strings.Create_Mind_Map_8] = "【8】मुफ़्त नोड जोड़ें";
stringMap[strings.Create_Mind_Map_9] = "【9】किसी भी नोड के बीच चार लाइन शैलियों के संबंध बनाएं";
stringMap[strings.Create_Mind_Map_10] = "【10】चित्र (स्थानीय + सामग्री पुस्तकालय), आइकन, नोट्स, नोड्स के लिंक डालें";
stringMap[strings.Create_Mind_Map_11] = "【11】कॉपी/पेस्ट नोड, कॉपी/पेस्ट शैली";
stringMap[strings.Create_Mind_Map_12] = "【12】निरसन";
stringMap[strings.Create_Mind_Map_13] = "【13】बहु-चयन, एकाधिक नोड्स का एक साथ संचालन";
stringMap[strings.Create_Mind_Map_14] = "【14】सम्मिलित करने योग्य सांख्यिकीय ग्राफ";
stringMap[strings.Create_Mind_Map_15] = "【15】पूरा नक्शा रिकॉर्ड किया जा सकता है";
stringMap[strings.Create_Mind_Map_16] = "【16】नक्शा कॉपी करें";
stringMap[strings.Create_Mind_Map_17] = "【17】आप मानचित्र या संपूर्ण मानचित्र को अन्य फ़ाइलों के मानचित्र पर कॉपी कर सकते हैं";
stringMap[strings.Create_Mind_Map_18] = "【18】सारांश के बाद नक्शा बनाया जा सकता है";
stringMap[strings.Create_Mind_Map_19] = "【19】संपादन करते समय, आप मानचित्र को संपादित करने के लिए स्विच कर सकते हैं (कंप्यूटर साइड)";
stringMap[strings.Create_Mind_Map_20] = "【20】ब्लूटूथ कीबोर्ड संपादन मानचित्र:\n\t[Command]+[a]मुख्य नोड का चयन करें\n\t[Command]+[←][↑][→][↓]चयनित लक्ष्य बदलें या मानचित्र को स्थानांतरित करें\n \ t[कमांड]+[e]लक्ष्य संपादित करें\n\t[कमांड]+[w]चाइल्ड नोड बनाएं\n\t[Command]+[s]लक्षित सिबलिंग नोड बनाएं (शीर्ष)\n\t[Command] + [d]लक्षित सिबलिंग नोड बनाएं (नीचे)\n\t[Command]+[q]चयनित लक्ष्य से बाहर निकलें\n\t[Command]+[-][+]मानचित्र ज़ूम करें ";

stringMap[strings.Beautify_The_Mind_Map] = "मन के नक्शे को सुशोभित करें";
stringMap[strings.Beautify_The_Mind_Map_1] = "【1】माइंड मैप का बैकग्राउंड कलर सेट करें";
stringMap[strings.Beautify_The_Mind_Map_2] = "【2】संपूर्ण मानचित्र रेखा की मोटाई और रंग सेट करें, चाहे वह पतला हो या नहीं";
stringMap[strings.Beautify_The_Mind_Map_3] = "【3】टेक्स्ट फिल कलर, बॉर्डर कलर, बॉर्डर 13 शेप, बॉर्डर की चौड़ाई, शैडो इफेक्ट सेट करें";
stringMap[strings.Beautify_The_Mind_Map_4] = "【4】सभी या नक्शे के हिस्से का रंग और चौड़ाई सेट करें";
stringMap[strings.Beautify_The_Mind_Map_5] = "【5】टेक्स्ट फ़ॉन्ट आकार सेट करें, बोल्ड, इटैलिक, स्टाइल हटाएं";
stringMap[strings.Beautify_The_Mind_Map_6] = "【6】चित्र का आकार, स्थिति, आकार शैली सेट करें";
stringMap[strings.Beautify_The_Mind_Map_7] = "【7】आइकन की सात श्रेणियां जोड़ें (सामान्य / 3 डी / अध्ययन / कार्य / लोग / जानवर / भोजन) और आकार / स्थिति निर्धारित करें";
stringMap[strings.Beautify_The_Mind_Map_8] = "【8】संपूर्ण मानचित्र में एक-क्लिक शैली संदर्भ जोड़ें";
stringMap[strings.Beautify_The_Mind_Map_9] = "【9】समूह प्रबंधन नक्शा";
stringMap[strings.Beautify_The_Mind_Map_10] = "【10】वैयक्तिकृत पृष्ठभूमि (सामग्री पुस्तकालय) को संपूर्ण मानचित्र में जोड़ा जा सकता है";
stringMap[strings.Beautify_The_Mind_Map_9] = "【11】व्यक्तिगत सीमाओं या पृष्ठभूमि को एकल नोड (सामग्री पुस्तकालय) में जोड़ा जा सकता है";
stringMap[strings.Beautify_The_Mind_Map_9] = "【12】नोड की लंबाई खींची जा सकती है";


stringMap[strings.Map_Output] = "नक्शा आउटपुट";
stringMap[strings.Map_Output_1] = "【1】पिक्चर को सेव करना";
stringMap[strings.Map_Output_2] = "【2】पीडीएफ सहेजें";
stringMap[strings.Map_Output_3] = "【3】लिंक शेयर करें";
stringMap[strings.Map_Output_4] = "【4】तूफान को साझा करें";
stringMap[strings.Map_Output_4] = "【5】प्रिंट";

stringMap[strings.Work_together] = "एक साथ काम करो";
stringMap[strings.Work_together_1] = "【1】एक साझा समूह बनाएं (समूह लेबल, समूह घोषणा)";
stringMap[strings.Work_together_2] = "【2】साझा समूह के सदस्यों को जोड़ें";
stringMap[strings.Work_together_3] = "【3】समूह में एकाधिक सदस्य एक ही मानचित्र को एक साथ संपादित करते हैं";
stringMap[strings.Work_together_4] = "【4】समूह में साझा की गई फ़ाइलें अपलोड करें（word/excle/pdf）";
stringMap[strings.Work_together_5] = "【5】आप समूह में एक संदेश छोड़ सकते हैं";
stringMap[strings.Work_together_6] = "【6】साझा समूह प्रबंधन अनुमतियाँ सेटिंग";
stringMap[strings.Work_together_7] = "【7】विघटित समूह";

stringMap[strings.Work_together] = "ज्ञान साझा करना (तूफान)";
stringMap[strings.Community_Sharing_1] = "【1】आप अपनी रचना को तूफान में साझा कर सकते हैं, ताकि अधिक लोग देख सकें";
stringMap[strings.Community_Sharing_1] = "【2】संबंधित तूफान सामग्री देखने के लिए आप टैग का चयन कर सकते हैं";
stringMap[strings.Community_Sharing_1] = "【3】आप तूफान कार्यों पर लाइक और कमेंट कर सकते हैं";

//最近更新
stringMap[strings.Recently_Updated_Title_1] = "एक व्यक्तिगत खाता बनाएँ";
stringMap[strings.Recently_Updated_Title_2] = "विशाल टेम्पलेट ज्ञानकोष";
stringMap[strings.Recently_Updated_Title_3] = "कई प्लेटफार्मों का समर्थन करें";
stringMap[strings.Recently_Updated_Title_4] = "हर विवरण को अनुकूलित करें";
stringMap[strings.Recently_Updated_Title_5] = "निजीकृत शैली शैली पुस्तकालय";
stringMap[strings.Recently_Updated_Title_6] = "नक्शा आउटपुट";
stringMap[strings.Recently_Updated_Title_7] = "बादल भंडारण";
stringMap[strings.Recently_Updated_Title_8] = "पंद्रह प्रकार के नक्शे";


stringMap[strings.Recently_Updated_Content_1] = "एक व्यक्तिगत खाता बनाएं और बनाए गए मानचित्र को खाते से सहेजें";
stringMap[strings.Recently_Updated_Content_2] = "सीखने और काम की दक्षता में सुधार के लिए मास टेम्प्लेट नॉलेज बेस को सीधे उद्धृत किया जाता है";
stringMap[strings.Recently_Updated_Content_3] = "समर्थन वेब संस्करण ऑनलाइन उत्पादन, आईओएस मोबाइल टर्मिनल, मैक टर्मिनल, एंड्रॉइड मोबाइल टर्मिनल, एंड्रॉइड टैबलेट मल्टी-प्लेटफॉर्म उपयोग Support";
stringMap[strings.Recently_Updated_Content_4] = "व्यक्तिगत प्राथमिकताओं के अनुसार पाठ, पृष्ठभूमि, रेखाएं, चित्र चिह्न आदि के डिजाइन को सुशोभित कर सकते हैं";
stringMap[strings.Recently_Updated_Content_5] = "व्यक्तिगत शैली शैली पुस्तकालय को सीधे उद्धृत किया जाता है, जिससे रंग मिलान की समस्या समाप्त हो जाती है";
stringMap[strings.Recently_Updated_Content_6] = "चित्रों को सहेज सकते हैं, पीडीएफ प्रारूप, सीधे दोस्तों के साथ साझा कर सकते हैं";
stringMap[strings.Recently_Updated_Content_7] = "मजबूत क्लाउड स्टोरेज सपोर्ट, अब डेटा हानि के बारे में चिंता करने की ज़रूरत नहीं है";
stringMap[strings.Recently_Updated_Content_8] = "टू-वे, राइट, लेफ्ट, ऑर्गनाइजेशनल स्ट्रक्चर, सिंगल बबल चार्ट, डबल बबल चार्ट, लेफ्ट ट्री चार्ट, राइट ट्री चार्ट, टू-वे ट्री चार्ट, टेबल, हॉरिजॉन्टल टाइमलाइन, वर्टिकल टाइमलाइन, एस टाइमलाइन, आठ लेआउट को बदला जा सकता है मर्जी";

//हाल ही में हटाया गया
stringMap[strings.Mind_Mindmap_Deleted_Title] = "हाल ही में हटाया गया";
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = "बहाल";
stringMap[strings.Mind_Mindmap_Deleted_Forever] = "हटाना";
stringMap[strings.Mind_Mindmap_Deleted_Filename] = "फ़ाइल का नाम";
stringMap[strings.Mind_Mindmap_Deleted_Tips] = "एक बार यहां हटा दिए जाने पर, इसे स्थायी रूप से हटा दिया जाएगा";
stringMap[strings.Mind_Mindmap_Deleted_Operating] = "ऑपरेटिंग";
stringMap[strings.Mind_Mindmap_Deleted_Selected] = "हाल ही में हटाए गए मानचित्र खोजें";
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = "तिथि हटाएं";

//संपर्क करें
stringMap[strings.Contact_Us_No_Public] = "सार्वजनिक";
stringMap[strings.Contact_Us_QQ_Group] = "क्यूक्यू समूह";
stringMap[strings.Contact_Us_Message_Board] = "संदेश बोर्ड";
stringMap[strings.Contact_Us_Username] = "नाम";
stringMap[strings.Contact_Us_Email] = "मेलबॉक्स";
stringMap[strings.Contact_Us_Voicemail] = "कृपया अपनी बहुमूल्य टिप्पणियाँ दें";
stringMap[strings.Contact_Us_Send_Email] = "ईमेल भेजें";

//संदेश सत्यापित करें
stringMap[strings.Input_Username_isNull] = "नाम खाली नहीं हो सकता";
stringMap[strings.Input_Email_isNull] = "कृपया अपना वैध ईमेल दर्ज करें";
stringMap[strings.Input_Voicemail_isNull] = "कृपया सामग्री दर्ज करें";
stringMap[strings.Input_Introduction_isNull] = "परिचय खाली नहीं हो सकता";
stringMap[strings.Input_Title_isNull] = "शीर्षक खाली नहीं हो सकता";

stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] = "एक मान्य दूरभाष क्रमांक दर्ज करे";
stringMap[strings.Input_Please_Enter_Verification_Code] = "कृपया सत्यापन कोड दर्ज करें!";
stringMap[strings.Input_Please_Enter_The_Password] = "कृपया पासवर्ड दर्ज करें!";
stringMap[strings.Input_The_Password_Format_Is_Incorrect] = "पासवर्ड प्रारूप गलत है!";
stringMap[strings.Input_Please_Enter_A_New_Password] = "कृपया एक नया पासवर्ड दर्ज करें!";
stringMap[strings.Input_Confirm_The_New_Password] = "कृपया पुष्टि पासवर्ड दर्ज करें!";
stringMap[strings.Input_The_Passwords_Are_Inconsistent] = "असंगत पासवर्ड!";
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] = "कृपया सही सत्यापन कोड दर्ज करें!";
stringMap[strings.Input_The_Verification_Code_Error] = "सत्यापन कोड त्रुटि!";

//मदद का उपयोग करना
stringMap[strings.Mind_Usinghelp_1] = "माइंड मैप बनाएं";
stringMap[strings.Mind_Usinghelp_2] = "नोड्स कैसे जोड़ें";
stringMap[strings.Mind_Usinghelp_3] = "मुक्त नोड बनाएं";
stringMap[strings.Mind_Usinghelp_4] = "संघ बनाना";
stringMap[strings.Mind_Usinghelp_5] = "कैनवास को सुशोभित करें";
stringMap[strings.Mind_Usinghelp_6] = "पाठ को सुशोभित करें";
stringMap[strings.Mind_Usinghelp_7] = "नोट्स डालें, लिंक्स";
stringMap[strings.Mind_Usinghelp_8] = "आइकन डालें";
stringMap[strings.Mind_Usinghelp_9] = "चित्र सम्मिलित करें";
stringMap[strings.Mind_Usinghelp_10] = "कैसे हटाना रद्द करें";
stringMap[strings.Mind_Usinghelp_11] = "दिमाग का नक्शा निर्यात करें";
stringMap[strings.Mind_Usinghelp_12] = "सदस्य उन्नयन";
stringMap[strings.Mind_Usinghelp_13] = "गर्म कुंजी";
stringMap[strings.Mind_Usinghelp_Understood] = "समझ गया";
stringMap[strings.Mind_Usinghelp_14] = "सामग्री पुस्तकालय डालें";
//सहायता-2 . का प्रयोग करें
stringMap[strings.Mind_Usinghelp_15] = "नया नक्शा";
// stringMap[strings.Mind_Usinghelp_2] = "नोड जोड़ें";
// stringMap[strings.Mind_Usinghelp_4] = "संघ बनाना";
// stringMap[strings.Mind_Usinghelp_5] = "कैनवास को सुशोभित करें";
// stringMap[strings.Mind_Usinghelp_6] = "पाठ को सुशोभित करें";
// stringMap[strings.Mind_Usinghelp_7] = "नोट्स, लिंक जोड़ें Add";
stringMap[strings.Mind_Usinghelp_17] = "कार्रवाई पूर्ववत करें, रद्द करें";
stringMap[strings.Mind_Usinghelp_18] = "स्वैप करने के लिए नोड खींचें";
stringMap[strings.Mind_Usinghelp_19] = "नोड संरेखण";
stringMap[strings.Mind_Usinghelp_20] = "नक्शा प्रकार बदलें";
stringMap[strings.Mind_Usinghelp_21] = "मानचित्र रेखा शैली";
stringMap[strings.Mind_Usinghelp_22] = "नक्शा पृष्ठभूमि रंग";
stringMap[strings.Mind_Usinghelp_23] = "नोड आकार सेट करें";
stringMap[strings.Mind_Usinghelp_24] = "नोड लाइन शैली";
stringMap[strings.Mind_Usinghelp_25] = "नोड चित्र सेटिंग्स";
stringMap[strings.Mind_Usinghelp_26] = "नोड आइकन सेटिंग्स";
stringMap[strings.Mind_Usinghelp_27] = "नोड जोड़ सारांश";
stringMap[strings.Mind_Usinghelp_28] = "सिंगल नोड फ्रेम चयन";
stringMap[strings.Mind_Usinghelp_29] = "मल्टी-नोड ब्लॉक";
stringMap[strings.Mind_Usinghelp_30] = "नोड सीमा जोड़ें";
stringMap[strings.Mind_Usinghelp_31] = "एक चार्ट जोड़ें";
stringMap[strings.Mind_Usinghelp_32] = "छिपे हुए नोड्स और पुनर्प्राप्ति";
stringMap[strings.Mind_Usinghelp_33] = "नोड एकाधिक संचालन";
stringMap[strings.Mind_Usinghelp_34] = "अंदाज़ बदलो";
stringMap[strings.Mind_Usinghelp_35] = "भाषण और स्पष्टीकरण डालें";
stringMap[strings.Mind_Usinghelp_36] = "तस्वीर के लिए नक्शा";
stringMap[strings.Mind_Usinghelp_37] = "नक्शा पीडीएफ दस्तावेज़";
stringMap[strings.Mind_Usinghelp_38] = "नक्शा प्रिंट करें";
stringMap[strings.Mind_Usinghelp_39] = "लिंक साझा करना और देखना";
stringMap[strings.Mind_Usinghelp_40] = "तूफान के लिए मानचित्र साझा करना";
stringMap[strings.Mind_Usinghelp_41] = "फोल्डर का उपयोग";
stringMap[strings.Mind_Usinghelp_42] = "मानचित्र हटाएं और पुनर्स्थापित करें";
//使用帮助3
stringMap[strings.Mind_Usinghelp_43] = "मित्र समूह में शामिल होने के लिए आवेदन करें";
stringMap[strings.Mind_Usinghelp_44] = "समूह को कैसे भंग करें";
stringMap[strings.Mind_Usinghelp_45] = "दस्तावेज़ अपलोड करें";
stringMap[strings.Mind_Usinghelp_46] = "आवेदन में शामिल होने के लिए सहमत";
stringMap[strings.Mind_Usinghelp_47] = "सह-संपादन";
stringMap[strings.Mind_Usinghelp_48] = "नया साझाकरण समूह";
stringMap[strings.Mind_Usinghelp_49] = "समूह के सदस्यों को आमंत्रित करें";

//风暴
stringMap[strings.Mind_Storm_Recommend] = "की सिफारिश";
stringMap[strings.Mind_Storm_Boilingpoint] = "क्वथनांक";
stringMap[strings.Mind_Storm_Unfold] = "उधेड़ना";
stringMap[strings.Mind_Storm_Putaway] = "जेल भेजना";
stringMap[strings.Mind_Storm_Myclassification] = "मेरा वर्गीकरण";
stringMap[strings.Mind_Storm_Recommended_Classification] = "अनुशंसित वर्गीकरण";
stringMap[strings.Mind_Storm_Click_Add_Category] = "श्रेणी जोड़ने के लिए क्लिक करें";
stringMap[strings.Mind_Storm_No_Classification] = "कोई वर्गीकरण नहीं";
stringMap[strings.Mind_Storm_No_Tinterested] = "रुचि नहीं";
stringMap[strings.Mind_Storm_Report] = "रिपोर्ट good";
stringMap[strings.Mind_Storm_Views] = "विचारों";

stringMap[strings.Mind_Storm_Pornographic] = "अश्लील अश्लीलता";
stringMap[strings.Mind_Storm_Advertising_Harassment] = "विज्ञापन उत्पीड़न";
stringMap[strings.Mind_Storm_Political_Religion] = "राजनीतिक धर्म";
stringMap[strings.Mind_Storm_Infringement] = "उल्लंघन (चित्र, मानहानि, साहित्यिक चोरी, कपटपूर्ण उपयोग)";
stringMap[strings.Mind_Storm_Prohibited_Content] = "निषिद्ध सामग्री";
stringMap[strings.Mind_Storm_Spread_Rumors] = "प्रसारित अफवाहें";
stringMap[strings.Mind_Storm_Report_Successful] = "रिपोर्ट सफल";

//编辑
stringMap[strings.Global_Edit] = "संपादित करें";
stringMap[strings.Global_Cancel] = "रद्द करना";
stringMap[strings.Global_Ok] = "निर्धारित";
stringMap[strings.Global_Name] = "उपनाम";
stringMap[strings.Global_Introduction] = "परिचय";
stringMap[strings.Global_Change_Avatar] = "परिवर्तन अवतार";
stringMap[strings.Global_Local_Upload] = "स्थानीय अपलोड";

//个人资料
stringMap[strings.User_Personal_information] = "व्यक्तिगत जानकारी";
stringMap[strings.User_Member_Validity_Period] = "सदस्यता वैधता अवधि";
stringMap[strings.User_My_Share] = "मेरे हिस्से";
stringMap[strings.User_My_Collection] = "मेरा संग्रह";
stringMap[strings.User_More_Message] = "अधिक";
stringMap[strings.User_Deleted_Sotrm] = "तूफान हटाएं";
stringMap[strings.User_Folder_Rename] = "नाम बदलें";
stringMap[strings.User_Folder_Rename_Root] = "अनुमतियों को संशोधित करें";
stringMap[strings.User_Folder_Rename_Root_Title] = "अनुमतियों को संशोधित करें";
stringMap[strings.User_Folder_Root_Public] = "पूरी तरह से सार्वजनिक";
stringMap[strings.User_Folder_Root_Viewonly] = "सार्वजनिक फ़ोल्डर केवल देखने की अनुमति देता है";
stringMap[strings.User_Folder_Root_Private] = "निजी";
stringMap[strings.User_Folder_Root_Public_Introduction] = "अन्य लोग आपके पसंदीदा को आपके प्रोफाइल पेज पर देख सकते हैं";
stringMap[strings.User_Message_Link] = "संपर्क";
stringMap[strings.User_New_Group] = "नया समूह";
stringMap[strings.User_New_Group_Title] = "एक नया समूह बनाएं";
stringMap[strings.User_New_Group_Style_Title] = "फाइल का प्रकार";
stringMap[strings.User_Share_Is_Null] = "अभी तक कोई हिस्सा नहीं!";
stringMap[strings.User_Deleted_Storm_Tips] = "क्या आप वाकई इस तूफान को हटाना चाहते हैं?";
stringMap[strings.User_Remove_From_List] = "सूची से निकालें",
    stringMap[strings.User_Move_To] = "करने के लिए कदम",
    stringMap[strings.User_Deleted_Link] = "लिंक हटाएं"
stringMap[strings.User_Collction_Is_Null] = "कोई संग्रह नहीं"
stringMap[strings.User_Collection] = "पसंदीदा"

//登录框
stringMap[strings.User_Lolgin_Welcome_To_Login] = "मन में आपका स्वागत है+"
stringMap[strings.User_Lolgin_Free_Registration] = "निःशुल्क पंजीकरण"
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] = "कृपया फ़ोन नंबर दर्ज करें"
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] = "कृपया सत्यापन कोड दर्ज करें"
stringMap[strings.User_Lolgin_Obtain] = "प्राप्त"
stringMap[strings.User_Lolgin_Password_Login] = "पासवर्ड लॉगिन"
stringMap[strings.User_Lolgin_Login] = "लॉग इन करें"
stringMap[strings.User_Lolgin_Register_An_Account] = "एक खाता दर्ज करो"
stringMap[strings.User_Lolgin_Already_Have_An_Account] = "पहले से ही एक खाता है"
stringMap[strings.User_Lolgin_To_Log_In] = "लॉग इन करने के लिए"
stringMap[strings.User_Lolgin_Please_Enter_Password] = "कृपया पासवर्ड दर्ज करें"
stringMap[strings.User_Lolgin_Confirm_Password] = "पासवर्ड की पुष्टि कीजिये"
stringMap[strings.User_Lolgin_Registered] = "दर्ज कराई"
stringMap[strings.User_Lolgin_Account_Login] = "खाता प्रवेश"
stringMap[strings.User_Lolgin_Scan_Code_Login] = "स्कैन कोड लॉगिन"
stringMap[strings.User_Lolgin_Forgot_Password] = "पासवर्ड भूल गए"
stringMap[strings.User_Lolgin_Verification_Code_Login] = "सत्यापन कोड लॉगिन"
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = "माइंड+ खोलें और सेटिंग इंटरफ़ेस के शीर्ष पर स्वाइप करें"
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = "यह क्यूआर कोड पांच मिनट में समाप्त हो जाता है, कृपया क्लिक करें"
stringMap[strings.User_Lolgin_Refresh] = "ताज़ा करना"
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = "स्कैनिंग सफल रही, कृपया अपने फोन पर पुष्टि करें"
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] = "लॉगिन को अधिकृत करना है या नहीं"
stringMap[strings.User_Lolgin_Please_Enter_New_Password] = "कृपया एक नया पासवर्ड दर्ज करें"
stringMap[strings.User_Lolgin_Reset] = "रीसेट"

//我的导图
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] = "टेम्प्लेट प्रकार या टेम्प्लेट खोजें"
stringMap[strings.My_Mind_Date] = "तारीख"
stringMap[strings.My_Mind_Create_Folder] = "फोल्डर बनाएं"
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] = "कृपया फ़ोल्डर का नाम दर्ज करें"
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] = "कृपया एक नया फ़ोल्डर नाम दर्ज करें"
stringMap[strings.My_Mind__No_Map_Yet_Go] = "अभी तक कोई नक्शा नहीं है, जाओ"
stringMap[strings.My_Mind_Create] = "सृजन करना"
stringMap[strings.My_Mind_Right] = "सही!"
stringMap[strings.My_Mind_Open] = "चालू करो"
stringMap[strings.My_Mind_Type_Left_Right] = "दोहरी गाइड आरेख"
stringMap[strings.My_Mind_Type_Unilateral] = "एकतरफा नक्शा"
stringMap[strings.My_Mind_Type_Bottom] = "संगठन चार्ट"
stringMap[strings.My_Mind_Type_Bubble] = "बबल चार्ट"
stringMap[strings.My_Mind_Type_Time_Vertical] = "अनुसूची चार्ट"
stringMap[strings.My_Mind_Blank_Mind_Map] = "खाली दिमाग का नक्शा"
stringMap[strings.My_Mind_All] = "सब"
stringMap[strings.My_Mind_Views] = "विचारों："

//风暴搜索
stringMap[strings.Storm_Search_How_Title] = "प्रसिद्ध टग्स"
stringMap[strings.Storm_History_Search] = "ऐतिहासिक खोज "

//公共 header
stringMap[strings.Public_Header_Mind] = "नक्शा"
stringMap[strings.Public_Header_Outline] = "रेखांकित करें"
stringMap[strings.Public_Header_Download_Apps] = "ऐप्स डाउनलोड करें"
stringMap[strings.Public_Header_Mobile_Version] = "मोबाइल वर्शन"
stringMap[strings.Public_Header_Creator] = "रचनाकार："
stringMap[strings.Public_Header_View_Frequency] = "आवृत्ति देखें："
stringMap[strings.Public_Header_Save] = "बचा ले"
stringMap[strings.Header_Mind_Tips_Saved] = "बचाया"
stringMap[strings.Header_Mind_Tips_Edit] = "संपादित"
stringMap[strings.Header_Mind_Tips_Update_Saved] = "अपडेट सहेजा गया"

//导图编辑菜单
stringMap[strings.Mind_Edit_Revoke] = "वापस लेना"
stringMap[strings.Mind_Edit_Restore] = "बहाल"
stringMap[strings.Mind_Edit_Subtopic] = "उपविषय"
stringMap[strings.Mind_Edit_Top_Subtopic] = "उपविषय"
stringMap[strings.Mind_Edit_Bottom_Subtopic] = "अगला टॉपिक"
stringMap[strings.Mind_Edit_Free_Node] = "मुक्त नोड"
stringMap[strings.Mind_Edit_Summary] = "सारांश"
stringMap[strings.Mind_Edit_Block] = "खंड मैथा"
stringMap[strings.Mind_Edit_Insert] = "डालने"
stringMap[strings.Mind_Edit_Related] = "साथी"
stringMap[strings.Mind_Edit_Style] = "अंदाज"
stringMap[strings.Mind_Edit_Recording] = "रिकॉर्डिंग"
stringMap[strings.Mind_Edit_Insert_Picture] = "चित्र सम्मिलित करें"
stringMap[strings.Mind_Edit_Insert_Icon] = "आइकन डालें"
stringMap[strings.Mind_Edit_Insert_Remarks] = "नोट डालें"
stringMap[strings.Mind_Edit_Insert_Link] = "लिंक डालें"
stringMap[strings.Mind_Edit_Insert_Summary_Graph] = "चार्ट डालें"
stringMap[strings.Mind_Edit_Upload] = "डालना"
stringMap[strings.Mind_Edit_Right_Icon] = "आइकन"
stringMap[strings.Mind_Edit_Right_Style] = "अंदाज"
stringMap[strings.Mind_Edit_Audio_Decoding] = "डिकोडिंग..."
stringMap[strings.Mind_Edit_Audio_Duration] = "समयांतराल"
stringMap[strings.Mind_Edit_Delete_Audio_Tips] = "क्या आप निश्चित रूप से रिकॉर्ड किए गए ऑडियो को हटाना चाहते हैं?"
stringMap[strings.Mind_Edit_Join_Membership] = "सदस्यता में शामिल हों"
stringMap[strings.Mind_Edit_Upgrade] = "उन्नयन"
stringMap[strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap[strings.Mind_Edit_Join_Membership_Tips] = "सदस्यों के पास अधिक रिकॉर्डिंग समय हो सकता है, अब एक सदस्य के रूप में अपग्रेड करने के लिए?"

stringMap[strings.Mind_Edit_Choose_Material] = "सामग्री चुनें"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = "चित्र को यहां खींचें और छोड़ें"
stringMap[strings.Mind_Edit_Select_locally] = "स्थानीय रूप से चुनें"
stringMap[strings.Mind_Edit_Choose_From] = "सामग्री पुस्तकालय से चुनें"
stringMap[strings.Mind_Edit_Mine] = "मेरी"
stringMap[strings.Mind_Edit_My_Material] = "मेरी सामग्री"
stringMap[strings.Mind_Edit_Recently_Used] = "हाल ही में उपयोग किया गया"
stringMap[strings.Mind_Edit_Use] = "प्रयोग करें"
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = "अस्थायी रूप से कोई सामग्री नहीं, इसे जोड़ें!"
stringMap[strings.Mind_Edit_Select_Chart_Type] = "चार्ट प्रकार चुनें"
stringMap[strings.Mind_Edit_Remarks] = "टिप्पणियों"
stringMap[strings.Mind_Edit_Remarks_Tips] = "कृपया टिप्पणी दर्ज करें"
stringMap[strings.Mind_Edit_Add] = "में जोड़े"
stringMap[strings.Mind_Edit_Please_Enter_The_Link] = "请输入链接"

//编辑页左侧菜单
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] = "पिक्चर को सेव करना"
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] = "पीडीएफ निर्यात करें"
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] = "लिंक शेयर करें"
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] = "तूफान को साझा करें"
stringMap[strings.Mind_Edit_Left_Menu_Printing] = "प्रिंट"
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = "पूर्ण संस्करण अनलॉक करें"
stringMap[strings.Mind_Edit_Left_Menu_Help] = "ह मदद"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] = "प्रारूप सहेजें"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] = "pngप्रारूप"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = "jpgप्रारूप"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = "webpप्रारूप"
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] = "नक्शा साझा करें"
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] = "शेयर स्विच"
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = "लिंक को कॉपी करके भेजा जा सकता है"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] = "प्रतिरूप जोड़ना"
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] = "यदि आप लिंक बदलना चाहते हैं, तो कृपया क्लिक करें"
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] = "पुनर्जन्म"
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] = "लिंक पासवर्ड："
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] = "बचाने की अनुमति दें"
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = "देखे जाने की अधिकतम संख्या"
stringMap[strings.Mind_Edit_Left_Menu_Infinite] = "असीमित"
stringMap[strings.Mind_Edit_Left_Menu_Limit] = "सीमा"
stringMap[strings.Mind_Edit_Left_Menu_Second] = "बार"
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] = "लिंक वैधता अवधि"
stringMap[strings.Mind_Edit_Left_Menu_1_Day] = "एक दिन"
stringMap[strings.Mind_Edit_Left_Menu_3_Days] = "3 दिन"
stringMap[strings.Mind_Edit_Left_Menu_7_Days] = "7 दिन"
stringMap[strings.Mind_Edit_Left_Menu_Permanent] = "स्थायी"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = "सामग्री और लिंक कॉपी करें "
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] = "क्या आप वाकई पुन: उत्पन्न करना चाहते हैं? पिछला लिंक अमान्य होगा!"
stringMap[strings.Mind_Edit_Left_Menu_Share] = "इसे शेयर करें"
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] = "कस्टम वर्गीकरण："
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] = "टिप्पणियों की अनुमति देना है या नहीं"
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] = "श्रेणी जोड़ें संपादित करें"
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] = "सदस्य लाभ"
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] = "समर्थन मंच"
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] = "सदस्यता में शामिल हों"
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = "सक्रियण कोड मोचन"
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] = "खरीद के बाद सदस्य अधिकार"
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] = "भुगतान का तरीका चुनें"
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] = "गतिविधि मूल्य"
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = "वास्तव में भुगतान की गई राशि"
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] = "अब नवीनीकृत करें"
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] = "सेवा की शर्तें"
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] = "गोपनीयता नीति"
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] = "एक्टिवेशन कोड："
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = "कृपया सक्रियण कोड दर्ज करें"
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = "सक्रियण कोड सत्यापित करें"
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] = "श्रेणी का चयन करें"
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = "मुख्य शीर्षक खाली नहीं हो सकता"
stringMap[strings.Mind_Edit_Left_Menu_Coupon] = "कूपन："
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = "कृपया कूपन कोड दर्ज करें"
stringMap[strings.Mind_Confirm_Use_Coupons] = "कूपन के उपयोग की पुष्टि करें"
stringMap[strings.Mind_Edit_Left_Menu_Verification] = "सत्यापन"
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] = "रियायती मूल्य"
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] = "तुरंत भुगतान करें"
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] = "वीचैट पे"
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = "मोचन जानकारी की पुष्टि करें"
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] = "रिडेम्पशन कोड："
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] = "लेन देन"

//编辑页右侧菜单
stringMap[strings.Mind_Edit_Right_Menu_canvas] = "कैनवास"
stringMap[strings.Mind_Edit_Right_Menu_background_Color] = "पीछे का रंग"
stringMap[strings.Mind_Edit_Right_Menu_layout] = "ख़ाका"
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] = "रेखा शैली"
stringMap[strings.Mind_Edit_Right_Menu_line_Color] = "रेखा का रंग"
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] = "रेखा की चौडाई"
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] = "लंबा और पतला"
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] = "थीम फ़ॉन्ट"
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] = "बाल फ़ॉन्ट"
stringMap[strings.Mind_Edit_Right_Menu_text_Color] = "पाठ का रंग"
stringMap[strings.Mind_Edit_Right_Menu_font_Size] = "फ़ॉन्ट आकार"
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] = "फ़ॉन्ट शैली"
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] = "सीमा रंग"
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] = "रंग भरना"
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] = "सीमा आकार"
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] = "सीमा चौड़ाई"
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = "छाया प्रभाव दिखाएँ (सदस्यता समारोह)"
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] = "रेखा का रंग"
stringMap[strings.Mind_Edit_Right_Menu_Dotted_Line] = "बिंदुयुक्त रेखा"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] = "छवि का आकार"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] = "चित्र स्थान"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] = "चित्र आकार"
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] = "चिह्न आकार"
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] = "चिह्न स्थिति"
stringMap[strings.Mind_Edit_Right_Menu_routine] = "पारंपरिक"
stringMap[strings.Mind_Edit_Right_Menu_priority] = "वरीयता"
stringMap[strings.Mind_Edit_Right_Menu_Progress] = "अनुसूची"
stringMap[strings.Mind_Edit_Right_Menu_Stars] = "सितारा"
stringMap[strings.Mind_Edit_Right_Menu_Week] = "सप्ताह"
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] = "अवतार"
stringMap[strings.Mind_Edit_Right_Menu_3D] = "3D"
stringMap[strings.Mind_Edit_Right_Menu_Learning] = "सीखना"
stringMap[strings.Mind_Edit_Right_Menu_Work] = "नौकरियां"
stringMap[strings.Mind_Edit_Right_Menu_Characters] = "चरित्र"
stringMap[strings.Mind_Edit_Right_Menu_Animals] = "चरित्र"
stringMap[strings.Mind_Edit_Right_Menu_Food] = "खाना"
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] = "कॉपी स्टाइल"
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] = "पेस्ट शैली"
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] = "आकार संशोधित करें"
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] = "छवि देखें"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] = "चित्र हटाएं"
stringMap[strings.Mind_Edit_Node_Menu_Copy] = "प्रतिलिपि"
stringMap[strings.Mind_Edit_Node_Menu_Paste] = "पेस्ट करें"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] = "लाइन हटाएं"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = "हटाना"
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] = "नोट्स संपादित करें"
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] = "खुली लिंक"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] = "नोट हटाएं"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] = "लिंक हटाएं"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] = "आइकन हटाएं"
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] = "चिह्न शैली"

//统计图编辑面板
stringMap[strings.Echart_Edit_Statistical] = "आंकड़े"
stringMap[strings.Echart_Edit_Value] = "मूल्य"
stringMap[strings.Echart_Edit_Color] = "रंग"
stringMap[strings.Echart_Edit_Title] = "शीर्षक"
stringMap[strings.Echart_Edit_Detailed_Description] = "विस्तृत विवरण"
stringMap[strings.Echart_Edit_Value_Sort] = "तरह"
stringMap[strings.Echart_Edit_Value_Display_Type] = "डिस्प्ले प्रकार"
stringMap[strings.Echart_Edit_Value_Color_Scheme] = "रंग योजना"
stringMap[strings.Echart_Edit_Value_Display_Size] = "प्रदर्शन का आकार"
stringMap[strings.Echart_Edit_Value_Title_Size] = "शीर्षक का आकार"
stringMap[strings.Echart_Edit_Value_Explain_Size] = "आकार की व्याख्या करें"
stringMap[strings.Echart_Edit_Value_Subscribe] = "अंशदान"

//风暴标签页
stringMap[strings.StormBookTag_Subscribed] = "已订阅"
stringMap[strings.StormBookTag_List] = "列表"

//风暴分享页
stringMap[strings.Storm_Share_Comment] = "टिप्पणी"
stringMap[strings.Storm_Share_Reply] = "जवाब दे दो"
stringMap[strings.Storm_Share_Let_Me_Comment_Too] = "मुझे भी कमेंट करने दो~"
stringMap[strings.Storm_Share_Release] = "रिहाई"
stringMap[strings.Storm_Share_Main_Title] = "मुख्य शीर्षक"
stringMap[strings.Storm_Share_Subtitle] = "उपशीर्षक"
stringMap[strings.Storm_Share_Author] = "लेखक"
stringMap[strings.Storm_Share_Replies] = "जवाब"
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] = "अभी तक कोई टिप्पणी नहीं, पहले आएं"
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] = "कृपया एक टिप्पणी दर्ज करें~"
stringMap[strings.Storm_Share_Delete_Comment_Tips] = "क्या आप इस कमेंट को मिटाने के बारे में पक्के हैं?"

//导图内部使用帮助
stringMap[strings.Mind_Usinghelp_Btn_1] = "शॉर्टकट कुंजियाँ (खिड़कियाँ)"
stringMap[strings.Mind_Usinghelp_Btn_2] = "शॉर्टकट कुंजी (मैक)"
stringMap[strings.Mind_Usinghelp_Btn_3] = "विवरण"
stringMap[strings.Mind_Usinghelp_Btn_4] = "[Ctrl] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_5] = "[Command] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_6] = "चाइल्ड नोड जोड़ें"
stringMap[strings.Mind_Usinghelp_Btn_7] = "[Delet]"
stringMap[strings.Mind_Usinghelp_Btn_8] = "वर्तमान नोड हटाएं (ध्यान दें कि यदि यह संपादन स्थिति में है तो इसे हटाया नहीं जा सकता है)"
stringMap[strings.Mind_Usinghelp_Btn_9] = "[Ctrl] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_10] = "[Command] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_11] = "वर्तमान नोड को संशोधित करें"
stringMap[strings.Mind_Usinghelp_Btn_12] = "[Ctrl] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_13] = "[Command] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_14] = "[Ctrl] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_15] = "[Command] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_16] = "नक्शा बड़ा करें"
stringMap[strings.Mind_Usinghelp_Btn_17] = "[Ctrl] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_18] = "[Command] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_19] = "नक्शा ज़ूम आउट करें"
stringMap[strings.Mind_Usinghelp_Btn_20] = "[Ctrl] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_21] = "[Command] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_22] = "[Ctrl] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_23] = "[Command] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_24] = "[Ctrl] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_25] = "[Command] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_26] = "[Ctrl] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_27] = "[Command] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_28] = "संपादन से बाहर निकलें"

//提示文字
stringMap[strings.Message_Tips_Login_Success] = "लॉग इन सफल"
stringMap[strings.Message_Tips_Dlt_Success] = "सफलतापूर्वक हटा दिया गया"
stringMap[strings.Message_Tips_Report_Success] = "रिपोर्ट सफल"
stringMap[strings.Message_Tips_Copy_Success] = "सफलतापूर्वक कॉपी करें"
stringMap[strings.Message_Tips_Collction_Success] = "संग्रह सफलता"
stringMap[strings.Message_Tips_Avatar_Modification_Success] = "अवतार सफलतापूर्वक संशोधित"
stringMap[strings.Message_Tips_Signature_Modification_Success] = "हस्ताक्षर सफलतापूर्वक संशोधित किया गया"
stringMap[strings.Message_Tips_Name_Modification_Success] = "उपनाम सफलतापूर्वक संशोधित किया गया"
stringMap[strings.Message_Tips_Modification_Success] = "सफलतापूर्वक संशोधित"
stringMap[strings.Message_Tips_New_Profile_Success] = "नया फ़ोल्डर सफलतापूर्वक बनाएं"
stringMap[strings.Message_Tips_Mobile_Success] = "सफलतापूर्वक ले जाया गया"
stringMap[strings.Message_Tips_Submit_Success] = "सफलतापूर्वक जमा"
stringMap[strings.Message_Tips_Exchange_Success] = "सफल मोचन"
stringMap[strings.Message_Tips_Please_Code] = "कृपया सक्रियण कोड दर्ज करें"
stringMap[strings.Message_Tips_Agree_To_Terms] = "कृपया गोपनीयता नीति से सहमत हों"
stringMap[strings.Message_Tips_Please_Sign_In] = "साइन इन करें"
stringMap[strings.Message_Tips_Share_Success] = "सफल साझाकरण"
stringMap[strings.Message_Tips_Create_Success] = "सफलतापूर्वक बनाया गया"
stringMap[strings.Message_Tips_Profile_Name_No_Null] = "फ़ोल्डर का नाम खाली नहीं हो सकता"
stringMap[strings.Message_Tips_Selecte_Success] = "खोज पूर्ण"
stringMap[strings.Message_Tips_Subscription_Success] = "सफलतापूर्वक सब्सक्राइब किया गया"
stringMap[strings.Message_Tips_Unsubscribe_Success] = "सफलतापूर्वक सदस्यता समाप्त करें"
stringMap[strings.Message_Tips_Related_Content_Not_Found] = "संबंधित सामग्री नहीं मिली"
stringMap[strings.Message_Tips_Max_Add_Class] = "आप केवल अधिक से अधिक जोड़ सकते हैं"
stringMap[strings.Message_Tips_Registered_Success] = "पंजीकरण सफलता"
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] = "स्कैन कोड लॉगिन विफल"
stringMap[strings.Message_Tips_Share_Is_Null] = "शेयर लिंक मौजूद नहीं है"
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] = "अपलोड फ़ाइल 3MB . से अधिक नहीं हो सकती"
stringMap[strings.Message_Tips_Get_Permission_Successfully] = "अनुमति लेने में सफलता मिली"
stringMap[strings.Message_Tips_Membership_Required] = "सदस्यता आवश्यक"
stringMap[strings.Message_Tips_Save_Success] = "सफलतापूर्वक बचाया"
stringMap[strings.Message_Tips_Scan_Code_Login_Success] = "स्कैन कोड लॉगिन सफलतापूर्वक"
stringMap[strings.Message_Tips_Style] = "वर्गीकरण"

//大纲模式
stringMap[strings.Outline_Menu_Indent] = "खरोज"
stringMap[strings.Outline_Menu_Decrease_Indent] = "इंडेंटेशन घटाएं"

//帮助提示
stringMap[strings.Help_Background_Content] = "रेखा पृष्ठभूमि संबंधित, रेखा पृष्ठभूमि संबंधित, रेखा पृष्ठभूमि संबंधित, रेखा पृष्ठभूमि संबंधित, रेखा पृष्ठभूमि संबंधित, रेखा पृष्ठभूमि संबंधित, रेखा पृष्ठभूमि संबंधित, रेखा पृष्ठभूमि संबंधित, रेखा पृष्ठभूमि संबंधित,"
stringMap[strings.Help_Mind_Content] = "आप अपनी पसंद के अनुसार अलग-अलग लेआउट के साथ मैप बना सकते हैं: डुअल-गाइड मैप, लेफ्ट-साइड मैप, राइट-साइड मैप, ऑर्गनाइजेशन चार्ट, टू-वे ट्री, लेफ्ट-साइड ट्री, राइट-साइड ट्री और टेबल"
stringMap[strings.Help_Share_Link_Content] = "लिंक साझा करना WeChat, QQ, Weibo और अन्य चैनलों में लिंक के रूप में साझा किया जा सकता है, और मित्र सामग्री देख सकते हैं"
stringMap[strings.Using_Templates] = "टेम्प्लेट का उपयोग करें"

//邀请码
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] = "कृपया आमंत्रण कोड दर्ज करें"
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = "कृपया सही आमंत्रण कोड दर्ज करें"
stringMap[strings.User_Lolgin_Optional] = "ऐच्छिक"

//使用素材

stringMap[strings.Cannot_Add_To_Node_Background] = "नोड पृष्ठभूमि पर लागू नहीं है"
stringMap[strings.Cannot_Add_To_Img_Background] = "पाठ पृष्ठभूमि के लिए उपयुक्त नहीं है"
stringMap[strings.Cannot_Add_To_Mind_Background] = "रैपिंग लाइन बैकग्राउंड के लिए उपयुक्त नहीं है"
stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = "एक पृष्ठभूमि छवि जोड़ें"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = "पृष्ठभूमि छवि हटाएं"
stringMap[strings.Mind_Add_Background_Image] = "पृष्ठभूमि छवि डालें"
stringMap[strings.Mind_Delete_Background_Image] = "पृष्ठभूमि छवि हटाएं"

stringMap[strings.Permanent_Member] = "स्थायी सदस्य"
stringMap[strings.Insufficient_Storage_Space] = "अपर्याप्त संग्रहण स्थान, स्थान बढ़ाने के लिए सदस्यों को अपग्रेड करें."
stringMap[strings.Used_Space] = "भंडारण"
stringMap[strings.Current_Storage] = "वर्तमान भंडारण："
stringMap[strings.Used_Space_Upgrade] = "स्टोरेज स्पेस अपग्रेड"
stringMap[strings.My_Mind_Is_Null_Tips] = "माइंड+ ब्रेन मैप और नोट्स सुपर निमोनिक्स, सोच को पंख दें बनाएं, प्रदर्शित करें, योजना बनाएं, नोट करें"
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] = "अपर्याप्त संग्रहण स्थान, टेम्पलेट का उपयोग करने के लिए सदस्यों को अपग्रेड करें।"

stringMap[strings.Group_Management] = "समूह"
stringMap[strings.Save_Img_Fill_Full] = "पक्का"
stringMap[strings.Save_Img_Center_Tilt] = "केंद्र झुकाव"
stringMap[strings.Save_Img_File_Full_Dark_Color] = "ढका हुआ (अंधेरा)"
stringMap[strings.Save_Img_Mind_Map_Watermarking] = "माइंड+ माइंड मैप वॉटरमार्क"
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = "ज्ञान सृजन की सुरक्षा के लिए वॉटरमार्क जोड़ें："
stringMap[strings.Save_Img_Watermark_Text] = "वॉटरमार्क टेक्स्ट："
stringMap[strings.Save_Img_Watermark_Logo] = "वॉटरमार्क लोगो："
stringMap[strings.Save_Img_Watermark_Style] = "वॉटरमार्क शैली："
stringMap[strings.Save_Img_Please_Input] = "कृपया दर्ज करें"
stringMap[strings.Save_Img_Chop_Tips] = "वॉटरमार्क अधिकारों का आनंद लेने के लिए एक सदस्य खोलें"
stringMap[strings.Public_Header_Mindmap_Mind] = "नक्शा मोड"
stringMap[strings.Copy_Mind_Map] = "नक्शा कॉपी करें"
stringMap[strings.Font_Style_Bold] = "साहसिक"
stringMap[strings.Font_Style_Italics] = "तिरछा"
stringMap[strings.Font_Style_Delete_Line] = "स्ट्राइकथ्रू"
stringMap[strings.Remove_Any] = "हटाना"

stringMap[strings.Mindmap_Review] = "मानचित्र समीक्षा"
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] = "प्रभाव को गहरा करें और याददाश्त बढ़ाएं, विचारों और सूचनाओं को कुशलता से सुलझाएं"
stringMap[strings.Mindmap_Review_Content] = "मानचित्र समीक्षा संपादन प्रक्रिया के प्लेबैक को संदर्भित करती है, और सामग्री को केंद्रीय विषय से क्रमिक रूप से चलाया जाता है; मानचित्र समीक्षा आपको सिस्टम इन्वेंट्री, विश्लेषण करने और विचारों और खामियों को खोजने में मदद कर सकती है। समीक्षा प्रक्रिया के दौरान, आपको विभिन्न प्रेरणाएँ मिल सकती हैं ."
stringMap[strings.Mindmap_Review_Speed] = "स्पीड"
stringMap[strings.Mindmap_Review_Word_Speed] = "पाठ की गति"
stringMap[strings.Mindmap_Review_Background_Ratio] = "पृष्ठभूमि अनुपात"
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] = "वर्तमान प्लेबैक नोड आवर्धन"
stringMap[strings.Mindmap_Review_Node_Playback_Order] = "नोड प्लेबैक ऑर्डर"


stringMap[strings.Mind_Group_Picture_Upload] = "अवतार अपलोड"
stringMap[strings.Mind_Group_Upload_From_Local] = "स्थानीय अपलोड"
stringMap[strings.Mind_Group_Mind_Mapping] = "मन मानचित्रण"
stringMap[strings.Mind_Group_Information] = "डेटा"

stringMap[strings.Mind_Group_Invite_New_Members] = "नए सदस्यों को आमंत्रित करें"
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = "कृपया फ़ोन नंबर दर्ज करें"
stringMap[strings.Mind_Group_Cancel] = "रद्द करना"
stringMap[strings.Mind_Group_Confirm] = "निर्धारित"
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = "फ़ोन नंबर खाली नहीं हो सकता"
stringMap[strings.Mind_Group_Invite_The_Successful] = "आमंत्रण सफल"


stringMap[strings.Mind_Group_Shared_Group_Membership] = "साझा समूह के सदस्य"
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "साझा समूह के सदस्यों की अधिकतम संख्या" 
stringMap[strings.Mind_Group_Membership_Limit] = "सदस्य सीमा" 
stringMap[strings.Mind_Group_Current_Member_Limit] = "वर्तमान सदस्य सीमा" 
stringMap[strings.Mind_Group_Upgrade_Member_Limit] = "सदस्य सीमा अपग्रेड करें"
stringMap[strings.Mind_Group_Members_Of_The_Search] = "सदस्यों के लिए खोजें"
stringMap[strings.Mind_Group_Creator] = "रचनाकार"
stringMap[strings.Mind_Group_Administrator] = "प्रशासक"
stringMap[strings.Mind_Group_Number_Of_People] = "लोग"
stringMap[strings.Mind_Group_Selected] = "चुना"
stringMap[strings.Mind_Group_Member] = "सदस्य"
stringMap[strings.Mind_Group_Check_All] = "सभी का चयन करे"
stringMap[strings.Mind_Group_Remove] = "हटाना"
stringMap[strings.Mind_Group_Invite_New_Members] = "नए सदस्यों को आमंत्रित करें"
stringMap[strings.Mind_Group_Delete_Members] = "सदस्य हटाएं"
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] = "कृपया हटाने के लिए सदस्य का चयन करें "
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "क्या आप वाकई इस समूह से उपयोगकर्ता को निकालना चाहते हैं?"
stringMap[strings.Mind_Group_Add_As_Administrator] = "प्रबंधक जोड़ें"
stringMap[strings.Mind_Group_Accomplish] = "अंजाम देना"
stringMap[strings.Mind_Group_Compile] = "संपादित करें"
stringMap[strings.Mind_Group_Group_Owner] = "भगवान"
stringMap[strings.Mind_Group_Add_Admin] = "प्रबंधक जोड़ें"
stringMap[strings.Mind_Group_Group_ID] = "समूह आईडी"
stringMap[strings.Mind_Group_Group_Number] = "समूह संख्या"
stringMap[strings.Mind_Group_Name] = "नाम"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "कृपया साझा करने वाले समूह का नाम दर्ज करें"
stringMap[strings.Mind_Group_Introduce] = "परिचय"
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "कृपया साझाकरण समूह का संक्षिप्त परिचय दें"
stringMap[strings.Mind_Group_Announcement] = "मुनादी करना"
stringMap[strings.Mind_Group_Shared_Group_Tag] = "साझा समूह लेबल"
stringMap[strings.Mind_Group_Custom_Tag] = "कस्टम लेबल"
stringMap[strings.Mind_Group_Save] = "बचा ले"
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "समूह का नाम खाली नहीं हो सकता"
stringMap[strings.Mind_Group_Establish] = "सृजन करना"
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] = "मानचित्र/फ़ोल्डर बनाने की अनुमति दें"
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] = "मानचित्रों के संपादन की अनुमति दें"
stringMap[strings.Mind_Group_Holder] = "सब"
stringMap[strings.Mind_Group_Only_The_Administrator] = "केवल प्रशासक"
stringMap[strings.Mind_Group_Not_Allow] = "अनुमति नहीं हैं"
stringMap[strings.Mind_Group_Allows_Map_Deletion] = "नक्शा हटाने की अनुमति दें"
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "फ़ोरम को संदेश भेजने की अनुमति दें"
stringMap[strings.Mind_Group_Export] = "निर्यात"
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "छवियों/पीडीएफ को सहेजने की अनुमति दें"
stringMap[strings.Mind_Group_Open_Group_Watermark] = "ग्रुप वॉटरमार्क चालू करें"
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "ओपन करने के बाद ग्रुप का लोगो माइंड मैप पर प्रिंट हो जाएगा"
stringMap[strings.Mind_Group_Allow_Saving_Maps] = "मानचित्रों को सहेजने की अनुमति दें"
stringMap[strings.Mind_Group_Save_It_To_My_Map] = "मेरे मानचित्र में सहेजें"
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "तूफान को साझा करने की अनुमति देना है या नहीं"
stringMap[strings.Mind_Group_Join_The_Way] = "शामिल कैसे हों"
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] = "क्या नए सदस्यों को आमंत्रित करने की अनुमति दी जाए"
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "क्या खोज समूह संख्या को शामिल होने देना है"
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "क्या आपको शामिल होने पर संदेश को सत्यापित करने की आवश्यकता है"
stringMap[strings.Mind_Group_Shared_Group_Data] = "साझा समूह जानकारी"
stringMap[strings.Mind_Group_Authority_Management] = "प्राधिकरण प्रबंधन"
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] = "साझा समूह छोड़ें"
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "क्या आप निश्चित रूप से साझाकरण समूह छोड़ना चाहते हैं?"
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] = "विघटित समूह"
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "समूह भंग होने के बाद फ़ाइलें हटा दी जाएंगी। विघटन की पुष्टि करें?"
stringMap[strings.Mind_Group_Return_To_Home_Page] = "वापस मुख्य पृष्ठ पर"
stringMap[strings.Mind_Group_Join_The_Group] = "समूह में शामिल"
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] = "खोजने के लिए कृपया समूह संख्या/समूह का नाम दर्ज करें"
stringMap[strings.Mind_Group_Apply_To_Join] = "सदस्यता के लिए आवेदन"
stringMap[strings.Mind_Group_Join] = "शामिल हों"
stringMap[strings.Mind_Group_Verification] = "सत्यापन"
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] = "कृपया एक सत्यापन संदेश दर्ज करें"
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] = "सामग्री खाली नहीं हो सकती"
stringMap[strings.Mind_Group_Name] = "साझा समूह का नाम"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "कृपया साझा करने वाले समूह का नाम दर्ज करें"
stringMap[strings.Mind_Group_Introduction] = "समूह परिचय साझा करना"
stringMap[strings.Mind_Group_Group_Indication] = "समूह संख्या"
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "निर्माता आलसी है, मैंने इसे अभी तक पेश नहीं किया है~"
stringMap[strings.Mind_Group_Shared_Group_Announcement] = "समूह घोषणा साझा करना"
stringMap[strings.Mind_Group_No_Announcement] = "कोई घोषणा नहीं"
stringMap[strings.Mind_Group_Click_On] = "विस्तार करने के लिए क्लिक करें"
stringMap[strings.Mind_Group_Click_On_The_Hidden] = "छिपाने के लिए क्लिक करें"
stringMap[strings.Mind_Group_Altogether] = "संपूर्ण"
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "ग्रुप नंबर को सफलतापूर्वक कॉपी करें"
stringMap[strings.Mind_Group_Stick] = "ऊपर"
stringMap[strings.Mind_Group_There_Is_A_New_Content] = "नई सामग्री"
stringMap[strings.Mind_Group_Cancel_The_Top] = "अनपिंक"
stringMap[strings.Mind_Group_Setting_Up] = "सेट अप"
stringMap[strings.Mind_Group_Usinghelp] = "मदद का उपयोग करना"
stringMap[strings.Mind_Group_Set_The_Message] = "समूह संदेश"
stringMap[strings.Mind_Group_Apply_To_Join] = "सदस्यता के लिए आवेदन"
stringMap[strings.Mind_Group_Invite_To_Join] = "शामिल होने के लिए आमंत्रित करें"
stringMap[strings.Mind_Group_Consent] = "इस बात से सहमत"
stringMap[strings.Mind_Group_Turn_Down] = "इनकार"
stringMap[strings.Mind_Group_Label] = "लेबल"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "कृपया एक साझा समूह लेबल नाम दर्ज करें"
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "ज्ञान सामग्री को सह-निर्माण और साझा करने के लिए मित्रों और सहकर्मियों को आमंत्रित करें"
stringMap[strings.Mind_Group_Immediately_Create] = "अब बनाओ"
stringMap[strings.Mind_Group_Immediately_To_Join] = "अब सम्मिलित हों"

stringMap[strings.Currently_Selected] = "वर्तमान ध्यान"
stringMap[strings.LabelManagement] = "लेबल प्रबंधन"
stringMap[strings.NoAttention] = "कोई ध्यान नहीं"
stringMap[strings.Mindmap_Review_Play] = "खेल"

stringMap[strings.Mind_Group_Joined] = "में शामिल हो गए"
stringMap[strings.Mind_Group_Download_App] = "ऐप डाउनलोड करें"
stringMap[strings.Mind_Group_No_Content] = "अभी तक कोई सामग्री नहीं"
stringMap[strings.Mind_Group_Individual] = "ए"
stringMap[strings.Mind_Group_Message_Area] = "संदेश क्षेत्र"
stringMap[strings.Mind_Group_No_Message] = "अभी तक कोई टिप्पणी नहीं, जाओ"
stringMap[strings.Mind_Group_Leave_Message] = "एक संदेश छोड़ें"
stringMap[strings.Mind_Group_Bar] = "बार"
stringMap[strings.Mind_Group_See_More] = "और देखें"
stringMap[strings.Mind_Group_Enter_Invitation_Code] = "आमंत्रण कोड दर्ज करें"
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] = "एक साझा समूह में शामिल होने के लिए आवेदन करें"
stringMap[strings.Reasons_For_Application_0_30_Words] = "आवेदन का कारण (0-30 शब्द)"
stringMap[strings.Mind_Group_Send_Out] = "भेजने"
stringMap[strings.Mind_Group_Join_Successfully] = "सफलतापूर्वक जुड़ें"
stringMap[strings.Mind_Group_Application_Sent_Successfully] = "आवेदन सफलतापूर्वक भेजा गया"

stringMap[strings.Mind_Group_Save_The_Mapping] = "नक्शा सहेजें"
stringMap[strings.Mind_Group_Update_Record] = "रिकॉर्ड संशोधित करें"

stringMap[strings.Mind_Edit_Page_Switch_Mapping] = "नक्शा स्विच करें"
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] = "मानचित्र समीक्षा"
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "कोई मौजूदा उपयोगकर्ता मानचित्र संपादित कर रहा है"
stringMap[strings.Mind_Edit_Page_Password] = "पारण शब्द"

stringMap[strings.Mind_Edit_Page_Sharing_Group] = "साझा समूह"
stringMap[strings.Mind_MBean] = "एम बीन्स"
stringMap[strings.Mind_My_MBean] = "माई एम बीन"
stringMap[strings.Alipay] = "अलीपे"
stringMap[strings.Alipay_Payment] = "अली-पे के साथ भुगतान करें"
stringMap[strings.Please_Pay_On_The_Payment_Page] = "भुगतान करने के लिए कृपया भुगतान पृष्ठ पर जाएं"
stringMap[strings.WeChat] = "WeChat"
stringMap[strings.Mind_Shooping_Total] = "संपूर्ण"
stringMap[strings.Mind_Shooping_Confirm_Payment] = "भुगतान की पुष्टि करें"
stringMap[strings.Mind_Shooping_Redeem_Now] = "अब एवज करें"

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] = "भुगतान और खरीद की पुष्टि करना है या नहीं"
stringMap[strings.Mind_Tips] = "प्रेरित करना"
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "आपके एम बीन्स पर्याप्त नहीं हैं, क्या आप एम बीन्स खरीदना चाहते हैं?"

stringMap[strings.Mind_share_scan_code] = "माइंड+स्कैन कोड"

stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "क्या इसे उस स्थान पर बहाल किया गया है जहां इसे आखिरी बार खेला गया था?"
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "शेष खेलने योग्य समय"

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] = "फ़ाइल पूरी तरह से हटा दी जाएगी, क्या आप इसे भंग करना सुनिश्चित करते हैं?"
stringMap[strings.Group_Mind_Up_One_Level] = "ऊपरी स्तर"
stringMap[strings.Group_Mind_No_Documents] = "फ़ाइल नहीं"
stringMap[strings.Group_Mind_PaymentSuccessful] = "भुगतान सफल"

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] = "लोगों द्वारा सहयोगात्मक संपादन, कई टर्मिनलों का रीयल-टाइम सिंक्रोनाइज़ेशन, दस्तावेज़ साझा करना, टीम सहयोग दक्षता में सुधार"

stringMap[strings.Mind_Edit_Subscript] = "सबस्क्रिप्ट"
stringMap[strings.Mind_Edit_Text_Location] = "पाठ की स्थिति"
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] = "साझा समूह में आयात करें"

stringMap[strings.Standard_Colors] = "मानक रंग"
stringMap[strings.More_Colors] = "और अधिक रंग"
stringMap[strings.Reset_Password] = "पासवर्ड रीसेट"
stringMap[strings.Account_Password_Login] = "खाता पासवर्ड लॉगिन"
stringMap[strings.Authentication_code_Login_registration] = "सत्यापन कोड लॉगिन/रजिस्टर"
stringMap[strings.One_End_Creation_Multi_End_Synchronization] = "वन एंड क्रिएशन, मल्टी एंड सिंक्रोनाइज़ेशन"
stringMap[strings.Rich_And_Diverse_Mapping_Structure] = "समृद्ध और विविध मानचित्र संरचना"
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] = "विशाल उत्तम टेम्पलेट लाइब्रेरी"
stringMap[strings.Map_Review_Play] = "मानचित्र समीक्षा-प्ले"
stringMap[strings.CommunityLearningAndSharingPlatform] = "नॉलेज लर्निंग और शेयरिंग एक्सचेंज प्लेटफॉर्म"

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] = "संग्रहण स्थान अपग्रेड करें"
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] = "मुझे भी कमेंट करने दो"
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "कोई खबर सूचना नहीं"
stringMap[strings.Failed_To_Get_Recording_Device] = "रिकॉर्डिंग डिवाइस प्राप्त करने में विफल"

stringMap[strings.Search_Pagination] = "खोज पृष्ठ पर अंक लगाना"
stringMap[strings.Please_Enter_A_Paging_Name] = "कृपया पृष्ठ का नाम दर्ज करें"
stringMap[strings.Operation_Settings] = "ऑपरेशन सेटिंग्स"
stringMap[strings.Shortcut_key_Settings] = "शॉर्टकट कुंजी सेटिंग्स"
stringMap[strings.Press_Key_Combination] = "कृपया आवश्यक कुंजी संयोजन दबाएं"
stringMap[strings.Mind_Reduction] = "कमी"
stringMap[strings.Double_Click_Add_Free_Node] = "एक खाली नोड जोड़ने के लिए रिक्त स्थान पर डबल-क्लिक करें"
stringMap[strings.Shortcut_Conflict] = "शॉर्टकट कुंजी संघर्ष"
stringMap[strings.Select_Main_Node] = "मुख्य नोड चुनें"
stringMap[strings.Telephone] = "फोन"
stringMap[strings.Microblog] = "माइक्रोब्लॉग"
stringMap[strings.Address] = "पता"
stringMap[strings.Company_Address] = "ब्लॉक बी, बिल्डिंग 21, नंबर 1158 झोंगक्सिन रोड, सोंगजियांग जिला, शंघाई (सोंगजियांग लिंगांग काओहेजिंग)"
stringMap[strings.Mind_Archive] = "आर्काइव"
stringMap[strings.Mind_Bookshelf] = "बुकशेल्फ़"
stringMap[strings.Search_Archive] = "बुकशेल्फ़ खोजें"
stringMap[strings.Modify_Archive_Order] = "बुकशेल्फ़ ऑर्डर संशोधित करें"
stringMap[strings.Modification_Order] = "संशोधन आदेश"
stringMap[strings.Please_Enter_Archive_Name] = "कृपया बुकशेल्फ़ का नाम दर्ज करें"
stringMap[strings.Message_Tips_Archive_Name_No_Null] = "बुक शेल्फ का नाम खाली नहीं हो सकता"
stringMap[strings.Mind_Edit_Delete_Archive_Tips] = "क्या आप वाकई इस बुकशेल्फ़ को हटाना चाहते हैं?"
stringMap[strings.Mind_Edit_Delete_Bookcase_Tips] = "क्या आप वाकई इस बुककेस को हटाना चाहते हैं?"
stringMap[strings.Mind_Edit_Delete_Map_Tips] = "क्या आप वाकई इस मानचित्र को हटाना चाहते हैं?"
stringMap[strings.Mind_Edit_Confirm] = "पुष्टि करें"
stringMap[strings.Mind_Import_Map] = "मानचित्र आयात करें"
stringMap[strings.Mind_Bookcase] = "बुककेस"
stringMap[strings.Added_Successfully] = "सफलतापूर्वक जोड़ा गया"
stringMap[strings.Message_Tips_Bookcase_Name_No_Null] = "बुककेस का नाम खाली नहीं हो सकता"

stringMap[strings.Mind_Association_Map] = "एसोसिएशन मैप"
stringMap[strings.Mind_confirm_Association_Map] = "क्या आप निश्चित रूप से इस मानचित्र को संबद्ध करना चाहते हैं?"
stringMap[strings.Mind_Association_Map_See] = "एसोसिएशन मैप देखें"
stringMap[strings.Mind_Association_Map_Delete] = "एसोसिएशन मैप हटाएं"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "सहसंबंध रेखा संरचना"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "सहसंबंध रेखा की शुरुआत"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "सहसंबंध रेखा का अंत"

stringMap[strings.Share_Group_To_Storm] = "क्या समूह को तूफान में साझा करना है"
stringMap[strings.Apply_To_Join_Storm_Platform] = "तूफान मंच पर, अन्य लोग सामान्य ज्ञान के प्रेमियों के साथ विचार-मंथन करते हुए शामिल होने के लिए देखते हैं और आवेदन करते हैं"
stringMap[strings.Pay_To_Goin] = "जुड़ने के लिए भुगतान करें"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "शामिल होने पर एम बीन्स का भुगतान करना होगा"
stringMap[strings.Mbean_Can_Be_Realized] = "एमबीन एहसास हुआ"

stringMap[strings.Mind_Visit] = "विजिट"
stringMap[strings.Mind_Details] = "विवरण"
stringMap[strings.Mind_See] = "देखें"
stringMap[strings.Earn_Mbeans] = "एमबीन्स कमाएँ"
stringMap[strings.Mind_Explain] = "विवरण"
stringMap[strings.Mind_Parenting] = "पेरेंटिंग"
stringMap[strings.Mind_Internet] = "इंटरनेट"
stringMap[strings.Mind_Psychology] = "मनोविज्ञान"
stringMap[strings.Mind_literature] = "साहित्य"
stringMap[strings.Mind_Technology] = "प्रौद्योगिकी"
stringMap[strings.Mind_Workplace] = "कार्यस्थल"
stringMap[strings.Mind_Life] = "लाइफ"
stringMap[strings.Mind_Subject] = "विषय"
stringMap[strings.Hot_Group] = "हॉट ग्रुप"
stringMap[strings.Open_Group] = "ओपन ग्रुप"
stringMap[strings.Mind_Group_Used] = "इस्तेमाल किया गया"

stringMap[strings.Mind_Click_Select] = "चयन करने के लिए क्लिक करें"
stringMap[strings.Mind_Double_Click_Enter] = "प्रवेश करने के लिए डबल-क्लिक करें"
stringMap[strings.Search_Shared_Groups] = "साझा समूह खोजें"
stringMap[strings.Search_Storm] = "स्टॉर्म खोजें"
stringMap[strings.Members_Save_Free] = "सदस्य सेव फ्री"
stringMap[strings.Bank_Card_Account_Name] = "कृपया अपना बैंक कार्ड खाता नाम दर्ज करें"
stringMap[strings.Bank_Card_No] = "बैंक कार्ड नंबर"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "कृपया अपना बैंक कार्ड नंबर दर्ज करें"
stringMap[strings.Branch_Sub_Branch_Information] = "शाखा शाखा की जानकारी"
stringMap[strings.Branch_Sub_Branch_Example] = "उदाहरण के लिए: हांग्जो शाखा चेंग्शी उप-शाखा"
stringMap[strings.Application_For_Withdrawal] = "आवेदन निकासी के लिए"
stringMap[strings.Withdrawal_Amount] = "आहरण राशि"
stringMap[strings.Withdrawal] = "वापसी"
stringMap[strings.Current_Revenue] = "वर्तमान आय"
stringMap[strings.Recharge] = "रिचार्ज"
stringMap[strings.Current_Total_MBeans] = "MBeans की वर्तमान कुल संख्या"
stringMap[strings.Withdrawal_Status] = "निकासी की स्थिति"
stringMap[strings.Insufficient_Withdrawal_Amount] = "अपर्याप्त निकासी राशि"
stringMap[strings.Load_More] = "अधिक लोड करें"
stringMap[strings.Loading] = "लोड हो रहा है"
stringMap[strings.It_Is_Over] = "यह खत्म हो गया है"
stringMap[strings.Default_Label] = "डिफ़ॉल्ट लेबल"
stringMap[strings.Selected_Label] = "चयनित लेबल"
stringMap[strings.Current_Location] = "वर्तमान स्थान"
stringMap[strings.MGold_Introduction] = "M गोल्ड आपके द्वारा माइंड+ के माध्यम से अर्जित किया जाता है"
stringMap[strings.MGold_Details] = "आपका भुगतान किया हुआ क्लाउड समूह, समूह में ज्ञान के नक्शे, शिक्षण सामग्री आदि हैं। यदि उपयोगकर्ता भुगतान M बीन्स में शामिल होते हैं, तो उनमें से 50% को M गोल्ड और M गोल्ड में परिवर्तित किया जाएगा। 50 से अधिक निकाला जा सकता है"
stringMap[strings.Cloud_Group_Introduction] = "अपने समूह को साझा करें, अन्य इसे तूफान पृष्ठ पर देख सकते हैं"
stringMap[strings.Cloud_Group_Details] = "आपका भुगतान किया हुआ क्लाउड समूह। समूह में ज्ञान के नक्शे, शिक्षण सामग्री आदि हैं। यदि उपयोगकर्ता भुगतान M बीन्स में शामिल होते हैं, तो उनमें से 50% को M गोल्ड और M गोल्ड में बदल दिया जाएगा। 50 से अधिक वापस लिया जा सकता है।"
stringMap[strings.Share_Storm_Introduction] = "तूफान समुदाय को अपना नक्शा साझा करें, ताकि अधिक लोग आपसे सीख सकें और ज्ञान का पता लगा सकें"
stringMap[strings.Share_Storm_Details] = "यदि आप मानचित्रों को सहेजने के लिए एम बीन्स के उपयोग को सक्षम करते हैं, तो अन्य उपयोगकर्ता आपके ज्ञान मानचित्र का उपयोग कर सकते हैं, और आपको एम बीन्स का 50% भी मिलेगा। एम बीन्स का उपयोग सदस्यों को खरीदने और भुगतान में शामिल होने के लिए किया जा सकता है। समूह , आप अन्य लोगों के मानचित्रों का उपयोग करने के लिए भी भुगतान कर सकते हैं।"

stringMap[strings.Linked_Successfully] = "सफलतापूर्वक लिंक किया गया"
stringMap[strings.Cancel_Archiving] = "संग्रह रद्द करें"
stringMap[strings.Please_Select_Archive_Location] = "कृपया संग्रह स्थान का चयन करें"
stringMap[strings.The_Search_Result_Is_Empty] = "खोज परिणाम खाली हैं"

stringMap[strings.Customize_A_Style] = "गैर-सदस्य केवल एक शैली को अनुकूलित कर सकते हैं"
stringMap[strings.Adds_Current_Guide_Style_Library] = "वर्तमान मानचित्र शैली को शैली पुस्तकालय में जोड़ें"
stringMap[strings.Trial_Version] = "ट्रायल वर्जन"
stringMap[strings.Not_Supported_At_The_Moment] = "वर्तमान में समर्थित नहीं है"
stringMap[strings.Mind_File] = "फाइल"
stringMap[strings.Mind_Import] = "आयात"
stringMap[strings.Importing] = "आयात करना"

stringMap[strings.Mind_Edit_Insert_Video] = "वीडियो डालें"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "वीडियो को यहां खींचें और छोड़ें"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "वीडियो अपलोड करने की अवधि 60 से अधिक नहीं हो सकती"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "वीडियो देखें"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "वीडियो हटाएं"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "अपलोड किया गया वीडियो 10MB से अधिक नहीं हो सकता"
stringMap[strings.Mind_Upload_Video_Restrictions] = "केवल MP4 प्रारूप में 120s और 10M आकार तक के वीडियो अपलोड करने का समर्थन करता है"
stringMap[strings.Mind_Video_Size] = "वीडियो का आकार"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "एक्सपोर्ट वर्ड"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "निर्यात TXT"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap[strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap[strings.Export_Failed] = "निर्यात विफल"
stringMap[strings.Add_File] = "फ़ाइल जोड़ें"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "अपलोड करने के लिए सीधे फ़ाइल में खींचें"
stringMap[strings.Supported_Imported_File_Types] = "फ़ाइल प्रकार जो वर्तमान में आयात के लिए समर्थित हैं: Word (केवल .docx), XMind, .txt"
stringMap[strings.No_Permission_Do_This] = "आपको इस ऑपरेशन को करने की अनुमति नहीं है"

stringMap[strings.Upgrad_To_Professional] = "पेशेवर संस्करण में अपग्रेड करें"
stringMap[strings.Non_Members_Insert_Videos] = "गैर-सदस्य केवल पंजीकरण के 24 घंटे बाद ही वीडियो सम्मिलित कर सकते हैं"
stringMap[strings.Upload_Failed] = "अपलोड विफल"
stringMap[strings.Upload_Failed_Title_Message] = "अपलोड विफल रहा, कृपया पुष्टि करने के लिए क्लिक करें, अपलोड करने में विफल शेष चित्रों को हटा दें"

stringMap[strings.Mind_Edit_Members_Use] = "सदस्य उपयोग करें"
stringMap[strings.Invitation_Code] = "निमंत्रण कोड"
stringMap[strings.Student_Certification] = "छात्र प्रमाणन"
stringMap[strings.I_Get_It] = "मैं करूंगा, गाइड को छोड़ दूंगा"
stringMap[strings.Welcome_To_Mind] = "माइंड+ में शामिल होने के लिए युवा गुरु का स्वागत है, आइए हम सोचने की यात्रा शुरू करें!"
stringMap[strings.Mind_Before] = "फर्स्ट"
stringMap[strings.Try_A_Map] = "एक नक्शा आज़माएं"
stringMap[strings.Click_Create] = "बनाने के लिए क्लिक करें"
stringMap[strings.Click_Create_A_Mind_Map] = "माइंड मैप बनाने के लिए क्लिक करें"
stringMap[strings.Mind_About] = "अबाउट"
stringMap[strings.See_Checking_Data] = "व्यक्तिगत जानकारी देखें"
stringMap[strings.Modify_Checking_Data] = "व्यक्तिगत जानकारी संशोधित करें"
stringMap[strings.Mind_Shear] = "कतरनी"

stringMap[strings.Mind_Style_Empty] = "कोई पसंदीदा या कस्टम शैली नहीं"
stringMap[strings.Mind_Branch_Free_Layout] = "शाखा मुक्त लेआउट"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "दो-तरफा संरचना और बबल मैप शाखाओं को लेआउट में स्वतंत्र रूप से खींचा जा सकता है"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "फ्री थीम ड्रैग स्नैप"

stringMap[strings.Select_A_Node] = "एक विषय चुनें"
stringMap[strings.Create_Child_Node] = "चाइल्ड थीम बनाएं"
stringMap[strings.Add_A_Nice_Image_To_The_Node] = "अगला हम चाइल्ड थीम में एक अच्छा जोड़ देंगे"
stringMap[strings.Click_Icon_Panel] = "आइकन पैनल पर क्लिक करें"
stringMap[strings.Select_An_Icon_You_Like] = "एक आइकन चुनें जिसे आप पसंद करते हैं"
stringMap[strings.Replace_The_Mind_Map_With_A_New_One] = "अगला हम माइंड मैप को एक नए से बदल देंगे"
stringMap[strings.Select_The_Central_Theme] = "केंद्रीय विषय का चयन करें"
stringMap[strings.Switch_To_Canvas_Panel] = "कैनवास पैनल पर स्विच करें"
stringMap[strings.Click_Layout] = "लेआउट पर क्लिक करें"
stringMap[strings.Choose_A_Layout_You_Like] = "अपनी पसंद का लेआउट चुनें"
stringMap[strings.Mind_Map_Save_Share] = "बधाई हो, आपने सफलतापूर्वक एक सुंदर दिमाग का नक्शा बनाया है! आइए इसे सहेजें और अपने दोस्तों के साथ साझा करें ~"
stringMap[strings.Click_Save_Picture] = "तस्वीर सहेजने के लिए क्लिक करें"
stringMap[strings.Click_OK] = "ओके पर क्लिक करें"
stringMap[strings.Congratulations_On_Completing_The_Novice_Guide] = "नौसिखिया गाइड को पूरा करने पर बधाई~"
stringMap[strings.Upload_Succeeded] = "सफलतापूर्वक अपलोड किया गया"

stringMap[strings.Mind_Edit_Node_Menu_Quick_Style] = "क्विक स्टाइल"
stringMap[strings.Mind_Extremely_Important] = "बेहद महत्वपूर्ण"
stringMap[strings.Mind_Important] = "महत्वपूर्ण"
stringMap[strings.Mind_Commonly] = "सामान्य"
stringMap[strings.Mind_Ordinary] = "साधारण"
stringMap[strings.Member_Exclusive] = "यह सुविधा सदस्यों के लिए विशिष्ट है। क्या आप अपनी सदस्यता को अपग्रेड करना चाहते हैं?"
stringMap[strings.Member_Exclusive_Color_Scheme] = "यह रंग योजना सदस्यों के लिए विशिष्ट है। क्या आप अपनी सदस्यता को अपग्रेड करना चाहते हैं?"
stringMap[strings.Mind_Enlarge] = "बढ़ाना"
stringMap[strings.Mind_Narrow] = "संकीर्ण"
stringMap[strings.Mind_Center] = "केंद्रित"
stringMap[strings.Mind_Full_Screen] = "पूर्ण स्क्रीन"

stringMap[strings.Mind_Association_Node] = "एसोसिएशन नोड"

stringMap[strings.Mind_Resource_See] = "अनुलग्नक देखें"
stringMap[strings.Mind_Resource_Delete] = "अटैचमेंट हटाएं"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "फ़ाइलों को यहां खींचें और छोड़ें"
stringMap [strings.Mind_Edit_choose_explain] = "फ़ाइल चुनें"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "अपलोड की गई फ़ाइल 20MB से अधिक नहीं हो सकती"

stringMap[strings.Please_Enter_Mind_Password] = "कृपया एक 4- 8 अक्षर पासवर्ड भरें"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "कृपया पासवर्ड प्रविष्ट करें"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "यह फंक्शन एक सदस्य फंक्शन है"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "फ़ाइल पासवर्ड हटाया गया है"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "पासवर्ड अक्षरों की संख्या पर्याप्त नहीं है. कृपया 4 उलटा 8 अक्षर के साथ पासवर्ड भरें"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "पासवर्ड बहुत लंबा है. कृपया पासवर्ड को 4 उलटा 8 अंक के साथ प्रविष्ट करें"
stringMap[strings.Please_Enter_Mind_Password_Success] = "पासवर्ड सफलतापूर्वक सेट"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "पासवर्ड फ़ॉर्मेट त्रुटि"
stringMap[strings.Mind_Load_Data_Error] = "डाटा लोडिंग त्रुटि"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Password error"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Only the creator of this map can set the password"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;
