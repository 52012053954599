// package mind.yushu.com.mindmap.utils;

import UiUtil from "./UiUtil"

// import android.graphics.Color;

// import mind.yushu.com.R;

/**
 * Created by tony on 2019/12/18.
 */

class Colors {

        constructor() {
                //临时写的假数据，以后要删除。
                // this.white = 0xffffff;
                // this.clear = 0xFFE066;
                // this.black60 = 0x600000;
                // this.black30 = 0x420000;
                // this.red = 0x420000,
                // this.black80 = 0x861818;


                // this.transparent = UiUtil.colors(R.color.transparent),
                // this.window_transparent = UiUtil.colors(R.color.window_transparent),
                // this.white = UiUtil.colors(R.color.white),
                // this.black_gray = UiUtil.colors(R.color.black_gray),
                // this.yellow = UiUtil.colors(R.color.yellow),
                // this.blue = UiUtil.colors(R.color.blue),
                // this.red = UiUtil.colors(color.red),

                // this.deep_black_yellow = UiUtil.colors(R.color.deep_black_yellow),
                // this.deep_yellow = UiUtil.colors(R.color.deep_yellow),
                // this.weak_yellow = UiUtil.colors(R.color.weak_yellow),
                // this.gray = UiUtil.colors(R.color.gray),
                // this.gray10 = UiUtil.colors(R.color.gray10),
                // this.black = UiUtil.colors(R.color.black100),

                // this.black80_a = this.getUiColorByAlpha(0x000000, 0.8),
                // this.black75_a = UiUtil.colors(R.color.black75_a),
                // this.black70_a = UiUtil.colors(R.color.black70_a),
                // this.black50_a = UiUtil.colors(R.color.black50_a),
                // this.black30_a = UiUtil.colors(R.color.black30_a),
                // this.black20_a = UiUtil.colors(R.color.black20_a),
                // this.black10_a = UiUtil.colors(R.color.black10_a),
                // this.black5_a = UiUtil.colors(R.color.black5_a),

                // this.black80 = 0x2D2D2D,
                // this.black75 = UiUtil.colors(R.color.black75),
                // this.black70 = UiUtil.colors(R.color.black70),
                // this.black60 = UiUtil.colors(R.color.black60),
                // this.black55 = UiUtil.colors(R.color.black55),
                // this.black50 = UiUtil.colors(R.color.black50),
                // this.black40 = UiUtil.colors(R.color.black40),
                // this.black30 = UiUtil.colors(R.color.black30),
                // this.black25 = UiUtil.colors(R.color.black25),
                // this.black20 = UiUtil.colors(R.color.black20),
                // this.black10 = UiUtil.colors(R.color.black10),

                // this.white10 = UiUtil.colors(R.color.white10),
                // this.white15 = UiUtil.colors(R.color.white15),
                // this.white20 = UiUtil.colors(R.color.white20),
                // this.white30 = UiUtil.colors(R.color.white30),
                // this.white50 = UiUtil.colors(R.color.white50),
                // this.white60 = UiUtil.colors(R.color.white60),
                // this.white70 = UiUtil.colors(R.color.white70),
                // this.white80 = UiUtil.colors(R.color.white80),
                // this.white90 = UiUtil.colors(R.color.white90),
                // this.white95 = UiUtil.colors(R.color.white95),

                // this.clear = UiUtil.colors(R.color.transparent),
                // this.clearIos = 1,
                // this.mainThemeColor = UiUtil.colors(R.color.mainThemeColor),
                // this.colorPrimary = UiUtil.colors(R.color.colorPrimary),
                // this.redButtonBackgroundColor = new UiUtil().colors(R.color.red_button_background_color),

                this.colorPrimary = 0xffffff//
                this.colorPrimaryDark = 0xffffff//
                this.colorAccent = 0xffffff//
                this.clear = 1
                this.clearIos = 1,
                        this.transparent = 1// 
                this.white = 0xffffff//0xffffff//
                this.white80_a = this.getUiColorByAlpha(0xffffff, 0.8)//
                this.white50_ = 0xffffff//
                this.white10_a = this.getUiColorByAlpha(0xffffff, 0.1)//
                this.black_gray = 0x4C4C4C//
                this.yellow = 0xFEF693//
                this.blue = 0x0000FF//
                this.red = 0xFF0000//
                this.deep_black_yellow = 0xEF7B00//
                this.deep_yellow = 0xFF9900//
                this.weak_yellow = 0xFE9B21//
                this.gray = 0x333333//
                this.gray10 = 0xF5F5F5//
                this.black = 0x000002//

                this.black100 = 0x000002//
                this.black95 = 0x020202//
                this.black90 = 0x111111//
                this.black80 = 0x2D2D2D//
                this.black75 = 0x343434//
                this.black70 = 0x373737//
                this.black60 = 0x4a4a4a//
                this.black55 = 0x616161//
                this.black50 = 0x787878//
                this.black40 = 0xA1A1A1//
                this.black35 = 0xCCCCCC//
                this.black30 = 0xDEDEDE//
                this.black25 = 0xEBEBEB//
                this.black20 = 0xF5F5F8//
                this.black10 = 0xfafafa//

                this.black80_a = this.getUiColorByAlpha(0xffffff, 0.8)//
                this.black75_a = this.getUiColorByAlpha(0xffffff, 0.75)//
                this.black70_a = this.getUiColorByAlpha(0xffffff, 0.7)//
                this.black50_a = this.getUiColorByAlpha(0xffffff, 0.5)//
                this.black30_a = this.getUiColorByAlpha(0xffffff, 0.3)//
                this.black20_a = this.getUiColorByAlpha(0xffffff, 0.2)//
                this.black10_a = this.getUiColorByAlpha(0xffffff, 0.1)//
                this.black5_a = this.getUiColorByAlpha(0xffffff, 0.05)//

                this.white10 = this.getUiColorByAlpha(0xffffff, 0.1)//
                this.white15 = 0xffffff//
                this.white20 = 0xffffff//
                this.white30 = 0xffffff//
                this.white50 = 0xffffff//
                this.white60 = 0xffffff//
                this.white70 = 0xffffff//
                this.white80 = 0xffffff//
                this.white90 = 0xffffff//
                this.white95 = 0xffffff//

                this.mainLightThemeColor = 0xD6300B//
                this.mainThemeColor = 0xD6300B//
                this.cltxt_title_color = 0x343434//
                this.cltxt_title_color_amend = 0x909090//
                this.main_tab_bar_background = 0xf9f9f9//
                this.main_background_color = 0xf0f0f0//

                this.clbg_activity_color = 0xdf3a27//


                this.navigation_bar_bg = 0x485261//<!--导航栏颜色-->
                this.system_status_bar_bg = 0x2b313a//<!--导航栏颜色-->
                this.main_color = 0x00b1f8//<!-- 用于app主色调蓝色 -->
                this.main_color_50 = 0x00b1f8//
                this.warn_color = 0xD00000//<!-- 用于警告的颜色 -->
                this.read_message_color = 0x36c30e//<!-- 用于已读消息的颜色 -->
                this.font_color_title = 0x4C4C4C//<!-- 用于1级标题文字的颜色 -->
                this.font_color_content = 0xADADAD//<!-- 用于内容，输入等正文的颜色 -->
                this.font_color_other = 0xBBBBBB//<!-- 用于时间，备注等最次级文字的颜色 -->
                this.line_color = 0xE6E6E6//<!-- 用于所有分割线的底层的颜色 -->
                this.mask_color = 0x000000//<!-- 用于所有蒙版的颜色 -->
                this.contact_catalog_color = 0x929292//
                this.tag_bg_color = 0xf6f6f6//<!-- 用于备注类容的底层的颜色 -->

                this.font_color_white = 0xffffff//<!-- 白色字 -->

                this.red_button_background_color = 0xFC4758//<!-- 白色字 -->


                this.cltxt_section_color = 0x7f7f7f//
                this.clbg_diliver_color = 0xcdcdcd//
                this.clbg_foot_color = 0xf8f8f8//
                this.clbg_actionbar_color = 0xf3f3f3//
                this.clbg_tabwidget_color = 0xf7f7f7//
                this.clbg_activity_pressed_color = 0xca2e18//
                this.clline_man_color = 0x82b9ea//
                this.clline_woman_color = 0xf485b9//

                this.clbg_popupdialog_color = 0x000000//
                this.clbg_info_actionbar = 0xEBEBEB
                this.normal_line = 0xdcdcdc//
                this.text_hint = 0xc7c7cd//

                this.progressColor = 0x4CC59B;
                this.progressDarkColorColor = 0x4CC59B;
                this.progressVigilanceColor = 0xEF306B;
                this.progressVigilanceDarkColorColor = 0xEF306B;





                this.SelectedColore1 = [0xFFECEC, 0xFFB3B3, 0xFF7676, 0xEF4242, 0xB20000,
                        0xEFB0B0, 0xE36565, 0xD72C2C, 0xB30000, 0x6E0000,
                        0xD97474, 0xB96363, 0x8D3B3B, 0x762020, 0x540000,
                        0xFF2828, 0xD12121, 0xC32323, 0xAC1F1F, 0x9C1C1C, 0x861818, 0x6B1313,
                        0xBE0000, 0xA70000, 0x9D0000, 0x840000, 0x600000, 0x420000], //红

                        this.SelectedColore2 = [0xFFF0E7, 0xFFBB93, 0xFD833C, 0xFC6333, 0xE35326,
                                0xFFF4CA, 0xFFEB9D, 0xFFE066, 0xFFD530, 0xFFCB00,
                                0xF0D66D, 0xD1BA5F, 0xB7A353, 0x8D7E40, 0x64592D, 0x40391D,
                                0xFFE5D6, 0xEBBFA5, 0xEBA379, 0xEB905A, 0xEB742D, 0xEB5800,
                                0xC6AD99, 0xC69872, 0xC68045, 0xC6681A, 0xC65A00,
                                0x8F7865, 0x8F6847, 0x8F5625, 0x8F4200,
                                0xE0B998, 0xB7977C, 0x7E6855, 0x4A3D32],

                        this.SelectedColore3 = [0xE0F4F0, 0x89E7D4, 0x14C6A3, 0x00A383, 0x006853,
                                0xD5FDF6, 0xBCEEC6, 0x8AEE9E, 0x4DEE6D, 0x1DEE46, 0x00BB25, 0x00911D, 0x006D16, 0x003F0D,
                                0xC2C6C5, 0x80C6B4, 0x52C6A8, 0x1FC69B, 0x00B083,
                                0xD1EAE6, 0x8BCCC1, 0x4CB2A1, 0x3F9385, 0x275B53, 0x24413D],


                        this.SelectedColore4 = [0xE8EFFC, 0x83B0FF, 0x4388FF, 0x1E70FF, 0x1135B4,
                                0xDCE9FF, 0x9CA2D1, 0x747FD1, 0x4051D1, 0x1D32D1, 0x000F7D,
                                0x8E909A, 0x616A9A, 0x414F9A, 0x1C309A, 0x00189A,
                                0x6A6A7B, 0x55557B, 0x3A3A7B, 0x29297B, 0x00007B,
                                0xBDBDD6, 0xA4A4B9, 0x858596, 0x5D5D69, 0x484869, 0x36364F, 0x000035],

                        this.SelectedColore5 = [0xEEE5FF, 0xD99BFF, 0xB15EFF, 0x7838D8, 0x5C0BA8,
                                0xE8D9FF, 0xC7B4E3, 0xA57BE3, 0x8A4EE3, 0x6F20E3, 0x6700BF,
                                0xA197AA, 0x9074AA, 0x8257AA, 0x7236AA, 0x6316AA, 0x48008A,
                                0xCBB9DB, 0x9B8DA7, 0x776C80, 0x4E3861, 0x3E2950],

                        this.SelectedColore6 = [0xFFE9FA, 0xFFB2ED, 0xFF65DC, 0xFF00C5, 0xCF009F,
                                0xFFDFF8, 0xECBDE2, 0xE27BCC, 0xE247C1, 0xAC3693, 0xAC0088,
                                0x988794, 0x986E8E, 0x985087, 0x983280, 0x6D0053,
                                0xCEAFC6, 0x806C7B, 0x4F3649, 0x392033, 0x29001F],

                        this.SelectedColore7 = [
                                this.clear, 0xffffff, 0xF4F4F4, 0xbbbbbb, 0x888888, 0x555555, 0x333333, 0x000002,
                                0xeeeeee, 0xaaaaaa, 0x777777, 0x444444, 0x222222, 0x010101,
                                0xcccccc, 0x666666, 0x343434, 0x111111, 0x010101,
                                0xffffff, 0xFBFBFB, 0xF2F2F2, 0xE4E4E4, 0xD5D5D5, 0xBFBFBF, 0xA4A4A4,
                                0x8E8E8E, 0x747474, 0x5C5C5C, 0x3F3F3F, 0x272727, 0x080808],

                        this.historyFull = [0xffffff, 0xF4F4F4, 0xeeeeee, 0xbbbbbb, 0x999999, 0x666666, 0x333333, 0x000002];
                this.RainbowColores = [
                        [0x25B6EB, 0xff3567, 0x66AD5D, 0x9cac28, 0x53e3fd, 0x3e1a00, 0x56DB81, 0xFFA945],
                        [0x3B6E7B, 0x6bcdda, 0xd0f3f7, 0xdfebb7, 0xf9957d, 0xb87348, 0xDA6B7A, 0x5B64E4],
                        [0xAD0B39, 0xd93463, 0x256fa8, 0xffcd38, 0x574a96, 0x381d25, 0xAD0BA8, 0x0B8FAD],
                        [0x010009, 0x131122, 0x00a1ac, 0xf2dab5, 0xa23d39, 0x413535, 0x0057AC, 0x00AC6F],
                        [0xEE7D03, 0xffad26, 0x7ae0ff, 0xfb0304, 0x02FAA6, 0xabfe03, 0xFF269E, 0xFFE326],
                        [0x258EB3, 0x60cff7, 0x5e2975, 0xffe482, 0xff6363, 0xbcff63, 0x60F7A0, 0xF7A860],
                        [0x006B0E, 0x009714, 0xd8e069, 0x4d2d46, 0x30555c, 0x14c4e3, 0x009775, 0x976500],
                        [0x0F5A96, 0x2E98EC, 0xFF7474, 0x60B6F1, 0xFECC55, 0xF0A3DB, 0x81CE6F, 0xFA8554],
                        [0x00A03C, 0x76C1F3, 0xEECFB2, 0x65D8C7, 0xACE3CD, 0xB6C3EB, 0xF6B9B6, 0x62BB85],
                        [0xCAA096, 0xF68080, 0x5EB7F1, 0xFECC54, 0xDDA3CE, 0xA184DD, 0x99CB3E, 0x51BDC0],
                        [0x0F383E, 0x34D0E5, 0xF58AC8, 0xA283DB, 0x0FD3C7, 0xE7AA87, 0x97BB26, 0xF1AF42],
                        [0xCF366B, 0x0289D1, 0x3D3FA5, 0x05AE3E, 0xFD6C03, 0xC40284, 0x91D302, 0x6F2288],
                        [0x3498DB, 0x517193, 0xF56A86, 0x35A3BF, 0xCC7EAF, 0xF2C24F, 0x00AF9C, 0x79AF0A],
                        [0x7CD83A, 0x92ff45, 0xfeee03, 0xff6d24, 0x0c9cdb, 0xf5589c],
                        [0x007BC5, 0x0099f6, 0xf57a22, 0x009714, 0xfee800, 0xff0797],
                        [0xC1686A, 0xf08189, 0xc4f081, 0x81aef0, 0xf0e181, 0xc4896c],
                        [0xD30024, 0xd4344f, 0xfa9d9d, 0xffac38, 0xdfdd83, 0xa50b25],
                        [0xF5D900, 0xf5ea95, 0xfc8e5b, 0xfc5956, 0xc93e4f, 0x3d1734],
                        [0x035B19, 0x048e27, 0x20f856, 0xf26b27, 0xf82056, 0xc50538],
                        [0xF03480, 0xcf3b2d, 0x007e8f, 0xd93232, 0xffac38, 0xbd2020],
                        [0xA70000, 0x87411e, 0xf0a884, 0xf26b27, 0xde2f75, 0xd1061b],
                        [0x760094, 0x94134e, 0xc75c90, 0xde6a9e, 0xfecee7, 0x425328],
                        [0xCA6611, 0xca9466, 0xf03c42, 0xfba454, 0xef611d, 0xb9aa3b],
                        [0xFFA600, 0xce3400, 0x020202, 0xfcba79, 0xc22303, 0xdce79b],
                        [0x2C0800, 0xdc1915, 0xc79a57, 0xebd6c1, 0xacb1c5, 0x855a49],
                        [0x63B33D, 0xaef78c, 0xf7be8c, 0xec7b5d, 0xa24660, 0x5c2283],
                        [0x1C8082, 0x0e423a, 0x57c976, 0xffc77f, 0xd4714d, 0xbd1b2e],
                        [0x352644, 0x44262e, 0xa30a0f, 0xdf0b08, 0xf94314, 0xb0af30],
                        [0x36DDCB, 0xf53f4e, 0xf27277, 0xe8ab79, 0x99bf8d, 0x5eb98b],
                        [0xE5A839, 0x94ddc0, 0xffd46c, 0xfb9560, 0xfb553f, 0xe2285a],
                        [0x36B7DD, 0xf04c7c, 0x1ccca9, 0xf59cb6, 0x3fe4c2, 0xfeda7c],
                        [0x020328, 0xF43A3F, 0xF56F03, 0x4C6DFB, 0xF1D23B, 0x3fe4c2]];
        }

        FistTabRainbowColores = [
                [0x00926E, 0xFFFFFF, 0xFFCF03, 0xEF6222, 0xFC003F, 0x642CA9, 0xFF35AC, 0xFF74D5],
                [0xFFFFFF, 0xC6F6FA, 0x67D9E7, 0x40B8CF, 0x3AABBF, 0x3499AD, 0x2C8598, 0x267484]];

        FistStyleRainbowColores1 = [
                [0xFFFFFF, 0x020229, 0xF4413A, 0xF6A04E, 0xF4D322, 0x38BC7B, 0x486AFF, 0x4D49BE],
                [0xFFFFFF, 0x36B7DD, 0xf04c7c, 0x1ccca9, 0xf59cb6, 0x3fe4c2, 0xfeda7c],
                [0xFFFFFF, 0x1F2766, 0xF68156, 0xF7AD36, 0xB7C82B, 0x3598B9, 0x7575BC, 0xA165A8],
                [0xFFFFFF, 0x99142F, 0xF3D96F, 0xF9BE71, 0xF68156, 0xA71D3A, 0xC5404D, 0xF8A787],
                [0xFFFFFF, 0x245570, 0x235D99, 0x534E97, 0x7B4183, 0xA23E6B, 0xC34150, 0xCA5835],
                [0xFFFFFF, 0x0D0D0D, 0xF22816, 0xF2B808, 0x29DA5B, 0x2A3EDA, 0x7F29DA, 0xDA29A7],
                [0xFFFFFF, 0x191959, 0xF8AAAA, 0xF67B31, 0x8DB5FF, 0x4A51D9],
                [0xEDF4FF, 0x0A052E, 0xF7AA3A, 0xD389D5, 0x3792D2],
                [0xEFF9FF, 0x001B3E, 0x004B7C, 0x534D98, 0x7D4083, 0xC34250, 0xA43C69],
                [0xF8F5DD, 0x163E5D, 0x4A9D9D, 0x7884A4, 0xA979AA, 0xAA7979, 0x79AAA9, 0x76AA73],
                [0x274653, 0xDCA76F, 0xDC9E6F, 0xDC6F6F, 0xBBDC6F, 0x72DC6F, 0x6FDCC3, 0x7E6FDC, 0xDC6FA4]];
    
        FistStyleFreeGeneralizationColores1 = [
                 [0x020229, 0xF4413A, 0xF4413A],
                 [0x36B7DD, 0xf04c7c, 0xf04c7c],
                 [0x1F2766, 0xF68156, 0xF68156],
                 [0x99142F, 0xF3D96F, 0xF3D96F],
                 [0x245570, 0x235D99, 0x235D99],
                 [0x0D0D0D, 0xF22816, 0xF22816],
                 [0x191959, 0xF8AAAA, 0xF8AAAA],
                 [0x0A052E, 0xF7AA3A, 0xF7AA3A],
                 [0xFF5251, 0xF5DADE, 0xFF5251],
                 [0x604444, 0x9A9478, 0x9A9478],
                 [0x289D8F, 0x5F8E89, 0xABFFF6]];

        FistStyleRainbowColores2 = [
            [0xFFFFFF, 0x12100E, 0x494949, 0x494949, 0x494949],
            [0xFFFFFF, 0x060606, 0x494949, 0x8C8C8C, 0x494949, 0x8C8C8C],
            
            //玫瑰
            [0xFEF0F3, 0xA5133C, 0xF9B3C1, 0xF6758F, 0xC9184A],
            [0xFBCCD5, 0xA5133C, 0xC9184A, 0xC9184A, 0xC9184A],
            [0xA4133D, 0xFEF0F3, 0xFEF0F3, 0xFEF0F3, 0xFEF0F3],

            //海洋
            [0xB4F2FE, 0x030C2E, 0x030C2E, 0x030C2E, 0x030C2E],
            [0x265CAE, 0xB4F2FD, 0xB4F2FD, 0xB4F2FD, 0xB4F2FD],
            [0x10206A, 0xB4F2FE, 0xB4F2FE, 0xB4F2FE, 0xB4F2FE],
            [0x030C2D, 0xB4F2FD, 0x6EE2FD, 0x41B7E3, 0x265CAE],

            //薄荷
            [0xFFFFFF, 0x1D6562, 0x9CEAEF, 0x68D8D6, 0x39AFA9],
            [0x1D6561, 0xFFFFFE, 0x68D8D6, 0x68D8D6, 0x68D8D6],
            [0xC4FEF9, 0x1D6562, 0x39AFA9, 0x39AFA9, 0x39AFA9],

            //假日
            [0x101F24, 0xD5F2E3, 0xF0A346, 0xE12A37, 0xBC191D, 0x2D6D65],
            [0xF0A347, 0x101F23, 0x101F23, 0x101F23, 0x101F23],
            [0x2D6D65, 0xD5F2E3, 0xD5F2E3, 0xD5F2E3, 0xD5F2E3],

            //紫藤
            [0xFEFBEF, 0x73369D, 0xFAD58A, 0xDCBEF5, 0xB67BE6, 0x9D4EDD],
            [0xDCBEF4, 0x73369D, 0x9D4EDD, 0x9D4EDD, 0x9D4EDD],
            [0x73369E, 0xFEFBEF, 0xFEFBEF, 0xFEFBEF, 0xFEFBEF],

            [0xFFEDDE, 0x692F00, 0x692F00, 0x692F00, 0x692F00],
            [0xFAF8ED, 0x216D77, 0xF9BC9F, 0xF9BC9F, 0xF9BC9F],

            [0xD389D5, 0x0A052E, 0x0A052E, 0x0A052E, 0x0A052E],

            [0xFFFFFF, 0x5C38E5, 0x5C38E5, 0x5C38E5, 0x5C38E5],
            [0xFFFFFF, 0xF64337, 0xF64337, 0xF64337, 0xF64337],
            [0xD9EFEE, 0xF54337, 0x50C3F8, 0x50C3F8, 0x50C3F8],

            [0xFFFFFF, 0x216D77, 0x216D77, 0x216D77, 0x216D77],
            [0x216D71, 0xFAF8ED, 0x83C5BE, 0x83C5BE, 0x83C5BE],

            [0x8CB5FF, 0x191959, 0x191959, 0x191959, 0x191959],
            [0x0A052E, 0xEDF4FF, 0x3792D2, 0x8EDDF9, 0x3792D2, 0x8EDDF9],
            [0x0A052E, 0xF7AA3A, 0xF7AA3A, 0xF7AA3A, 0xF7AA3A],

            [0x12100E, 0xA6A6A6, 0xA6A6A6, 0xA6A6A6, 0xA6A6A6]];
            
        FistStyleFreeGeneralizationColores2 = [ //0:rootColor\1:sonColor\1:lineColoe
                [0x494949, 0xDBDBDB, 0x494949],
                [0x010101, 0xDBDBDB, 0x494949],
                
                //玫瑰
                [0xF9B3C1, 0xEAC1CF, 0xA4133C],
                [0xFEF0F3, 0xA4133C, 0xF78CA3],
                [0xFBCCD5, 0xB63F61, 0xFEF0F3],

                //海洋
                [0x3A7CA6, 0x26485B, 0x0D2F42],
                [0x6EE1FE, 0xCDFFFF, 0xB3F2FD],
                [0x6EE1FE, 0x427ABE, 0xB3F2FD],
                [0x3AB7E2, 0x0E2454, 0x243B56],

                //薄荷
                [0x67D8D6, 0xD3E0DF, 0x036562],
                [0xC4FFF9, 0x468382, 0xFFFFFF],
                [0x0AAFA9, 0xA7FEF5, 0x036562],

                //假日
                [0xE22937, 0x374A49, 0xD6F2E3],
                [0xE22937, 0x29383B, 0x101F22],
                [0xF0A346, 0x4F887E, 0xD6F2E3],

                //紫藤
                [0x5C0AA8, 0xE3D3DF, 0x5C0AA8],
                [0xFFFBEF, 0xCA9DEE, 0x72369D],
                [0xFBD48A, 0x8E5BAD, 0xFFFFFF],

                [0xFC901B, 0x865450, 0x6D3B37],
                [0x7E4E2B, 0xFAE3D1, 0x7E4E2B],

                [0xEDF3FF, 0x231E47, 0x231E47],

                [0xA239EA, 0xCFD4D6, 0x0A2833],
                [0xA3123C, 0xA3123C, 0xEEC5CE],
                [0x51C2F8, 0xA4DDF8, 0x317798],

                [0x67D8D6, 0x468382, 0x036562],
                [0x191959, 0x89AAEA, 0x191959],

                [0xF1EEFE, 0x7596DE, 0x8EDDF9],
                [0x3CB7E2, 0x243B56, 0xB5F1FD],
                [0xFFFFFF, 0x302E2C, 0xA6A6A6],

                [0xFFFFFF, 0x302E2C, 0xA6A6A6]];

        getColorIntValue(value) {
                if ((typeof value) == "string") {
                        if ("none" == value) {
                                return this.clear
                        } else if (value.startsWith("#")) {
                                value = value.substr(1)
                                if (value.length == 8) {
                                        value = value.substring(0, 6)
                                }
                                return value = parseInt(value, 16)
                        } else if (value.startsWith("0x")) {
                                return value = parseInt(value.substr(2), 16)
                        } else if (value.indexOf("rgba") > -1 && value.indexOf(",") > -1 && value.indexOf(")") > -1) {
                                let list = value.split(",")
                                if (list.length == 4) {
                                        var r = parseInt(list[0].split('(')[1]);
                                        var g = parseInt(list[1]);
                                        var b = parseInt(list[2].split(')')[0]);
                                        var hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
                                        if (hex != null) {
                                                return parseInt(hex, 16)
                                        }
                                }  
                                let alphaNumber = parseInt(value, 10)
                        } else {
                                return parseInt(value, 16)
                        }
                } else if ((typeof value) == "number") {
                        return value
                } else {
                        return this.white;
                }
        }

        getUiColor(value, alpha = 1.0) {//传入十进制，number类型
                if ((typeof value) == "string") {
                        if (value.indexOf("#") > -1) {
                                value = parseInt(this.HexToRgb(value), 10)
                        } else {
                                return value
                        }
                }
                if (value == undefined) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }
                if (value == 1 || value == 0) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }
                if (value == 16316671) {        //16316671是画布的透明色
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }
                if (alpha == null || (typeof value) != "number") {
                        alpha = 1.0;   
                }
                // if (isClear(value)) {
                //     return clear;
                // }
                // if (value < 0x01000000) {
                //     value = value + 0xff000000;
                // }

                var a, b, c;

                let red = (value & 0xff0000) >> 16;
                let green = (value & 0x00ff00) >> 8;
                let blue = (value & 0x0000ff);
                return 'rgba(' + red + ',' + green + ',' + blue + ', ' + alpha + ')';
        }

        getUiColorAlpha(value) {
                if ((typeof value) == "string") {
                        if (value.indexOf("#") == 0 && value.length == 9) {
                              let alphaStr = value.substring(7, 9)  
                              let alphaNumber = parseInt(alphaStr, 16)
                              if (alphaNumber != null && (typeof alphaNumber) == "number" && alphaNumber >= 0 && alphaNumber <= 255) {
                                return alphaNumber/255
                              }
                        } else if (value.indexOf("rgba") > -1 && value.indexOf(",") > -1 && value.indexOf(")") > -1) {
                                let list = value.split(",")
                                if (list.length == 4 && list[3].indexOf(")") > -1) {
                                        list = list[3].split(")")
                                        let alphaNumber = parseInt(list[0], 10)
                                        if (alphaNumber != null && (typeof alphaNumber) == "number" && alphaNumber >= 0 && alphaNumber <= 1.0) {
                                                return alphaNumber
                                        }
                                }  
                        } 
                        let alphaNumber = parseInt(value, 10)
                        if (alphaNumber != null && (typeof alphaNumber) == "number" && alphaNumber >= 0 && alphaNumber <= 1.0) {
                                return alphaNumber
                        }
                } else if ((typeof value) == "number" && value >= 0 && value <= 1.0) {
                        return value;
                }

                return 1.0
        }

        getInputUiColor(value) {
                if (value == undefined) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }
                if (value == 1 || value == 0) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }

                // if (isClear(value)) {
                //     return clear;
                // }
                // if (value < 0x01000000) {
                //     value = value + 0xff000000;
                // }

                var a, b, c;

                let red = (value & 0xff0000) >> 16;
                let green = (value & 0x00ff00) >> 8;
                let blue = (value & 0x0000ff);
                return 'rgb(' + red + ',' + green + ',' + blue + ')';
        }
        getUiColorByAlpha(value, alpha) { //alpha : 0 ~ 1
                if (value == 1 || value == 0 || value == 16316671) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)'
                }
                var a, b, c;

                let red = (value & 0xff0000) >> 16;
                let green = (value & 0x00ff00) >> 8;
                let blue = (value & 0x0000ff);
                return 'rgba(' + red + ',' + green + ',' + blue + ',' + alpha + ')';
        }

        isClear(color) {
                return color == this.clear || color == this.clearIos || color == 0;
        }

        isLightColor(color, darknessValue = 0.5) {
                let red = (color & 0xff0000) >> 16;
                let green = (color & 0x00ff00) >> 8;
                let blue = (color & 0x0000ff);
                let darkness = 1 - (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
                if (darkness < darknessValue) {
                        return true; // It's a light color
                } else {
                        return false; // It's a dark color
                }
        }

        isDarkColor(color, darknessValue = 0.5) {
                if (this.isClear(color)) {
                        return false;
                }
                return !this.isLightColor(color, darknessValue);
        }

        getColorFormHexByRgb(r, g, b) { //return 16 String
                return ((r << 16) | (g << 8) | b).toString(16);
        }

        getColorByRgb(r, g, b) { //return 10
                let hex = this.getColorFormHexByRgb(r, g, b);
                let color = "0x" + hex;
                return parseInt(color);
        }


        /**************************颜色处理***********************************/
        //hex颜色转rgb颜色
        HexToRgb(str) {
                var r = /^\#?[0-9A-Fa-f]{6}$/;
                //test方法检查在字符串中是否存在一个模式，如果存在则返回true，否则返回false
                if (!r.test(str)) return window.alert("输入错误的hex");
                //replace替换查找的到的字符串
                str = str.replace("#", "");
                //match得到查询数组
                var hxs = str.match(/../g);
                //alert('bf:'+hxs)
                for (var i = 0; i < 3; i++) hxs[i] = parseInt(hxs[i], 16);
                //alert(parseInt(80, 16))
                //console.log(hxs);
                return hxs;
        }
        //GRB颜色转Hex颜色
        RgbToHex(a, b, c) {
                var r = /^\d{1,3}$/;
                if (!r.test(a) || !r.test(b) || !r.test(c)) return window.alert("输入错误的rgb颜色值");
                var hexs = [a.toString(16), b.toString(16), c.toString(16)];
                for (var i = 0; i < 3; i++) if (hexs[i].length == 1) hexs[i] = "0" + hexs[i];
                return "#" + hexs.join("");
        }

        //得到hex颜色值为color的加深颜色值，level为加深的程度，限0-1之间
        getDarkColor(color, level) {
                var r = /^\#?[0-9A-Fa-f]{6}$/;
                if (!r.test(color)) return window.alert("输入错误的hex颜色值");
                var rgbc = this.HexToRgb(color);
                //floor 向下取整
                for (var i = 0; i < 3; i++) rgbc[i] = Math.floor(rgbc[i] * (1 - level));
                return this.RgbToHex(rgbc[0], rgbc[1], rgbc[2]);
        }
        //得到hex颜色值为color的减淡颜色值，level为加深的程度，限0-1之间
        getLightColor(color, level) {
                var r = /^\#?[0-9A-Fa-f]{6}$/;
                if (!r.test(color)) return window.alert("输入错误的hex颜色值");
                var rgbc = this.HexToRgb(color);
                for (var i = 0; i < 3; i++) rgbc[i] = Math.floor((255 - rgbc[i]) * level + rgbc[i]);
                return this.RgbToHex(rgbc[0], rgbc[1], rgbc[2]);
        }

        getNumberToRgbDark(color, level = 0.1) {  //加深颜色的方法，返回#开头的十六进制字符串。
                if (color == undefined) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }
                if (color == 1 || color == 0) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }
                if (color == 16316671) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }


                let red = (color & 0xff0000) >> 16;
                let green = (color & 0x00ff00) >> 8;
                let blue = (color & 0x0000ff);
                let rgbc = [];
                rgbc[0] = red;
                rgbc[1] = green;
                rgbc[2] = blue;
                //   //floor 向下取整
                // console.log('前');
                //  console.log(rgbc[0]);
                //  console.log(rgbc[1]);
                //  console.log(rgbc[2]);
                for (var i = 0; i < 3; i++) {
                        rgbc[i] = Math.floor(rgbc[i] * (1 - level));
                        if (rgbc[i] > 255) {
                                rgbc[i] = 255;
                        }
                }
                //floor 向下取整
                //  console.log('后');
                //  console.log(rgbc[0]);
                //  console.log(rgbc[1]);
                //  console.log(rgbc[2]);
                return this.RgbToHex(rgbc[0], rgbc[1], rgbc[2]);

                // console.log('red',red);
                // console.log('green',green);
                // console.log('blue',blue);
        }

        getNumberToRgbStringDark(color, level = 0.1) {   //加深颜色的方法，返回rgb或者rgba值。
                if (color == undefined) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }
                if (color == 1 || color == 0) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }
                if (color == 16316671) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                }


                let red = (color & 0xff0000) >> 16;
                let green = (color & 0x00ff00) >> 8;
                let blue = (color & 0x0000ff);
                let rgbc = [];
                rgbc[0] = red;
                rgbc[1] = green;
                rgbc[2] = blue;
                //   //floor 向下取整
                // console.log('前');
                //  console.log(rgbc[0]);
                //  console.log(rgbc[1]);
                //  console.log(rgbc[2]);
                for (var i = 0; i < 3; i++) {
                        rgbc[i] = Math.floor(rgbc[i] * (1 - level));
                        if (rgbc[i] > 255) {
                                rgbc[i] = 255;
                        }
                }
                //floor 向下取整
                //  console.log('后');
                //  console.log(rgbc[0]);
                //  console.log(rgbc[1]);
                //  console.log(rgbc[2]);
                return 'rgb(' + rgbc[0] + ',' + rgbc[1] + ',' + rgbc[2] + ')';

                // console.log('red',red);
                // console.log('green',green);
                // console.log('blue',blue);
        }

        getNextRgbaColor(color, level = 36, alpha = 1.0) { 
                if (color == undefined) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ', ' + alpha + ')';
                }
                if (color == 1 || color == 0) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ', ' + alpha + ')';
                }
                if (color == 16316671) {
                        return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ', ' + alpha + ')';
                }
                let hsv = this.RGBtoHSV(color)
                let h = hsv.h + level
                if (h < 0) {
                        h = 0
                }
                if (h > 360) {
                        h = h - 360
                }
                return this.hsvToRgb(hsv.s, h, hsv.v, alpha)
        }

        hsvToRgb(s, h, v, a) {
                s = s / 100;
                v = v / 100;
                var r = 0, g = 0, b = 0;
                var i = parseInt((h / 60) % 6);
                var f = h / 60 - i;
                var p = v * (1 - s);
                var q = v * (1 - f * s);
                var t = v * (1 - (1 - f) * s);
                switch (i) {
                    case 0:
                        r = v; g = t; b = p;
                        break;
                    case 1:
                        r = q; g = v; b = p;
                        break;
                    case 2:
                        r = p; g = v; b = t;
                        break;
                    case 3:
                        r = p; g = q; b = v;
                        break;
                    case 4:
                        r = t; g = p; b = v;
                        break;
                    case 5:
                        r = v; g = p; b = q;
                        break;
                    default:
                        break;
                }
                r = parseInt(r * 255.0)
                g = parseInt(g * 255.0)
                b = parseInt(b * 255.0)
                return 'rgba(' + r + ',' + g + ',' + b + ', ' + a + ')';
        }

        RGBtoHSV(color) {
                let red = (color & 0xff0000) >> 16;
                let green = (color & 0x00ff00) >> 8;
                let blue = (color & 0x0000ff);  
                var r=red/255
                var g=green/255
                var b=blue/255

                var h,s,v;
                var min=Math.min(r,g,b);
                var max=v=Math.max(r,g,b);
                var l=(min+max)/2;
                var difference = max-min;
                
                if(max==min){
                        h=0;
                }else{
                        switch(max){
                                case r: h=(g-b)/difference+(g < b ? 6 : 0);break;
                                case g: h=2.0+(b-r)/difference;break;
                                case b: h=4.0+(r-g)/difference;break;
                        }
                        h=Math.round(h*60);
                }
                if(max==0){
                        s=0;
                }else{
                        s=1-min/max;
                }
                s=Math.round(s*100);
                v=Math.round(v*100);
                return {
                        h: h,
                        s: s,
                        v: v
                };
        }

        getNumberToRgbNumberDark(color, level = 0.1) { //加深颜色的方法，返回数字
                // if (color == undefined) {
                //         return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                // }
                // if (color == 1 || color == 0) {
                //         return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                // }
                // if (color == 16316671) {
                //         return 'rgba(' + 255 + ',' + 255 + ',' + 255 + ',0)';
                // }


                // let red = (color & 0xff0000) >> 16;
                // let green = (color & 0x00ff00) >> 8;
                // let blue = (color & 0x0000ff);
                // let rgbc = [];
                // rgbc[0] = red;
                // rgbc[1] = green;
                // rgbc[2] = blue;
                // //   //floor 向下取整
                // // console.log('前');
                // //  console.log(rgbc[0]);
                // //  console.log(rgbc[1]);
                // //  console.log(rgbc[2]);
                // for (var i = 0; i < 3; i++){
                //         rgbc[i] = Math.floor(rgbc[i] * (1 - level));
                //         if (rgbc[i] > 255) {
                //                 rgbc[i] = 255;
                //         }
                // } 
                //  //floor 向下取整
                // //  console.log('后');
                // //  console.log(rgbc[0]);
                // //  console.log(rgbc[1]);
                // //  console.log(rgbc[2]);
                // return 'rgb(' + rgbc[0] + ',' + rgbc[1] + ',' + rgbc[2] + ')';

                // // console.log('red',red);
                // // console.log('green',green);
                // // console.log('blue',blue);

                return Math.floor(color * (1 - level))
        }
        /****************************颜色处理结束**************************************/

}


export default new Colors()
