import Config from '../Config'
import Util from '../../../../utils/Util'
import CGPoint from "../../../../viewmodel/core/base/basedata/CGPoint"
import MindElementShapeType from "../../../../viewmodel/datatype/MindElementShapeType"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import Size from "../../../../viewmodel/core/base/Size"
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType"
import Point from '../../../../viewmodel/core/base/Point'
import CGRect from '../../../../viewmodel/core/base/basedata/Rect'
import MindElementCalculation from './MindElementCalculation'
import Colors from '../../../../utils/Colors'
import { lte } from 'semver'
import ConnectMapElementContent from '../../../../viewmodel/mindelementdata/mindcontent/ConnectMapElementContent'
import UiUtil from '../../../../utils/UiUtil'
import ImageLayoutType from '../../../../viewmodel/datatype/ImageLayoutType'
import MindElementAlignmentType from '../../../../viewmodel/datatype/MindElementAlignmentType'
import EdgeInsets from '../../basemode/EdgeInsets'
import Strings from '../../../../utils/Strings'

import store from "../../../../core/store/index"
import NodeLayoutType from '../../../../viewmodel/datatype/NodeLayoutType'
/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class BaseMindElementDataCalculation {
    constructor(data, nodeLayoutType) {
        this.maxHeight = 1000;//定义最大高度
        this.minHeight = 30;//定义最小高度
        this.maxWidth = 600;//定义最大宽度
        this.minWidth = 10;//定义最小宽度

        this.data = data;
        this.taskOriginalSize = new Size(0,0);
        this.latexOriginalSize = new Size(0, 0);
        this.imageSpace = 8;
        this.iconSpace = 4;
        this.iconTextSpace = 4;
        this.iconMargin = 8;
        this.UiUtil = new UiUtil();
        this.Util = Util;
        if (data != null) {
            if (data.type == MindElementType.MAIN_SUBJECT) {
                this.iconMargin = 8;
                this.iconTextSpace = 3;
            } else if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
                this.iconMargin = 6;
                this.iconTextSpace = 2;
            } else if (data.type == MindElementType.SON_SUBJECT) {
                this.iconMargin = 4;
                this.iconTextSpace = 0;
            }
        }
    }

    getTextTop() {
        return this.getTextEdgeInsets().top;
    }

    getTextLeft() {
        return this.getTextEdgeInsets().left;
    }

    getIconLayout() {
        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            return IconLayoutType.LAYOUT_LEFT;
        }

        return iconElementContents[0].layoutType;
    }

    includeIconAndTextSize() {
        let width = 0;
        let height = 0;
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return new Size(0, 0);
        }

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            width = textMindElementContent.width;
            height = textMindElementContent.height;
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
            } else {
                width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
            }
            if (iconsSize.getHeight() + this.iconSpace * 2 > (textMindElementContent.height)) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = (textMindElementContent.height);
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > (textMindElementContent.width)) {
                width = iconsSize.getWidth();
            } else {
                width = (textMindElementContent.width);
            }
            height = iconsSize.getHeight() + this.iconSpace + (textMindElementContent.height);
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2;
            }
        }
        return new Size(width, height);
    }

    includeIconAndTextAndLatexSize() {
        return this.addLatexSize(this.includeIconAndTextSize());
    }


    includeIconAndTextAndTaskSize() {
        return this.addTaskSize(this.includeIconAndTextSize());
    }

    includeIconAndTextAndTaskAndLatextSize() {
        return this.addLatexSize(this.addTaskSize(this.includeIconAndTextSize()));
    }

    includeIconAndTextAndLinkAndRemarksAndTaskSize() {
        return this.addTaskSize(this.includeIconAndTextAndLinkAndRemarksSize());
    }

    includeIconAndTextAndLinkAndRemarksSize() {
        let width = 0;
        let height = 0;
        let textMindElementContent = this.getTextMindElementContent();

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0 ||
            !this.data.isContainLinkContent() || !this.data.isContainRemarksContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        let textLinkRemarksSize = this.includeTextAndLinkAndRemarksSize();

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconMargin + textLinkRemarksSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textLinkRemarksSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textLinkRemarksSize.getHeight();
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textLinkRemarksSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textLinkRemarksSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textLinkRemarksSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height = iconsSize.getHeight() + this.iconSpace * 2 + textLinkRemarksSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textLinkRemarksSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeTextAndLinkAndRemarksAndTaskSize() {
        return this.addTaskSize(this.includeTextAndLinkAndRemarksSize());
    }

    includeTextAndLinkAndRemarksAndLatexSize() {
        return this.addLatexSize(this.includeTextAndLinkAndRemarksSize());
    }

    includeTextAndLinkAndRemarksSize() {
        let width = 0;
        let height = 0;
        let textMindElementContent = this.getTextMindElementContent();

        if (!this.data.isContainLinkContent() && this.data.isContainRemarksContent()) {
            return this.includeRemarksAndTextSize();
        } else if (this.data.isContainLinkContent() && !this.data.isContainRemarksContent()) {
            return this.includeLinkAndTextSize();
        } else if (!this.data.isContainLinkContent() && !this.data.isContainRemarksContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let linkElementContent = this.data.linkElementContent;
        let remarksElementContent = this.data.remarksElementContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let iconsSize = new Size((linkElementContent.width + remarksElementContent.width) + this.iconSpace, (linkElementContent.height));

        if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
        } else {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
        }
        if (iconsSize.getHeight() + this.iconSpace * 2 > (textMindElementContent.height)) {
            height = iconsSize.getHeight() + this.iconSpace * 2;
        } else {
            height = (textMindElementContent.height);
        }

        if (text.length == 0 && !this.data.isEdit) {
            width = iconsSize.getWidth() + this.iconMargin * 2;
        }
        return new Size(width, height);
    }

    includeIconAndTextRemarksAndTaskSize() {
        return this.addTaskSize(this.includeIconAndTextRemarksSize());
    }

    includeIconAndTextRemarksAndTaskAndLatexSize() {
        return this.addLatexSize(this.includeIconAndTextRemarksAndTaskSize());
    }

    includeIconAndTextRemarksAndLatexSize() {
        return this.addLatexSize(this.includeIconAndTextRemarksSize());
    }

    includeIconAndTextRemarksSize() {
        let width = 0;
        let height = 0;
        let textMindElementContent = this.getTextMindElementContent();

        let remarksElementContent = this.data.remarksElementContent;
        if (remarksElementContent == null || remarksElementContent.text.length == 0 || !this.data.isContainRemarksContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        let textRemarksSize = this.includeRemarksAndTextSize();

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconMargin + textRemarksSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textRemarksSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textRemarksSize.getHeight();
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textRemarksSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textRemarksSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textRemarksSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height = iconsSize.getHeight() + this.iconSpace * 2 + textRemarksSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textRemarksSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeImageAndTextRemarksAndTaskSize() {
        return this.addTaskSize(this.includeImageAndTextRemarksSize());
    }

    includeImageAndTextRemarksAndLatexSize() {
        return this.addLatexSize(this.includeImageAndTextRemarksSize());
    }

    includeImageAndTextRemarksSize() {
        let width = 0;
        let height = 0;
        let textRemarksSize = this.includeRemarksAndTextSize();

        if (this.data.imageContent == null) {
            width = (textRemarksSize.getWidth());
            height = (textRemarksSize.getHeight());
            return new Size(width, height);
        }
        let imageContent = this.data.imageContent;
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textRemarksSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textRemarksSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textRemarksSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textRemarksSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textRemarksSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textRemarksSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == ImageLayoutType.LAYOUT_TOP) {
                height = iconsSize.getHeight() + this.iconSpace * 2 + textRemarksSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textRemarksSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeImageAndTextAndIconAndTaskSize() {
        return this.addTaskSize(this.includeImageAndTextAndIconSize());
    }

    includeImageAndTextAndIconSize() {
        let width = 0;
        let height = 0;
        if (this.data.imageContent == null) {
            return includeIconAndTextSize();
        }
        let imageContent = this.data.imageContent;
        let textContent = this.getTextMindElementContent();
        if (textContent == null) {
            return new Size(0, 0);
        }
        let isShowText = isShowText();
        let textSize = new Size(isShowText ? textContent.width : 0, isShowText ? textContent.height : 0);
        let imageSize = new Size(this.data.imageContent.width, this.data.imageContent.height);
        let iconsSize = caluleIconElementSize();
        let iconsAndTextHeight = Math.max(iconsSize.getHeight() + this.iconSpace * 2, textSize.getHeight());
        let layoutType = imageContent.layoutType;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textSize.getWidth() + imageSize.getWidth();

            if (iconsAndTextHeight > imageSize.getHeight()) {
                height = iconsAndTextHeight;
            } else {
                height = imageSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (imageSize.getWidth() > iconsSize.getWidth() + this.iconSpace + textSize.getWidth()) {
                width = imageSize.getWidth();
            } else {
                width = iconsSize.getWidth() + this.iconSpace + textSize.getWidth();
            }
            height = iconsAndTextHeight + imageSize.getHeight();
        }
        return new Size(width, height);
    }

    includeImageAndTextAndLinkAndRemarksAndTaskSize() {
        return this.addTaskSize(this.includeImageAndTextAndLinkAndRemarksSize());
    }

    includeImageAndTextAndLinkAndRemarksSize() {
        let width = 0;
        let height = 0;
        let textLinkRemarksSize = this.includeTextAndLinkAndRemarksSize();
        if (this.data.imageContent == null) {
            width = (textLinkRemarksSize.getWidth());
            height = (textLinkRemarksSize.getHeight());
            return new Size(width, height);
        }
        let imageContent = this.data.imageContent;
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textLinkRemarksSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textLinkRemarksSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textLinkRemarksSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textLinkRemarksSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textLinkRemarksSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textLinkRemarksSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == ImageLayoutType.LAYOUT_TOP) {
                height = iconsSize.getHeight() + this.iconSpace * 2 + textLinkRemarksSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textLinkRemarksSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeImageAndTextConnectMapAndTaskSize() {
        return this.addTaskSize(this.includeImageAndTextConnectMapSize());
    }

    includeImageAndTextConnectMapAndLatexSize() {
        return this.addLatexSize(this.includeImageAndTextConnectMapSize());
    }

    includeImageAndTextConnectMapSize() {
        let width = 0;
        let height = 0;
        let textConnectMapSize = this.includeConnectMapAndTextSize();

        if (this.data.imageContent == null) {
            width = (textConnectMapSize.getWidth());
            height = (textConnectMapSize.getHeight());
            return new Size(width, height);
        }
        let imageContent = this.data.imageContent;
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textConnectMapSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textConnectMapSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textConnectMapSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textConnectMapSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textConnectMapSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textConnectMapSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == ImageLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textConnectMapSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textConnectMapSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeTextAndLinkAndConnectMapAndTaskSize() {
        return this.addTaskSize(this.includeTextAndLinkAndConnectMapSize());
    }

    includeTextAndLinkAndConnectMapAndLatexSize() {
        return this.addLatexSize(this.includeTextAndLinkAndConnectMapSize());
    }

    includeTextAndLinkAndConnectMapSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return new Size(0, 0);
        }

        if (!this.data.isContainLinkContent() && this.data.isContainConnectMapContent()) {
            return this.includeConnectMapAndTextSize();
        } else if (this.data.isContainLinkContent() && !this.data.isContainConnectMapContent()) {
            return this.includeLinkAndTextSize();
        } else if (!this.data.isContainLinkContent() && !this.data.isContainConnectMapContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let linkElementContent = this.data.linkElementContent;
        let connectMapElementContent = this.data.connectMapElementContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let iconsSize = new Size(linkElementContent.width + connectMapElementContent.width + this.iconSpace, linkElementContent.height);

        if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
        } else {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
        }
        if (iconsSize.getHeight() + this.iconSpace * 2 > (textMindElementContent.height)) {
            height = iconsSize.getHeight() + this.iconSpace * 2;
        } else {
            height = textMindElementContent.height;
        }

        if (text.length == 0 && !this.data.isEdit) {
            width = iconsSize.getWidth() + this.iconMargin * 2;
        }
        return new Size(width, height);
    }

    includeConnectMapAndTextAndTaskSize() {
        return this.addTaskSize(this.includeConnectMapAndTextSize());
    }

    includeConnectMapAndTextAndTaskAndLatexSize() {
        return this.addLatexSize(this.addTaskSize(this.includeConnectMapAndTextSize()));
    }

    includeConnectMapAndTextAndLatexSize() {
        return this.addLatexSize(this.includeConnectMapAndTextSize());
    }

    includeConnectMapAndTextSize() {
        let size = new Size(0, 0);
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }

        if (!this.data.isContainConnectMapContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let connectMapElementContent = this.data.connectMapElementContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let iconsSize = new Size((connectMapElementContent.width), (connectMapElementContent.height));

        if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
        } else {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
        }
        if (iconsSize.getHeight() + this.iconSpace * 2 > (textMindElementContent.height)) {
            height = iconsSize.getHeight() + this.iconSpace * 2;
        } else {
            height = (textMindElementContent.height);
        }

        if (text.length == 0 && !this.data.isEdit) {
            width = iconsSize.getWidth() + this.iconMargin * 2;
        }
        return new Size(width, height);
    }

    includeRemarksAndTextAndTaskSize() {
        return this.addTaskSize(this.includeRemarksAndTextSize());
    }

    includeRemarksAndTextSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return new Size(0, 0);
        }

        if (!this.data.isContainRemarksContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let remarksElementContent = this.data.remarksElementContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let iconsSize = new Size(remarksElementContent.width, remarksElementContent.height);

        if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
        } else {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
        }
        if (iconsSize.getHeight() + this.iconSpace * 2 > (textMindElementContent.height)) {
            height = iconsSize.getHeight() + this.iconSpace * 2;
        } else {
            height = textMindElementContent.height;
        }

        if (text.length == 0 && !this.data.isEdit) {
            width = iconsSize.getWidth() + this.iconMargin * 2;
        }
        return new Size(width, height);
    }

    includeImageAndTextAndLinkAndConnectMapSize() {
        let width = 0;
        let height = 0;
        let textLinkConnectMapSize = this.includeTextAndLinkAndConnectMapSize();
        if (this.data.imageContent == null) {
            width = (textLinkConnectMapSize.getWidth());
            height = (textLinkConnectMapSize.getHeight());
            return new Size(width, height);
        }
        let imageContent = this.data.imageContent;
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textLinkConnectMapSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textLinkConnectMapSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textLinkConnectMapSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textLinkConnectMapSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textLinkConnectMapSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textLinkConnectMapSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == ImageLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textLinkConnectMapSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textLinkConnectMapSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeIconAndTextAndConnectMapAndRemarksSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0 ||
            !this.data.isContainConnectMapContent() || !this.data.isContainRemarksContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        let textConnectMapRemarksSize = this.includeTextAndRemarksAndConnectMapSize();

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconMargin + textConnectMapRemarksSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textConnectMapRemarksSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textConnectMapRemarksSize.getHeight();
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textConnectMapRemarksSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textConnectMapRemarksSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textConnectMapRemarksSize.getHeight();
        }
        if (text.length == 0 && !data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height = iconsSize.getHeight() + this.iconSpace * 2 + textConnectMapRemarksSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textConnectMapRemarksSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeLinkAndTextAndTaskSize() {
        return this.addTaskSize(this.includeLinkAndTextSize());
    }

    includeMoreAndTextAndTaskSize() {
        return this.addTaskSize(this.includeMoreAndTextSize());
    }

    includeResourceAndTextAndTaskSize() {
        return this.addTaskSize(this.includeResourceAndTextSize());
    }

    includeLinkAndTextAndLatexSize() {
        return this.addLatexSize(this.includeLinkAndTextSize());
    }

    includeMoreAndTextAndLatexSize() {
        return this.addLatexSize(this.includeMoreAndTextSize());
    }

    includeResourceAndTextAndLatexSize() {
        return this.addLatexSize(this.includeResourceAndTextSize());
    }

    includeLinkAndTextSize() {
        let width = 0;
        let height = 0;
        let textMindElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        } else {
            return new Size(0, 0);
        }

        if (!this.data.isContainLinkContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let linkElementContent = this.data.linkElementContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let iconsSize = new Size(linkElementContent.width, linkElementContent.height);

        if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
        } else {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
        }
        if (iconsSize.getHeight() + this.iconSpace * 2 >= textMindElementContent.height) {
            height = iconsSize.getHeight() + this.iconSpace * 2;
        } else {
            height = textMindElementContent.height;
        }

        if (text.length == 0 && !this.data.isEdit) {
            width = iconsSize.getWidth() + this.iconMargin * 2;
        }
        return new Size(width, height);
    }

    includeMoreAndTextSize() {
        let width = 0;
        let height = 0;
        let textMindElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        } else {
            return new Size(0, 0);
        }

        if (this.data.moreContent == null) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let moreContent = this.data.moreContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let iconsSize = new Size(moreContent.width, moreContent.height);

        if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
        } else {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
        }
        if (iconsSize.getHeight() + this.iconSpace * 2 >= textMindElementContent.height) {
            height = iconsSize.getHeight() + this.iconSpace * 2;
        } else {
            height = textMindElementContent.height;
        }

        if (text.length == 0 && !this.data.isEdit) {
            width = iconsSize.getWidth() + this.iconMargin * 2;
        }
        return new Size(width, height);
    }

    includeResourceAndTextSize() {
        let width = 0;
        let height = 0;
        let textMindElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        } else {
            return new Size(0, 0);
        }

        if (this.data.resourceContent == null) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let resourceContent = this.data.resourceContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let iconsSize = new Size(resourceContent.width, resourceContent.height);

        if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
        } else {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
        }
        if (iconsSize.getHeight() + this.iconSpace * 2 >= textMindElementContent.height) {
            height = iconsSize.getHeight() + this.iconSpace * 2;
        } else {
            height = textMindElementContent.height;
        }

        if (text.length == 0 && !this.data.isEdit) {
            width = iconsSize.getWidth() + this.iconMargin * 2;
        }
        return new Size(width, height);
    }

    includeIconAndTextAndLinkAndConnectMapSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return new Size(0, 0);
        }

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0 ||
                !this.data.isContainLinkContent() || !this.data.isContainConnectMapContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        let textLinkConnectMapSize = this.includeTextAndLinkAndConnectMapSize();

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconMargin + textLinkConnectMapSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textLinkConnectMapSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textLinkConnectMapSize.getHeight();
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textLinkConnectMapSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textLinkConnectMapSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textLinkConnectMapSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textLinkConnectMapSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textLinkConnectMapSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeImageAndTextAndRemarksAndConnectMapSize() {
        let width = 0;
        let height = 0;

        let textremarksConnectMapSize = this.includeTextAndRemarksAndConnectMapSize();
        if (this.data.imageContent == null) {
            width = (textremarksConnectMapSize.getWidth());
            height = (textremarksConnectMapSize.getHeight());
            return new Size(width, height);
        }
        let imageContent = this.data.imageContent;
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textremarksConnectMapSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textremarksConnectMapSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textremarksConnectMapSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textremarksConnectMapSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textremarksConnectMapSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textremarksConnectMapSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == ImageLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textremarksConnectMapSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textremarksConnectMapSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    getTextMindElementContent() {
        if (this.data.textContent != null) {
            return this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            return this.data.generalizationContent;
        } else {
            return null;
        }
    }

    caluleIconElementRect(startX, startY) {
        let iconsRect = new CGRect((startX), (startY), 0, 0);
        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            return iconsRect;
        }

        let space = new UiUtil().dip2px(4);
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return iconsRect;
        }

        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = (index) * iconCell.width + startX;
            } else {
                iconCell.x = (index) * (iconCell.width + space) + startX;
            }
            if (textMindElementContent != null) {
                iconCell.y = textMindElementContent.y + (textMindElementContent.height - iconCell.height) / 2;
            } else {
                iconCell.y = startY;
            }
            iconsRect.width(iconCell.x + iconCell.width);
            iconsRect.height(iconCell.height);
            iconsRect.y = (iconCell.y);
        }
        return iconsRect;
    }

    caluleIconElementSize() {
        let width = 0;
        let height = 0;

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            return new Size(0, 0);
        }

        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                width = width + (iconCell.width);
            } else {
                width = width + this.iconSpace + (iconCell.width);
            }
            if (height < iconCell.height) {
                height = iconCell.height;
            }
        }
        return new Size(width, height);
    }

    includeImageAndTextLinkSize() {
        let width = 0;
        let height = 0;
        let textLinkSize = this.includeLinkAndTextSize();

        if (this.data.imageContent == null) {
            width = (textLinkSize.getWidth());
            height = (textLinkSize.getHeight());
            return new Size(width, height);
        }
        let imageContent = this.data.imageContent;
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textLinkSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textLinkSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textLinkSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textLinkSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textLinkSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textLinkSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == ImageLayoutType.LAYOUT_TOP) {
                height = iconsSize.getHeight() + this.iconSpace * 2 + textLinkSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textLinkSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeImageAndTextMoreSize() {
        let width = 0;
        let height = 0;
        let textMoreSize = this.includeMoreAndTextSize();

        if (this.data.imageContent == null) {
            width = (textMoreSize.getWidth());
            height = (textMoreSize.getHeight());
            return new Size(width, height);
        }
        let imageContent = this.data.imageContent;
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textMoreSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textMoreSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textMoreSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textMoreSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textMoreSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textMoreSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == ImageLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textMoreSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textMoreSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeImageAndTextResourceSize() {
        let width = 0;
        let height = 0;
        let textResourceSize = this.includeResourceAndTextSize();

        if (this.data.imageContent == null) {
            width = (textResourceSize.getWidth());
            height = (textResourceSize.getHeight());
            return new Size(width, height);
        }
        let imageContent = this.data.imageContent;
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        if (layoutType == ImageLayoutType.LAYOUT_LEFT || layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconSpace + textResourceSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textResourceSize.getHeight()) {
                height = iconsSize.getHeight() + iconSpace * 2;
            } else {
                height = textResourceSize.getHeight();
            }
        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textResourceSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textResourceSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textResourceSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == ImageLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textResourceSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textResourceSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeTextAndRemarksAndConnectMapSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();

        if (!this.data.isContainRemarksContent() && this.data.isContainConnectMapContent()) {
            return this.includeConnectMapAndTextSize();
        } else if (this.data.isContainRemarksContent() && !this.data.isContainConnectMapContent()) {
            return includeRemarksAndTextSize();
        } else if (!this.data.isContainRemarksContent() && !this.data.isContainConnectMapContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let remarksElementContent = this.data.remarksElementContent;
        let connectMapElementContent = this.data.connectMapElementContent;
        let text = "";

        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        let iconsSize = new Size((remarksElementContent.width + connectMapElementContent.width) + this.iconSpace, (remarksElementContent.height));

        if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width) + this.iconTextSpace;
        } else {
            width = iconsSize.getWidth() + this.iconMargin + (textMindElementContent.width);
        }
        if (iconsSize.getHeight() + this.iconSpace * 2 > (textMindElementContent.height)) {
            height = iconsSize.getHeight() + this.iconSpace * 2;
        } else {
            height = (textMindElementContent.height);
        }

        if (text.length == 0 && !this.data.isEdit) {
            width = iconsSize.getWidth() + this.iconMargin * 2;
        }
        return new Size(width, height);
    }

    includeIconAndTextLinkAndTaskSize() {
        return this.addTaskSize(this.includeIconAndTextLinkSize());
    }

    includeIconAndTextMoreAndTaskSize() {
        return this.addTaskSize(this.includeIconAndTextMoreSize());
    }

    includeIconAndTextResourceAndTaskSize() {
        return this.addTaskSize(this.includeIconAndTextResourceSize());
    }

    includeIconAndTextLinkSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0 || !this.data.isContainLinkContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        let textLinkSize = this.includeLinkAndTextSize();

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconMargin + textLinkSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textLinkSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textLinkSize.getHeight();
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textLinkSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textLinkSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textLinkSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height = iconsSize.getHeight() + this.iconSpace * 2 + textLinkSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textLinkSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeIconAndTextMoreSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0 || this.data.moreContent == null) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        let textMoreSize = this.includeMoreAndTextSize();

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconMargin + textMoreSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textMoreSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textMoreSize.getHeight();
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textMoreSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textMoreSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textMoreSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textMoreSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textMoreSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    includeIconAndTextResourceSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0 || this.data.resourceContent == null) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        let textResourceSize = this.includeResourceAndTextSize();

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconMargin + textResourceSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textResourceSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textResourceSize.getHeight();
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textResourceSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textResourceSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textResourceSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textResourceSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textResourceSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    getTextRectSize( text, textSize, bold) {
        let size = MindElementCalculation.caluleText(text, textSize, bold);
        return new CGRect(0, 0, size.getWidth(), size.getHeight());
    }

    caluleTextCGRect() {

        let text = "";
        let textStrikethrough = false;
        let textBold = false;
        let textFontSize = 18;
        let textColor = Colors.black60;
        let textItalics = false;

        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
            textStrikethrough = this.data.textContent.textStrikethrough;
            textBold = this.data.textContent.textBold;
            textFontSize = this.data.textContent.textFontSize;
            textColor = this.data.textContent.textColor;
            textItalics = this.data.textContent.textItalics;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
            textStrikethrough = this.data.generalizationContent.textStrikethrough;
            textBold = this.data.generalizationContent.textBold;
            textFontSize = this.data.generalizationContent.textFontSize;
            textColor = this.data.generalizationContent.textColor;
            textItalics = this.data.generalizationContent.textItalics;
        }
        text = "AbcdefjJG";

        if (textMindElementContent == null) {
            return new CGRect(0, 0, 0, 0);
        }
        let maxWidth = Config.contentMaxWidth;
        if(this.data.customWidth > 0){
            if (this.data.customWidth > new UiUtil().dip2px(12)) {
                maxWidth = this.data.customWidth;
            } else {
                maxWidth = new UiUtil().dip2px(12);
            }
        }
        let size = MindElementCalculation.caluleText(text, textFontSize, textBold,maxWidth);

        return new CGRect(0, 0, size.getWidth(), size.getHeight());
    }

    getMargin(contentSize) {
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return new EdgeInsets().init();
        }
        let left = 0;
        let top = 0;
        let right = 0;
        let bottom = 0;

        let textY = this.getTextTop();
        let textX = this.getTextLeft();
        let x = 0;

        //MindElementShapeType.Ellipse_3D:
        let ellipseHeight;

        // MindElementShapeType.Cube:
        let cubeHeight;

        // MindElementShapeType.Form:
        let formHeight

        // MindElementShapeType.Three_Circular_Cloud:
        let radius;
        let leftCloudAngle;
        let rightCloudAngle;
        let leftIntersectionPointX;
        let leftIntersectionPointY;
        let rightIntersectionPointX;
        let rightIntersectionPointY;
        let leftRadius;
        let rightRadius;
        let offset;

        // MindElementShapeType.LeftArrow2:
        let arrowAngle;
        let withoutPace;
        let arrowHeight;

        // MindElementShapeType.Triangle:
        let triangleContentWidth;
        let triangleContentHeight;
        let topHeight;
        let triangleHeight;
        let sideWidth;

        // MindElementShapeType.InvertedTriangle:
        let bottomHeight;

        // MindElementShapeType.RightTriangle:
        let rightWidth;
        let triangleWidth;

        // case MindElementShapeType.LeftTriangle:
        let leftWidth;


        let textEdge = new EdgeInsets(0, 0, 0, 0) //this.getTextEdgeInsets() //

        if (this.data.mindElementShape == null) {
            this.data.mindElementShape = MindElementShapeType.Corner;
        }
        if (this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            if (this.data.type == MindElementType.SON_SUBJECT) {
                left = right = this.UiUtil.dip2px(4);
                top = bottom = this.UiUtil.dip2px(4);
            } else {
                left = right = this.UiUtil.dip2px(8);
                top = bottom = this.UiUtil.dip2px(4);
            }
        } else {
            switch (this.data.mindElementShape) {
                case MindElementShapeType.Corner:
                case MindElementShapeType.Card:
                case MindElementShapeType.Underline:
                    if (this.data.type == MindElementType.SON_SUBJECT) {
                        left = right = this.UiUtil.dip2px(4) - textEdge.left;
                        top = bottom = this.UiUtil.dip2px(4) - textEdge.top;
                    } else {
                        left = right = this.UiUtil.dip2px(8) - textEdge.left;
                        top = bottom = this.UiUtil.dip2px(4) - textEdge.top;
                    }    
                    break;
                case MindElementShapeType.CornerShadow:
                    if (this.data.type == MindElementType.SON_SUBJECT) {
                        left = right = this.UiUtil.dip2px(4) - textEdge.left;
                        top = bottom = this.UiUtil.dip2px(2);
                    } else {
                        left = right = this.UiUtil.dip2px(8) - textEdge.left;
                        top = bottom = this.UiUtil.dip2px(2);
                    }
    
                    break;
                case MindElementShapeType.Two_Underline:
                    if (this.data.type == MindElementType.SON_SUBJECT) {
                        left = right = this.UiUtil.dip2px(4) - textEdge.left;
                        top = this.UiUtil.dip2px(4) - textEdge.top;
                        bottom = this.UiUtil.dip2px(6) + this.data.borderWidth -  textEdge.bottom;
                    } else {
                        left = right = this.UiUtil.dip2px(8) - textEdge.left;
                        top = this.UiUtil.dip2px(4) - textEdge.top;
                        bottom = this.UiUtil.dip2px(6) + this.data.borderWidth - textEdge.bottom;
                    }
    
                    break;
                case MindElementShapeType.Brackets:
                    left = right = this.UiUtil.dip2px(6) - textEdge.left;
                    top = bottom = this.UiUtil.dip2px(0);
                    break;
                case MindElementShapeType.Large_braces:
                    left = right = this.UiUtil.dip2px(8) - textEdge.left;
                    top = bottom = this.UiUtil.dip2px(2);
                    break;
                case MindElementShapeType.Angle_braces:
                    left = right = this.UiUtil.dip2px(10) - textEdge.left;
                    top = bottom = this.UiUtil.dip2px(-3);
                    break;
                case MindElementShapeType.Middle_brackets:
                    left = right = this.UiUtil.dip2px(10) - textEdge.left;
                    top = bottom = this.UiUtil.dip2px(3);
                    break;
                case MindElementShapeType.Corner_Right:
                    if (this.data.type == MindElementType.SON_SUBJECT) {
                        left = right = this.UiUtil.dip2px(6) - textEdge.left;
                        top = bottom = this.UiUtil.dip2px(0);
                    } else {
                        left = right = this.UiUtil.dip2px(8) - textEdge.left;
                        top = bottom = this.UiUtil.dip2px(2);
                    }
                    break;
                case MindElementShapeType.Semicircle:
                    radius = contentSize.getHeight() / 2;
                    left = right = radius - textEdge.left;
                    top = bottom = this.UiUtil.dip2px(2) - textEdge.top;
                    break;
                case MindElementShapeType.Diamond:
    
                    let startPoint = new Point(0, contentSize.getHeight() / 2);
                    let endPoint = new Point(contentSize.getWidth() / 2, 0);
                    let angle = this.Util.getAnglesWithThreePoints(startPoint, endPoint, new Point(startPoint.x, endPoint.y));
                    x = ((contentSize.getHeight() / 2 - textY) / (Math.tan(angle)));
    
                    if (textX <= x) {
                        left = right = x - textX;
                        top = bottom = left;
                    }
                    break;
                case MindElementShapeType.Cloud:
                    left = right = this.UiUtil.dip2px(8) - textEdge.left;
                    top = bottom = this.UiUtil.dip2px(5);
                    break;
                case MindElementShapeType.CircularAndRight:
                    left = right = this.UiUtil.dip2px(8) - textEdge.left;
                    top = bottom = this.UiUtil.dip2px(3);
                    break;
                case MindElementShapeType.Circular:
                case MindElementShapeType.Circular_Right_Top:
                case MindElementShapeType.Book:
                    radius = Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 - textEdge.left;
                    left = right = radius - contentSize.getWidth() / 2;
                    top = bottom = radius - contentSize.getHeight() / 2;
                    break;
                case MindElementShapeType.Ellipse:
                    radius = Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 - textEdge.left;
                    let space = this.data.type == MindElementType.EXPLAIN ? new UiUtil().dip2px(8) : 0;
                    if (Math.abs(contentSize.getWidth() - contentSize.getHeight()) < this.UiUtil.dip2px(20)) {
                        left = right = radius - contentSize.getWidth() / 2 + space;
                        top = bottom = radius - contentSize.getHeight() / 2;
                    } else if (contentSize.getWidth() > contentSize.getHeight()) {
                        left = right = radius - contentSize.getWidth() / 2 + this.UiUtil.dip2px(12) + space;
                        top = bottom = (radius - contentSize.getHeight() / 2)/2;
                    } else {
                        left = right = (radius - contentSize.getWidth() / 2)/2+ space;
                        top = bottom = radius - contentSize.getHeight() / 2 + this.UiUtil.dip2px(12);
                    }
                    break;
                case MindElementShapeType.Ellipse_3D:
                    ellipseHeight = Config.getEllipse3DHeight(this.data, contentSize.getWidth(), contentSize.getHeight());
                    left = right = this.UiUtil.dip2px(8) - textEdge.left;
                    top = ellipseHeight + (contentSize.getHeight() > this.UiUtil.dip2px(36) ? this.UiUtil.dip2px(10) : this.UiUtil.dip2px(4));
                    bottom = ellipseHeight / 2 + (contentSize.getHeight() > this.UiUtil.dip2px(36) ? this.UiUtil.dip2px(6) : this.UiUtil.dip2px(2));
                    break;
                case MindElementShapeType.Cube:
                    cubeHeight = Config.getCube3DHeight(this.data, contentSize.getWidth(), contentSize.getHeight());
                    left = this.UiUtil.dip2px(8) - textEdge.left;
                    right = cubeHeight + this.UiUtil.dip2px(8) - textEdge.right;
                    top = cubeHeight + this.UiUtil.dip2px(6);
                    bottom = this.UiUtil.dip2px(6);
                    break;
                case MindElementShapeType.Form:
                    formHeight = Config.getFormHeight(this.data, contentSize.getWidth(), contentSize.getHeight());
                    left = right = this.UiUtil.dip2px(8) - textEdge.left;
                    top = this.UiUtil.dip2px(8);
                    bottom = formHeight + this.UiUtil.dip2px(6);
                    break;
                case MindElementShapeType.Three_Circular_Cloud:
                    radius = Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2;
                    leftCloudAngle = 16;
                    rightCloudAngle = 8;
                    leftIntersectionPointX = radius + ((radius) * this.Util.cosd(180 + leftCloudAngle));
                    leftIntersectionPointY = radius + ((radius) *  this.Util.sind(180 + leftCloudAngle));
                    rightIntersectionPointX = radius + ((radius) *  this.Util.cosd(360 - rightCloudAngle));
                    rightIntersectionPointY = radius + ((radius) *  this.Util.sind(360 - rightCloudAngle));
                    leftRadius = (radius * 2 - leftIntersectionPointY) / 2;
                    rightRadius = (radius * 2 - rightIntersectionPointY) / 2;
    
                    left = radius - contentSize.getWidth() / 2 + leftRadius - leftIntersectionPointX;
                    right = radius - contentSize.getWidth() / 2 + rightRadius - (radius * 2 - rightIntersectionPointX);
                    offset = ((radius - contentSize.getHeight() / 2) * 0.4);
                    top = radius - contentSize.getHeight() / 2 + offset;
                    bottom = radius - contentSize.getHeight() / 2 - offset;
                    break;
                case MindElementShapeType.Ring:
                case MindElementShapeType.Ring3:
                    radius = Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 + Config.Node_Ring_Space - textEdge.left;
                    left = right = radius - contentSize.getWidth() / 2;
                    top = bottom = radius - contentSize.getHeight() / 2;
                    break;
                case MindElementShapeType.Ring2:
                    radius = Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 + Config.Node_Ring_Space - textEdge.left;
                    left = right = radius - contentSize.getWidth() / 2;
                    top = radius - contentSize.getHeight() / 2;
                    bottom = Math.sqrt(Math.pow(radius, 2) + Math.pow(radius, 2)) - contentSize.getHeight() / 2;
                    break;
                case MindElementShapeType.Ring2_UP:
                    radius = Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 + Config.Node_Ring_Space - textEdge.left;
                    left = right = radius - contentSize.getWidth() / 2;
                    bottom = radius - contentSize.getHeight() / 2;
                    top = Math.sqrt(Math.pow(radius, 2) + Math.pow(radius, 2)) - contentSize.getHeight() / 2;
                    break;
                case MindElementShapeType.Hexagon:
                case MindElementShapeType.Left_Right_Three_Side:
                case MindElementShapeType.Left_Straight_Right_Arrow:
                case MindElementShapeType.Right_Straight_Left_Arrow:
                case MindElementShapeType.RightArrow:
                case MindElementShapeType.LeftArrow:
                    if (this.data.type == MindElementType.SON_SUBJECT) {
                        top = bottom = this.UiUtil.dip2px(1);
                    } else {
                        top = bottom = this.UiUtil.dip2px(2);
                    }
                    left = right = (contentSize.getHeight() + top) / 2 - textEdge.left;
                    break;
                case MindElementShapeType.LeftArrow2:
                    arrowAngle = 80;
                    withoutPace = contentSize.getHeight()/2 / this.Util.tan(arrowAngle/2);
                    arrowHeight = (this.Util.tan(arrowAngle/2) * withoutPace * 2 * 2);
                    top = bottom = (arrowHeight - contentSize.getHeight()) / 2;
                    left = (withoutPace + withoutPace / 2);
                    right = withoutPace / 2;
                    break;
                case MindElementShapeType.RightArrow2:
                    arrowAngle = 80;
                    withoutPace = contentSize.getHeight()/2 / this.Util.tan(arrowAngle/2);
                    arrowHeight = (this.Util.tan(arrowAngle/2) * withoutPace * 2 * 2);
                    top = bottom = (arrowHeight - contentSize.getHeight()) / 2;
                    right = (withoutPace + withoutPace / 2);
                    left = withoutPace / 2;
                    break;
                case MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle:
                    left = right = this.UiUtil.dip2px(8) - textEdge.left;
                    top = bottom = this.UiUtil.dip2px(5);
                    break;
                case MindElementShapeType.Triangle:
                    triangleContentWidth = contentSize.getWidth() + this.UiUtil.dip2px(4) - textEdge.left - textEdge.right;
                    triangleContentHeight = contentSize.getHeight();
                    topHeight = (triangleContentWidth / 2) / Math.tan(this.Util.toRadians(30));
                    triangleHeight = topHeight + triangleContentHeight + topHeight / 2;
                    sideWidth = triangleHeight / Math.sin(this.Util.toRadians(60));
    
                    left = right = (sideWidth - contentSize.getWidth()) / 2;
                    top = topHeight;
                    bottom = topHeight/2;
                    break;
                case MindElementShapeType.InvertedTriangle:
                    triangleContentWidth = contentSize.getWidth() + this.UiUtil.dip2px(4) - textEdge.left - textEdge.right;
                    triangleContentHeight = contentSize.getHeight();
                    bottomHeight = (triangleContentWidth / 2) / Math.tan(this.Util.toRadians(30));
                    triangleHeight = bottomHeight + triangleContentHeight + bottomHeight / 2;
                    sideWidth = triangleHeight / Math.sin(this.Util.toRadians(60));
    
                    left = right = (sideWidth - contentSize.getWidth()) / 2;
                    top = bottomHeight / 2;
                    bottom = bottomHeight;
                    break;
                case MindElementShapeType.RightTriangle:
                    triangleContentWidth = contentSize.getWidth() - textEdge.left - textEdge.right;
                    triangleContentHeight = contentSize.getHeight() + this.UiUtil.dip2px(4);
                    rightWidth = (triangleContentHeight / 2) / Math.tan(this.Util.toRadians(30));
                    triangleWidth = rightWidth + triangleContentWidth + rightWidth / 2;
                    sideWidth = triangleWidth / Math.sin(this.Util.toRadians(60));
    
                    left = (rightWidth) / 4;
                    right = (rightWidth) / 2;
                    top = bottom = (sideWidth - contentSize.getHeight()) / 2;
                    break;
                case MindElementShapeType.LeftTriangle:
                    triangleContentWidth = contentSize.getWidth() - textEdge.left - textEdge.right;
                    triangleContentHeight = contentSize.getHeight() + this.UiUtil.dip2px(4);
                    leftWidth = (triangleContentHeight / 2) / Math.tan(this.Util.toRadians(30));
                    triangleWidth = leftWidth + triangleContentWidth + leftWidth / 2;
                    sideWidth = triangleWidth / Math.sin(this.Util.toRadians(60));
                    left = leftWidth;
                    right = leftWidth/2;
                    top = bottom = (sideWidth - contentSize.getHeight()) / 2;
                    break;
                case MindElementShapeType.Oblique_corner:
                    let obliqueAngle = 60;
                    top = bottom = this.UiUtil.dip2px(2)  - textEdge.top;
                    left = (contentSize.getHeight() + top*2) / this.Util.tan(obliqueAngle);
                    right = left;
                    break;
                case MindElementShapeType.Heart:
                    radius = Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 - textEdge.left;
                    left = right = radius * 1.125 - contentSize.getWidth() / 2;
                    top = (radius * 2 - contentSize.getHeight()) * 0.350;
                    bottom = (radius * 2 - contentSize.getHeight()) * 0.650;
                    break;
                case MindElementShapeType.Gemstone:
                    radius = Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2  - textEdge.left;
                    left = right = radius * 1.206 - contentSize.getWidth() / 2;
                    top = (radius * 2 - contentSize.getHeight()) * 0.200;
                    bottom = (radius * 2 - contentSize.getHeight()) * 0.800;
                    break;
                case MindElementShapeType.Star:
                    radius = (Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 * 1.58)  - textEdge.left;
                    left = right = radius - contentSize.getWidth() / 2;
                    top = (radius * 2 - contentSize.getHeight())/2;
                    bottom = (radius * 2 - contentSize.getHeight())/2;
                    break;
                case MindElementShapeType.Octagonal:
                    radius = (Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 * 1.18) - textEdge.left;
                    left = right = radius - contentSize.getWidth() / 2;
                    top = (radius * 2 - contentSize.getHeight())/2;
                    bottom = (radius * 2 - contentSize.getHeight())/2;
                    break;
                case MindElementShapeType.Regular_hexagon:
                    let margin = 10
                    radius = (Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2) + margin;
                    let hSpace = radius - Util.sind(60) * radius
                    //radius = (Math.sqrt(Math.pow(contentSize.getWidth(),2) + Math.pow(contentSize.getHeight(),2)) / 2 * 1.28) - textEdge.left;
                    left = right = radius - contentSize.getWidth() / 2;
                    top = (radius * 2 - contentSize.getHeight())/2 - hSpace;
                    bottom = (radius * 2 - contentSize.getHeight())/2 - hSpace;
                    break;
                case MindElementShapeType.Shield:
                    let scale = 0.81;
                    var beforHeight = contentSize.getHeight() / (0.6434 - 0.089);
                    var beforWidth = contentSize.getWidth() + this.UiUtil.dip2px(10) * 2;
                    if (beforWidth/beforHeight > scale) {
                        left = right = this.UiUtil.dip2px(10) - textEdge.left;
                        beforHeight = beforWidth / scale;
                        let spaceV = (beforHeight * (0.6434 - 0.089) - contentSize.getHeight()) / 2;
                        top = (beforHeight * 0.089) + spaceV;
                        bottom = (beforHeight * (1 - 0.6434)) + spaceV;
                    } else {
                        top = (beforHeight * 0.089);
                        bottom =(beforHeight * (1 - 0.6434));
                        left = right = (beforHeight * scale - contentSize.getWidth()) / 2;
                    }
                    break;
                case MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner:
                    if (this.data.type == MindElementType.MAIN_SUBJECT) {
                        left = right = this.UiUtil.dip2px(8);
                        top = bottom = this.UiUtil.dip2px(12);
                    } else if (this.data.type == MindElementType.SON_SUBJECT) {
                        left = right = this.UiUtil.dip2px(4);
                        top = bottom = this.UiUtil.dip2px(6);
                    } else {
                        left = right = this.UiUtil.dip2px(6);
                        top = bottom = this.UiUtil.dip2px(10);
                    }
                    break;
                default:
                    left = right = 0;
                    top = bottom = 0;
            }
        }
        
        return new EdgeInsets(left, top, right, bottom);

    }

    setMindElementSize(width, height, edgeInsets) {
        this.data.width = (width) + edgeInsets.left + edgeInsets.right;
        this.data.height = (height) + edgeInsets.top + edgeInsets.bottom;
        this.setCustomSize(this.data.customWidth);
    }

    changeDataWidth(data, width, toWidth) {
        data.width = width;
        switch (data.alignmentType) {
            case MindElementAlignmentType.RIGHT:
                toWidth = toWidth * 2;
                break;
            case MindElementAlignmentType.MIDDLE:
            case MindElementAlignmentType.UNKNOWN:
                toWidth = toWidth;
                break;
            case MindElementAlignmentType.LEFT:
                toWidth = 0;
                break;
            default:
                toWidth = 0;
        }

        if (data.imageContent != null) {
            data.imageContent.x = data.imageContent.x + toWidth / 2;
        }
        if (data.textContent != null) {
            data.textContent.x = data.textContent.x + toWidth / 2;
        }
        if (data.generalizationContent != null) {
            data.generalizationContent.x = data.generalizationContent.x + toWidth / 2;
        }
        if (data.iconElementContents != null) {
            let iconCount = data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    data.iconElementContents[index].x = data.iconElementContents[index].x + toWidth / 2;
                }
            }
        }
        if (data.linkElementContent != null) {
            data.linkElementContent.x = data.linkElementContent.x + toWidth / 2;
        }
        if (data.moreContent != null) {
            data.moreContent.x = data.moreContent.x + toWidth / 2;
        }
        if (data.remarksElementContent != null) {
            data.remarksElementContent.x = data.remarksElementContent.x + toWidth / 2;
        }
        if (data.connectMapElementContent != null) {
            data.connectMapElementContent.x = data.connectMapElementContent.x + toWidth / 2;
        }

        if (data.taskContent != null) {
            data.taskContent.x = data.taskContent.x + toWidth / 2;
        }

        if (data.latexContent != null) {
            data.latexContent.x = data.latexContent.x + toWidth / 2;
        }
        //lxz add 附件
        if (data.resourceContent !=null){
            data.resourceContent.x = data.resourceContent.x + toWidth / 2;
        }
    }

    changeDataHeight(data, height, toHeight) {
        data.height = height;
        if (data.imageContent != null) {
            data.imageContent.y = data.imageContent.y + toHeight / 2;
        }
        if (data.textContent != null) {
            data.textContent.y = data.textContent.y + toHeight / 2;
        }
        if (data.generalizationContent != null) {
            data.generalizationContent.y = data.generalizationContent.y + toHeight / 2;
        }
        if (data.iconElementContents != null) {
            let iconCount = data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    data.iconElementContents[index].y = data.iconElementContents[index].y + toHeight / 2;
                }
            }
        }
        if (data.linkElementContent != null) {
            data.linkElementContent.y = data.linkElementContent.y + toHeight / 2;
        }
        if (data.moreContent != null) {
            data.moreContent.y = data.moreContent.y + toHeight / 2;
        }
        if (data.remarksElementContent != null) {
            data.remarksElementContent.y = data.remarksElementContent.y + toHeight / 2;
        }
        if (data.connectMapElementContent != null) {
            data.connectMapElementContent.y = data.connectMapElementContent.y + toHeight / 2;
        }

        if (data.taskContent != null) {
            data.taskContent.y = data.taskContent.y + toHeight / 2;
        }

        if (data.latexContent != null) {
            data.latexContent.y = data.latexContent.y + toHeight / 2;
        }
        //lxz add 附件
        if (data.resourceContent != null){
            data.resourceContent.y = data.resourceContent.y + toHeight / 2;
        }
    }

    setCustomSize(customWidth) {
        if (customWidth <= this.data.width) {
            return;
        }

        let newAddWidth = customWidth - this.data.width;
        // let newAddHeight = this.data.customHeight - this.data.height;
        switch (this.data.alignmentType) {
            case MindElementAlignmentType.RIGHT:
                newAddWidth = (customWidth - this.data.width) * 2;
                break;
            case MindElementAlignmentType.MIDDLE:
            case MindElementAlignmentType.UNKNOWN:
                newAddWidth = (customWidth - this.data.width);
                break;
            case MindElementAlignmentType.LEFT:
                newAddWidth = 0;
                break;
            default:
                newAddWidth = (customWidth - this.data.width);
        }
        if (this.data.mindElementShape == MindElementShapeType.Circular ||
            this.data.mindElementShape == MindElementShapeType.Ring ||
            this.data.mindElementShape == MindElementShapeType.Ring2 ||
            this.data.mindElementShape == MindElementShapeType.Ring3 ||
            this.data.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.data.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.data.mindElementShape == MindElementShapeType.Book ||
            this.data.mindElementShape == MindElementShapeType.Star ||
            this.data.mindElementShape == MindElementShapeType.Octagonal ||
            (this.data.layout == NodeLayoutType.LAYOUT_CIRCLE && this.data.type != MindElementType.EXPLAIN)) {
            newAddWidth = (customWidth - this.data.width)
        }

        this.data.width = customWidth;
        if (this.data.imageContent != null) {
            this.data.imageContent.x = this.data.imageContent.x + newAddWidth / 2;
        }
        if (this.data.textContent != null) {
            this.data.textContent.x = this.data.textContent.x + newAddWidth / 2;
        }
        if (this.data.generalizationContent != null) {
            this.data.generalizationContent.x = this.data.generalizationContent.x + newAddWidth / 2;
        }
        if (this.data.iconElementContents != null) {
            let iconCount = this.data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    this.data.iconElementContents[index].x = this.data.iconElementContents[index].x + newAddWidth / 2;
                }
            }
        }
        if (this.data.linkElementContent != null) {
            this.data.linkElementContent.x = this.data.linkElementContent.x + newAddWidth / 2;
        }
        if (this.data.remarksElementContent != null) {
            this.data.remarksElementContent.x = this.data.remarksElementContent.x + newAddWidth / 2;
        }
        if (this.data.moreContent != null) {
            this.data.moreContent.x = this.data.moreContent.x + newAddWidth / 2;
        }
        if (this.data.connectMapElementContent != null) {
            this.data.connectMapElementContent.x = this.data.connectMapElementContent.x + newAddWidth / 2;
        }

        if (this.data.taskContent != null) {
            this.data.taskContent.x = this.data.taskContent.x + newAddWidth / 2;
        }
        if (this.data.latexContent != null) {
            this.data.latexContent.x = this.data.latexContent.x + newAddWidth / 2;
        }
        //lxz add 附件
        if (this.data.resourceContent !=null){
            this.data.resourceContent.x = this.data.resourceContent.x + newAddWidth / 2;
        }

        if (this.data.mindElementShape == MindElementShapeType.Circular ||
            this.data.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.data.mindElementShape == MindElementShapeType.Ring ||
            this.data.mindElementShape == MindElementShapeType.Ring3 ||
            this.data.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.data.mindElementShape == MindElementShapeType.Book ||
            this.data.mindElementShape == MindElementShapeType.Star ||
            this.data.mindElementShape == MindElementShapeType.Octagonal ||
            (this.data.layout == NodeLayoutType.LAYOUT_CIRCLE && this.data.type != MindElementType.EXPLAIN)) {
            newAddWidth = customWidth - this.data.height;
            this.data.height = customWidth;

            if (this.data.imageContent != null) {
                this.data.imageContent.y = this.data.imageContent.y + newAddWidth / 2;
            }
            if (this.data.textContent != null) {
                this.data.textContent.y = this.data.textContent.y + newAddWidth / 2;
            }
            if (this.data.generalizationContent != null) {
                this.data.generalizationContent.y = this.data.generalizationContent.y + newAddWidth / 2;
            }
            if (this.data.iconElementContents != null) {
                let iconCount = this.data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + newAddWidth / 2;
                    }
                }
            }
            if (this.data.linkElementContent != null) {
                this.data.linkElementContent.y = this.data.linkElementContent.y + newAddWidth / 2;
            }
            if (this.data.remarksElementContent != null) {
                this.data.remarksElementContent.y = this.data.remarksElementContent.y + newAddWidth / 2;
            }
            if (this.data.moreContent != null) {
                this.data.moreContent.y = this.data.moreContent.y + newAddWidth / 2;
            }
            if (this.data.connectMapElementContent != null) {
                this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + newAddWidth / 2;
            }
            if (this.data.taskContent != null) {
                this.data.taskContent.y = this.data.taskContent.y + newAddWidth / 2;
            }
            if (this.data.latexContent != null) {
                this.data.latexContent.y = this.data.latexContent.y + newAddWidth / 2;
            }
            //lxz add 附件
            if (this.data.resourceContent !=null){
                this.data.resourceContent.y = this.data.resourceContent.y + newAddWidth / 2;
            }
        } else if (this.data.mindElementShape == MindElementShapeType.Heart ||
                    this.data.mindElementShape == MindElementShapeType.Shield ||
                    this.data.mindElementShape == MindElementShapeType.Gemstone) {
            var whScale = 1.0
            if (this.data.mindElementShape == MindElementShapeType.Heart) {
                whScale = 1.125
            } else if (this.data.mindElementShape == MindElementShapeType.Shield) {
                whScale = 0.81
            } else if (this.data.mindElementShape == MindElementShapeType.Gemstone) {
                whScale = 1.206
            }
            newAddWidth = customWidth - this.data.height;
            let customHeiht = customWidth / whScale
            newAddWidth = customHeiht - this.data.height;
            this.data.height = customHeiht;

            if (this.data.imageContent != null) {
                this.data.imageContent.y = this.data.imageContent.y + newAddWidth / 2;
            }
            if (this.data.textContent != null) {
                this.data.textContent.y = this.data.textContent.y + newAddWidth / 2;
            }
            if (this.data.generalizationContent != null) {
                this.data.generalizationContent.y = this.data.generalizationContent.y + newAddWidth / 2;
            }
            if (this.data.iconElementContents != null) {
                let iconCount = this.data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + newAddWidth / 2;
                    }
                }
            }
            if (this.data.linkElementContent != null) {
                this.data.linkElementContent.y = this.data.linkElementContent.y + newAddWidth / 2;
            }
            if (this.data.remarksElementContent != null) {
                this.data.remarksElementContent.y = this.data.remarksElementContent.y + newAddWidth / 2;
            }
            if (this.data.connectMapElementContent != null) {
                this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + newAddWidth / 2;
            }
            if (this.data.moreContent != null) {
                this.data.moreContent.y = this.data.moreContent.y + newAddWidth / 2;
            }
            if (this.data.taskContent != null) {
                this.data.taskContent.y = this.data.taskContent.y + newAddWidth / 2;
            }
            if (this.data.latexContent != null) {
                this.data.latexContent.y = this.data.latexContent.y + newAddWidth / 2;
            }
            //lxz add 附件
            if (this.data.resourceContent !=null){
                this.data.resourceContent.y = this.data.resourceContent.y + newAddWidth / 2;
            }
        }
    }

    
    setMindElementContentMove( left,  top,  right,  bottom) {

        if (this.data.imageContent != null) {
            this.data.imageContent.x = this.data.imageContent.x + left;
            this.data.imageContent.y = this.data.imageContent.y + top;
        }
        if (this.data.textContent != null) {
            this.data.textContent.x = this.data.textContent.x + left;
            this.data.textContent.y = this.data.textContent.y + top;
        }
        if (this.data.generalizationContent != null) {
            this.data.generalizationContent.x = this.data.generalizationContent.x + left;
            this.data.generalizationContent.y = this.data.generalizationContent.y + top;
        }
        if (this.data.iconElementContents != null) {
            let iconCount = this.data.iconElementContents.length;
            if (iconCount > 0) {
                for (var index = 0; index < iconCount; index++) {
                    this.data.iconElementContents[index].x = this.data.iconElementContents[index].x + left;
                    this.data.iconElementContents[index].y = this.data.iconElementContents[index].y + top;
                }
            }
        }
        if (this.data.linkElementContent != null) {
            this.data.linkElementContent.x = this.data.linkElementContent.x + left;
            this.data.linkElementContent.y = this.data.linkElementContent.y + top;
        }
        if (this.data.remarksElementContent != null) {
            this.data.remarksElementContent.x = this.data.remarksElementContent.x + left;
            this.data.remarksElementContent.y = this.data.remarksElementContent.y + top;
        }
        if (this.data.connectMapElementContent != null) {
            this.data.connectMapElementContent.x = this.data.connectMapElementContent.x + left;
            this.data.connectMapElementContent.y = this.data.connectMapElementContent.y + top;
        }

        if (this.data.taskContent != null) {
            this.data.taskContent.x = this.data.taskContent.x + left;
            this. data.taskContent.y = this.data.taskContent.y + top;
        }
    }

    includeIconAndTextConnectMapAndTaskSize() {
        return this.addTaskSize(this.includeIconAndTextConnectMapSize());
    }

    includeIconAndTextConnectMapAndLatexSize() {
        return this.addLatexSize(this.includeIconAndTextConnectMapSize());
    }

    addTaskSize( size) {
        let width = size.getWidth();
        let height = size.getHeight();
        if (this.data.taskContent != null) {
            let taskSize = this.getTaskOriginalSize();
            if (taskSize.getWidth() > 0) {
                let textEdgeInsets = this.getTextEdgeInsets();
                width = Math.max(width, taskSize.getWidth() + textEdgeInsets.left + textEdgeInsets.right);
                height = height + textEdgeInsets.bottom + taskSize.getHeight();
            }
        }
        return new Size(width, height);
    }

    addLatexSize(size) {
        store.state.nameLaTeX = this.data.latexContent.text;
        let width = size.getWidth();
        let height = size.getHeight();
        if (this.data.isContainLatextContent()) {
            let latexSize = this.getLatexOriginalSize();
            if (latexSize.getWidth() > 0) {
                let textEdgeInsets = this.getTextEdgeInsets();
                width = Math.max(width, latexSize.getWidth() + textEdgeInsets.left + textEdgeInsets.right);
                height = height + textEdgeInsets.bottom + latexSize.getHeight();
            }
        }
        return new Size(width, height);
    }

    includeIconAndTextConnectMapSize() {
        let width = 0;
        let height = 0;

        let textMindElementContent = this.getTextMindElementContent();

        if (!this.data.isContainConnectMapContent()) {
            width = (textMindElementContent.width);
            height = (textMindElementContent.height);
            return new Size(width, height);
        }
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        let textConnectMapSize = this.includeConnectMapAndTextSize();

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();
        if (layoutType == IconLayoutType.LAYOUT_LEFT || layoutType == IconLayoutType.LAYOUT_RIGHT) {
            width = iconsSize.getWidth() + this.iconMargin + textConnectMapSize.getWidth();
            if (iconsSize.getHeight() + this.iconSpace * 2 > textConnectMapSize.getHeight()) {
                height = iconsSize.getHeight() + this.iconSpace * 2;
            } else {
                height = textConnectMapSize.getHeight();
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            if (iconsSize.getWidth() > textConnectMapSize.getWidth()) {
                width = iconsSize.getWidth();
            } else {
                width = textConnectMapSize.getWidth();
            }
            height = iconsSize.getHeight() + this.iconSpace + textConnectMapSize.getHeight();
        }
        if (text.length == 0 && !this.data.isEdit) {
            if (layoutType == IconLayoutType.LAYOUT_TOP) {
                height =  iconsSize.getHeight() + this.iconSpace * 2 + textConnectMapSize.getHeight();
            } else {
                width = iconsSize.getWidth() + this.iconMargin * 2 + textConnectMapSize.getWidth();
            }
        }
        return new Size(width, height);
    }

    getTextContent() {
        let text = "";
        if (this.data.textContent != null) {
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            text = this.data.generalizationContent.text;
        }
        return text;
    }

    getTextEdgeInsets() {

        switch (this.data.type) {
            case MindElementType.MAIN_SUBJECT:
                return Config.MainInputUIEdgeInsets;
            case MindElementType.SUBJECT:
                return Config.SubjectInputUIEdgeInsets;
            case MindElementType.CONTENT_GENERALIZATION:
                return Config.GeneralizationInputUIEdgeInsets;
            case MindElementType.SON_SUBJECT:
                return Config.SonSubjectInputUIEdgeInsets;
            case MindElementType.EXPLAIN:
                return Config.ExplainInputUIEdgeInsets;
            default:
                return Config.SubjectInputUIEdgeInsets;
        }
    }

    getLatexOriginalSize() {
        if (!this.data.isContainLatextContent()) {
            this.latexOriginalSize  = new Size(0, 0);
        } else if (this.latexOriginalSize.getWidth() == 0) {
            let text = this.data.latexContent.text;
            let fontSize = this.data.latexContent.textFontSize;
            let textSize = new Size(0, 0);
            // textSize = new Size(this.data.latexContent.width, this.data.latexContent.height);
            textSize =  MindElementCalculation.getLatexSize(text,Config.latexFontSize);
            // textSize = new MindElementCalculation().getLatexSize(this.data.latexContent.text,this.data.latexContent.textFontSize);
            this.latexOriginalSize = new Size(textSize.getWidth() + Config.MindLatexSpace * 2,
                    textSize.getHeight() + Config.MindLatexSpace * 2);
            return this.latexOriginalSize;
        }
        return this.latexOriginalSize;
    }



    getTaskOriginalSize() {
        if (this.data.taskContent == null) {
            this.taskOriginalSize  = new Size(0, 0);
        } else if (this.taskOriginalSize.getWidth() == 0) {
            let taskContentText = this.data.taskContent.getContentText();
            let taskExplain = this.data.taskContent.getExplain();
            let taskContentTextSize = new Size(0, 0);
            let taskExplainTextSize = new Size(0, 0);
            if (!new Strings().isEmpty(taskContentText)) {
                taskContentTextSize = MindElementCalculation.caluleText(taskContentText, this.data.taskContent.textFontSize, false);
                taskContentTextSize = new Size(taskContentTextSize.getWidth(), taskContentTextSize.getHeight() + Config.MindTaskProgressHeight);
            }
            if (!new Strings().isEmpty(taskExplain)) {
                taskExplainTextSize = MindElementCalculation.caluleText(taskExplain, this.data.taskContent.textFontSize, false);

            }
            let taskHeight = Math.ceil(taskContentTextSize.getHeight() + taskExplainTextSize.getHeight());
            let taskWidth = Math.ceil(Math.max(taskContentTextSize.getWidth(), taskExplainTextSize.getWidth()) + 
                                Config.MindTaskProgressSpace + new UiUtil().dip2px(1) + taskHeight);
            this.taskOriginalSize = new Size(taskWidth, taskHeight);            
            return this.taskOriginalSize;
        }
        return this.taskOriginalSize;
    }

    isShowText() {
        let text = this.getTextContent();
        return !new Strings().isEmpty(text) || this.data.isEdit;
    }


}
export default BaseMindElementDataCalculation