const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Mind + Mind Map';
stringMap [strings.TEMPLATE] = 'Modelo';
stringMap [strings.Mind_Edit_Main_Topic] = 'Filial Tópico';
stringMap [strings.Global_Add_Free_Node_Tips] = 'Clique na tela para selecionar o local a ser adicionado';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'Clique duas vezes na tela para selecionar o local a ser adicionado';
stringMap [strings.Global_Add_Relationship_Tips] = 'Selecione o alvo que deve ser associado';
stringMap [strings.Global_Add_Target_Tips] = 'Selecione um nó para adicionar objetos';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Este objeto já está associado a outros mapas';
stringMap [strings.Mind_Edit_Main_Idea] = 'Tema central';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'O mapa associado não existe';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'Prioridade';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'Progresso';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'Estrelas';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'semana';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Retrato';
stringMap [strings.Establish] = 'Criar';
stringMap [strings.Create_From_Template] = 'Criar a partir do modelo';
stringMap [strings.Common] = 'Comum';
stringMap [strings.Function_List] = 'Lista de funções';
stringMap [strings.Recent_Updates] = 'Atualizado recentemente';
stringMap [strings.The_Latest_To_Delete] = 'Excluído recentemente';
stringMap [strings.Setting_Up] = 'Entre em contato';
stringMap [strings.Usinghelp] = 'Usando a ajuda';
stringMap [strings.The_Download_Client] = 'Baixar versão cliente / móvel';
stringMap [strings.Global_Purchase] = 'Comprar';
stringMap [strings.Global_Login] = 'Login';
stringMap [strings.My_map] = 'Meu Mapa';
stringMap [strings.Create_Map] = 'Criar um mapa';
stringMap [strings.Storm] = 'Tempestade';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Comum nCaracterística';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Característica';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'Centro de Organização';
stringMap [strings.Mind_Edit_Free_Topic] = 'Tópico Livre';
stringMap [strings.Mind_Edit_Sub_Topic] = 'Subtópico';
stringMap [strings.Mind_Organization_Main_Topic] = 'Departamento';
stringMap [strings.Mind_Edit_Main_Topic] = 'Filial Tópico';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'Planejamento';
stringMap [strings.Mind_Time_Edit_Head] = 'Tempo';
stringMap [strings.Mind_Time_Edit_Title] = 'Plano';
stringMap [strings.Mind_Edit_Details] = 'Descrição detalhada';
stringMap [strings.Checking_Data] = 'Verificando dados';
stringMap [strings.Setting_Information] = 'Informações de configuração';
stringMap [strings.Sign_Out] = 'Sair';

// lista de funções
stringMap [strings.Create_a_Mind_Map] = 'Criar um mapa mental';
stringMap [strings.Create_Mind_Map_1] = '[1] Criar um mapa com quatorze layouts (bidirecional / direita / esquerda / estrutura organizacional / gráfico de bolha única / gráfico de bolha dupla / mapa da árvore da esquerda / mapa da árvore da direita / diagrama da árvore de duas vias / tabela / linha do tempo horizontal / linha do tempo vertical / linha do tempo S / pirâmide) ';
stringMap [strings.Create_Mind_Map_2] = '[2] Modelos podem ser usados ​​para criar mapas rapidamente (um grande número de referências de conteúdo de modelo)';
stringMap [strings.Create_Mind_Map_3] = '[3] Editar o mapa com nove tipos de estilos de linha';
stringMap [strings.Create_Mind_Map_4] = '[4] Faça nove alterações de layout e edições no mapa';
stringMap [strings.Create_Mind_Map_5] = '[5] Adicionar nós filhos ao nó de destino e adicionar nós no mesmo nível (superior e inferior)';
stringMap [strings.Create_Mind_Map_6] = '[6] Resumo: Adicionar [Resumo] para descrever um único nó / vários nós';
stringMap [strings.Create_Mind_Map_7] = '[7] Bloco: adicionar [bloco] a um único nó / caixas de vários nós do mapa a ser modularizado';
stringMap [strings.Create_Mind_Map_8] = '[8] Adicionar nó livre';
stringMap [strings.Create_Mind_Map_9] = '[9] Crie uma associação de quatro estilos de linha entre quaisquer nós';
stringMap [strings.Create_Mind_Map_10] = '[10] Inserir imagens (local + biblioteca de materiais), ícones, notas, links para o nó';
stringMap [strings.Create_Mind_Map_11] = '[11] Copiar / colar nó, copiar / colar estilo';
stringMap [strings.Create_Mind_Map_12] = '[12] Cancelar, restaurar e cancelar';
stringMap [strings.Create_Mind_Map_13] = '[13] Várias seleções estão disponíveis, vários nós podem ser operados simultaneamente';
stringMap [strings.Create_Mind_Map_14] = '[14] Gráfico estatístico pode ser inserido';
stringMap [strings.Create_Mind_Map_15] = '[15] O mapa inteiro pode ser gravado';
stringMap [strings.Create_Mind_Map_16] = '[16] Copiar mapa';
stringMap [strings.Create_Mind_Map_17] = '[17] Você pode copiar o mapa ou o mapa inteiro para o mapa de outros arquivos';
stringMap [strings.Create_Mind_Map_18] = '[18] Um mapa pode ser criado após o resumo';
stringMap [strings.Create_Mind_Map_19] = '[19] A edição do mapa pode ser trocada durante a edição (lado do computador)';
stringMap [strings.Create_Mind_Map_20] = '[20] Mapa de edição do teclado Bluetooth: \ n \ t [Command] + [a] Selecione o nó mestre \ n \ t [Command] + [←] [↑] [→] [↓ ] Alterar o alvo de seleção ou mover o mapa \ n \ t [Comando] + [e] Editar alvo \ n \ t [Comando] + [w] Criar nó filho \ n \ t [Comando] + [s] Criar nó irmão de destino ( Superior) \ n \ t [Comando] + [d] Criar nó irmão de destino (parte inferior) \ n \ t [Comando] + [q] Sair do destino selecionado \ n \ t [Comando] + [-] [+] Zoom mapa';

stringMap [strings.Beautify_The_Mind_Map] = 'Embeleze o mapa mental';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] Definir a cor de fundo do mapa mental';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] Defina a espessura e a cor de toda a linha do mapa, seja ela cônica ou não';
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] Definir cor de preenchimento do texto, cor da borda, formas da borda 13, largura da borda, efeito de sombra';
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] Definir toda ou parte da cor da linha e largura do mapa';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] Definir tamanho da fonte do texto, negrito, itálico, excluir estilo';
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] Definir o tamanho da imagem, posição e estilo de forma';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] Adicionar ícones de sete categorias (comum / 3D / estudo / trabalho / pessoa / animal / comida) e definir o tamanho / localização';
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] Adicionar uma referência de estilo de um clique a todo o mapa';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Mapa de gerenciamento de grupo';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] Um fundo personalizado (biblioteca de materiais) pode ser adicionado a todo o mapa';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] Bordas ou fundos individuais (biblioteca de materiais) podem ser adicionados a um único nó';
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] O comprimento do nó pode ser arrastado';


stringMap [strings.Map_Output] = 'Saída do mapa';
stringMap [strings.Map_Output_1] = '[1] Salvar imagem';
stringMap [strings.Map_Output_2] = '[2] Salvar PDF';
stringMap [strings.Map_Output_3] = '[3] Compartilhar link';
stringMap [strings.Map_Output_4] = '[4] Compartilhar com a tempestade';
stringMap [strings.Map_Output_4] = '[5] Imprimir';

stringMap [strings.Work_together] = 'Trabalhar em colaboração';
stringMap [strings.Work_together_1] = '[1] Criar um grupo compartilhado (etiqueta do grupo, anúncio do grupo)';
stringMap [strings.Work_together_2] = '[2] Novos membros do grupo compartilhado';
stringMap [strings.Work_together_3] = '【3】 Vários membros no grupo editam o mesmo mapa juntos';
stringMap [strings.Work_together_4] = '[4] Carregar arquivos compartilhados dentro do grupo (palavra / excle / pdf)';
stringMap [strings.Work_together_5] = '[5] Você pode deixar uma mensagem no grupo';
stringMap [strings.Work_together_6] = '[6] Configurações de autoridade de gerenciamento de grupo compartilhado';
stringMap [strings.Work_together_7] = '[7] Dissolver grupo';

stringMap [strings.Work_together] = 'Compartilhamento de criativos (tempestade)';
stringMap [strings.Community_Sharing_1] = '[1] Você pode compartilhar sua criação com a tempestade para que mais pessoas possam vê-la';
stringMap [strings.Community_Sharing_1] = '[2] Você pode selecionar tags para visualizar o conteúdo relacionado à tempestade';
stringMap [strings.Community_Sharing_1] = '[3] Você pode gostar e comentar sobre trabalhos de tempestade';

//Atualizado recentemente
stringMap [strings.Recently_Updated_Title_1] = 'Criar uma conta pessoal';
stringMap [strings.Recently_Updated_Title_2] = 'Base de conhecimento de modelos massiva';
stringMap [strings.Recently_Updated_Title_3] = 'Suportar várias plataformas';
stringMap [strings.Recently_Updated_Title_4] = 'Personalizar todos os detalhes';
stringMap [strings.Recently_Updated_Title_5] = 'Biblioteca de estilos pessoais';
stringMap [strings.Recently_Updated_Title_6] = 'Saída do mapa';
stringMap [strings.Recently_Updated_Title_7] = 'Cloud Storage';
stringMap [strings.Recently_Updated_Title_8] = 'Quinze tipos de mapas';


stringMap [strings.Recently_Updated_Content_1] = 'Crie uma conta pessoal e salve o mapa criado com a conta';
stringMap [strings.Recently_Updated_Content_2] = 'Um grande número de bases de conhecimento de modelos são citados diretamente para melhorar a eficiência do aprendizado';
stringMap [strings.Recently_Updated_Content_3] = 'Suporte à produção online da versão web, terminal móvel IOS, terminal Mac, terminal móvel Android, uso de plataforma múltipla de tablet Android';
stringMap [strings.Recently_Updated_Content_4] = 'Você pode embelezar o design do texto, fundo, linhas, ícones de imagem, etc. de acordo com as preferências pessoais';
stringMap [strings.Recently_Updated_Content_5] = 'A biblioteca de estilos pessoais é citada diretamente, eliminando o problema de correspondência de cores';
stringMap [strings.Recently_Updated_Content_6] = 'Você pode salvar imagens em formato PDF e compartilhar diretamente com amigos';
stringMap [strings.Recently_Updated_Content_7] = 'Forte suporte de armazenamento em nuvem, não precisa mais se preocupar com a perda de dados';
stringMap [strings.Recently_Updated_Content_8] = 'Bidirecional, direita, esquerda, estrutura organizacional, gráfico de bolha única, gráfico de bolha dupla, gráfico de árvore esquerdo, gráfico de árvore direito, gráfico de árvore bidirecional, tabela, linha do tempo horizontal, linha do tempo vertical, S linha do tempo, oito layouts podem ser alterados à vontade ';

// Excluído recentemente
stringMap [strings.Mind_Mindmap_Deleted_Title] = 'Excluído recentemente';
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'Recuperação';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'Excluir';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'Nome do arquivo';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'Após deletar aqui, será removido permanentemente';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'Operação';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'Pesquisar mapa excluído recentemente';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'Excluir data';

//Contate-Nos
stringMap [strings.Contact_Us_No_Public] = 'Conta Pública';
stringMap [strings.Contact_Us_QQ_Group] = 'Grupo QQ';
stringMap [strings.Contact_Us_Message_Board] = 'Quadro de mensagens';
stringMap [strings.Contact_Us_Username] = 'Nome';
stringMap [strings.Contact_Us_Email] = 'Email';
stringMap [strings.Contact_Us_Voicemail] = 'Por favor, deixe seus valiosos comentários';
stringMap [strings.Contact_Us_Send_Email] = 'Enviar e-mail';

// verificar mensagem
stringMap [strings.Input_Username_isNull] = 'O nome não pode estar vazio';
stringMap [strings.Input_Email_isNull] = 'Por favor, digite o endereço de e-mail correto';
stringMap [strings.Input_Voicemail_isNull] = 'Por favor, digite o conteúdo enviado';
stringMap [strings.Input_Introduction_isNull] = 'A introdução não pode estar vazia';
stringMap [strings.Input_Title_isNull] = 'O título não pode estar vazio';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'Por favor, digite o número de telefone correto';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'Por favor, digite o código de verificação! ';
stringMap [strings.Input_Please_Enter_The_Password] = 'Por favor, digite a senha! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'O formato da senha está incorreto! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'Por favor, digite uma nova senha! ';
stringMap [strings.Input_Confirm_The_New_Password] = 'Por favor, digite a senha de confirmação! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'As senhas são inconsistentes! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'Por favor, digite o código de verificação correto! ';
stringMap [strings.Input_The_Verification_Code_Error] = 'Erro no código de verificação! ';

// Usando a ajuda
stringMap [strings.Mind_Usinghelp_1] = 'Criar um mapa mental';
stringMap [strings.Mind_Usinghelp_2] = 'Como adicionar um nó';
stringMap [strings.Mind_Usinghelp_3] = 'Criar um nó livre';
stringMap [strings.Mind_Usinghelp_4] = 'Estabelecer associação';
stringMap [strings.Mind_Usinghelp_5] = 'embelezar a tela';
stringMap [strings.Mind_Usinghelp_6] = 'embelezar o texto';
stringMap [strings.Mind_Usinghelp_7] = 'Inserir notas, links';
stringMap [strings.Mind_Usinghelp_8] = 'Inserir ícone';
stringMap [strings.Mind_Usinghelp_9] = 'Inserir imagem';
stringMap [strings.Mind_Usinghelp_10] = 'Como restaurar a exclusão';
stringMap [strings.Mind_Usinghelp_11] = 'Exportar mapa mental';
stringMap [strings.Mind_Usinghelp_12] = 'Atualização de associação';
stringMap [strings.Mind_Usinghelp_13] = 'Tecla de atalho';
stringMap [strings.Mind_Usinghelp_Undersgether] = 'Entendi';
stringMap [strings.Mind_Usinghelp_14] = 'Inserir biblioteca de materiais';
// Use help-2
stringMap [strings.Mind_Usinghelp_15] = 'Novo mapa';
// stringMap [strings.Mind_Usinghelp_2] = 'Adicionar nó';
// stringMap [strings.Mind_Usinghelp_4] = 'Estabelecer associação';
// stringMap [strings.Mind_Usinghelp_5] = 'Embeleze a tela';
// stringMap [strings.Mind_Usinghelp_6] = 'Embelezar o texto';
// stringMap [strings.Mind_Usinghelp_7] = 'Adicionar notas, links';
stringMap [strings.Mind_Usinghelp_17] = 'Desfazer operação, cancelar';
stringMap [strings.Mind_Usinghelp_18] = 'Arraste o nó para trocar';
stringMap [strings.Mind_Usinghelp_19] = 'Alinhamento de nós';
stringMap [strings.Mind_Usinghelp_20] = 'Alterar tipo de mapa';
stringMap [strings.Mind_Usinghelp_21] = 'Estilo de linha do mapa';
stringMap [strings.Mind_Usinghelp_22] = 'Cor de fundo do mapa';
stringMap [strings.Mind_Usinghelp_23] = 'Definir forma do nó';
stringMap [strings.Mind_Usinghelp_24] = 'Estilo de linha do nó';
stringMap [strings.Mind_Usinghelp_25] = 'Configurações da imagem do nó';
stringMap [strings.Mind_Usinghelp_26] ='Configurações do ícone do nó';
stringMap [strings.Mind_Usinghelp_27] = 'Nó Adicionar Resumo';
stringMap [strings.Mind_Usinghelp_28] = 'Seleção de caixa de nó único';
stringMap [strings.Mind_Usinghelp_29] = 'Multi-nós em blocos';
stringMap [strings.Mind_Usinghelp_30] = 'Nó adicionar borda';
stringMap [strings.Mind_Usinghelp_31] = 'Adicionar mapa estatístico';
stringMap [strings.Mind_Usinghelp_32] = 'Ocultar nó e restaurar';
stringMap [strings.Mind_Usinghelp_33] = 'Nó de operações múltiplas';
stringMap [strings.Mind_Usinghelp_34] = 'Alterar estilo';
stringMap [strings.Mind_Usinghelp_35] = 'Inserir voz e explicação';
stringMap [strings.Mind_Usinghelp_36] = 'Guia do mapa na imagem';
stringMap [strings.Mind_Usinghelp_37] = 'Mapear documento PDF';
stringMap [strings.Mind_Usinghelp_38] = 'Imprimir mapa';
stringMap [strings.Mind_Usinghelp_39] = 'Compartilhamento e visualização de links';
stringMap [strings.Mind_Usinghelp_40] = 'Mapa para compartilhar com a tempestade';
stringMap [strings.Mind_Usinghelp_41] = 'Usando a pasta';
stringMap [strings.Mind_Usinghelp_42] = 'Excluir e restaurar mapa';
// Use a ajuda 3
stringMap [strings.Mind_Usinghelp_43] = 'Amigos se inscrevem para entrar no grupo';
stringMap [strings.Mind_Usinghelp_44] = 'Como dissolver o grupo';
stringMap [strings.Mind_Usinghelp_45] = 'Carregar informações do arquivo';
stringMap [strings.Mind_Usinghelp_46] = 'Concordar em ingressar no aplicativo';
stringMap [strings.Mind_Usinghelp_47] = 'Edição colaborativa';
stringMap [strings.Mind_Usinghelp_48] = 'Novo Grupo de Compartilhamento';
stringMap [strings.Mind_Usinghelp_49] = 'Convidar membros do grupo';

//tempestade
stringMap [strings.Mind_Storm_Recommend] = 'Recomendação';
stringMap [strings.Mind_Storm_Boilingpoint] = 'Ponto de ebulição';
stringMap [strings.Mind_Storm_Unfold] = 'Desdobrar';
stringMap [strings.Mind_Storm_Putaway] = 'Armazenamento';
stringMap [strings.Mind_Storm_Myclassification] = 'Minha classificação';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'Classificação recomendada';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'Clique para adicionar categoria';
stringMap [strings.Mind_Storm_No_Classification] = 'Ainda sem classificação';
stringMap [strings.Mind_Storm_No_Tinterested] = 'Não estou interessado';
stringMap [strings.Mind_Storm_Report] = 'Relatório';
stringMap [strings.Mind_Storm_Views] = 'Visualizações';

stringMap [strings.Mind_Storm_Pornographic] = 'Pornográfico vulgar';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'Publicidade Assédio';
stringMap [strings.Mind_Storm_Political_Religion] = 'Política e religião';
stringMap [strings.Mind_Storm_Infringement] = 'Infração (retrato, difamação, plágio, uso fraudulento)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'Conteúdo proibido';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'Divulgue e divulgue boatos';
stringMap [strings.Mind_Storm_Report_Successful] = 'Relatório com sucesso';

//editar
stringMap [strings.Global_Edit] = 'Editar';
stringMap [strings.Global_Cancel] = 'Cancelar';
stringMap [strings.Global_Ok] = 'OK';
stringMap [strings.Global_Name] = 'Apelido';
stringMap [strings.Global_Introduction] = 'Introdução';
stringMap [strings.Global_Change_Avatar] = 'Alterar Avatar';
stringMap [strings.Global_Local_Upload] = 'Upload local';

//informações pessoais
stringMap [strings.User_Personal_information] = 'Informações pessoais';
stringMap [strings.User_Member_Validity_Period] = 'Período de validade do membro';
stringMap [strings.User_My_Share] = 'Meu compartilhamento';
stringMap [strings.User_My_Collection] = 'Minha coleção';
stringMap [strings.User_More_Message] = 'Mais';
stringMap [strings.User_Deleted_Sotrm] = 'Excluir tempestade';
stringMap [strings.User_Folder_Rename] = 'Renomear';
stringMap [strings.User_Folder_Rename_Root] = 'Modificar permissões';
stringMap [strings.User_Folder_Rename_Root_Title] = 'Modificar permissões';
stringMap [strings.User_Folder_Root_Public] = 'Totalmente público';
stringMap [strings.User_Folder_Root_Viewonly] = 'A pasta pública só pode ser visualizada';
stringMap [strings.User_Folder_Root_Private] = 'Privado';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'Outras pessoas podem ver seus favoritos na página de seu perfil';
stringMap [strings.User_Message_Link] = 'Link';
stringMap [strings.User_New_Group] = 'Novo grupo';
stringMap [strings.User_New_Group_Title] = 'Criar um novo grupo';
stringMap [strings.User_New_Group_Style_Title] = 'Tipo de arquivo';
stringMap [strings.User_Share_Is_Null] = 'Ainda não há compartilhamento! ';
stringMap [strings.User_Deleted_Storm_Tips] = 'Tem certeza que deseja excluir esta tempestade? ';
stringMap [strings.User_Remove_From_List] = 'Remover da lista',
    stringMap [strings.User_Move_To] = 'Mover para',
    stringMap [strings.User_Deleted_Link] = 'Excluir link'
stringMap [strings.User_Collction_Is_Null] = 'Nenhuma coleção ainda'
stringMap [strings.User_Collection] = 'Coleção'

// caixa de login
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'Bem-vindo à mente +'
stringMap [strings.User_Lolgin_Free_Registration] = 'Registro grátis'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'Por favor, digite o seu número de telefone'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'Por favor, digite o código de verificação'
stringMap [strings.User_Lolgin_Obtain] = 'Obter'
stringMap [strings.User_Lolgin_Password_Login] = 'Senha de login'
stringMap [strings.User_Lolgin_Login] = 'Login'
stringMap [strings.User_Lolgin_Register_An_Account] = 'Registro da conta'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'Já tenho uma conta'
stringMap [strings.User_Lolgin_To_Log_In] = 'Ir para fazer o login'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'Por favor, digite uma senha'
stringMap [strings.User_Lolgin_Confirm_Password] = 'Confirmar senha'
stringMap [strings.User_Lolgin_Registered] = 'Registrado'
stringMap [strings.User_Lolgin_Account_Login] = 'Login da conta'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'Ler código de login'
stringMap [strings.User_Lolgin_Forgot_Password] = 'Esqueci a senha'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'Login do código de verificação'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Open Mind + scan no topo da interface de configuração'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'Este código QR expira em cinco minutos, clique quando expirar'
stringMap [strings.User_Lolgin_Refresh] = 'Atualizar'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'A digitalização foi bem-sucedida, confirme no seu telefone'
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'Autorizar o login'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'Por favor, digite uma nova senha'
stringMap [strings.User_Lolgin_Reset] = 'Redefinir'

// Meu mapa
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'Tipo de modelo de pesquisa ou modelo'
stringMap [strings.My_Mind_Date] = 'Data'
stringMap [strings.My_Mind_Create_Folder] = 'Criar pasta'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'Por favor, digite o nome da pasta'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Por favor, digite um novo nome de pasta'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'Nenhum mapa ainda, vá rápido'
stringMap [strings.My_Mind_Create] = 'Criar'
stringMap [strings.My_Mind_Right] = 'Barra!'
stringMap [strings.My_Mind_Open] = 'Abrir'
stringMap [strings.My_Mind_Type_Left_Right] = 'Mapa de guia duplo'
stringMap [strings.My_Mind_Type_Unilateral] = 'Mapa unilateral'
stringMap [strings.My_Mind_Type_Bottom] = 'Organograma'
stringMap [strings.My_Mind_Type_Bubble] = 'Gráfico de bolhas'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'Programar mapa'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Mapa mental em branco'
stringMap [strings.My_Mind_All] = 'Todos'
stringMap [strings.My_Mind_Views] = 'Visualizações:'

// Storm search
stringMap [strings.Storm_Search_How_Title] = 'Principais Tags'
stringMap [strings.Storm_History_Search] = 'Pesquisa de histórico'

// Public header
stringMap [strings.Public_Header_Mind] = 'Mapa'
stringMap [strings.Public_Header_Outline] = 'Contorno'
stringMap [strings.Public_Header_Download_Apps] = 'Baixar cliente'
stringMap [strings.Public_Header_Mobile_Version] = 'Versão móvel'
stringMap [strings.Public_Header_Creator] = 'Criador:'
stringMap [strings.Public_Header_View_Frequency] = 'Visualizações:'
stringMap [strings.Public_Header_Save] = 'Salvar'
stringMap [strings.Header_Mind_Tips_Saved] = 'Salvo'
stringMap [strings.Header_Mind_Tips_Edit] = 'Editado'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'Atualização foi salva'

// Menu de edição de mapa
stringMap [strings.Mind_Edit_Revoke] = 'Revogar'
stringMap [strings.Mind_Edit_Restore] = 'Restaurar'
stringMap [strings.Mind_Edit_Subtopic] = 'Subtópico'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Subtópico superior'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Subtópico inferior'
stringMap [strings.Mind_Edit_Free_Node] = 'Nó Livre'
stringMap [strings.Mind_Edit_Summary] = 'Resumo'
stringMap [strings.Mind_Edit_Block] = 'Em um bloco'
stringMap [strings.Mind_Edit_Insert] = 'Inserir'
stringMap [strings.Mind_Edit_Related] = 'Relacionado'
stringMap [strings.Mind_Edit_Style] = 'Estilo'
stringMap [strings.Mind_Edit_Recording] = 'Gravação'
stringMap [strings.Mind_Edit_Insert_Picture] = 'Inserir uma imagem'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Inserir ícone'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'Inserir observações'
stringMap [strings.Mind_Edit_Insert_Link] = 'Inserir link'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'Inserir gráfico estatístico'
stringMap [strings.Mind_Edit_Upload] = 'Carregar'
stringMap [strings.Mind_Edit_Right_Icon] = 'Ícone'
stringMap [strings.Mind_Edit_Right_Style] = 'Estilo'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'Decodificando ...'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Duração'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = 'Tem certeza que deseja deletar o áudio gravado? '
stringMap [strings.Mind_Edit_Join_Membership] = 'Abrir adesão'
stringMap [strings.Mind_Edit_Upgrade] = 'Atualizar'
stringMap [strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'Membros podem ter mais tempo de gravação, agora ir para upgrade para assinatura? '

stringMap [strings.Mind_Edit_Choose_Material] = 'Selecionar material'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Arraste e solte as fotos aqui'
stringMap [strings.Mind_Edit_Select_locally] = 'Selecionar do local'
stringMap [strings.Mind_Edit_Choose_From] = 'Selecionar da biblioteca de materiais'
stringMap [strings.Mind_Edit_Mine] = 'Meu'
stringMap [strings.Mind_Edit_My_Material] = 'Meu Material'
stringMap [strings.Mind_Edit_Recently_Used] = 'Usado recentemente'
stringMap [strings.Mind_Edit_Use] = 'Usar'
stringMap [strings.Mind_Edit_No_Material_Temporously_Go_Add_It] = 'Nenhum material no momento, vá e adicione! '
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'Selecione o tipo de gráfico'
stringMap [strings.Mind_Edit_Remarks] = 'Observações'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'Por favor, insira as observações'
stringMap [strings.Mind_Edit_Add] = 'Adicionar'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'Por favor, digite o link'

// Edite o menu no lado esquerdo da página
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'Salvar imagem'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'Exportar PDF'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'Compartilhar links'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Compartilhar com a tempestade'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'Imprimir'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Desbloquear a versão completa'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'Ajuda'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Salvar formato'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'formato png'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'formato jpg'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'formato webp'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'Compartilhar mapa'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'Botão de compartilhamento'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'Você pode copiar o link e enviá-lo'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'Copiar link'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'Se você deseja alterar o link, clique em'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'Gerar novamente'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'Senha do link:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='Permitir salvar'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Número máximo de visualizações'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'Infinito'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'Limite'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'Segundo'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'Período de validade do link'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '1 dia'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '3 dias'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '7 dias'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'Permanente'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Copiar conteúdo e link'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = 'Tem certeza que deseja regenerar? O link anterior será inválido! '
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'Compartilhar'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'Categoria personalizada:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Permitir comentários'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Editar para adicionar categoria'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Direitos de membro'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'Plataforma de suporte'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Abrir membros'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Troca de código de ativação'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Benefícios da associação após a compra'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'Selecione a forma de pagamento'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'Preço da atividade'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'Valor realmente pago'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Renovar imediatamente'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Termos de Serviço》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《Política de privacidade》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'Código de ativação:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Por favor, digite o código de ativação'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'Verificar código de ativação'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'Selecionar categorias'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Título principal não pode estar vazio'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'Cupom:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Por favor, digite o código do cupom'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'Confirme o uso de cupons'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'Verificação'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Valor preferencial'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'Pague imediatamente'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Confirmar informações de troca'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Código de troca:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Troca'

// Edite o menu do lado direito da página
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'Tela'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'Cor de fundo'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Layout'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'Estilo de linha'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'Cor da linha'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'Largura da linha'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'Linhas são afiladas'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'Fonte do tema'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'Fonte infantil'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'Cor do texto'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'Tamanho da fonte'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'Estilo da fonte'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'Cor da borda'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'Cor de preenchimento'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Forma da borda'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'Largura da borda'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Mostrar efeito de sombra (função de inscrição)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'Cor da linha'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'Linha pontilhada'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'Tamanho da imagem'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'Localização da imagem'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Forma da imagem'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'Tamanho do ícone'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'Posição do ícone'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'Geral'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'Prioridade'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'Progresso'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'Estrelas'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'semana'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Retrato'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'Aprendizagem'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'Trabalho'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'Personagens'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'Animais'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'Comida'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'Copiar estilo'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'Estilo de colagem'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Modificar forma'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'Ver imagem'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Excluir imagem'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'Copiar'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'Colar'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'Excluir linha'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Excluir'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'Editar comentários'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'Abrir link'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Excluir observações'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'Excluir link'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'Excluir ícone'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'Estilo do ícone'

// Painel de edição de gráfico estatístico
stringMap [strings.Echart_Edit_Statistically] = 'Estatísticas'
stringMap [strings.Echart_Edit_Value] = 'Valor'
stringMap [strings.Echart_Edit_Color] = 'Cor'
stringMap [strings.Echart_Edit_Title] = 'Título'
stringMap [strings.Echart_Edit_Detailed_Description] = 'Descrição detalhada'
stringMap [strings.Echart_Edit_Value_Sort] = 'Classificar'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'Tipo de exibição'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'Esquema de cores'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'Tamanho de exibição'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'Tamanho do título'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'Explique o tamanho'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'Inscrever-se'

// Guia Storm
stringMap [strings.StormBookTag_Subscribed] = 'Inscrito'
stringMap [strings.StormBookTag_List] = 'Lista'

// página de compartilhamento do Storm
stringMap [strings.Storm_Share_Comment] = 'Comentário'
stringMap [strings.Storm_Share_Reply] = 'Responder'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'Deixe-me comentar também ~'
stringMap [strings.Storm_Share_Release] = 'Liberar'
stringMap [strings.Storm_Share_Main_Title] = 'Título principal'
stringMap [strings.Storm_Share_Subtitle] = 'Legenda'
stringMap [strings.Storm_Share_Author] = 'Autor'
stringMap [strings.Storm_Share_Replies] = 'Responder'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'Ainda não há comentários. Seja o primeiro a ser o primeiro.'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Por favor, insira um comentário ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = 'Tem certeza que deseja deletar este comentário? '

// Ajuda para uso interno do mapa
stringMap [strings.Mind_Usinghelp_Btn_1] = 'Teclas de atalho (janelas)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'Tecla de atalho (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'Descrição'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Comando] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'Adicionar nó filho'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[Delet]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'Excluir o nó atual (note que não pode ser excluído se estiver no estado de edição)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Comando] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'Modificar o nó atual'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Comando] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Comando] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'Ampliar o mapa'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Comando] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'Cuidado com o mapa'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Comando] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Comando] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Comando] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Comando] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'Sair da edição'

// Texto Prompt
stringMap [strings.Message_Tips_Login_Success] = 'Login bem-sucedido'
stringMap [strings.Message_Tips_Dlt_Success] = 'Excluir com sucesso'
stringMap [strings.Message_Tips_Report_Success] = 'Relatório com sucesso'
stringMap [strings.Message_Tips_Copy_Success] = 'Cópia bem sucedida'
stringMap [strings.Message_Tips_Collction_Success] = 'Coleta bem-sucedida'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Modificação do avatar com sucesso'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'Sucesso na modificação da assinatura'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'apelido modificado com sucesso'
stringMap [strings.Message_Tips_Modification_Success] = 'Modificação bem-sucedida'
stringMap [strings.Message_Tips_New_Profile_Success] = 'Nova pasta com sucesso'
stringMap [strings.Message_Tips_Mobile_Success] = 'Sucesso no celular'
stringMap [strings.Message_Tips_Submit_Success] = 'Enviar com sucesso'
stringMap [strings.Message_Tips_Exchange_Success] = 'Troca bem-sucedida'
stringMap [strings.Message_Tips_Please_Code] = 'Por favor, digite o código de ativação'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'Por favor, concorde com a política de privacidade'
stringMap [strings.Message_Tips_Please_Sign_In] = 'Faça login'
stringMap [strings.Message_Tips_Share_Success] = 'Compartilhamento bem-sucedido'
stringMap [strings.Message_Tips_Create_Success] = 'Criado com sucesso'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'O nome da pasta não pode estar vazio'
stringMap [strings.Message_Tips_Selecte_Success] = 'Pesquisa concluída'
stringMap [strings.Message_Tips_Subscription_Success] = 'Assinatura com sucesso'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'Cancelamento de inscrição bem-sucedido'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'Conteúdo relacionado não encontrado'
stringMap [strings.Message_Tips_Max_Add_Class] = 'Só pode adicionar no máximo'
stringMap [strings.Message_Tips_Registered_Success] = 'Registrado com sucesso'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'Falha no login da verificação do código'
stringMap [strings.Message_Tips_Share_Is_Null] = 'Compartilhar link não existe'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'O arquivo enviado não pode exceder 3 MB'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'Obter permissão com sucesso'
stringMap [strings.Message_Tips_Membership_Required] = 'Inscrição necessária'
stringMap [strings.Message_Tips_Save_Success] = 'Salvar com sucesso'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'Ler código de login com sucesso'
stringMap [strings.Message_Tips_Style] = 'Uma categoria?'

// Modo de contorno
stringMap [strings.Outline_Menu_Indent] = 'Recuar'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'Diminuir recuo'

// Help hint
stringMap [strings.Help_Background_Content] = 'Linha de fundo relacionado, linha de fundo relacionado, linha de fundo relacionado, linha de fundo relacionado, linha de fundo relacionado, linha de fundo relacionado, linha de fundo relacionado, linha de fundo relacionado, linha de fundo relacionado,'
stringMap [strings.Help_Mind_Content] = 'Mapas com layouts diferentes podem ser feitos de acordo com as preferências: mapa guia duplo, mapa esquerdo, mapa direito, organograma, árvore bidirecional, árvore esquerda, árvore direita, tabela'
stringMap [strings.Help_Share_Link_Content] = 'Compartilhar links podem ser compartilhados no WeChat, QQ, Weibo e outros canais na forma de links, e os amigos podem ver o conteúdo'
stringMap [strings.Using_Templates] = 'Usando modelos'

//Código de Convite
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Por favor, digite o código do convite'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Por favor, digite o código de convite correto'
stringMap [strings.User_Lolgin_Optional] = 'Opcional'

// Use material

stringMap [strings.Cannot_Add_To_Node_Background] = 'Não aplicável ao fundo do nó'
stringMap [strings.Cannot_Add_To_Img_Background] = 'Não aplicável ao fundo do texto'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'Não aplicável ao fundo da linha de parcela'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Adicionar imagem de fundo'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Excluir imagem de fundo'
stringMap [strings.Mind_Add_Background_Image] = 'Inserir imagem de fundo'
stringMap [strings.Mind_Delete_Background_Image] = 'Excluir imagem de fundo'

stringMap [strings.Permanent_Member] = 'Membro Permanente'
stringMap [strings.Insufficient_Storage_Space] = 'Espaço de armazenamento insuficiente, atualize a associação para aumentar o espaço. '
stringMap [strings.Used_Space] = 'Espaço de armazenamento'
stringMap [strings.Current_Storage] = 'Armazenamento atual:'
stringMap [strings.Used_Space_Upgrade] = 'Atualização do espaço de armazenamento'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Mind + Mind Map & Notes Super mnemônicos, adicionar asas ao pensamento, criar, exibir, planejar e anotar'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'O espaço de armazenamento é insuficiente. Você pode usar o modelo quando fizer upgrade de sua assinatura. '

stringMap [strings.Group_Management] = 'Grupo'
stringMap [strings.Save_Img_Fill_Full] = 'Pavimentar cheio'
stringMap [strings.Save_Img_Center_Tilt] = 'Inclinação central'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'Totalmente (escuro)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Mind + Mind Map Watermarking'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = "Adicionar marca d'água para proteger a criação de conhecimento:"
stringMap [strings.Save_Img_Watermark_Text] = "Texto da marca d'água:"
stringMap [strings.Save_Img_Watermark_Logo] = "Marca d'água LOGO:"
stringMap [strings.Save_Img_Watermark_Style] = "Estilo da marca d'água:"
stringMap [strings.Save_Img_Please_Input] = 'Por favor, digite'
stringMap [strings.Save_Img_Chop_Tips] = "Você pode aproveitar os direitos da marca d'água ao abrir um membro"
stringMap [strings.Public_Header_Mindmap_Mind] = 'Modo de mapa'
stringMap [strings.Copy_Mind_Map] = 'Copiar mapa mental'
stringMap [strings.Font_Style_Bold] = 'Negrito'
stringMap [strings.Font_Style_Italics] = 'Itálico'
stringMap [strings.Font_Style_Delete_Line] = 'Tachado'
stringMap [strings.Remove_Any] = 'Remover'

stringMap [strings.Mindmap_Review] = 'Revisão do mapa'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'Aprofunde a impressão e melhore a memória, classifique ideias e informações de forma eficiente'
stringMap [strings.Mindmap_Review_Content] = 'A revisão do mapa refere-se à reprodução do processo de edição, e o conteúdo é reproduzido em sequência a partir do tema central; a revisão do mapa pode ajudá-lo a realizar o inventário do sistema, análise e encontrar ideias e lacunas . Durante o processo de revisão, você pode encontrar diferentes inspirações '
stringMap [strings.Mindmap_Review_Speed] = 'Velocidade'
stringMap [strings.Mindmap_Review_Word_Speed] = 'Velocidade da palavra'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'Proporção de fundo'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'Ampliação do nó de reprodução atual'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Ordem de reprodução do nó'


stringMap [strings.Mind_Group_Picture_Upload] = 'Upload de imagem'
stringMap [strings.Mind_Group_Upload_From_Local] = 'Upload local'
stringMap [strings.Mind_Group_Mind_Mapping] = 'Mapa mental'
stringMap [strings.Mind_Group_Information] = 'Dados'

stringMap [strings.Mind_Group_Invite_New_Members] = 'Convide novos membros'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Por favor, digite o seu número de telefone'
stringMap [strings.Mind_Group_Cancel] = 'Cancelar'
stringMap [strings.Mind_Group_Confirm] = 'OK'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'O número do celular não pode estar vazio'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'Convite bem sucedido'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "Membros do grupo compartilhado"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Número máximo de membros do grupo compartilhado"
stringMap [strings.Mind_Group_Membership_Limit] = "Limite de membros"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Limite superior do membro atual"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "Atualizar limite superior de membro"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Pesquisar membros"
stringMap [strings.Mind_Group_Creator] = "Criador"
stringMap [strings.Mind_Group_Administrator] = "Administrador"
stringMap [strings.Mind_Group_Number_Of_People] = "Pessoas"
stringMap [strings.Mind_Group_Selected] = "Selecionado"
stringMap [strings.Mind_Group_Member] = "Membro"
stringMap [strings.Mind_Group_Check_All] = "Selecionar tudo"
stringMap [strings.Mind_Group_Remove] = "Remover"
stringMap [strings.Mind_Group_Invite_New_Members] = "Convide novos membros"
stringMap [strings.Mind_Group_Delete_Members] = "Excluir membros"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Selecione o membro a ser excluído"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Tem certeza que deseja remover o usuário deste grupo?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Adicionar Administrador"
stringMap [strings.Mind_Group_Accomplish] = "Concluído"
stringMap [strings.Mind_Group_Compile] = "Editar"
stringMap [strings.Mind_Group_Group_Owner] = "Proprietário do Grupo"
stringMap [strings.Mind_Group_Add_Admin] = "Adicionar Administrador"
stringMap [strings.Mind_Group_Group_ID] = "ID do grupo"
stringMap [strings.Mind_Group_Group_Number] = "Número do Grupo"
stringMap [strings.Mind_Group_Name] = "Nome"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Por favor, digite o nome do grupo compartilhado"
stringMap [strings.Mind_Group_Introduce] = "Introduzir"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Por favor, apresente brevemente os grupos compartilhados"
stringMap [strings.Mind_Group_Announcement] = "Anúncio"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Tag de grupo compartilhado"
stringMap [strings.Mind_Group_Custom_Tag] = "Tag personalizada"
stringMap [strings.Mind_Group_Save] = "Salvar"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "O nome do grupo não pode estar vazio"
stringMap [strings.Mind_Group_Establish] = "Criar"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Permitir a criação de mapas / pastas"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Permitir edição de mapas"
stringMap [strings.Mind_Group_Holder] = "Todos"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Somente administrador"
stringMap [strings.Mind_Group_Not_Allow] = "Não permitido"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Permitir excluir mapa"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Permitir que os fóruns postem mensagens"
stringMap [strings.Mind_Group_Export] = "Exportar"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Permitir salvar imagens / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Abrir marca d'água do grupo"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Após a abertura, o logotipo do grupo será impresso no mapa mental"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Permitir salvar mapas"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "Salvar no meu mapa"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Permitir ou não o compartilhamento com a tempestade"
stringMap [strings.Mind_Group_Join_The_Way] = "Como participar"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "Permitir que novos membros sejam convidados"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Permitir a adição de números de grupos de pesquisa"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Se a mensagem precisa ser validada ao entrar"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Dados do grupo compartilhado"
stringMap [strings.Mind_Group_Authority_Management] = "Gerenciamento de autoridade"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Sair do grupo de compartilhamento"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Tem certeza que deseja sair do grupo compartilhado?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Grupo de Dissolução"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Os arquivos serão excluídos depois que o grupo for dissolvido. Confirmar para separar?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Retornar à página inicial"
stringMap [strings.Mind_Group_Join_The_Group] = "Entrar no Grupo"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Por favor, digite o número / nome do grupo para pesquisar"
stringMap [strings.Mind_Group_Apply_To_Join] = "Aplicar para aderir"
stringMap [strings.Mind_Group_Join] = "Entrar"
stringMap [strings.Mind_Group_Verification] = "Verificação"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Por favor, insira a mensagem de validação"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "O conteúdo não pode estar vazio"
stringMap [strings.Mind_Group_Name] = "Nome do grupo compartilhado"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Por favor, digite o nome do grupo compartilhado"
stringMap [strings.Mind_Group_Introduction] = "Apresentação do grupo de compartilhamento"
stringMap [strings.Mind_Group_Group_Indication] = "Número do Grupo"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "O criador é preguiçoso, ainda não o apresentei ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Anúncio do grupo compartilhado"
stringMap [strings.Mind_Group_No_Announcement] = "Nenhum anúncio ainda"
stringMap [strings.Mind_Group_Click_On] = "Clique para expandir"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "Clique para ocultar"
stringMap [strings.Mind_Group_Altogether] = "Total"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Copiando o número do grupo com sucesso"
stringMap [strings.Mind_Group_Stick] = "Manter no topo"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Há novo conteúdo"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Cancelar o Topo"
stringMap [strings.Mind_Group_Setting_Up] = "Configurações"
stringMap [strings.Mind_Group_Usinghelp] = "Usando a ajuda"
stringMap [strings.Mind_Group_Set_The_Message] = "Grupo de mensagens"
stringMap [strings.Mind_Group_Apply_To_Join] = "Aplicar para aderir"
stringMap [strings.Mind_Group_Invite_To_Join] = "Convidar para participar"
stringMap [strings.Mind_Group_Consent] = "Concordo"
stringMap [strings.Mind_Group_Turn_Down] = "Rejeitar"
stringMap [strings.Mind_Group_Label] = "Etiqueta"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Por favor, digite o nome do rótulo do grupo compartilhado"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Convide amigos e colegas para co-criar e compartilhar conteúdo de conhecimento"
stringMap [strings.Mind_Group_Immediately_Create] = "Criar imediatamente"
stringMap [strings.Mind_Group_Immediately_To_Join] = "Inscreva-se agora"

stringMap [strings.Currently_Selected] = "Atualmente seguindo"
stringMap [strings.LabelManagement] ="Gerenciamento de etiqueta"
stringMap [strings.NoAttention] = "Sem atenção"
stringMap [strings.Mindmap_Review_Play] = 'Reproduzir'

stringMap [strings.Mind_Group_Joined] = "Entrou em"
stringMap [strings.Mind_Group_Download_App] = "Baixar APP"
stringMap [strings.Mind_Group_No_Content] = "Nenhum conteúdo ainda"
stringMap [strings.Mind_Group_Individual] = "peças"
stringMap [strings.Mind_Group_Message_Area] = "Área de Mensagem"
stringMap [strings.Mind_Group_No_Message] = "Sem mensagem, vá agora"
stringMap [strings.Mind_Group_Leave_Message] = "Deixe uma mensagem"
stringMap [strings.Mind_Group_Bar] = "Bar"
stringMap [strings.Mind_Group_See_More] = "Ver mais"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Digite o código do convite"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Inscreva-se para entrar no grupo de compartilhamento"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Motivos para a aplicação (0-30 palavras)"
stringMap [strings.Mind_Group_Send_Out] = "Enviar"
stringMap [strings.Mind_Group_Join_Successfully] = "Entrar com sucesso"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "A aplicação foi enviada com sucesso"

stringMap [strings.Mind_Group_Save_The_Mapping] = "Salvar mapa"
stringMap [strings.Mind_Group_Update_Record] = "Modificar registro"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Mudar Mapa"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Revisão do mapa"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Um usuário existente está editando o mapa"
stringMap [strings.Mind_Edit_Page_Password] = "Senha"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "Grupo de compartilhamento"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "Meu MBean"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Pagamento Alipay"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Vá para a página de pagamento para pagar"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Total"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Confirmar pagamento"
stringMap [strings.Mind_Shooping_Redeem_Now] = "Resgatar agora"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "Se deseja confirmar o pagamento da compra"
stringMap [strings.Mind_Tips] = "Dicas"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "Seus M Beans são insuficientes, deseja comprar M Beans"

stringMap [strings.Mind_share_scan_code] = "Mente + código de leitura"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Quer restaurar para o último lugar reproduzido?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Restantes tempos de jogo"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "O arquivo será completamente excluído, tem certeza de que deseja encerrar?"
stringMap [strings.Group_Mind_Up_One_Level] = "Um nível acima"
stringMap [strings.Group_Mind_No_Documents] = "Nenhum arquivo ainda"
stringMap [strings.Group_Mind_PaymentSuccessful] = "Pagamento bem-sucedido"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Edição colaborativa de pessoas, sincronização multiterminal em tempo real, compartilhamento de documentos, melhoria da eficiência da colaboração em equipe"

stringMap [strings.Mind_Edit_Subscript] = "Subscrito"
stringMap [strings.Mind_Edit_Text_Location] = "Local do Texto"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "Importar para grupo compartilhado"

stringMap [strings.Standard_Colors] = "Cores padrão"
stringMap [strings.More_Colors] = "Mais cores"
stringMap [strings.Reset_Password] = "Redefinir senha"
stringMap [strings.Account_Password_Login] = "Login com senha da conta"
stringMap [strings.Authentication_code_Login_registration] = "Login / registro do código de verificação"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "Criação de uma extremidade, sincronização de várias extremidades"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Estrutura de mapa rica e diversa"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Grande biblioteca de modelos requintados"
stringMap [strings.Map_Review_Play] = "Revisão do mapa-Reproduzir"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Plataforma de intercâmbio de aprendizagem e compartilhamento de conhecimento"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "Atualizar espaço de armazenamento"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Deixe-me comentar sobre isso"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Nenhuma notificação de mensagem ainda"
stringMap [strings.Failed_To_Get_Recording_Device] = "Falha ao obter o dispositivo de gravação"

stringMap [strings.Search_Pagination] = "Paginação de pesquisa"
stringMap [strings.Please_Enter_A_Paging_Name] = "Por favor, digite o nome da página"
stringMap [strings.Operation_Settings] = "Configurações de operação"
stringMap [strings.Shortcut_key_Settings] = "Configurações de teclas de atalho"
stringMap [strings.Press_Key_Combination] = "Por favor, pressione a combinação de teclas necessária"
stringMap [strings.Mind_Reduction] = "Redução"
stringMap [strings.Double_Click_Add_Free_Node] = "Clique duas vezes no espaço em branco para adicionar um nó livre"
stringMap [strings.Shortcut_Conflict] = "Conflito de tecla de atalho"
stringMap [strings.Select_Main_Node] = "Selecionar nó principal"
stringMap [strings.Telephone] = "Telefone"
stringMap [strings.Microblog] = "Microblog"
stringMap [strings.Address] = "Endereço"
stringMap [strings.Company_Address] = "Bloco B, Edifício 21, No. 1158 Zhongxin Road, distrito de Songjiang, Xangai (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "Arquivo"
stringMap [strings.Mind_Bookshelf] = "Estante"
stringMap [strings.Search_Archive] = "Pesquisar estante de livros"
stringMap [strings.Modify_Archive_Order] = "Modificar pedido da estante"
stringMap [strings.Modification_Order] = "Ordem de modificação"
stringMap [strings.Please_Enter_Archive_Name] = "Por favor, digite o nome da estante"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "O nome da estante de livros não pode estar vazio"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "Tem certeza que deseja excluir esta estante?"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "Tem certeza que deseja excluir esta estante?"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "Tem certeza que deseja deletar este mapa?"
stringMap [strings.Mind_Edit_Confirm] = "Confirmar"
stringMap [strings.Mind_Import_Map] = "Importar Mapa"
stringMap [strings.Mind_Bookcase] = "Estante"
stringMap [strings.Added_Successfully] = "Adicionado com sucesso"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "O nome da estante não pode estar vazio"

stringMap [strings.Mind_Association_Map] = "Mapa de associação"
stringMap [strings.Mind_confirm_Association_Map] = "Tem certeza que deseja associar este mapa?"
stringMap [strings.Mind_Association_Map_See] = "Ver Mapa de Associação"
stringMap [strings.Mind_Association_Map_Delete] = "Excluir Mapa de Associação"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "Estrutura da linha de correlação"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Início da linha de correlação"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "O fim da linha de correlação"

stringMap [strings.Share_Group_To_Storm] = "Quer compartilhar o grupo com a tempestade"
stringMap [strings.Apply_To_Join_Storm_Platform] = "Na plataforma de tempestade, outras pessoas veem e se inscrevem, discutindo com entusiastas com conhecimento comum"
stringMap [strings.Pay_To_Goin] = "Pague para aderir"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "Os beans M precisam ser pagos ao entrar"
stringMap [strings.Mbean_Can_Be_Realized] = "Mbean realizado"

stringMap [strings.Mind_Visit] = "Visitar"
stringMap [strings.Mind_Details] = "Detalhes"
stringMap [strings.Mind_See] = "Exibir"
stringMap [strings.Earn_Mbeans] = "Ganhe Mbeans"
stringMap [strings.Mind_Explain] = "Descrição"
stringMap [strings.Mind_Parenting] = "Paternidade"
stringMap [strings.Mind_Internet] = "Internet"
stringMap [strings.Mind_Psychology] = "Psicologia"
stringMap [strings.Mind_literature] = "Literatura"
stringMap [strings.Mind_Technology] = "Tecnologia"
stringMap [strings.Mind_Workplace] = "Local de trabalho"
stringMap [strings.Mind_Life] = "Vida"
stringMap [strings.Mind_Subject] = "Assunto"
stringMap [strings.Hot_Group] = "Grupo Quente"
stringMap [strings.Open_Group] = "Grupo aberto"
stringMap [strings.Mind_Group_Used] = "Usado"

stringMap [strings.Mind_Click_Select] = "Clique para selecionar"
stringMap [strings.Mind_Double_Click_Enter] = "Clique duas vezes para entrar"
stringMap [strings.Search_Shared_Groups] = "Pesquisar grupos compartilhados"
stringMap [strings.Search_Storm] = "Pesquisar tempestade"
stringMap [strings.Members_Save_Free] = "Membros salvam gratuitamente"
stringMap [strings.Bank_Card_Account_Name] = "Por favor, insira o nome da conta do seu cartão bancário"
stringMap [strings.Bank_Card_No] = "Número do cartão do banco"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "Por favor, digite o número do seu cartão bancário"
stringMap [strings.Branch_Sub_Branch_Information] = "Informações da filial"
stringMap [strings.Branch_Sub_Branch_Example] = "Por exemplo: Hangzhou Branch Chengxi Sub-branch"
stringMap [strings.Application_For_Withdrawal] = "Pedido de retirada"
stringMap [strings.Withdrawal_Amount] = "Valor de saque"
stringMap [strings.Withdrawal] = "Retirar"
stringMap [strings.Current_Revenue] = "Receita atual"
stringMap [strings.Recharge] = "Recarregar"
stringMap [strings.Current_Total_MBeans] = "Número total atual de MBeans"
stringMap [strings.Withdrawal_Status] = "Status de retirada"
stringMap [strings.Insufficient_Withdrawal_Amount] = "Valor de saque insuficiente"
stringMap [strings.Load_More] = "Carregar mais"
stringMap [strings.Loading] = "Carregando"
stringMap [strings.It_Is_Over] = "Acabou"
stringMap [strings.Default_Label] = "Rótulo padrão"
stringMap [strings.Selected_Label] = "Marcador selecionado"
stringMap [strings.Current_Location] = "Local Atual"
stringMap [strings.MGold_Introduction] = "M ouro é ganho por você através do Mind +"
stringMap [strings.MGold_Details] = "Seu grupo de nuvem paga, há mapas de conhecimento, materiais de aprendizagem, etc. no grupo. Se os usuários ingressarem nos M beans de pagamento, 50% deles serão convertidos em M gold e M gold maior mais de 50 podem ser retirados "
stringMap [strings.Cloud_Group_Introduction] = "Compartilhe seu grupo, outras pessoas podem vê-lo na página da tempestade"
stringMap [strings.Cloud_Group_Details] = "Seu grupo de nuvem paga. Existem mapas de conhecimento, materiais de aprendizagem, etc. no grupo. Se os usuários ingressarem nos M beans de pagamento, 50% deles serão convertidos em M gold e M gold maior mais de 50 podem ser extraídos "
stringMap [strings.Share_Storm_Introduction] = "Compartilhe seu mapa com a comunidade da tempestade, para que mais pessoas possam aprender e explorar seu conhecimento"
stringMap [strings.Share_Storm_Details] = "Se você habilitar o uso de beans M para salvar mapas, outros usuários podem usar seu mapa de conhecimento, e você também obterá 50% dos beans M. grupos, você também pode pagar para usar os mapas de outras pessoas. "

stringMap [strings.Linked_Successfully] = "Vinculado com sucesso"
stringMap [strings.Cancel_Archiving] = "Cancelar arquivamento"
stringMap [strings.Please_Select_Archive_Location] = "Selecione o local do arquivo"
stringMap [strings.The_Search_Result_Is_Empty] = "Os resultados da pesquisa estão vazios"

stringMap [strings.Customize_A_Style] = "Os não membros podem personalizar apenas um estilo"
stringMap [strings.Adds_Current_Guide_Style_Library] = "Adicionar o estilo de mapa atual à biblioteca de estilos"
stringMap [strings.Trial_Version] = "Versão de teste"
stringMap [strings.Not_Supported_At_The_Moment] = "Atualmente não suportado"
stringMap [strings.Mind_File] = "Arquivo"
stringMap [strings.Mind_Import] = "Importar"
stringMap [strings.Importing] = "Importando"

stringMap [strings.Mind_Edit_Insert_Video] = "Inserir Vídeo"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Arraste e solte o vídeo aqui"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "A duração do upload do vídeo não pode exceder 60s"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "Exibir Vídeo"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "Excluir vídeo"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "O vídeo enviado não pode exceder 10 MB"
stringMap [strings.Mind_Upload_Video_Restrictions] = "Só suporta o envio de vídeos no formato MP4, de até 120s e 10M de tamanho"
stringMap [strings.Mind_Video_Size] = "Tamanho do vídeo"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "Exportar palavra"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "Exportar TXT"
stringMap [strings.Export_Failed] = "Falha na exportação"
stringMap [strings.Add_File] = "Adicionar arquivo"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "Arraste diretamente para o arquivo para enviar"
stringMap [strings.Supported_Imported_File_Types] = "Tipos de arquivo atualmente suportados para importação: Word (somente .docx), XMind, .txt"
stringMap [strings.No_Permission_Do_This] = "Você não tem permissão para realizar esta operação"

stringMap [strings.Upgrade_To_Professional] = "Atualizar para a edição profissional"
stringMap [strings.Non_Members_Insert_Videos] = "Não membros podem inserir vídeos apenas 24 horas após o registro"
stringMap [strings.Upload_Failed] = "Falha no upload"
stringMap [strings.Upload_Failed_Title_Message] = "Falha no upload, clique para confirmar, exclua as fotos restantes que falharam no upload"

stringMap [strings.Mind_Edit_Members_Use] = "Uso de membros"
stringMap [strings.Invitation_Code] = "Código de convite"
stringMap [strings.Student_Certification] = "Certificação do Aluno"
stringMap [strings.I_Get_It] = "Vou, pular o guia"
stringMap [strings.Welcome_To_Mind] = "Bem-vindo ao jovem mestre para se juntar à Mind +, vamos começar a jornada do pensamento!"
stringMap [strings.Mind_Before] = "Primeiro"
stringMap [strings.Experimente_A_Map] = "Experimente um mapa"
stringMap [strings.Click_Create] = "Clique para criar"
stringMap [strings.Click_Create_A_Mind_Map] = "Clique para criar um mapa mental"
stringMap [strings.Mind_About] = "Sobre"
stringMap [strings.See_Checking_Data] = "Ver informações pessoais"
stringMap [strings.Modify_Checking_Data] = "Modificar informações pessoais"
stringMap [strings.Mind_Shear] = "Shear"

stringMap [strings.Mind_Style_Empty] = "Sem estilo favorito ou personalizado"
stringMap [strings.Mind_Branch_Free_Layout] = "Layout livre de filial"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "Estrutura bidirecional e ramos do mapa de bolhas podem ser arrastados livremente para o layout"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Tema livre arrastar Snap"

stringMap [strings.Select_A_Node] = "Selecione um tópico"
stringMap [strings.Create_Child_Node] = "Criar tema filho"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "Em seguida, vamos adicionar um bom para o tema filho"
stringMap [strings.Click_Icon_Panel] = "Clique no painel de ícones"
stringMap [strings.Select_An_Icon_You_Like] = "Selecione um ícone de sua preferência"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "Em seguida, vamos substituir o mapa mental por um novo"
stringMap [strings.Select_The_Central_Theme] = "Selecione o tema central"
stringMap [strings.Switch_To_Canvas_Panel] = "Mudar para o painel da tela"
stringMap [strings.Click_Layout] = "Clique para visualizar o layout"
stringMap [strings.Choose_A_Layout_You_Like] = "Escolha um layout que você goste"
stringMap [strings.Mind_Map_Save_Share] = "Parabéns, você fez um belo mapa mental com sucesso! Vamos salvá-lo e compartilhá-lo com seus amigos ~"
stringMap [strings.Click_Save_Picture] = "Clique para salvar a imagem"
stringMap [strings.Click_OK] = "Clique em OK"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "Parabéns por completar o guia para iniciantes ~"
stringMap [strings.Upload_Succeeded] = "Carregado com sucesso"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "Estilo Rápido"
stringMap [strings.Mind_Extremely_Important] = "Extremamente importante"
stringMap [strings.Mind_Important] = "Importante"
stringMap [strings.Mind_Commonly] = "Geral"
stringMap [strings.Mind_Ordinary] = "Comum"
stringMap [strings.Member_Exclusive] = "Este recurso é exclusivo para membros. Deseja atualizar sua assinatura?"
stringMap [strings.Member_Exclusive_Color_Scheme] = "Este esquema de cores é exclusivo para membros. Deseja atualizar sua assinatura?"
stringMap [strings.Mind_Enlarge] = "Ampliar"
stringMap [strings.Mind_Narrow] = "Estreito"
stringMap [strings.Mind_Center] = "Centrado"
stringMap [strings.Mind_Full_Screen] = "Tela inteira"

stringMap [strings.Mind_Association_Node] = "Nó de associação"

stringMap [strings.Mind_Resource_See] = "Ver anexo"
stringMap [strings.Mind_Resource_Delete] = "Excluir anexos"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Arraste e solte arquivos aqui"
stringMap [strings.Mind_Edit_choose_explain] = "Escolher arquivo"
stringMap [strings.Message_Tips_Share_Explain_Does_Not_Exist] = "O arquivo enviado não pode exceder 20 MB"
stringMap [strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap [strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap [strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap [strings.Mind_Style_To_Peer] = "Use to peer"
stringMap [strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap [strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap [strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

module.exports = stringMap;