const {default: strings} = require ("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Mind + Mind Map';
stringMap [strings.TEMPLATE] = 'Vorlage';
stringMap [strings.Mind_Edit_Main_Topic] = 'Branchenthema';
stringMap [strings.Global_Add_Free_Node_Tips] = 'Klicken Sie auf den Bildschirm, um den hinzuzufügenden Speicherort auszuwählen';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'Doppelklicken Sie auf den Bildschirm, um den hinzuzufügenden Speicherort auszuwählen';
stringMap [strings.Global_Add_Relationship_Tips] = 'Wählen Sie das Ziel aus, das zugeordnet werden soll';
stringMap [strings.Global_Add_Target_Tips] = 'Bitte wählen Sie einen Knoten aus, um Objekte hinzuzufügen';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Dieses Objekt ist bereits anderen Maps zugeordnet';
stringMap [strings.Mind_Edit_Main_Idea] = 'Zentrales Thema';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'Die zugehörige Map existiert nicht';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'Priorität';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'Fortschritt';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'Sterne';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'week';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Portrait';
stringMap [strings.Establish] = 'Create';
stringMap [strings.Create_From_Template] = 'Aus Vorlage erstellen';
stringMap [strings.Common] = 'Common';
stringMap [strings.Function_List] = 'Funktionsliste';
stringMap [strings.Recent_Updates] = 'Kürzlich aktualisiert';
stringMap [strings.The_Latest_To_Delete] = 'Kürzlich gelöscht';
stringMap [strings.Setting_Up] = 'Kontaktieren Sie uns';
stringMap [strings.Usinghelp] = 'Hilfe verwenden';
stringMap [strings.The_Download_Client] = 'Client / Mobile-Version herunterladen';
stringMap [strings.Global_Purchase] = 'Kauf';
stringMap [strings.Global_Login] = 'Login';
stringMap [strings.My_map] = 'Meine Karte';
stringMap [strings.Create_Map] = 'Karte erstellen';
stringMap [strings.Storm] = 'Storm';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Common \ nCharacteristic';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Characteristic';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'Organisationscenter';
stringMap [strings.Mind_Edit_Free_Topic] = 'Freies Thema';
stringMap [strings.Mind_Edit_Sub_Topic] = 'Unterthema';
stringMap [strings.Mind_Organization_Main_Topic] = 'Abteilung';
stringMap [strings.Mind_Edit_Main_Topic] = 'Branchenthema';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'Planung';
stringMap [strings.Mind_Time_Edit_Head] = 'Zeit';
stringMap [strings.Mind_Time_Edit_Title] = 'Plan';
stringMap [strings.Mind_Edit_Details] = 'Detaillierte Beschreibung';
stringMap [strings.Checking_Data] = 'Daten prüfen';
stringMap [strings.Setting_Information] = 'Einstellungsinformationen';
stringMap [strings.Sign_Out] = 'Abmelden';

// Funktionsliste
stringMap [strings.Create_a_Mind_Map] = 'Mind Map erstellen';
stringMap [strings.Create_Mind_Map_1] = '[1] Erstellen Sie eine Karte mit vierzehn Layouts (Zweiwege / Rechts / Links / Organisationsstruktur / Einzelblasendiagramm / Doppelblasendiagramm / Linke Baumkarte / Rechte Baumkarte / Zweiwegbaumdiagramm / Tabelle / horizontale Zeitleiste / vertikale Zeitleiste / S-Zeitleiste / Pyramide) ';
stringMap [strings.Create_Mind_Map_2] = '[2] Mit Vorlagen können schnell Karten erstellt werden (eine große Anzahl von Verweisen auf Vorlageninhalte)';
stringMap [strings.Create_Mind_Map_3] = '[3] Bearbeiten Sie die Karte mit neun Arten von Linienstilen';
stringMap [strings.Create_Mind_Map_4] = '[4] Nehmen Sie neun Layoutänderungen und Änderungen an der Karte vor';
stringMap [strings.Create_Mind_Map_5] = '[5] Fügen Sie dem Zielknoten untergeordnete Knoten hinzu und fügen Sie Knoten auf derselben Ebene (oben und unten) hinzu';
stringMap [strings.Create_Mind_Map_6] = '[6] Zusammenfassung: Fügen Sie [Zusammenfassung] hinzu, um einen einzelnen Knoten / mehrere Knoten zu beschreiben';
stringMap [strings.Create_Mind_Map_7] = '[7] Block: Füge [Block] zu einem einzelnen Knoten / mehreren Knotenfeldern der zu modularisierenden Karte hinzu';
stringMap [strings.Create_Mind_Map_8] = '[8] freien Knoten hinzufügen';
stringMap [strings.Create_Mind_Map_9] = '[9] Erstellt eine Zuordnung von vier Linienstilen zwischen beliebigen Knoten';
stringMap [strings.Create_Mind_Map_10] = '[10] Bilder (lokale + Materialbibliothek), Symbole, Notizen, Links zum Knoten einfügen';
stringMap [strings.Create_Mind_Map_11] = '[11] Knoten kopieren / einfügen, Stil kopieren / einfügen';
stringMap [strings.Create_Mind_Map_12] = '[12] Abbrechen, Wiederherstellen und Abbrechen';
stringMap [strings.Create_Mind_Map_13] = '[13] Es stehen mehrere Auswahlen zur Verfügung, mehrere Knoten können gleichzeitig betrieben werden';
stringMap [strings.Create_Mind_Map_14] = '[14] Statistisches Diagramm kann eingefügt werden';
stringMap [strings.Create_Mind_Map_15] = '[15] Die gesamte Karte kann aufgezeichnet werden';
stringMap [strings.Create_Mind_Map_16] = '[16] Map kopieren';
stringMap [strings.Create_Mind_Map_17] = '[17] Sie können die Karte oder die gesamte Karte in die Karte anderer Dateien kopieren';
stringMap [strings.Create_Mind_Map_18] = '[18] Nach der Zusammenfassung kann eine Karte erstellt werden';
stringMap [strings.Create_Mind_Map_19] = '[19] Die Kartenbearbeitung kann während der Bearbeitung (Computerseite) umgeschaltet werden';
stringMap [strings.Create_Mind_Map_20] = '[20] Bluetooth-Tastaturbearbeitungskarte: \ n \ t [Befehl] + [a] Wählen Sie den Hauptknoten aus \ n \ t [Befehl] + [←] [↑] [→] [↓ ] Auswahlziel ändern oder Karte verschieben \ n \ t [Befehl] + [e] Ziel bearbeiten \ n \ t [Befehl] + [w] Untergeordneten Knoten erstellen \ n \ t [Befehl] + [s] Ziel-Geschwisterknoten erstellen ( Oben) \ n \ t [Befehl] + [d] Zielgeschwisterknoten erstellen (unten) \ n \ t [Befehl] + [q] Das ausgewählte Ziel verlassen \ n \ t [Befehl] + [-] [+] Zoom Karte';

stringMap [strings.Beautify_The_Mind_Map] = 'Verschönere die Mind Map';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] Setze die Hintergrundfarbe der Mind Map';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] Legen Sie die Dicke und Farbe der gesamten Kartenlinie fest, unabhängig davon, ob sie sich verjüngt oder nicht';
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] Textfüllfarbe, Rahmenfarbe, Rahmen 13-Formen, Rahmenbreite, Schatteneffekt festlegen';
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] Setzt die Linienfarbe und -breite der Karte ganz oder teilweise';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] Textschriftgröße festlegen, fett, kursiv, Stil löschen';
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] Bildgröße, Position und Formstil festlegen';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] Fügen Sie Symbole aus sieben Kategorien hinzu (allgemein / 3D / Studie / Arbeit / Person / Tier / Lebensmittel) und legen Sie die Größe / den Ort fest';
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] Fügen Sie der gesamten Karte einen Stilverweis mit einem Klick hinzu';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Gruppenverwaltungszuordnung';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] Ein personalisierter Hintergrund (Materialbibliothek) kann zur gesamten Karte hinzugefügt werden';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] Einzelne Rahmen oder Hintergründe (Materialbibliothek) können einem einzelnen Knoten hinzugefügt werden';
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] Die Länge des Knotens kann gezogen werden';


stringMap [strings.Map_Output] = 'Kartenausgabe';
stringMap [strings.Map_Output_1] = '[1] Bild speichern';
stringMap [strings.Map_Output_2] = '[2] PDF speichern';
stringMap [strings.Map_Output_3] = '[3] Link teilen';
stringMap [strings.Map_Output_4] = '[4] Für den Sturm freigeben';
stringMap [strings.Map_Output_4] = '[5] Print';

stringMap [strings.Work_together] = 'In Zusammenarbeit arbeiten';
stringMap [strings.Work_together_1] = '[1] Eine gemeinsam genutzte Gruppe erstellen (Gruppenbezeichnung, Gruppenansage)';
stringMap [strings.Work_together_2] = '[2] Neue freigegebene Gruppenmitglieder';
stringMap [strings.Work_together_3] = '【3】 Mehrere Mitglieder in der Gruppe bearbeiten dieselbe Karte zusammen';
stringMap [strings.Work_together_4] = '[4] Hochgeladene Dateien innerhalb der Gruppe hochladen (word / excle / pdf)';
stringMap [strings.Work_together_5] = '[5] Sie können eine Nachricht in der Gruppe hinterlassen';
stringMap [strings.Work_together_6] = '[6] Einstellungen für gemeinsam genutzte Gruppenverwaltungsberechtigungen';
stringMap [strings.Work_together_7] = '[7] Disband Group';

stringMap [strings.Work_together] = 'Kreatives Teilen (Sturm)';
stringMap [strings.Community_Sharing_1] = '[1] Sie können Ihre Kreation für den Sturm freigeben, damit mehr Menschen sie sehen können';
stringMap [strings.Community_Sharing_1] = '[2] Sie können Tags auswählen, um verwandte Sturminhalte anzuzeigen';
stringMap [strings.Community_Sharing_1] = '[3] Sie können Sturmarbeiten mögen und kommentieren';

//Kürzlich aktualisiert
stringMap [strings.Recently_Updated_Title_1] = 'Ein persönliches Konto erstellen';
stringMap [strings.Recently_Updated_Title_2] = 'Massive Template Community Base';
stringMap [strings.Recently_Updated_Title_3] = 'Mehrere Plattformen unterstützen';
stringMap [strings.Recently_Updated_Title_4] = 'Jedes Detail anpassen';
stringMap [strings.Recently_Updated_Title_5] = 'Persönliche Stilstilbibliothek';
stringMap [strings.Recently_Updated_Title_6] = 'Kartenausgabe';
stringMap [strings.Recently_Updated_Title_7] = 'Cloud-Speicher';
stringMap [strings.Recently_Updated_Title_8] = 'Fünfzehn Arten von Karten';


stringMap [strings.Recently_Updated_Content_1] = 'Erstelle ein persönliches Konto und speichere die erstellte Karte mit dem Konto';
stringMap [strings.Recently_Updated_Content_2] = 'Eine große Anzahl von Vorlagen-Wissensdatenbanken wird direkt zitiert, um die Lerneffizienz zu verbessern';
stringMap [strings.Recently_Updated_Content_3] = 'Unterstützung der Online-Produktion von Webversionen, mobiles IOS-Terminal, Mac-Terminal, mobiles Android-Terminal, plattformübergreifende Verwendung von Android-Tablets';
stringMap [strings.Recently_Updated_Content_4] = 'Sie können das Design von Text, Hintergrund, Linien, Bildsymbolen usw. nach persönlichen Vorlieben verschönern';
stringMap [strings.Recently_Updated_Content_5] = 'Persönliche Stilstilbibliothek wird direkt in Anführungszeichen gesetzt, wodurch die Probleme der Farbanpassung beseitigt werden';
stringMap [strings.Recently_Updated_Content_6] = 'Sie können Bilder im PDF-Format speichern und direkt mit Freunden teilen';
stringMap [strings.Recently_Updated_Content_7] = 'Starke Cloud-Speicherunterstützung, keine Sorgen mehr über Datenverlust';
stringMap [strings.Recently_Updated_Content_8] = 'Zweiwege, rechts, links, Organisationsstruktur, Einzelblasendiagramm, Doppelblasendiagramm, linkes Baumdiagramm, rechtes Baumdiagramm, Zweiwegbaumdiagramm, Tabelle, horizontale Zeitachse, vertikale Zeitachse, S. Zeitleiste, acht Layouts können nach Belieben geändert werden ';

// Kürzlich gelöscht
stringMap [strings.Mind_Mindmap_Deleted_Title] = 'Kürzlich gelöscht';
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'Wiederherstellung';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'Löschen';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'Dateiname';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'Nach dem Löschen hier wird es dauerhaft entfernt';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'Operation';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'Kürzlich gelöschte Karte durchsuchen';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'Datum löschen';

//kontaktiere uns
stringMap [strings.Contact_Us_No_Public] = 'Öffentliches Konto';
stringMap [strings.Contact_Us_QQ_Group] = 'QQ Group';
stringMap [strings.Contact_Us_Message_Board] = 'Message Board';
stringMap [strings.Contact_Us_Username] = 'Name';
stringMap [strings.Contact_Us_Email] = 'Email';
stringMap [strings.Contact_Us_Voicemail] = 'Bitte hinterlassen Sie Ihre wertvollen Kommentare';
stringMap [strings.Contact_Us_Send_Email] = 'E-Mail senden';

// Nachricht überprüfen
stringMap [strings.Input_Username_isNull] = 'Name darf nicht leer sein';
stringMap [strings.Input_Email_isNull] = 'Bitte geben Sie die richtige E-Mail-Adresse ein';
stringMap [strings.Input_Voicemail_isNull] = 'Bitte geben Sie den gesendeten Inhalt ein';
stringMap [strings.Input_Introduction_isNull] = 'Einführung darf nicht leer sein';
stringMap [strings.Input_Title_isNull] = 'Titel darf nicht leer sein';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'Bitte geben Sie die richtige Telefonnummer ein';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'Bitte geben Sie den Bestätigungscode ein! ';
stringMap [strings.Input_Please_Enter_The_Password] = 'Bitte geben Sie das Passwort ein! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'Das Passwortformat ist falsch! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'Bitte geben Sie ein neues Passwort ein! ';
stringMap [strings.Input_Confirm_The_New_Password] = 'Bitte geben Sie das Bestätigungskennwort ein! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'Passwörter sind inkonsistent! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'Bitte geben Sie den richtigen Bestätigungscode ein! ';
stringMap [strings.Input_The_Verification_Code_Error] = 'Bestätigungscodefehler! ';

// Hilfe benutzen
stringMap [strings.Mind_Usinghelp_1] = 'Mind Map erstellen';
stringMap [strings.Mind_Usinghelp_2] = 'Hinzufügen eines Knotens';
stringMap [strings.Mind_Usinghelp_3] = 'Einen freien Knoten erstellen';
stringMap [strings.Mind_Usinghelp_4] = 'Assoziation herstellen';
stringMap [strings.Mind_Usinghelp_5] = 'verschönere die Leinwand';
stringMap [strings.Mind_Usinghelp_6] = 'Text verschönern';
stringMap [strings.Mind_Usinghelp_7] = 'Notizen, Links einfügen';
stringMap [strings.Mind_Usinghelp_8] = 'Symbol einfügen';
stringMap [strings.Mind_Usinghelp_9] = 'Bild einfügen';
stringMap [strings.Mind_Usinghelp_10] = 'Wiederherstellen des Löschvorgangs';
stringMap [strings.Mind_Usinghelp_11] = 'Mind Map exportieren';
stringMap [strings.Mind_Usinghelp_12] = 'Mitgliedschafts-Upgrade';
stringMap [strings.Mind_Usinghelp_13] = 'Tastenkürzel';
stringMap [strings.Mind_Usinghelp_Understood] = 'Ich habe es verstanden';
stringMap [strings.Mind_Usinghelp_14] = 'Materialbibliothek einfügen';
// Benutze help-2
stringMap [strings.Mind_Usinghelp_15] = 'Neue Karte';
// stringMap [strings.Mind_Usinghelp_2] = 'Knoten hinzufügen';
// stringMap [strings.Mind_Usinghelp_4] = 'Assoziation herstellen';
// stringMap [strings.Mind_Usinghelp_5] = 'Verschönere die Leinwand';
// stringMap [strings.Mind_Usinghelp_6] = 'Text verschönern';
// stringMap [strings.Mind_Usinghelp_7] = 'Notizen, Links hinzufügen';
stringMap [strings.Mind_Usinghelp_17] = 'Vorgang rückgängig machen, abbrechen';
stringMap [strings.Mind_Usinghelp_18] = 'Knoten zum Tauschen ziehen';
stringMap [strings.Mind_Usinghelp_19] = 'Knotenausrichtung';
stringMap [strings.Mind_Usinghelp_20] = 'Kartentyp ändern';
stringMap [strings.Mind_Usinghelp_21] = 'Map line style';
stringMap [strings.Mind_Usinghelp_22] = 'Hintergrundfarbe der Karte';
stringMap [strings.Mind_Usinghelp_23] = 'Knotenform festlegen';
stringMap [strings.Mind_Usinghelp_24] = 'Knotenlinienstil';
stringMap [strings.Mind_Usinghelp_25] = 'Node Picture Settings';
stringMap [strings.Mind_Usinghelp_26] ='Einstellungen für Knotensymbole';
stringMap [strings.Mind_Usinghelp_27] = 'Node Add Summary';
stringMap [strings.Mind_Usinghelp_28] = 'Auswahl eines einzelnen Knotenfelds';
stringMap [strings.Mind_Usinghelp_29] = 'Mehrere Knoten in Blöcke';
stringMap [strings.Mind_Usinghelp_30] = 'Node add border';
stringMap [strings.Mind_Usinghelp_31] = 'Statistische Karte hinzufügen';
stringMap [strings.Mind_Usinghelp_32] = 'Knoten ausblenden und wiederherstellen';
stringMap [strings.Mind_Usinghelp_33] = 'Mehrere Operationen des Knotens';
stringMap [strings.Mind_Usinghelp_34] = 'Stil ändern';
stringMap [strings.Mind_Usinghelp_35] = 'Stimme und Erklärung einfügen';
stringMap [strings.Mind_Usinghelp_36] = 'Map in Bild führen';
stringMap [strings.Mind_Usinghelp_37] = 'PDF-Dokument zuordnen';
stringMap [strings.Mind_Usinghelp_38] = 'Karte drucken';
stringMap [strings.Mind_Usinghelp_39] = 'Linkfreigabe und -anzeige';
stringMap [strings.Mind_Usinghelp_40] = 'Map zum Teilen für den Sturm';
stringMap [strings.Mind_Usinghelp_41] = 'Verwenden des Ordners';
stringMap [strings.Mind_Usinghelp_42] = 'Karte löschen und wiederherstellen';
// Hilfe verwenden 3
stringMap [strings.Mind_Usinghelp_43] = 'Freunde bewerben sich, um der Gruppe beizutreten';
stringMap [strings.Mind_Usinghelp_44] = 'Auflösen der Gruppe';
stringMap [strings.Mind_Usinghelp_45] = 'Dateiinformationen hochladen';
stringMap [strings.Mind_Usinghelp_46] = 'Zustimmen, der Anwendung beizutreten';
stringMap [strings.Mind_Usinghelp_47] = 'Kollaboratives Bearbeiten';
stringMap [strings.Mind_Usinghelp_48] = 'Neue Freigabegruppe';
stringMap [strings.Mind_Usinghelp_49] = 'Gruppenmitglieder einladen';

//Sturm
stringMap [strings.Mind_Storm_Recommend] = 'Empfehlung';
stringMap [strings.Mind_Storm_Boilingpoint] = 'Siedepunkt';
stringMap [strings.Mind_Storm_Unfold] = 'Unfold';
stringMap [strings.Mind_Storm_Putaway] = 'Putaway';
stringMap [strings.Mind_Storm_Myclassification] = 'Meine Klassifizierung';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'Empfohlene Klassifizierung';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'Klicken, um Kategorie hinzuzufügen';
stringMap [strings.Mind_Storm_No_Classification] = 'Noch keine Klassifizierung';
stringMap [strings.Mind_Storm_No_Tinterested] = 'Nicht interessiert';
stringMap [strings.Mind_Storm_Report] = 'Report';
stringMap [strings.Mind_Storm_Views] = 'Ansichten';

stringMap [strings.Mind_Storm_Pornographic] = 'Pornografisch vulgär';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'Werbebelästigung';
stringMap [strings.Mind_Storm_Political_Religion] = 'Politik und Religion';
stringMap [strings.Mind_Storm_Infringement] = 'Verletzung (Porträt, Diffamierung, Plagiat, betrügerische Verwendung)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'Verbotener Inhalt';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'Gerüchte verbreiten und Gerüchte verbreiten';
stringMap [strings.Mind_Storm_Report_Successful] = 'Bericht erfolgreich';

//bearbeiten
stringMap [strings.Global_Edit] = 'Bearbeiten';
stringMap [strings.Global_Cancel] = 'Abbrechen';
stringMap [strings.Global_Ok] = 'OK';
stringMap [strings.Global_Name] = 'Spitzname';
stringMap [strings.Global_Introduction] = 'Einführung';
stringMap [strings.Global_Change_Avatar] = 'Avatar ändern';
stringMap [strings.Global_Local_Upload] = 'Lokaler Upload';

//persönliche Informationen
stringMap [strings.User_Personal_information] = 'Persönliche Informationen';
stringMap [strings.User_Member_Validity_Period] = 'Gültigkeitsdauer der Mitglieder';
stringMap [strings.User_My_Share] = 'Meine Freigabe';
stringMap [strings.User_My_Collection] = 'Meine Sammlung';
stringMap [strings.User_More_Message] = 'More';
stringMap [strings.User_Deleted_Sotrm] = 'Sturm löschen';
stringMap [strings.User_Folder_Rename] = 'Umbenennen';
stringMap [strings.User_Folder_Rename_Root] = 'Berechtigungen ändern';
stringMap [strings.User_Folder_Rename_Root_Title] = 'Berechtigungen ändern';
stringMap [strings.User_Folder_Root_Public] = 'Vollständig öffentlich';
stringMap [strings.User_Folder_Root_Viewonly] = 'Der öffentliche Ordner darf nur angezeigt werden';
stringMap [strings.User_Folder_Root_Private] = 'Private';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'Andere Personen können Ihre Favoriten auf Ihrer Profilseite anzeigen';
stringMap [strings.User_Message_Link] = 'Link';
stringMap [strings.User_New_Group] = 'Neue Gruppe';
stringMap [strings.User_New_Group_Title] = 'Neue Gruppe erstellen';
stringMap [strings.User_New_Group_Style_Title] = 'Dateityp';
stringMap [strings.User_Share_Is_Null] = 'Noch keine Freigabe! ';
stringMap [strings.User_Deleted_Storm_Tips] = 'Möchten Sie diesen Sturm wirklich löschen? ';
stringMap [strings.User_Remove_From_List] = 'Aus der Liste entfernen',
    stringMap [strings.User_Move_To] = 'Verschieben nach',
    stringMap [strings.User_Deleted_Link] = 'Link löschen'
stringMap [strings.User_Collction_Is_Null] = 'Noch keine Sammlung'
stringMap [strings.User_Collection] = 'Sammlung'

// Anmeldefeld
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'Willkommen bei Mind +'
stringMap [strings.User_Lolgin_Free_Registration] = 'Kostenlose Registrierung'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'Bitte geben Sie Ihre Telefonnummer ein'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'Bitte geben Sie den Bestätigungscode ein'
stringMap [strings.User_Lolgin_Obtain] = 'Get'
stringMap [strings.User_Lolgin_Password_Login] = 'Passwort-Login'
stringMap [strings.User_Lolgin_Login] = 'Login'
stringMap [strings.User_Lolgin_Register_An_Account] = 'Kontoregistrierung'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'Bereits ein Konto haben'
stringMap [strings.User_Lolgin_To_Log_In] = 'Zum Anmelden gehen'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'Bitte geben Sie ein Passwort ein'
stringMap [strings.User_Lolgin_Confirm_Password] = 'Passwort bestätigen'
stringMap [strings.User_Lolgin_Registered] = 'Registriert'
stringMap [strings.User_Lolgin_Account_Login] = 'Kontoanmeldung'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'Scan-Code-Anmeldung'
stringMap [strings.User_Lolgin_Forgot_Password] = 'Passwort vergessen'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'Anmeldung zum Bestätigungscode'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Open Mind + Scan oben auf der Einstellungsoberfläche'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'Dieser QR-Code läuft in fünf Minuten ab, bitte klicken Sie, wenn er abläuft.'
stringMap [strings.User_Lolgin_Refresh] = 'Aktualisieren'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'Der Scanvorgang ist erfolgreich. Bitte bestätigen Sie dies auf Ihrem Telefon.'
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'Gibt an, ob die Anmeldung autorisiert werden soll'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'Bitte geben Sie ein neues Passwort ein'
stringMap [strings.User_Lolgin_Reset] = 'Zurücksetzen'

// Meine Karte
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'Vorlagentyp oder Vorlage suchen'
stringMap [strings.My_Mind_Date] = 'Datum'
stringMap [strings.My_Mind_Create_Folder] = 'Ordner erstellen'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'Bitte geben Sie den Ordnernamen ein'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Bitte geben Sie einen neuen Ordnernamen ein'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'Noch keine Karte, schnell gehen'
stringMap [strings.My_Mind_Create] = 'Erstellen'
stringMap [strings.My_Mind_Right] = 'Bar!'
stringMap [strings.My_Mind_Open] = 'Öffnen'
stringMap [strings.My_Mind_Type_Left_Right] = 'Dual Guide Map'
stringMap [strings.My_Mind_Type_Unilateral] = 'Einseitige Karte'
stringMap [strings.My_Mind_Type_Bottom] = 'Organigramm'
stringMap [strings.My_Mind_Type_Bubble] = 'Blasendiagramm'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'Schedule Map'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Leere Mind Map'
stringMap [strings.My_Mind_All] = 'Alle'
stringMap [strings.My_Mind_Views] = 'Ansichten:'

// Sturmsuche
stringMap [strings.Storm_Search_How_Title] = 'Top Tags'
stringMap [strings.Storm_History_Search] = 'Verlaufssuche'

// Öffentlicher Header
stringMap [strings.Public_Header_Mind] = 'Map'
stringMap [strings.Public_Header_Outline] = 'Gliederung'
stringMap [strings.Public_Header_Download_Apps] = 'Client herunterladen'
stringMap [strings.Public_Header_Mobile_Version] = 'Mobile Version'
stringMap [strings.Public_Header_Creator] = 'Ersteller:'
stringMap [strings.Public_Header_View_Frequency] = 'Ansichten:'
stringMap [strings.Public_Header_Save] = 'Speichern'
stringMap [strings.Header_Mind_Tips_Saved] = 'Gespeichert'
stringMap [strings.Header_Mind_Tips_Edit] = 'Bearbeitet'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'Update wurde gespeichert'

// Kartenbearbeitungsmenü
stringMap [strings.Mind_Edit_Revoke] = 'Widerrufen'
stringMap [strings.Mind_Edit_Restore] = 'Wiederherstellen'
stringMap [strings.Mind_Edit_Subtopic] = 'Unterthema'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Oberes Unterthema'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Unteres Unterthema'
stringMap [strings.Mind_Edit_Free_Node] = 'Freier Knoten'
stringMap [strings.Mind_Edit_Summary] = 'Zusammenfassung'
stringMap [strings.Mind_Edit_Block] = 'In einen Block'
stringMap [strings.Mind_Edit_Insert] = 'Einfügen'
stringMap [strings.Mind_Edit_Related] = 'Verwandte'
stringMap [strings.Mind_Edit_Style] = 'Stil'
stringMap [strings.Mind_Edit_Recording] = 'Aufnahme'
stringMap [strings.Mind_Edit_Insert_Picture] = 'Bild einfügen'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Symbol einfügen'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'Bemerkungen einfügen'
stringMap [strings.Mind_Edit_Insert_Link] = 'Link einfügen'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'Statistisches Diagramm einfügen'
stringMap [strings.Mind_Edit_Upload] = 'Hochladen'
stringMap [strings.Mind_Edit_Right_Icon] = 'Icon'
stringMap [strings.Mind_Edit_Right_Style] = 'Style'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'Decodierung ...'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Dauer'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = 'Sind Sie sicher, dass Sie das aufgenommene Audio löschen? '
stringMap [strings.Mind_Edit_Join_Membership] = 'Mitgliedschaft öffnen'
stringMap [strings.Mind_Edit_Upgrade] = 'Upgrade'
stringMap[strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'Mitglieder können mehr Aufnahmezeit haben. Gehen Sie jetzt zum Upgrade auf Mitgliedschaft? '

stringMap [strings.Mind_Edit_Choose_Material] = 'Material auswählen'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Bilder hier ziehen und ablegen'
stringMap [strings.Mind_Edit_Select_locally] = 'Aus lokal auswählen'
stringMap [strings.Mind_Edit_Choose_From] = 'Aus Materialbibliothek auswählen'
stringMap [strings.Mind_Edit_Mine] = 'My'
stringMap [strings.Mind_Edit_My_Material] = 'Mein Material'
stringMap [strings.Mind_Edit_Recently_Used] = 'Zuletzt verwendet'
stringMap [strings.Mind_Edit_Use] = 'Verwenden'
stringMap [strings.Mind_Edit_No_Material_TemporARY_Go_Add_It] = 'Momentan kein Material, geh und füge es hinzu! '
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'Diagrammtyp auswählen'
stringMap [strings.Mind_Edit_Remarks] = 'Bemerkungen'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'Bitte geben Sie die Anmerkungen ein'
stringMap [strings.Mind_Edit_Add] = 'Hinzufügen'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'Bitte geben Sie den Link ein'

// Bearbeiten Sie das Menü auf der linken Seite
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'Bild speichern'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'PDF exportieren'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'Links teilen'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Für den Sturm freigeben'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'Drucken'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Vollversion entsperren'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'Hilfe'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Format speichern'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'PNG-Format'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'JPG-Format'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'Webp-Format'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'Karte freigeben'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'Share switch'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'Sie können den Link kopieren und senden'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'Link kopieren'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'Wenn Sie den Link ändern möchten, klicken Sie bitte auf'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'Regenerieren'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'Link password:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='Speichern zulassen'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Maximale Anzahl von Ansichten'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'Unendlich'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'Limit'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'Second'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'Gültigkeitsdauer des Links'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '1 Tag'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '3 Tage'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '7 Tage'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'Permanent'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Inhalt und Link kopieren'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = 'Möchten Sie sich wirklich regenerieren? Der vorherige Link ist ungültig! '
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'Teilen'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'Benutzerdefinierte Kategorie:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Gibt an, ob Kommentare zugelassen werden sollen'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Bearbeiten, um Kategorie hinzuzufügen'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Mitgliedschaftsrechte'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'Support-Plattform'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Open Membership'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Austausch des Aktivierungscodes'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Vorteile der Mitgliedschaft nach dem Kauf'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'Zahlungsmethode auswählen'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'Aktivitätspreis'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'Tatsächlich gezahlter Betrag'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Sofortige Erneuerung'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Nutzungsbedingungen》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《Datenschutzrichtlinie》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'Aktivierungscode:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Bitte geben Sie den Aktivierungscode ein'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'Aktivierungscode überprüfen'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'Kategorien auswählen'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Haupttitel darf nicht leer sein'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'Coupon:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Bitte geben Sie den Gutscheincode ein'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'Bestätigen Sie die Verwendung von Gutscheinen'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'Verification'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Vorzugsbetrag'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immedantly] = 'Sofort bezahlen'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Austauschinformationen bestätigen'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Exchange-Code:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Exchange'

// Bearbeiten Sie das Menü auf der rechten Seite
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'Canvas'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'Hintergrundfarbe'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Layout'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'Linienstil'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'Linienfarbe'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'Linienbreite'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'Linien verjüngen sich'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'Themenschriftart'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'Kinderschrift'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'Textfarbe'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'Schriftgröße'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'Schriftstil'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'Rahmenfarbe'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'Füllfarbe'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Rahmenform'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'Rahmenbreite'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Schatteneffekt anzeigen (Abonnementfunktion)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'Linienfarbe'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'Gepunktete Linie'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'Bildgröße'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'Bildspeicherort'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Bildform'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'Symbolgröße'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'Symbolposition'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'Allgemein'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'Priorität'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'Fortschritt'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'Sterne'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'Woche'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Portrait'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'Lernen'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'Arbeit'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'Zeichen'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'Tiere'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'Essen'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'Kopierstil'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'Sticking style'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Form ändern'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'Bild anzeigen'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Bild löschen'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'Kopieren'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'Einfügen'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'Zeile löschen'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Löschen'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'Bemerkungen bearbeiten'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'Link öffnen'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Bemerkungen löschen'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'Link löschen'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'Symbol löschen'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'Symbolstil'

// Statistisches Diagrammbearbeitungsfeld
stringMap [strings.Echart_Edit_Statistical] = 'Statistik'
stringMap [strings.Echart_Edit_Value] = 'Wert'
stringMap [strings.Echart_Edit_Color] = 'Farbe'
stringMap [strings.Echart_Edit_Title] = 'Titel'
stringMap [strings.Echart_Edit_Detailed_Description] = 'Detaillierte Beschreibung'
stringMap [strings.Echart_Edit_Value_Sort] = 'Sortieren'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'Anzeigetyp'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'Farbschema'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'Anzeigegröße'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'Titelgröße'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'Größe erklären'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'Abonnieren'

// Registerkarte Sturm
stringMap [strings.StormBookTag_Subscribed] = 'Abonniert'
stringMap [strings.StormBookTag_List] = 'Liste'

// Storm-Sharing-Seite
stringMap [strings.Storm_Share_Comment] = 'Kommentar'
stringMap [strings.Storm_Share_Reply] = 'Antwort'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'Lassen Sie mich das auch kommentieren ~'
stringMap [strings.Storm_Share_Release] = 'Release'
stringMap [strings.Storm_Share_Main_Title] = 'Haupttitel'
stringMap [strings.Storm_Share_Subtitle] = 'Untertitel'
stringMap [strings.Storm_Share_Author] = 'Autor'
stringMap [strings.Storm_Share_Replies] = 'Antworten'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'Es gibt noch keine Kommentare. Sei der Erste, der der Erste ist.'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Bitte geben Sie einen Kommentar ein ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = 'Möchten Sie diesen Kommentar wirklich löschen? '

// Hilfe zur internen Verwendung der Karte
stringMap [strings.Mind_Usinghelp_Btn_1] = 'Tastenkombinationen (Fenster)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'Tastenkombination (Mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'Beschreibung'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Strg] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Befehl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'Untergeordneten Knoten hinzufügen'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[Löschen]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'Den aktuellen Knoten löschen (beachten Sie, dass er nicht gelöscht werden kann, wenn er sich im Bearbeitungsstatus befindet)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Strg] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Befehl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'Den aktuellen Knoten ändern'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Strg] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Befehl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Strg] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Befehl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'Karte vergrößern'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Strg] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Befehl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'Kümmere dich um die Karte'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Strg] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Befehl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Strg] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Befehl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Strg] + [Umschalt] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Befehl] + [Umschalt] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Strg] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Befehl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'Bearbeitung beenden'

// Eingabeaufforderungstext
stringMap [strings.Message_Tips_Login_Success] = 'Anmeldung erfolgreich'
stringMap [strings.Message_Tips_Dlt_Success] = 'Erfolgreich löschen'
stringMap [strings.Message_Tips_Report_Success] = 'Bericht erfolgreich'
stringMap [strings.Message_Tips_Copy_Success] = 'Kopieren erfolgreich'
stringMap [strings.Message_Tips_Collction_Success] = 'Sammlungserfolg'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Erfolg bei der Avatar-Änderung'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'Erfolg beim Ändern der Signatur'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'Spitzname erfolgreich geändert'
stringMap [strings.Message_Tips_Modification_Success] = 'Änderung erfolgreich'
stringMap [strings.Message_Tips_New_Profile_Success] = 'Erfolg neuer Ordner'
stringMap [strings.Message_Tips_Mobile_Success] = 'Mobiler Erfolg'
stringMap [strings.Message_Tips_Submit_Success] = 'Erfolgreich senden'
stringMap [strings.Message_Tips_Exchange_Success] = 'Austausch erfolgreich'
stringMap [strings.Message_Tips_Please_Code] = 'Bitte geben Sie den Aktivierungscode ein'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'Bitte stimmen Sie der Datenschutzrichtlinie zu'
stringMap [strings.Message_Tips_Please_Sign_In] = 'Bitte anmelden'
stringMap [strings.Message_Tips_Share_Success] = 'Freigabe erfolgreich'
stringMap [strings.Message_Tips_Create_Success] = 'Erfolgreich erstellt'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'Der Ordnername darf nicht leer sein'
stringMap [strings.Message_Tips_Selecte_Success] = 'Suche abgeschlossen'
stringMap [strings.Message_Tips_Subscription_Success] = 'Abonnement ist erfolgreich'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'Abmeldung erfolgreich abbestellen'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'Zugehöriger Inhalt nicht gefunden'
stringMap [strings.Message_Tips_Max_Add_Class] = 'Kann höchstens hinzufügen'
stringMap [strings.Message_Tips_Registered_Success] = 'Erfolgreich registriert'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'Code-Scan-Anmeldung fehlgeschlagen'
stringMap [strings.Message_Tips_Share_Is_Null] = 'Link zum Teilen ist nicht vorhanden'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'Die hochgeladene Datei darf 3 MB nicht überschreiten.'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'Berechtigung erfolgreich abrufen'
stringMap [strings.Message_Tips_Membership_Required] = 'Mitgliedschaft erforderlich'
stringMap [strings.Message_Tips_Save_Success] = 'Erfolgreich speichern'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'Code-Anmeldung erfolgreich scannen'
stringMap [strings.Message_Tips_Style] = 'Eine Kategorie?'

// Gliederungsmodus
stringMap [strings.Outline_Menu_Indent] = 'Einzug'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'Einrückung verringern'

// Hilfehinweis
stringMap [strings.Help_Background_Content] = 'Linienhintergrund, Linienhintergrund, Linienhintergrund, Linienhintergrund, Linienhintergrund, Linienhintergrund, Linienhintergrund, Linienhintergrund,'
stringMap [strings.Help_Mind_Content] = 'Karten mit unterschiedlichen Layouts können je nach Einstellungen erstellt werden: Dual-Guide-Karte, linke Karte, rechte Karte, Organigramm, Zwei-Wege-Baum, linker Baum, rechter Baum, Tabelle'
stringMap [strings.Help_Share_Link_Content] = 'Links teilen können in WeChat, QQ, Weibo und anderen Kanälen in Form von Links geteilt werden und Freunde können den Inhalt anzeigen'
stringMap [strings.Using_Templates] = 'Vorlagen verwenden'

//Einladungscode
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Bitte geben Sie den Einladungscode ein'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Bitte geben Sie den richtigen Einladungscode ein'
stringMap [strings.User_Lolgin_Optional] = 'Optional'

// Material verwenden

stringMap [strings.Cannot_Add_To_Node_Background] = 'Gilt nicht für den Knotenhintergrund'
stringMap [strings.Cannot_Add_To_Img_Background] = 'Gilt nicht für Texthintergrund'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'Gilt nicht für den Paketlinienhintergrund'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Hintergrundbild hinzufügen'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Hintergrundbild löschen'
stringMap [strings.Mind_Add_Background_Image] = 'Hintergrundbild einfügen'
stringMap [strings.Mind_Delete_Background_Image] = 'Hintergrundbild löschen'

stringMap [strings.Permanent_Member] = 'Ständiges Mitglied'
stringMap [strings.Insufficient_Storage_Space] = 'Unzureichender Speicherplatz, aktualisieren Sie die Mitgliedschaft, um den Speicherplatz zu erhöhen. '
stringMap [strings.Used_Space] = 'Speicherplatz'
stringMap [strings.Current_Storage] = 'Aktueller Speicher:'
stringMap [strings.Used_Space_Upgrade] = 'Speicherplatz-Upgrade'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Mind + Mind Map & Notes Super-Mnemonik, dem Denken Flügel verleihen, erstellen, anzeigen, planen und notieren'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'Der Speicherplatz ist nicht ausreichend. Sie können die Vorlage verwenden, wenn Sie Ihre Mitgliedschaft aktualisieren. '

stringMap [strings.Group_Management] = 'Gruppe'
stringMap [strings.Save_Img_Fill_Full] = 'Vollständig pflastern'
stringMap [strings.Save_Img_Center_Tilt] = 'Center Tilt'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'Voll (dunkel)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Mind + Mind Map Wasserzeichen'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'Wasserzeichen hinzufügen, um die Erstellung von Wissen zu schützen:'
stringMap [strings.Save_Img_Watermark_Text] = 'Wasserzeichentext:'
stringMap [strings.Save_Img_Watermark_Logo] = 'Wasserzeichen-LOGO:'
stringMap [strings.Save_Img_Watermark_Style] = 'Wasserzeichenstil:'
stringMap [strings.Save_Img_Please_Input] = 'Bitte eingeben'
stringMap [strings.Save_Img_Chop_Tips] = 'Sie können die Wasserzeichenrechte genießen, wenn Sie ein Mitglied öffnen.'
stringMap [strings.Public_Header_Mindmap_Mind] = 'Kartenmodus'
stringMap [strings.Copy_Mind_Map] = 'Mind Map kopieren'
stringMap [strings.Font_Style_Bold] = 'Fett'
stringMap [strings.Font_Style_Italics] = 'Kursiv'
stringMap [strings.Font_Style_Delete_Line] = 'Durchgestrichen'
stringMap [strings.Remove_Any] = 'Entfernen'

stringMap [strings.Mindmap_Review] = 'Kartenüberprüfung'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'Vertiefe den Eindruck und verbessere das Gedächtnis, sortiere Ideen und Informationen effizient aus'
stringMap [strings.Mindmap_Review_Content] = 'Die Kartenüberprüfung bezieht sich auf die Wiedergabe des Bearbeitungsprozesses. Der Inhalt wird nacheinander ab dem zentralen Thema abgespielt. Die Kartenüberprüfung kann Ihnen dabei helfen, Systeminventur durchzuführen, zu analysieren und Ideen und Lücken zu finden Während des Überprüfungsprozesses finden Sie möglicherweise unterschiedliche Inspirationen.'
stringMap [strings.Mindmap_Review_Speed] = 'Geschwindigkeit'
stringMap [strings.Mindmap_Review_Word_Speed] = 'Wortgeschwindigkeit'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'Hintergrundverhältnis'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'Aktuelle Vergrößerung des Wiedergabeknotens'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Reihenfolge der Knotenwiedergabe'


stringMap [strings.Mind_Group_Picture_Upload] = 'Bild hochladen'
stringMap [strings.Mind_Group_Upload_From_Local] = 'Lokaler Upload'
stringMap [strings.Mind_Group_Mind_Mapping] = 'Mind Map'
stringMap [strings.Mind_Group_Information] = 'Daten'

stringMap [strings.Mind_Group_Invite_New_Members] = 'Neue Mitglieder einladen'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Bitte geben Sie Ihre Telefonnummer ein'
stringMap [strings.Mind_Group_Cancel] = 'Abbrechen'
stringMap [strings.Mind_Group_Confirm] = 'OK'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'Handynummer darf nicht leer sein'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'Einladung erfolgreich'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "Gemeinsame Gruppenmitglieder"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Maximale Anzahl gemeinsam genutzter Gruppenmitglieder"
stringMap [strings.Mind_Group_Membership_Limit] = "Mitgliedschaftslimit"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Obergrenze des aktuellen Mitglieds"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "Obergrenze für Upgrade-Mitglieder"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Suche nach Mitgliedern"
stringMap [strings.Mind_Group_Creator] = "Ersteller"
stringMap [strings.Mind_Group_Administrator] = "Administrator"
stringMap [strings.Mind_Group_Number_Of_People] = "People"
stringMap [strings.Mind_Group_Selected] = "Ausgewählt"
stringMap [strings.Mind_Group_Member] = "Mitglied"
stringMap [strings.Mind_Group_Check_All] = "Alle auswählen"
stringMap [strings.Mind_Group_Remove] = "Entfernen"
stringMap [strings.Mind_Group_Invite_New_Members] = "Neue Mitglieder einladen"
stringMap [strings.Mind_Group_Delete_Members] = "Mitglieder löschen"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Bitte wählen Sie das zu löschende Mitglied aus."
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Möchten Sie den Benutzer wirklich aus dieser Gruppe entfernen?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Administrator hinzufügen"
stringMap [strings.Mind_Group_Accomplish] = "Abgeschlossen"
stringMap [strings.Mind_Group_Compile] = "Bearbeiten"
stringMap [strings.Mind_Group_Group_Owner] = "Gruppeninhaber"
stringMap [strings.Mind_Group_Add_Admin] = "Administrator hinzufügen"
stringMap [strings.Mind_Group_Group_ID] = "Gruppen-ID"
stringMap [strings.Mind_Group_Group_Number] = "Gruppennummer"
stringMap [strings.Mind_Group_Name] = "Name"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Bitte geben Sie den Namen der gemeinsam genutzten Gruppe ein"
stringMap [strings.Mind_Group_Introduce] = "Introduce"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Bitte stellen Sie gemeinsam genutzte Gruppen kurz vor"
stringMap [strings.Mind_Group_Announcement] = "Ankündigung"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Shared Group Tag"
stringMap [strings.Mind_Group_Custom_Tag] = "Benutzerdefiniertes Tag"
stringMap [strings.Mind_Group_Save] = "Speichern"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Gruppenname darf nicht leer sein"
stringMap [strings.Mind_Group_Establish] = "Erstellen"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Erlaube das Erstellen von Karten / Ordnern"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Bearbeitung von Karten zulassen"
stringMap [strings.Mind_Group_Holder] = "Jeder"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Nur Administrator"
stringMap [strings.Mind_Group_Not_Allow] = "Nicht erlaubt"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Karte löschen lassen"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Foren erlauben, Nachrichten zu posten"
stringMap [strings.Mind_Group_Export] = "Exportieren"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Bilder / PDF speichern lassen"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Gruppenwasserzeichen öffnen"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Nach dem Öffnen wird das Gruppenlogo auf der Mind Map gedruckt."
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Speichern von Karten zulassen"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "Auf meiner Karte speichern"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Gibt an, ob das Teilen für den Sturm zugelassen werden soll"
stringMap [strings.Mind_Group_Join_The_Way] = "Beitritt"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "Gibt an, ob neue Mitglieder eingeladen werden sollen"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Gibt an, ob Suchgruppennummern hinzugefügt werden sollen."
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Ob die Nachricht beim Beitritt validiert werden muss"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Gemeinsame Gruppendaten"
stringMap [strings.Mind_Group_Authority_Management] = "Berechtigungsverwaltung"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Freigabegruppe verlassen"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Sind Sie sicher, dass Sie die gemeinsam genutzte Gruppe verlassen?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Auflösungsgruppe"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Die Dateien werden gelöscht, nachdem die Gruppe aufgelöst wurde. Bestätigen, um sich aufzulösen?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Zur Startseite zurückkehren"
stringMap [strings.Mind_Group_Join_The_Group] = "Gruppe beitreten"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Bitte geben Sie die zu suchende Gruppennummer / den Gruppennamen ein"
stringMap [strings.Mind_Group_Apply_To_Join] = "Auf Beitritt anwenden"
stringMap [strings.Mind_Group_Join] = "Join"
stringMap [strings.Mind_Group_Verification] = "Überprüfung"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Bitte Validierungsnachricht eingeben"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "Inhalt darf nicht leer sein"
stringMap [strings.Mind_Group_Name] = "Freigegebener Gruppenname"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Bitte geben Sie den Namen der gemeinsam genutzten Gruppe ein"
stringMap [strings.Mind_Group_Introduction] = "Gruppeneinführung teilen"
stringMap [strings.Mind_Group_Group_Indication] = "Gruppennummer"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "Der Schöpfer ist faul, ich habe es noch nicht eingeführt ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Gemeinsame Gruppenansage"
stringMap [strings.Mind_Group_No_Announcement] = "Noch keine Ankündigung"
stringMap [strings.Mind_Group_Click_On] = "Zum Vergrößern anklicken"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "Zum Ausblenden klicken"
stringMap [strings.Mind_Group_Altogether] = "Gesamt"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Gruppennummer erfolgreich kopieren"
stringMap [strings.Mind_Group_Stick] = "Bleib oben"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Es gibt neuen Inhalt"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Top abbrechen"
stringMap [strings.Mind_Group_Setting_Up] = "Einstellungen"
stringMap [strings.Mind_Group_Usinghelp] = "Hilfe verwenden"
stringMap [strings.Mind_Group_Set_The_Message] = "Gruppennachricht"
stringMap [strings.Mind_Group_Apply_To_Join] = "Auf Beitritt anwenden"
stringMap [strings.Mind_Group_Invite_To_Join] = "Zum Beitritt einladen"
stringMap [strings.Mind_Group_Consent] = "Zustimmen"
stringMap [strings.Mind_Group_Turn_Down] = "Ablehnen"
stringMap [strings.Mind_Group_Label] = "Label"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Bitte geben Sie den Namen der gemeinsamen Gruppenbezeichnung ein."
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Freunde und Kollegen einladen, gemeinsam Wissensinhalte zu erstellen und zu teilen"
stringMap [strings.Mind_Group_Immededite_Create] = "Sofort erstellen"
stringMap [strings.Mind_Group_Immededite_To_Join] = "Jetzt beitreten"

stringMap [strings.Currently_Selected] = "Derzeit folgt"
stringMap [strings.LabelManagement] ="Etikettenverwaltung"
stringMap [strings.NoAttention] = "Keine Aufmerksamkeit"
stringMap [strings.Mindmap_Review_Play] = 'Spielen'

stringMap [strings.Mind_Group_Joined] = "Verbunden"
stringMap [strings.Mind_Group_Download_App] = "APP herunterladen"
stringMap [strings.Mind_Group_No_Content] = "Noch kein Inhalt"
stringMap [strings.Mind_Group_Individual] = "Teile"
stringMap [strings.Mind_Group_Message_Area] = "Nachrichtenbereich"
stringMap [strings.Mind_Group_No_Message] = "Keine Nachricht, jetzt gehen"
stringMap [strings.Mind_Group_Leave_Message] = "Nachricht hinterlassen"
stringMap [strings.Mind_Group_Bar] = "Bar"
stringMap [strings.Mind_Group_See_More] = "Mehr sehen"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Geben Sie den Einladungscode ein"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Bewerben, um der Freigabegruppe beizutreten"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Gründe für die Anwendung (0-30 Wörter)"
stringMap [strings.Mind_Group_Send_Out] = "Senden"
stringMap [strings.Mind_Group_Join_Successfully] = "Erfolgreich beitreten"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "Die Anwendung wurde erfolgreich gesendet"

stringMap [strings.Mind_Group_Save_The_Mapping] = "Karte speichern"
stringMap [strings.Mind_Group_Update_Record] = "Datensatz ändern"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Map wechseln"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Map Review"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Ein vorhandener Benutzer bearbeitet die Karte."
stringMap [strings.Mind_Edit_Page_Password] = "Passwort"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "Freigabegruppe"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "Meine MBean"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Alipay-Zahlung"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Bitte gehen Sie zur Zahlungsseite, um zu bezahlen"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Gesamt"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Zahlung bestätigen"
stringMap [strings.Mind_Shooping_Redeem_Now] = "Jetzt einlösen"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "Gibt an, ob die Zahlung für den Kauf bestätigt werden soll."
stringMap [strings.Mind_Tips] = "Tipps"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "Ihre M Beans sind unzureichend, möchten Sie M Beans kaufen?"

stringMap [strings.Mind_share_scan_code] = "Mind + Scan-Code"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Ob an der zuletzt gespielten Stelle wiederhergestellt werden soll?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Verbleibende spielbare Zeiten"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "Die Datei wird vollständig gelöscht. Sind Sie sicher, dass sie sich auflösen wird?"
stringMap [strings.Group_Mind_Up_One_Level] = "Up One Level"
stringMap [strings.Group_Mind_No_Documents] = "Noch keine Dateien"
stringMap [strings.Group_Mind_PaymentSuccessful] = "Zahlung erfolgreich"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Gemeinsame Bearbeitung von Personen, Echtzeitsynchronisation mit mehreren Terminals, gemeinsame Nutzung von Dokumenten, Verbesserung der Effizienz der Teamzusammenarbeit"

stringMap [strings.Mind_Edit_Subscript] = "Index"
stringMap [strings.Mind_Edit_Text_Location] = "Text Location"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "In gemeinsam genutzte Gruppe importieren"

stringMap [strings.Standard_Colors] = "Standardfarben"
stringMap [strings.More_Colors] = "Weitere Farben"
stringMap [strings.Reset_Password] = "Passwort zurücksetzen"
stringMap [strings.Account_Password_Login] = "Konto-Passwort-Anmeldung"
stringMap [strings.Authentication_code_Login_registration] = "Anmeldung / Registrierung des Bestätigungscodes"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "Erstellung eines Endes, Synchronisation mehrerer Enden"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Reichhaltige und vielfältige Kartenstruktur"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Massive exquisite Vorlagenbibliothek"
stringMap [strings.Map_Review_Play] = "Map Review-Play"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Exchange-Plattform zum Lernen und Teilen von Wissen"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "Speicherplatz aktualisieren"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Lassen Sie mich das auch kommentieren"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Noch keine Nachrichtenbenachrichtigung"
stringMap[strings.Failed_To_Get_Recording_Device] = "Aufzeichnungsgerät konnte nicht abgerufen werden"

stringMap[strings.Search_Pagination] = "Such-Paginierung"
stringMap[strings.Please_Enter_A_Paging_Name] = "Bitte geben Sie den Seitennamen ein"
stringMap[strings.Operation_Settings] = "Operationseinstellungen"
stringMap[strings.Shortcut_key_Settings] = "Tastenkombinationseinstellungen"
stringMap[strings.Press_Key_Combination] = "Bitte drücken Sie die gewünschte Tastenkombination"
stringMap[strings.Mind_Reduction] = "Reduktion"
stringMap[strings.Double_Click_Add_Free_Node] = "Doppelklicken Sie auf die Leerstelle, um einen freien Knoten hinzuzufügen"
stringMap[strings.Shortcut_Conflict] = "Konflikt Tastenkombination"
stringMap[strings.Select_Main_Node] = "Hauptknoten auswählen"
stringMap[strings.Telephone] = "Telefon"
stringMap[strings.Microblog] = "Microblog"
stringMap[strings.Address] = "Adresse"
stringMap[strings.Company_Address] = "Block B, Gebäude 21, Nr. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap[strings.Mind_Archive] = "Archiv"
stringMap[strings.Mind_Bookshelf] = "Bücherregal"
stringMap[strings.Search_Archive] = "Bücherregal durchsuchen"
stringMap[strings.Modify_Archive_Order] = "Bücherregal-Reihenfolge ändern"
stringMap[strings.Modification_Order] = "Änderungsreihenfolge"
stringMap[strings.Please_Enter_Archive_Name] = "Bitte geben Sie den Namen des Bücherregals ein"
stringMap[strings.Message_Tips_Archive_Name_No_Null] = "Der Name des Bücherregals darf nicht leer sein"
stringMap[strings.Mind_Edit_Delete_Archive_Tips] = "Möchten Sie dieses Bücherregal wirklich löschen?"
stringMap[strings.Mind_Edit_Delete_Bookcase_Tips] = "Möchten Sie dieses Bücherregal wirklich löschen?"
stringMap[strings.Mind_Edit_Delete_Map_Tips] = "Möchten Sie diese Karte wirklich löschen?"
stringMap[strings.Mind_Edit_Confirm] = "Bestätigen"
stringMap[strings.Mind_Import_Map] = "Karte importieren"
stringMap[strings.Mind_Bookcase] = "Bücherregal"
stringMap[strings.Added_Successfully] = "Erfolgreich hinzugefügt"
stringMap[strings.Message_Tips_Bookcase_Name_No_Null] = "Der Name des Bücherregals darf nicht leer sein"

stringMap[strings.Mind_Association_Map] = "Assoziationskarte"
stringMap[strings.Mind_confirm_Association_Map] = "Sind Sie sicher, dass Sie diese Karte verknüpfen?"
stringMap[strings.Mind_Association_Map_See] = "Assoziationskarte anzeigen"
stringMap[strings.Mind_Association_Map_Delete] = "Assoziationskarte löschen"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "Struktur der Korrelationslinien"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Beginn der Korrelationslinie"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "Das Ende der Korrelationslinie"

stringMap[strings.Share_Group_To_Storm] = "Ob die Gruppe für den Sturm geteilt werden soll"
stringMap[strings.Apply_To_Join_Storm_Platform] = "Auf der Storm-Plattform können andere Leute sehen und sich bewerben, Brainstorming mit Liebhabern des Allgemeinwissens"
stringMap[strings.Pay_To_Goin] = "Bezahlen, um beizutreten"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "M Bohnen müssen beim Beitritt bezahlt werden"
stringMap[strings.Mbean_Can_Be_Realized] = "Mbean realisiert"

stringMap[strings.Mind_Visit] = "Besuch"
stringMap[strings.Mind_Details] = "Details"
stringMap[strings.Mind_See] = "Ansicht"
stringMap[strings.Earn_Mbeans] = "Mbeans verdienen"
stringMap[strings.Mind_Explain] = "Beschreibung"
stringMap[strings.Mind_Parenting] = "Elternschaft"
stringMap[strings.Mind_Internet] = "Internet"
stringMap[strings.Mind_Psychology] = "Psychologie"
stringMap[strings.Mind_literature] = "Literatur"
stringMap[strings.Mind_Technology] = "Technologie"
stringMap[strings.Mind_Workplace] = "Arbeitsplatz"
stringMap[strings.Mind_Life] = "Leben"
stringMap[strings.Mind_Subject] = "Betreff"
stringMap[strings.Hot_Group] = "Hot Group"
stringMap[strings.Open_Group] = "Gruppe öffnen"
stringMap[strings.Mind_Group_Used] = "Gebraucht"

stringMap[strings.Mind_Click_Select] = "Zum Auswählen anklicken"
stringMap[strings.Mind_Double_Click_Enter] = "Doppelklicken, um einzutreten"
stringMap[strings.Search_Shared_Groups] = "Nach freigegebenen Gruppen suchen"
stringMap[strings.Search_Storm] = "Suchsturm"
stringMap[strings.Members_Save_Free] = "Mitglieder sparen kostenlos"
stringMap[strings.Bank_Card_Account_Name] = "Bitte geben Sie Ihren Bankkartenkontonamen ein"
stringMap[strings.Bank_Card_No] = "Bankkartennummer"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "Bitte geben Sie Ihre Bankkartennummer ein"
stringMap[strings.Branch_Sub_Branch_Information] = "Informationen zu Zweigniederlassungen"
stringMap[strings.Branch_Sub_Branch_Example] = "Zum Beispiel: Hangzhou Branch Chengxi Sub Branch"
stringMap[strings.Application_For_Withdrawal] = "Antrag auf Auszahlung"
stringMap[strings.Withdrawal_Amount] = "Auszahlungsbetrag"
stringMap[strings.Withdrawal] = "Zurückziehen"
stringMap[strings.Current_Revenue] = "Aktueller Umsatz"
stringMap[strings.Recharge] = "Aufladen"
stringMap[strings.Current_Total_MBeans] = "Aktuelle Gesamtanzahl der MBeans"
stringMap[strings.Withdrawal_Status] = "Auszahlungsstatus"
stringMap[strings.Insufficient_Withdrawal_Amount] = "Unzureichender Auszahlungsbetrag"
stringMap[strings.Load_More] = "Mehr laden"
stringMap[strings.Loading] = "Laden"
stringMap[strings.It_Is_Over] = "Es ist vorbei"
stringMap[strings.Default_Label] = "Standard-Label"
stringMap[strings.Selected_Label] = "Ausgewähltes Label"
stringMap[strings.Current_Location] = "Aktueller Standort"
stringMap[strings.MGold_Introduction] = "M Gold erhalten Sie durch Mind+"
stringMap[strings.MGold_Details] = "Ihre bezahlte Cloud-Gruppe, es gibt Wissenskarten, Lernmaterialien usw. in der Gruppe. Wenn Benutzer den Zahlungs-M-Beans beitreten, werden 50% davon in M-Gold umgewandelt und M-Gold höher als 50 können extrahiert werden"
stringMap[strings.Cloud_Group_Introduction] = "Teilen Sie Ihre Gruppe, andere können sie auf der Storm-Seite anzeigen"
stringMap[strings.Cloud_Group_Details] = "Ihre kostenpflichtige Cloud-Gruppe. Es gibt Wissenskarten, Lernmaterialien usw. in der Gruppe. Wenn Benutzer den Zahlungs-M-Boans beitreten, werden 50% davon in M-Gold umgewandelt und M-Gold höher als 50 können zurückgezogen werden."
stringMap[strings.Share_Storm_Introduction] = "Teilen Sie Ihre Karte mit der Storm-Community, damit mehr Leute von Ihnen lernen und Wissen entdecken können."
stringMap[strings.Share_Storm_Details] = "Wenn Sie die Verwendung von M-Beans zum Speichern von Karten aktivieren, können andere Benutzer Ihre Wissenskarte verwenden und Sie erhalten außerdem 50% der M-Beans. Mit M-Beans können Sie Mitglieder kaufen und kostenpflichtig beitreten Gruppen , Sie können auch bezahlen, um die Karten anderer Leute zu verwenden."

stringMap[strings.Linked_Successfully] = "Erfolgreich verlinkt"
stringMap[strings.Cancel_Archiving] = "Archivierung abbrechen"
stringMap[strings.Please_Select_Archive_Location] = "Bitte wählen Sie den Archivspeicherort aus"
stringMap[strings.The_Search_Result_Is_Empty] = "Suchergebnisse sind leer"

stringMap[strings.Customize_A_Style] = "Nichtmitglieder können nur einen Stil anpassen"
stringMap[strings.Adds_Current_Guide_Style_Library] = "Hinzufügen des aktuellen Kartenstils zur Stilbibliothek"
stringMap[strings.Trial_Version] = "Testversion"
stringMap[strings.Not_Supported_At_The_Moment] = "Derzeit nicht unterstützt"
stringMap[strings.Mind_File] = "Datei"
stringMap[strings.Mind_Import] = "Importieren"
stringMap[strings.Importing] = "Importieren"

stringMap[strings.Mind_Edit_Insert_Video] = "Video einfügen"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Video hier ziehen und ablegen"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "Die Upload-Videodauer darf 60s nicht überschreiten"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "Video ansehen"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "Video löschen"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "Das hochgeladene Video darf 10 MB nicht überschreiten"
stringMap[strings.Mind_Upload_Video_Restrictions] = "Unterstützt nur das Hochladen von Videos im MP4-Format, bis zu 120s und 10 M groß"
stringMap[strings.Mind_Video_Size] = "Videogröße"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "Wort exportieren"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "TXT exportieren"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap[strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap[strings.Export_Failed] = "Export fehlgeschlagen"
stringMap[strings.Add_File] = "Datei hinzufügen"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "Ziehen Sie die Datei direkt, um sie hochzuladen"
stringMap[strings.Supported_Imported_File_Types] = "Derzeit für den Import unterstützte Dateitypen: Word (nur .docx), XMind, .txt"
stringMap[strings.No_Permission_Do_This] = "Sie haben keine Berechtigung, diesen Vorgang auszuführen"

stringMap[strings.Upgrade_To_Professional] = "Upgrade auf Professional Edition"
stringMap[strings.Non_Members_Insert_Videos] = "Nicht-Mitglieder können erst 24 Stunden nach Registrierung Videos einfügen"
stringMap[strings.Upload_Failed] = "Upload fehlgeschlagen"
stringMap[strings.Upload_Failed_Title_Message] = "Upload fehlgeschlagen, bitte zum Bestätigen klicken, verbleibende Bilder löschen, deren Upload fehlgeschlagen ist"

stringMap[strings.Mind_Edit_Members_Use] = "Mitglieder verwenden"
stringMap[strings.Invitation_Code] = "Einladungscode"
stringMap[strings.Student_Certification] = "Studentenzertifizierung"
stringMap[strings.I_Get_It] = "Ich werde die Anleitung überspringen"
stringMap[strings.Welcome_To_Mind] = "Willkommen den jungen Meister bei Mind+, lasst uns die Reise des Denkens beginnen!"
stringMap[strings.Mind_Before] = "Zuerst"
stringMap[strings.Try_A_Map] = "Versuche eine Karte"
stringMap[strings.Click_Create] = "Klicken zum Erstellen"
stringMap[strings.Click_Create_A_Mind_Map] = "Klicken, um eine Mindmap zu erstellen"
stringMap[strings.Mind_About] = "Über"
stringMap[strings.See_Checking_Data] = "Persönliche Informationen anzeigen"
stringMap[strings.Modify_Checking_Data] = "Persönliche Daten ändern"
stringMap[strings.Mind_Shear] = "Scheren"

stringMap[strings.Mind_Style_Empty] = "Kein bevorzugter oder benutzerdefinierter Stil"
stringMap[strings.Mind_Branch_Free_Layout] = "Verzweigungsfreies Layout"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "Zwei-Wege-Struktur und Bubble-Map-Zweige können frei in das Layout gezogen werden"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Free Theme Drag Snap"

stringMap[strings.Select_A_Node] = "Wählen Sie ein Thema aus"
stringMap[strings.Create_Child_Node] = "Untergeordnetes Thema erstellen"
stringMap[strings.Add_A_Nice_Image_To_The_Node] = "Als nächstes fügen wir dem Child-Theme ein schönes hinzu"
stringMap[strings.Click_Icon_Panel] = "Klicken Sie auf das Symbolfeld"
stringMap[strings.Select_An_Icon_You_Like] = "Wählen Sie ein Symbol aus, das Ihnen gefällt"
stringMap[strings.Replace_The_Mind_Map_With_A_New_One] = "Als nächstes werden wir die Mindmap durch eine neue ersetzen"
stringMap[strings.Select_The_Central_Theme] = "Wählen Sie das zentrale Thema aus"
stringMap[strings.Switch_To_Canvas_Panel] = "Zum Canvas-Panel wechseln"
stringMap[strings.Click_Layout] = "Zum Layout anklicken"
stringMap[strings.Choose_A_Layout_You_Like] = "Wählen Sie ein Layout, das Ihnen gefällt"
stringMap[strings.Mind_Map_Save_Share] = "Herzlichen Glückwunsch, Sie haben erfolgreich eine wunderschöne Mindmap erstellt! Speichern wir sie und teilen Sie sie mit Ihren Freunden~"
stringMap[strings.Click_Save_Picture] = "Klicken, um das Bild zu speichern"
stringMap[strings.Click_OK] = "Klicken Sie auf OK"
stringMap[strings.Congratulations_On_Completing_The_Novice_Guide] = "Herzlichen Glückwunsch zum Abschluss des Anfängerleitfadens~"
stringMap[strings.Upload_Succeeded] = "Erfolgreich hochgeladen"

stringMap[strings.Mind_Edit_Node_Menu_Quick_Style] = "Schnellstil"
stringMap[strings.Mind_Extremely_Important] = "Extrem wichtig"
stringMap[strings.Mind_Important] = "Wichtig"
stringMap[strings.Mind_Commonly] = "Allgemein"
stringMap[strings.Mind_Ordinary] = "Gewöhnlich"
stringMap[strings.Member_Exclusive] = "Diese Funktion ist exklusiv für Mitglieder. Möchten Sie Ihre Mitgliedschaft aktualisieren?"
stringMap[strings.Member_Exclusive_Color_Scheme] = "Dieses Farbschema ist exklusiv für Mitglieder. Möchten Sie Ihre Mitgliedschaft aktualisieren?"
stringMap[strings.Mind_Enlarge] = "Vergrößern"
stringMap[strings.Mind_Narrow] = "Schmal"
stringMap[strings.Mind_Center] = "zentriert"
stringMap[strings.Mind_Full_Screen] = "Vollbild"

stringMap[strings.Mind_Association_Node] = "Assoziationsknoten"
stringMap[strings.Mind_Version_Number] = "Versionsnummer"
stringMap[strings.Mind_Copy_Rights] = "Copyright © 2021 Shanghai Yushu Technology Co., Ltd. Alle Rechte vorbehalten"

stringMap[strings.Mind_Resource_See] = "Anhang anzeigen"
stringMap[strings.Mind_Resource_Delete] = "Anhänge löschen"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Dateien hierher ziehen und ablegen"
stringMap[strings.Mind_Edit_choose_explain] = "Datei auswählen"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "Die hochgeladene Datei darf 20 MB nicht überschreiten"

stringMap[strings.Please_Enter_Mind_Password] = "Bitte geben Sie ein 4-8-stelliges Passwort ein"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Bitte geben Sie ein Passwort ein"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "Diese Funktion ist eine Stabfunktion"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "Das Dateipasswort wurde entfernt"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "Die Anzahl der Passwort-Ziffern reicht nicht aus. Bitte geben Sie das Passwort mit 4-invertierten 8-Ziffern ein."
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "Das Passwort ist zu lang. Bitte geben Sie das Passwort mit vier umgekehrten 8-Ziffern ein."
stringMap[strings.Please_Enter_Mind_Password_Success] = "Passwort erfolgreich gesetzt"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "Kennwortformatierungsfehler"
stringMap[strings.Mind_Load_Data_Error] = "Fehler beim Laden von Daten"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Passwort-Fehler"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Nur der Ersteller dieser Karte kann das Passwort festlegen"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"       
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
stringMap[strings.Global_Text_Find] = "Find"
stringMap[strings.Global_Text_Replace] = "Replace"
stringMap[strings.Global_Text_Replace_All] = "Replace All"
stringMap[strings.Global_Text_Find_Empty] = "No Content"
stringMap[strings.Global_Text_Find_Result] = "Item & of @"
stringMap[strings.Mind_Fast_Style_Rainbow_Label] = "Life"
stringMap[strings.Mind_Fast_Style_Solid_Label] = "Pure"
stringMap[strings.Mind_Import_File] = "Import File"

stringMap[strings.Mind_Add_Font_Label] = "+ Add local font:ttf、woff..."
stringMap[strings.Mind_Import_Font_Web_Tips] = "Please download the computer client from the official website to use this feature，https://mindyushu.com"
stringMap[strings.Mind_Import_Font_Upgrade_Label] = "The free trial version can only add one external font. Upgrading to Pro users can add more external fonts and all professional version features."

stringMap[strings.Mind_Flow_Chart_Map] = "Flow Chart"
stringMap[strings.Mind_Shape_Corner_Text] = "Process"
stringMap[strings.Mind_Shape_Start_Text] = "Start"
stringMap[strings.Mind_Shape_End_Text] = "End"
stringMap[strings.Mind_Shape_Determine_Text] = "Judging"
stringMap[strings.Mind_Shape_Ellipse_3D_Text] = "Database"
stringMap[strings.Mind_Shape_Cube_Text] = "Science"
stringMap[strings.Mind_Shape_Form_Text] = "Document"
stringMap[strings.Mind_Shape_Corner_Right_Text] = "Card"
stringMap[strings.Mind_Shape_Book_Text] = "Book"
stringMap[strings.Mind_Shape_Text] = "Text"

module.exports = stringMap;