const { default: strings } = require("./strings");

var stringMap = {};
stringMap[strings.About_App_Name] = "寻简思维导图";
stringMap[strings.TEMPLATE] = "模板";
stringMap[strings.Mind_Edit_Main_Topic] = "分支主题";
stringMap[strings.Global_Add_Free_Node_Tips] = "点击屏幕选择添加位置";
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = "双击屏幕选择添加位置";
stringMap[strings.Global_Add_Relationship_Tips] = "选择需要关联的目标";
stringMap[strings.Global_Add_Target_Tips] = "请选择一个节点添加对象";
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = "该对象已经关联其他导图";
stringMap[strings.Mind_Edit_Main_Idea] = "中心主题";
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = "关联导图不存在";
stringMap[strings.Mind_Style_Add_Icon_Priority] = "优先级";
stringMap[strings.Mind_Style_Add_Icon_Progress] = "进度";
stringMap[strings.Mind_Style_Add_Icon_Stars] = "星星";
stringMap[strings.Mind_Style_Add_Icon_Week] = "星期";
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = "头像";
stringMap[strings.Establish] = "创建";
stringMap[strings.Create_From_Template] = "从模板创建";
stringMap[strings.Common] = "通用";
stringMap[strings.Function_List] = "功能列表";
stringMap[strings.Recent_Updates] = "最近更新";
stringMap[strings.The_Latest_To_Delete] = "最近删除";
stringMap[strings.Setting_Up] = "联系我们";
stringMap[strings.Usinghelp] = "使用帮助";
stringMap[strings.The_Download_Client] = "下载客户端/手机版";
stringMap[strings.Global_Purchase] = "购买";
stringMap[strings.Global_Login] = "登录";
stringMap[strings.My_map] = "最近创作";
stringMap[strings.Create_Map] = "创建导图";
stringMap[strings.Storm] = "社区";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = "共同\n特点";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = "特点";
stringMap[strings.Mind_Organization_Edit_Main_Idea] = "组织中枢";
stringMap[strings.Mind_Edit_Free_Topic] = "自由主题";
stringMap[strings.Mind_Edit_Sub_Topic] = "子主题";
stringMap[strings.Mind_Organization_Main_Topic] = "部门";
stringMap[strings.Mind_Edit_Main_Topic] = "分支主题";
stringMap[strings.Mind_Time_Edit_Main_Idea] = "计划安排";
stringMap[strings.Mind_Time_Edit_Head] = "时间";
stringMap[strings.Mind_Time_Edit_Title] = "计划";
stringMap[strings.Mind_Edit_Details] = "描述";
stringMap[strings.Checking_Data] = "个人信息";
stringMap[strings.Individual_Account] = "个人账户";
stringMap[strings.Setting_Information] = "设置信息";
stringMap[strings.Sign_Out] = "退出登录";

//功能列表
stringMap[strings.Create_a_Mind_Map] = "创建思维导图";
stringMap[strings.Create_Mind_Map_1] = "【1】创建十四种布局的导图（双向/右侧/左侧/组织架构/单气泡图/双气泡图/左侧树形图/右侧树形图/双向树形图/表格/横向时间线/竖向时间线/S时间线/金字塔）";
stringMap[strings.Create_Mind_Map_2] = "【2】可使用模板快速创建导图（大量模板内容引用）";
stringMap[strings.Create_Mind_Map_3] = "【3】对导图进行九种类型的线条样式编辑";
stringMap[strings.Create_Mind_Map_4] = "【4】对导图进行九种布局的变更编辑";
stringMap[strings.Create_Mind_Map_5] = "【5】对目标节点添加子节点，添加同级节点（上、下）";
stringMap[strings.Create_Mind_Map_6] = "【6】摘要：对单个节点/多节点添加[摘要]进行说明";
stringMap[strings.Create_Mind_Map_7] = "【7】成块：对导图单个节点/多个节点框添加[成块]，进行模块化";
stringMap[strings.Create_Mind_Map_8] = "【8】添加自由节点";
stringMap[strings.Create_Mind_Map_9] = "【9】创建任意节点之间四种线条样式的关联";
stringMap[strings.Create_Mind_Map_10] = "【10】给节点插入图片（本地+素材库）、图标、备注、链接";
stringMap[strings.Create_Mind_Map_11] = "【11】复制/粘贴节点，拷贝/粘贴样式";
stringMap[strings.Create_Mind_Map_12] = "【12】撤销，恢复撤销";
stringMap[strings.Create_Mind_Map_13] = "【13】可多选，多个节点同步操作";
stringMap[strings.Create_Mind_Map_14] = "【14】可插入统计图";
stringMap[strings.Create_Mind_Map_15] = "【15】整个导图可以录音";
stringMap[strings.Create_Mind_Map_16] = "【16】复制导图";
stringMap[strings.Create_Mind_Map_17] = "【17】可以复制导图或者整个导图到其他文件的导图";
stringMap[strings.Create_Mind_Map_18] = "【18】摘要后面可以建立导图";
stringMap[strings.Create_Mind_Map_19] = "【19】编辑时可以切换导图编辑（电脑端）";
stringMap[strings.Create_Mind_Map_20] = "【20】蓝牙键盘编辑导图：\n\t[Command]+[a]选择主节点\n\t[Command]+[←][↑][→][↓]更改选择目标或者移动导图\n\t[Command]+[e]编辑目标\n\t[Command]+[w]创建子节点 \n\t[Command]+[s]创建目标兄弟节点（上）\n\t[Command]+[d]创建目标兄弟节点（下）\n\t[Command]+[q]退出选择的目标\n\t[Command]+[-][+]缩放导图 ";

stringMap[strings.Beautify_The_Mind_Map] = "美化思维导图";
stringMap[strings.Beautify_The_Mind_Map_1] = "【1】设置思维导图背景色";
stringMap[strings.Beautify_The_Mind_Map_2] = "【2】设置整个导图线条粗细，颜色，是否渐细";
stringMap[strings.Beautify_The_Mind_Map_3] = "【3】设置文本填充色、边框颜色、边框13种形状、边框宽度、阴影效果";
stringMap[strings.Beautify_The_Mind_Map_4] = "【4】设置导图所有或部分线条颜色、宽度";
stringMap[strings.Beautify_The_Mind_Map_5] = "【5】设置文本字体大小、加粗、斜体、删除样式";
stringMap[strings.Beautify_The_Mind_Map_6] = "【6】设置图片大小、位置、形状样式";
stringMap[strings.Beautify_The_Mind_Map_7] = "【7】添加七种类别的图标（常用/3D/学习/工作/人物/动物/食物）并设置大小/位置";
stringMap[strings.Beautify_The_Mind_Map_8] = "【8】添加对整个导图一键风格样式引用";
stringMap[strings.Beautify_The_Mind_Map_9] = "【9】分组管理导图";
stringMap[strings.Beautify_The_Mind_Map_10] = "【10】对整个导图可以添加个性背景（素材库）";
stringMap[strings.Beautify_The_Mind_Map_9] = "【11】对单个节点可以添加个性边框或者背景（素材库）";
stringMap[strings.Beautify_The_Mind_Map_9] = "【12】对于节点可以拖动长度";


stringMap[strings.Map_Output] = "导图输出";
stringMap[strings.Map_Output_1] = "【1】保存图片";
stringMap[strings.Map_Output_2] = "【2】保存PDF";
stringMap[strings.Map_Output_3] = "【3】分享链接";
stringMap[strings.Map_Output_4] = "【4】分享到社区";
stringMap[strings.Map_Output_4] = "【5】打印";

stringMap[strings.Work_together] = "协同办公";
stringMap[strings.Work_together_1] = "【1】创建共享组（组标签，组公告）";
stringMap[strings.Work_together_2] = "【2】新增共享组成员";
stringMap[strings.Work_together_3] = "【3】组内多成员协同编辑同一导图";
stringMap[strings.Work_together_4] = "【4】组内上传共享文件（word/excle/pdf）";
stringMap[strings.Work_together_5] = "【5】组内可以留言";
stringMap[strings.Work_together_6] = "【6】共享组管理权限设置";
stringMap[strings.Work_together_7] = "【7】解散组";

stringMap[strings.Work_together] = "知识共享（社区）";
stringMap[strings.Community_Sharing_1] = "【1】可以将自己的创作分享到社区，让更多人看到";
stringMap[strings.Community_Sharing_1] = "【2】可以选择标签查看相关社区内容";
stringMap[strings.Community_Sharing_1] = "【3】可以对于社区作品点赞和评价";

//最近更新
stringMap[strings.Recently_Updated_Title_1] = "建立个人账号";
stringMap[strings.Recently_Updated_Title_2] = "海量模板知识库";
stringMap[strings.Recently_Updated_Title_3] = "支持多平台";
stringMap[strings.Recently_Updated_Title_4] = "定制每一处细节";
stringMap[strings.Recently_Updated_Title_5] = "个性风格样式库";
stringMap[strings.Recently_Updated_Title_6] = "导图输出";
stringMap[strings.Recently_Updated_Title_7] = "云存储";
stringMap[strings.Recently_Updated_Title_8] = "十五种类型导图";


stringMap[strings.Recently_Updated_Content_1] = "创建个人账号，所制作导图跟随账号保存";
stringMap[strings.Recently_Updated_Content_2] = "海量模板知识库直接引用，提高学习工作效率";
stringMap[strings.Recently_Updated_Content_3] = "支持网页版在线制作、IOS手机端、Mac端、安卓手机端、安卓平板多平台使用";
stringMap[strings.Recently_Updated_Content_4] = "可根据个人喜好美化文本、背景、线条、图片图标等设计";
stringMap[strings.Recently_Updated_Content_5] = "个性风格样式库直接引用，省去配色烦恼";
stringMap[strings.Recently_Updated_Content_6] = "可保存图片、PDF格式，可直接分享好友";
stringMap[strings.Recently_Updated_Content_7] = "强力云端存储支持，再也不用担心资料丢失";
stringMap[strings.Recently_Updated_Content_8] = "双向、右侧、左侧、组织架构、单气泡图、双气泡图、左侧树形图、右侧树形图、双向树形图、表格、横向时间线、竖向时间线、S时间线，八种布局随意变更";

//最近删除
stringMap[strings.Mind_Mindmap_Deleted_Title] = "最近删除";
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = "恢复";
stringMap[strings.Mind_Mindmap_Deleted_Forever] = "删除";
stringMap[strings.Mind_Mindmap_Deleted_Filename] = "文件名";
stringMap[strings.Mind_Mindmap_Deleted_Tips] = "在此处删除之后，将永久移除";
stringMap[strings.Mind_Mindmap_Deleted_Operating] = "操作";
stringMap[strings.Mind_Mindmap_Deleted_Selected] = "搜索最近删除导图";
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = "删除日期";

//联系我们
stringMap[strings.Contact_Us_No_Public] = "公众号";
stringMap[strings.Contact_Us_QQ_Group] = "QQ群";
stringMap[strings.Contact_Us_Message_Board] = "意见反馈";
stringMap[strings.Contact_Us_Username] = "姓名";
stringMap[strings.Contact_Us_Email] = "邮箱";
stringMap[strings.Contact_Us_Voicemail] = "请留下宝贵的意见";
stringMap[strings.Contact_Us_Send_Email] = "发送邮箱";

//验证信息
stringMap[strings.Input_Username_isNull] = "姓名不能为空";
stringMap[strings.Input_Email_isNull] = "请输入正确的邮箱";
stringMap[strings.Input_Voicemail_isNull] = "请输入发送内容";
stringMap[strings.Input_Introduction_isNull] = "简介不能为空";
stringMap[strings.Input_Title_isNull] = "标题不能为空";

stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] = "请输入正确的手机号";
stringMap[strings.Input_Please_Enter_Verification_Code] = "请输入验证码！";
stringMap[strings.Input_Please_Enter_The_Password] = "请输入密码！";
stringMap[strings.Input_The_Password_Format_Is_Incorrect] = "密码格式不正确！";
stringMap[strings.Input_Please_Enter_A_New_Password] = "请输入新密码！";
stringMap[strings.Input_Confirm_The_New_Password] = "请输入确认密码！";
stringMap[strings.Input_The_Passwords_Are_Inconsistent] = "密码不一致！";
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] = "请输入正确的验证码！";
stringMap[strings.Input_The_Verification_Code_Error] = "验证码错误！";

//使用帮助
stringMap[strings.Mind_Usinghelp_1] = "创建思维导图";
stringMap[strings.Mind_Usinghelp_2] = "如何新增节点";
stringMap[strings.Mind_Usinghelp_3] = "创建自由节点";
stringMap[strings.Mind_Usinghelp_4] = "建立关联";
stringMap[strings.Mind_Usinghelp_5] = "美化画布";
stringMap[strings.Mind_Usinghelp_6] = "美化文本";
stringMap[strings.Mind_Usinghelp_7] = "添加备注、链接";
stringMap[strings.Mind_Usinghelp_8] = "添加图标";
stringMap[strings.Mind_Usinghelp_9] = "图片";
stringMap[strings.Mind_Usinghelp_10] = "如何恢复删除";
stringMap[strings.Mind_Usinghelp_11] = "导出思维导图";
stringMap[strings.Mind_Usinghelp_12] = "会员升级";
stringMap[strings.Mind_Usinghelp_13] = "快捷键";
stringMap[strings.Mind_Usinghelp_Understood] = "知道了";
stringMap[strings.Mind_Usinghelp_14] = "插入素材库";
//使用帮助-2
stringMap[strings.Mind_Usinghelp_15] = "新建导图";
// stringMap[strings.Mind_Usinghelp_2] = "添加节点";
// stringMap[strings.Mind_Usinghelp_4] = "建立关联";
// stringMap[strings.Mind_Usinghelp_5] = "美化画布";
// stringMap[strings.Mind_Usinghelp_6] = "美化文本";
// stringMap[strings.Mind_Usinghelp_7] = "添加备注、链接";
stringMap[strings.Mind_Usinghelp_17] = "撤销操作、取消";
stringMap[strings.Mind_Usinghelp_18] = "拖动节点换位";
stringMap[strings.Mind_Usinghelp_19] = "节点对齐";
stringMap[strings.Mind_Usinghelp_20] = "变更导图类型";
stringMap[strings.Mind_Usinghelp_21] = "导图线条样式";
stringMap[strings.Mind_Usinghelp_22] = "导图背景颜色";
stringMap[strings.Mind_Usinghelp_23] = "设置节点形状";
stringMap[strings.Mind_Usinghelp_24] = "节点线条样式";
stringMap[strings.Mind_Usinghelp_25] = "节点图片设置";
stringMap[strings.Mind_Usinghelp_26] = "节点图标设置";
stringMap[strings.Mind_Usinghelp_27] = "节点添加摘要";
stringMap[strings.Mind_Usinghelp_28] = "单节点框选";
stringMap[strings.Mind_Usinghelp_29] = "多节点成块";
stringMap[strings.Mind_Usinghelp_30] = "节点添加边框";
stringMap[strings.Mind_Usinghelp_31] = "添加统计图";
stringMap[strings.Mind_Usinghelp_32] = "隐藏节点及恢复";
stringMap[strings.Mind_Usinghelp_33] = "节点多个操作";
stringMap[strings.Mind_Usinghelp_34] = "更换风格";
stringMap[strings.Mind_Usinghelp_35] = "插入语音及解释";
stringMap[strings.Mind_Usinghelp_36] = "导图导成图片";
stringMap[strings.Mind_Usinghelp_37] = "导图PDF文档";
stringMap[strings.Mind_Usinghelp_38] = "打印导图";
stringMap[strings.Mind_Usinghelp_39] = "链接分享及查看";
stringMap[strings.Mind_Usinghelp_40] = "导图分享到社区";
stringMap[strings.Mind_Usinghelp_41] = "文件夹的使用";
stringMap[strings.Mind_Usinghelp_42] = "删除及恢复导图";
//使用帮助3
stringMap[strings.Mind_Usinghelp_43] = "好友申请加入组";
stringMap[strings.Mind_Usinghelp_44] = "如何解散组";
stringMap[strings.Mind_Usinghelp_45] = "上传文件资料";
stringMap[strings.Mind_Usinghelp_46] = "同意加入申请";
stringMap[strings.Mind_Usinghelp_47] = "协同编辑";
stringMap[strings.Mind_Usinghelp_48] = "新建共享组";
stringMap[strings.Mind_Usinghelp_49] = "邀请组成员";

//社区
stringMap[strings.Mind_Storm_Recommend] = "推荐";
stringMap[strings.Mind_Storm_Boilingpoint] = "沸点";
stringMap[strings.Mind_Storm_Unfold] = "展开";
stringMap[strings.Mind_Storm_Putaway] = "收起";
stringMap[strings.Mind_Storm_Myclassification] = "我的分类";
stringMap[strings.Mind_Storm_Recommended_Classification] = "推荐分类";
stringMap[strings.Mind_Storm_Click_Add_Category] = "点击添加分类";
stringMap[strings.Mind_Storm_No_Classification] = "暂无分类";
stringMap[strings.Mind_Storm_No_Tinterested] = "不感兴趣";
stringMap[strings.Mind_Storm_Report] = "举报";
stringMap[strings.Mind_Storm_Views] = "浏览次数";

stringMap[strings.Mind_Storm_Pornographic] = "色情低俗";
stringMap[strings.Mind_Storm_Advertising_Harassment] = "广告骚扰";
stringMap[strings.Mind_Storm_Political_Religion] = "政治宗教";
stringMap[strings.Mind_Storm_Infringement] = "侵权（肖像、诽谤、抄袭、冒用）";
stringMap[strings.Mind_Storm_Prohibited_Content] = "违禁内容";
stringMap[strings.Mind_Storm_Spread_Rumors] = "造谣传谣";
stringMap[strings.Mind_Storm_Report_Successful] = "举报成功";

//编辑
stringMap[strings.Global_Edit] = "编辑";
stringMap[strings.Global_Cancel] = "取消";
stringMap[strings.Global_Ok] = "确定";
stringMap[strings.Global_Name] = "昵称";
stringMap[strings.Global_Introduction] = "简介";
stringMap[strings.Global_Change_Avatar] = "更换头像";
stringMap[strings.Global_Local_Upload] = "本地上传";

//个人资料
stringMap[strings.User_Personal_information] = "个人资料";
stringMap[strings.User_Member_Validity_Period] = "会员有效期";
stringMap[strings.User_My_Share] = "我的分享";
stringMap[strings.User_My_Collection] = "我的收藏";
stringMap[strings.User_More_Message] = "更多";
stringMap[strings.User_Deleted_Sotrm] = "删除导图";
stringMap[strings.User_Folder_Rename] = "重命名";
stringMap[strings.User_Folder_Rename_Root] = "修改权限";
stringMap[strings.User_Folder_Rename_Root_Title] = "修改权限";
stringMap[strings.User_Folder_Root_Public] = "完全公开";
stringMap[strings.User_Folder_Root_Viewonly] = "文件夹公开仅允许查看";
stringMap[strings.User_Folder_Root_Private] = "私密";
stringMap[strings.User_Folder_Root_Public_Introduction] = "在您的个人资料页其他人可以查看您的收藏夹";
stringMap[strings.User_Message_Link] = "链接";
stringMap[strings.User_New_Group] = "新建分组";
stringMap[strings.User_New_Group_Title] = "创建新的分组";
stringMap[strings.User_New_Group_Style_Title] = "文件类型";
stringMap[strings.User_Share_Is_Null] = "暂无分享！";
stringMap[strings.User_Deleted_Storm_Tips] = "确定删除该导图吗？";
stringMap[strings.User_Remove_From_List] = "从列表删除",
stringMap[strings.User_Move_To] = "移动到",
stringMap[strings.User_Deleted_Link] = "删除链接"
stringMap[strings.User_Collction_Is_Null] = "暂无收藏"
stringMap[strings.User_Collection] = "收藏"

//登录框
stringMap[strings.User_Lolgin_Welcome_To_Login] = "欢迎登录寻简思维导图"
stringMap[strings.User_Lolgin_Free_Registration] = "免费注册"
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] = "请输入手机号"
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] = "请输入验证码"
stringMap[strings.User_Lolgin_Obtain] = "获取"
stringMap[strings.User_Lolgin_Password_Login] = "密码登录"
stringMap[strings.User_Lolgin_Login] = "登录"
stringMap[strings.User_Lolgin_Register_An_Account] = "账号注册"
stringMap[strings.User_Lolgin_Already_Have_An_Account] = "已有账号"
stringMap[strings.User_Lolgin_To_Log_In] = "去登录"
stringMap[strings.User_Lolgin_Please_Enter_Password] = "请输入密码"
stringMap[strings.User_Lolgin_Confirm_Password] = "确认密码"
stringMap[strings.User_Lolgin_Registered] = "注册"
stringMap[strings.User_Lolgin_Account_Login] = "账号登录"
stringMap[strings.User_Lolgin_Scan_Code_Login] = "扫码登录"
stringMap[strings.User_Lolgin_Forgot_Password] = "忘记密码"
stringMap[strings.User_Lolgin_Verification_Code_Login] = "验证码登录"
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = "打开寻简在设置界面顶部扫一扫"
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = "此二维码五分钟过期，过期请点击"
stringMap[strings.User_Lolgin_Refresh] = "刷新"
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = "扫描成功，请在手机上确认"
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] = "是否授权登录"
stringMap[strings.User_Lolgin_Please_Enter_New_Password] = "请输入新密码"
stringMap[strings.User_Lolgin_Reset] = "重置"

//我的导图
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] = "搜索模板类型或模板"
stringMap[strings.My_Mind_Date] = "日期"
stringMap[strings.My_Mind_Create_Folder] = "创建文件夹"
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] = "请输入文件夹名"
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] = "请输入新文件夹名"
stringMap[strings.My_Mind__No_Map_Yet_Go] = "暂无导图 快去"
stringMap[strings.My_Mind_Create] = "创建"
stringMap[strings.My_Mind_Right] = "吧!"
stringMap[strings.My_Mind_Open] = "打开"
stringMap[strings.My_Mind_Type_Left_Right] = "双向导图"
stringMap[strings.My_Mind_Type_Unilateral] = "逻辑图"
stringMap[strings.My_Mind_Type_Bottom] = "组织架构图"
stringMap[strings.My_Mind_Type_Bubble] = "气泡图"
stringMap[strings.My_Mind_Type_Time_Vertical] = "日程安排图"
stringMap[strings.My_Mind_Blank_Mind_Map] = "空白思维导图"
stringMap[strings.My_Mind_All] = "全部"
stringMap[strings.My_Mind_Views] = "浏览次数："

//社区搜索
stringMap[strings.Storm_Search_How_Title] = "热门标签"
stringMap[strings.Storm_History_Search] = "历史搜索"

//公共 header
stringMap[strings.Public_Header_Mind] = "导图"
stringMap[strings.Public_Header_Outline] = "大纲"
stringMap[strings.Public_Header_Download_Apps] = "下载客户端"
stringMap[strings.Public_Header_Mobile_Version] = "手机版"
stringMap[strings.Public_Header_Creator] = "创作者："
stringMap[strings.Public_Header_View_Frequency] = "查看次数："
stringMap[strings.Public_Header_Save] = "保存"
stringMap[strings.Header_Mind_Tips_Saved] = "已保存"
stringMap[strings.Header_Mind_Tips_Edit] = "已编辑"
stringMap[strings.Header_Mind_Tips_Update_Saved] = "更新已保存"

//导图编辑菜单
stringMap[strings.Mind_Edit_Revoke] = "撤销"
stringMap[strings.Mind_Edit_Restore] = "恢复"
stringMap[strings.Mind_Edit_Subtopic] = "子主题"
stringMap[strings.Mind_Edit_Top_Subtopic] = "上子主题"
stringMap[strings.Mind_Edit_Bottom_Subtopic] = "下子主题"
stringMap[strings.Mind_Edit_Free_Node] = "自由节点"
stringMap[strings.Mind_Edit_Summary] = "摘要"
stringMap[strings.Mind_Edit_Block] = "成块"
stringMap[strings.Mind_Edit_Insert] = "插入"
stringMap[strings.Mind_Edit_Related] = "连接线"
stringMap[strings.Mind_Edit_Style] = "一键配色"
stringMap[strings.Mind_Edit_Recording] = "录音"
stringMap[strings.Mind_Edit_Insert_Picture] = "图片"
stringMap[strings.Mind_Edit_Insert_Icon] = "图标"
stringMap[strings.Mind_Edit_Insert_Remarks] = "备注"
stringMap[strings.Mind_Edit_Insert_Link] = "链接"
stringMap[strings.Mind_Edit_Insert_Summary_Graph] = "统计图"
stringMap[strings.Mind_Edit_Upload] = "上传"
stringMap[strings.Mind_Edit_Right_Icon] = "图标"
stringMap[strings.Mind_Edit_Right_Style] = "样式"
stringMap[strings.Mind_Edit_Audio_Decoding] = "上传中..."
stringMap[strings.Mind_Edit_Audio_Duration] = "时长"
stringMap[strings.Mind_Edit_Delete_Audio_Tips] = "您确定删除录制的音频吗？"
stringMap[strings.Mind_Edit_Join_Membership] = "开通会员"
stringMap[strings.Mind_Edit_Upgrade] = "升级"
stringMap[strings.Mind_Edit_Upgrade_Item] = "升级至Pro"
stringMap[strings.Mind_Edit_Join_Membership_Tips] = "会员可以有更多的录制时长，现在去升级成会员？"

stringMap[strings.Mind_Edit_Choose_Material] = "选择素材"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = "拖放图片至此"
stringMap[strings.Mind_Edit_Select_locally] = "从本地选择"
stringMap[strings.Mind_Edit_Choose_From] = "从素材库选择"
stringMap[strings.Mind_Edit_Mine] = "我的"
stringMap[strings.Mind_Edit_My_Material] = "我的素材"
stringMap[strings.Mind_Edit_Recently_Used] = "最近使用"
stringMap[strings.Mind_Edit_Use] = "使用"
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = "暂无素材 快去添加吧！"
stringMap[strings.Mind_Edit_Select_Chart_Type] = "选择统计图类型"
stringMap[strings.Mind_Edit_Remarks] = "备注"
stringMap[strings.Mind_Edit_Remarks_Tips] = "请输入备注内容"
stringMap[strings.Mind_Edit_Add] = "添加"
stringMap[strings.Mind_Edit_Please_Enter_The_Link] = "请输入链接"

//编辑页左侧菜单
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] = "保存图片"
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] = "导出PDF"
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] = "分享链接"
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] = "分享到社区"
stringMap[strings.Mind_Edit_Left_Menu_Printing] = "打印"
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = "解锁完整版本"
stringMap[strings.Mind_Edit_Left_Menu_Help] = "帮助"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] = "保存格式"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] = "png格式"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = "jpg格式"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = "webp格式"
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] = "分享导图"
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] = "分享开关"
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = "可将链接复制并发送"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] = "复制链接"
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] = "如果你想更换链接，请点击"
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] = "换一换"
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] = "链接密码："
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] = "开启保存后，查看者可将其保存为自己的导图"
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = "最多可查看次数"
stringMap[strings.Mind_Edit_Left_Menu_Infinite] = "无限"
stringMap[strings.Mind_Edit_Left_Menu_Limit] = "限制"
stringMap[strings.Mind_Edit_Left_Menu_Second] = "次"
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] = "有效期"
stringMap[strings.Mind_Edit_Left_Menu_1_Day] = "1天"
stringMap[strings.Mind_Edit_Left_Menu_3_Days] = "3天"
stringMap[strings.Mind_Edit_Left_Menu_7_Days] = "7天"
stringMap[strings.Mind_Edit_Left_Menu_30_Days] = "30天"
stringMap[strings.Mind_Edit_Left_Menu_Permanent] = "永久"
stringMap[strings.Scan_The_Code_To_Get_The_file] = "微信扫码即可获取文件"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = "复制内容&链接 "
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] = "您确定要重新生成？之前的链接将会失效！"
stringMap[strings.Mind_Edit_Left_Menu_Share] = "分享"
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] = "自定义分类："
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] = "是否允许评论"
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] = "编辑添加分类"
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] = "会员权益"
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] = "支持平台"
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] = "开通会员"
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = "激活码兑换"
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] = "购买完即可会员权益"
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] = "选择支付方式"
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] = "活动价"
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = "实付金额"
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] = "立即续费"
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] = "《服务条款》"
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] = "《隐私政策》"
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] = "激活码："
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = "请输入激活码"
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = "验证激活码"
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] = "选择分类"
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = "主标题不能为空"
stringMap[strings.Mind_Edit_Left_Menu_Coupon] = "优惠券："
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = "请输入优惠券码"
stringMap[strings.Mind_Confirm_Use_Coupons] = "确认使用优惠券"
stringMap[strings.Mind_Edit_Left_Menu_Verification] = "验证"
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] = "优惠金额"
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] = "立即支付"
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] = "微信支付"
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = "确认兑换信息"
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] = "兑换码："
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] = "兑换"

//编辑页右侧菜单
stringMap[strings.Mind_Edit_Right_Menu_canvas] = "画布"
stringMap[strings.Mind_Edit_Right_Menu_background_Color] = "背景颜色"
stringMap[strings.Mind_Edit_Right_Menu_layout] = "布局"
stringMap[strings.Mind_Edit_Right_Menu_Layout_branch] = "分支结构"
stringMap[strings.Mind_Edit_Right_Menu_Line_branch] = "分支线条"
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] = "线条样式"
stringMap[strings.Mind_Edit_Right_Menu_line_Color] = "线条颜色"
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] = "线条宽度"
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] = "线条渐细"
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] = "主题字体"
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] = "子级字体"
stringMap[strings.Mind_Edit_Right_Menu_text_Color] = "文本颜色"
stringMap[strings.Mind_Edit_Right_Menu_font_Size] = "字体大小"
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] = "字体样式"
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] = "边框颜色"
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] = "填充色"
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] = "形状"
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] = "边框宽度"
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = "显示阴影效果（订阅功能）"
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] = "线条颜色"
stringMap[strings.Mind_Edit_Right_Menu_Dotted_Line] = "虚线"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] = "图片大小"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] = "图片位置"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] = "图片形状"
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] = "图标大小"
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] = "图标位置"
stringMap[strings.Mind_Edit_Right_Menu_routine] = "常规"
stringMap[strings.Mind_Edit_Right_Menu_priority] = "优先级"
stringMap[strings.Mind_Edit_Right_Menu_Progress] = "进度"
stringMap[strings.Mind_Edit_Right_Menu_Stars] = "星星"
stringMap[strings.Mind_Edit_Right_Menu_Week] = "星期"
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] = "头像"
stringMap[strings.Mind_Edit_Right_Menu_3D] = "3D"
stringMap[strings.Mind_Edit_Right_Menu_Learning] = "学习"
stringMap[strings.Mind_Edit_Right_Menu_Work] = "工作"
stringMap[strings.Mind_Edit_Right_Menu_Characters] = "人物"
stringMap[strings.Mind_Edit_Right_Menu_Animals] = "动物"
stringMap[strings.Mind_Edit_Right_Menu_Food] = "食物"
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] = "拷贝样式"
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] = "粘贴样式"
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] = "修改形状"
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] = "查看图片"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] = "删除图片"
stringMap[strings.Mind_Edit_Node_Menu_Copy] = "复制"
stringMap[strings.Mind_Edit_Node_Menu_Paste] = "粘贴"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] = "删除线条"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = "删除"
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] = "编辑备注"
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] = "打开链接"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] = "删除备注"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] = "删除链接"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] = "删除图标"
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] = "图标样式"

//统计图编辑面板
stringMap[strings.Echart_Edit_Statistical] = "统计"
stringMap[strings.Echart_Edit_Value] = "值"
stringMap[strings.Echart_Edit_Color] = "颜色"
stringMap[strings.Echart_Edit_Title] = "标题"
stringMap[strings.Echart_Edit_Detailed_Description] = "详细描述"
stringMap[strings.Echart_Edit_Value_Sort] = "排序"
stringMap[strings.Echart_Edit_Value_Display_Type] = "展示类型"
stringMap[strings.Echart_Edit_Value_Color_Scheme] = "配色方案"
stringMap[strings.Echart_Edit_Value_Display_Size] = "展示大小"
stringMap[strings.Echart_Edit_Value_Title_Size] = "标题大小"
stringMap[strings.Echart_Edit_Value_Explain_Size] = "解释大小"
stringMap[strings.Echart_Edit_Value_Subscribe] = "订阅"

//社区标签页
stringMap[strings.StormBookTag_Subscribed] = "已订阅"
stringMap[strings.StormBookTag_List] = "列表"

//社区分享页
stringMap[strings.Storm_Share_Comment] = "评论"
stringMap[strings.Storm_Share_Reply] = "回复"
stringMap[strings.Storm_Share_Let_Me_Comment_Too] = "我也来评论一下吧~"
stringMap[strings.Storm_Share_Release] = "发布"
stringMap[strings.Storm_Share_Main_Title] = "主标题"
stringMap[strings.Storm_Share_Subtitle] = "副标题"
stringMap[strings.Storm_Share_Author] = "作者"
stringMap[strings.Storm_Share_Replies] = "条回复"
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] = "暂无评论，快来抢沙发吧"
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] = "请输入评论呢~"
stringMap[strings.Storm_Share_Delete_Comment_Tips] = "确定删除该评论吗？"

//导图内部使用帮助
stringMap[strings.Mind_Usinghelp_Btn_1] = "快捷键(windows)"
stringMap[strings.Mind_Usinghelp_Btn_2] = "快捷键(mac)"
stringMap[strings.Mind_Usinghelp_Btn_3] = "描述"
stringMap[strings.Mind_Usinghelp_Btn_4] = "[Ctrl] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_5] = "[Command] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_6] = "添加子节点"
stringMap[strings.Mind_Usinghelp_Btn_7] = "[Delet]"
stringMap[strings.Mind_Usinghelp_Btn_8] = "删除当前节点（非编辑状态）"
stringMap[strings.Mind_Usinghelp_Btn_9] = "[Ctrl] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_10] = "[Command] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_11] = "修改当前节点"
stringMap[strings.Mind_Usinghelp_Btn_12] = "[Ctrl] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_13] = "[Command] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_14] = "[Ctrl] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_15] = "[Command] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_16] = "放大导图"
stringMap[strings.Mind_Usinghelp_Btn_17] = "[Ctrl] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_18] = "[Command] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_19] = "缩小导图"
stringMap[strings.Mind_Usinghelp_Btn_20] = "[Ctrl] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_21] = "[Command] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_22] = "[Ctrl] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_23] = "[Command] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_24] = "[Ctrl] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_25] = "[Command] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_26] = "[Ctrl] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_27] = "[Command] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_28] = "退出编辑"

//提示文字
stringMap[strings.Message_Tips_Login_Success] = "登录成功"
stringMap[strings.Message_Tips_Dlt_Success] = "删除成功"
stringMap[strings.Message_Tips_Report_Success] = "举报成功"
stringMap[strings.Message_Tips_Copy_Success] = "复制成功"
stringMap[strings.Message_Tips_Collction_Success] = "收藏成功"
stringMap[strings.Message_Tips_Avatar_Modification_Success] = "头像修改成功"
stringMap[strings.Message_Tips_Signature_Modification_Success] = "签名修改成功"
stringMap[strings.Message_Tips_Name_Modification_Success] = "昵称修改成功"
stringMap[strings.Message_Tips_Modification_Success] = "修改成功"
stringMap[strings.Message_Tips_New_Profile_Success] = "新建文件夹成功"
stringMap[strings.Message_Tips_Mobile_Success] = "移动成功"
stringMap[strings.Message_Tips_Submit_Success] = "提交成功"
stringMap[strings.Message_Tips_Exchange_Success] = "兑换成功"
stringMap[strings.Message_Tips_Please_Code] = "请输入激活码"
stringMap[strings.Message_Tips_Agree_To_Terms] = "请同意隐私条款"
stringMap[strings.Message_Tips_Please_Sign_In] = "请登录"
stringMap[strings.Message_Tips_Share_Success] = "分享成功"
stringMap[strings.Message_Tips_Create_Success] = "创建成功"
stringMap[strings.Message_Tips_Profile_Name_No_Null] = "文件名不能为空"
stringMap[strings.Message_Tips_Selecte_Success] = "搜索完成"
stringMap[strings.Message_Tips_Subscription_Success] = "订阅成功"
stringMap[strings.Message_Tips_Unsubscribe_Success] = "取消订阅成功"
stringMap[strings.Message_Tips_Related_Content_Not_Found] = "未找到相关内容"
stringMap[strings.Message_Tips_Max_Add_Class] = "最多只能添加"
stringMap[strings.Message_Tips_Registered_Success] = "注册成功"
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] = "扫码登录失败"
stringMap[strings.Message_Tips_Share_Is_Null] = "分享链接不存在"
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] = "上传文件不能超过3MB"
stringMap[strings.Message_Tips_Get_Permission_Successfully] = "获取权限成功"
stringMap[strings.Message_Tips_Membership_Required] = "需要会员"
stringMap[strings.Message_Tips_Save_Success] = "保存成功"
stringMap[strings.Message_Tips_Scan_Code_Login_Success] = "扫码登录成功"
stringMap[strings.Message_Tips_Style] = "个分类呢"

//大纲模式
stringMap[strings.Outline_Menu_Indent] = "缩进"
stringMap[strings.Outline_Menu_Decrease_Indent] = "减少缩进"

//帮助提示
stringMap[strings.Help_Background_Content] = "线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,线条背景相关,"
stringMap[strings.Help_Mind_Content] = "可以根据喜好做不同布局的导图：双向导图、左侧导图、右侧导图、组织机构图、双向树、左侧树、右侧树、表格"
stringMap[strings.Help_Share_Link_Content] = "分享链接可以以链接形式在微信、QQ、微博等渠道方式分享，好友可以查看内容"
stringMap[strings.Using_Templates] = "使用模板"

//邀请码
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] = "请输入邀请码"
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = "请输入正确邀请码"
stringMap[strings.User_Lolgin_Optional] = "选填"

//使用素材

stringMap[strings.Cannot_Add_To_Node_Background] = "不适用于节点背景"
stringMap[strings.Cannot_Add_To_Img_Background] = "不适用于文字背景"
stringMap[strings.Cannot_Add_To_Mind_Background] = "不适用于包裹线背景"
stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = "添加背景图片"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = "删除背景图片"
stringMap[strings.Mind_Add_Background_Image] = "背景图"
stringMap[strings.Mind_Delete_Background_Image] = "删除背景图"

stringMap[strings.Permanent_Member] = "永久会员"
stringMap[strings.Insufficient_Storage_Space] = "存储空间不足，升级会员增加空间。"
stringMap[strings.Used_Space] = "存储空间"
stringMap[strings.Current_Storage] = "当前存储："
stringMap[strings.Used_Space_Upgrade] = "存储空间升级"
stringMap[strings.My_Mind_Is_Null_Tips] = "寻简脑图&笔记 超级记忆术, 给思维插上翅膀 创作、展示、计划、笔记"
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] = "存储空间不足，升级会员即可使用模板。"

stringMap[strings.Group_Management] = "群组"
stringMap[strings.Save_Img_Fill_Full] = "铺满"
stringMap[strings.Save_Img_Center_Tilt] = "居中倾斜"
stringMap[strings.Save_Img_File_Full_Dark_Color] = "铺满（深色）"
stringMap[strings.Save_Img_Mind_Map_Watermarking] = "寻简思维导图水印"
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = "添加水印保护知识创作："
stringMap[strings.Save_Img_Watermark_Text] = "水印文字"
stringMap[strings.Save_Img_Watermark_Logo] = "水印LOGO："
stringMap[strings.Save_Img_Watermark_Style] = "水印样式"
stringMap[strings.Save_Img_Please_Input] = "请输入"
stringMap[strings.Save_Img_Chop_Tips] = "开通会员即可享移除默认水印、添加自定义水印、插入背景 "
stringMap[strings.Public_Header_Mindmap_Mind] = "导图模式"
stringMap[strings.Copy_Mind_Map] = "复制导图"
stringMap[strings.Font_Style_Bold] = "粗体"
stringMap[strings.Font_Style_Italics] = "斜体"
stringMap[strings.Font_Style_Delete_Line] = "删除线"
stringMap[strings.Remove_Any] = "移除"

stringMap[strings.Mindmap_Review] = "导图回顾"
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] = "加深印象增强记忆，高效梳理思路和信息"
stringMap[strings.Mindmap_Review_Content] = "导图回顾即编辑过程回放，内容从中心主题出发依次播放;导图回顾可以帮您进行系统盘点、分析、查找思路漏洞，回顾过程中可能会发现不一样的灵感"
stringMap[strings.Mindmap_Review_Speed] = "速度"
stringMap[strings.Mindmap_Review_Word_Speed] = "文字速度"
stringMap[strings.Mindmap_Review_Background_Ratio] = "背景比例"
stringMap[strings.Mindmap_Review_Play_Order_Peer] = '优先播放同级内容'
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] = "当前播放节点放大倍数"
stringMap[strings.Mindmap_Review_Node_Playback_Order] = "节点播放顺序"


stringMap[strings.Mind_Group_Picture_Upload] = "头像上传"
stringMap[strings.Mind_Group_Upload_From_Local] = "本地上传"
stringMap[strings.Mind_Group_Mind_Mapping] = "思维导图"
stringMap[strings.Mind_Group_Information] = "资料"

stringMap[strings.Mind_Group_Invite_New_Members] = "邀请新成员"
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = "请输入手机号"
stringMap[strings.Mind_Group_Cancel] = "取消"
stringMap[strings.Mind_Group_Confirm] = "确定"
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = "手机号不能为空"
stringMap[strings.Mind_Group_Invite_The_Successful] = "邀请成功"


stringMap[strings.Mind_Group_Shared_Group_Membership] = "共享组成员"
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "共享组成员数量上限" 
stringMap[strings.Mind_Group_Membership_Limit] = "成员上限" 
stringMap[strings.Mind_Group_Current_Member_Limit] = "当前成员上限" 
stringMap[strings.Mind_Group_Upgrade_Member_Limit] = "升级成员上限"
stringMap[strings.Mind_Group_Members_Of_The_Search] = "搜索成员"
stringMap[strings.Mind_Group_Creator] = "创建者"
stringMap[strings.Mind_Group_Administrator] = "管理员"
stringMap[strings.Mind_Group_Number_Of_People] = "人"
stringMap[strings.Mind_Group_Selected] = "已选择"
stringMap[strings.Mind_Group_Member] = "成员"
stringMap[strings.Mind_Group_Check_All] = "全选"
stringMap[strings.Mind_Group_Remove] = "移除"
stringMap[strings.Mind_Group_Invite_New_Members] = "邀请新成员"
stringMap[strings.Mind_Group_Delete_Members] = "删除成员"
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] = "请选择要删除的成员 "
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "确定要将用户从本组中移除？"
stringMap[strings.Mind_Group_Add_As_Administrator] = "添加管理员"
stringMap[strings.Mind_Group_Accomplish] = "完成"
stringMap[strings.Mind_Group_Compile] = "编辑"
stringMap[strings.Mind_Group_Group_Owner] = "群主"
stringMap[strings.Mind_Group_Add_Admin] = "添加管理员"
stringMap[strings.Mind_Group_Group_ID] = "组id"
stringMap[strings.Mind_Group_Group_Number] = "组号"
stringMap[strings.Mind_Name] = "名称"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "请输入共享组名称"
stringMap[strings.Mind_Group_Introduce] = "介绍"
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "请简要介绍共享组"
stringMap[strings.Mind_Group_Announcement] = "公告"
stringMap[strings.Mind_Group_Shared_Group_Tag] = "共享组标签"
stringMap[strings.Mind_Group_Custom_Tag] = "自定义标签"
stringMap[strings.Mind_Group_Save] = "保存"
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "组名不能为空"
stringMap[strings.Mind_Group_Establish] = "创建"
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] = "允许创建导图/文件夹"
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] = "允许编辑导图"
stringMap[strings.Mind_Group_Holder] = "所有人"
stringMap[strings.Mind_Group_Only_The_Administrator] = "仅管理员"
stringMap[strings.Mind_Group_Not_Allow] = "不允许"
stringMap[strings.Mind_Group_Allows_Map_Deletion] = "允许删除导图"
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "允许讨论区发消息"
stringMap[strings.Mind_Group_Export] = "导出"
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "允许保存图片/PDF/Word/TXT"
stringMap[strings.Mind_Group_Open_Group_Watermark] = "开启组水印"
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "开启后，思维导图上会印上组标识"
stringMap[strings.Mind_Group_Allow_Saving_Maps] = "允许保存导图"
stringMap[strings.Mind_Group_Save_It_To_My_Map] = "保存到我的导图"
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "是否允许分享到社区"
stringMap[strings.Mind_Group_Join_The_Way] = "加入方式"
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] = "是否允许邀请新成员"
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "是否允许搜索组号加入"
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "加入时是否需要验证消息"
stringMap[strings.Mind_Group_Shared_Group_Data] = "共享组资料"
stringMap[strings.Mind_Group_Authority_Management] = "权限管理"
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] = "退出共享组"
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "是否确认退出共享组？"
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] = "解散组"
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "解散组后文件将删除，确认解散？"
stringMap[strings.Mind_Group_Return_To_Home_Page] = "返回到首页"
stringMap[strings.Mind_Group_Join_The_Group] = "加入组"
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] = "请输入组号/组名搜索"
stringMap[strings.Mind_Group_Apply_To_Join] = "申请加入"
stringMap[strings.Mind_Group_Join] = "加入"
stringMap[strings.Mind_Group_Verification] = "验证"
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] = "请输入验证消息"
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] = "内容不能为空"
stringMap[strings.Mind_Group_Name] = "共享组名称"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "请输入共享组名称"
stringMap[strings.Mind_Group_Introduction] = "共享组介绍"
stringMap[strings.Mind_Group_Group_Indication] = "组号"
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "创建者很懒，还没有介绍哦~"
stringMap[strings.Mind_Group_Shared_Group_Announcement] = "共享组公告"
stringMap[strings.Mind_Group_No_Announcement] = "暂无公告"
stringMap[strings.Mind_Group_Click_On] = "点击展开"
stringMap[strings.Mind_Group_Click_On_The_Hidden] = "点击隐藏"
stringMap[strings.Mind_Group_Altogether] = "共"
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "复制组号成功"
stringMap[strings.Mind_Group_Stick] = "置顶"
stringMap[strings.Mind_Group_There_Is_A_New_Content] = "有新内容"
stringMap[strings.Mind_Group_Cancel_The_Top] = "取消置顶"
stringMap[strings.Mind_Group_Setting_Up] = "设置"
stringMap[strings.Mind_Group_Usinghelp] = "使用帮助"
stringMap[strings.Mind_Group_Set_The_Message] = "组消息"
stringMap[strings.Mind_Group_Apply_To_Join] = "申请加入"
stringMap[strings.Mind_Group_Invite_To_Join] = "邀请加入"
stringMap[strings.Mind_Group_Consent] = "同意"
stringMap[strings.Mind_Group_Turn_Down] = "拒绝"
stringMap[strings.Mind_Group_Label] = "标签"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "请输入共享组标签名称"
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "邀请好友、同事共同创作、共享知识内容"
stringMap[strings.Mind_Group_Immediately_Create] = "立即创建"
stringMap[strings.Mind_Group_Immediately_To_Join] = "立即加入"

stringMap[strings.Currently_Selected] = "当前关注"
stringMap[strings.LabelManagement] = "标签管理"
stringMap[strings.NoAttention] = "暂无关注"
stringMap[strings.Mindmap_Review_Play] = "播放"

stringMap[strings.Mind_Group_Joined] = "已加入"
stringMap[strings.Mind_Group_Download_App] = "下载APP"
stringMap[strings.Mind_Group_No_Content] = "暂无内容"
stringMap[strings.Mind_Group_Individual] = "个"
stringMap[strings.Mind_Group_Message_Area] = "留言区"
stringMap[strings.Mind_Group_No_Message] = "暂无留言，快去"
stringMap[strings.Mind_Group_Leave_Message] = "留言"
stringMap[strings.Mind_Group_Bar] = "吧"
stringMap[strings.Mind_Group_See_More] = "查看更多"
stringMap[strings.Mind_Group_Enter_Invitation_Code] = "输入邀请码"
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] = "申请加入共享组"
stringMap[strings.Reasons_For_Application_0_30_Words] = "申请理由（0-30字）"
stringMap[strings.Mind_Group_Send_Out] = "发送"
stringMap[strings.Mind_Group_Join_Successfully] = "加入成功"
stringMap[strings.Mind_Group_Application_Sent_Successfully] = "申请发送成功"

stringMap[strings.Mind_Group_Save_The_Mapping] = "保存到我的"
stringMap[strings.Mind_Group_Update_Record] = "修改记录"

stringMap[strings.Mind_Edit_Page_Switch_Mapping] = "切换导图"
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] = "导图回顾"
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "已有用户在编辑导图"
stringMap[strings.Mind_Edit_Page_Password] = "密码"

stringMap[strings.Mind_Edit_Page_Sharing_Group] = "共享组"
stringMap[strings.Mind_MBean] = "M豆"
stringMap[strings.Mind_My_MBean] = "我的M豆"
stringMap[strings.Alipay] = "支付宝"
stringMap[strings.Alipay_Payment] = "支付宝支付"
stringMap[strings.Please_Pay_On_The_Payment_Page] = "请到支付页面支付"
stringMap[strings.WeChat] = "微信"
stringMap[strings.Mind_Shooping_Total] = "合计"
stringMap[strings.Mind_Shooping_Confirm_Payment] = "确认支付"
stringMap[strings.Mind_Shooping_Redeem_Now] = "立即兑换"

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] = "是否确认支付购买"
stringMap[strings.Mind_Tips] = "提示"
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "您的M豆不足，是否去购买M豆"

stringMap[strings.Mind_share_scan_code] = "寻简扫码"

stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "是否恢复到上次播放的地方？"
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "剩余可播放次数"

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] = "文件将彻底删除，确认解散吗"
stringMap[strings.Group_Mind_Up_One_Level] = "上一级"
stringMap[strings.Group_Mind_No_Documents] = "暂无文件"
stringMap[strings.Group_Mind_PaymentSuccessful] = "支付成功"

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] = "人协同编辑，多端即时同步，共享文档，提升团队协作效率"

stringMap[strings.Mind_Edit_Subscript] = "下标"
stringMap[strings.Mind_Edit_Text_Location] = "文本位置"
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] = "导入到共享组"

stringMap[strings.Standard_Colors] = "标准颜色"
stringMap[strings.More_Colors] = "更多颜色"
stringMap[strings.Reset_Password] = "重置密码"
stringMap[strings.Account_Password_Login] = "账号密码登录"
stringMap[strings.Authentication_code_Login_registration] = "验证码登录/注册"
stringMap[strings.One_End_Creation_Multi_End_Synchronization] = "一端创作 多端同步"
stringMap[strings.Rich_And_Diverse_Mapping_Structure] = "丰富多样导图结构"
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] = "海量精美模板库"
stringMap[strings.Map_Review_Play] = "导图回顾-播放"
stringMap[strings.CommunityLearningAndSharingPlatform] = "知识学习与分享交流平台"

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] = "升级存储空间"
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] = "我也来评论一下吧"
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "暂无消息通知"
stringMap[strings.Failed_To_Get_Recording_Device] = "获取录音设备失败"

stringMap[strings.Search_Pagination] = "搜索分页"
stringMap[strings.Please_Enter_A_Paging_Name] = "请输入分页名"
stringMap[strings.Operation_Settings] = "操作设置"
stringMap[strings.Shortcut_key_Settings] = "快捷键设置"
stringMap[strings.Press_Key_Combination] = "请按下需要的组合键"
stringMap[strings.Mind_Reduction] = "还原"
stringMap[strings.Double_Click_Add_Free_Node] = "双击空白处增加自由节点"
stringMap[strings.Shortcut_Conflict] = "快捷键冲突"
stringMap[strings.Select_Main_Node] = "选择主节点"
stringMap[strings.Telephone] = "电话"
stringMap[strings.Microblog] = "微博"
stringMap[strings.Address] = "地址"
stringMap[strings.Company_Address] = "上海市松江区中心路1158号(松江临港漕河泾)21号楼B座"
stringMap[strings.Mind_Archive] = "归档"
stringMap[strings.Mind_Bookshelf] = "书架"
stringMap[strings.Search_Archive] = "搜索书架"
stringMap[strings.Modify_Archive_Order] = "修改书架顺序"
stringMap[strings.Modification_Order] = "修改顺序"
stringMap[strings.Please_Enter_Archive_Name] = "请输入书架名称"
stringMap[strings.Message_Tips_Archive_Name_No_Null] = "书架名称不能为空"
stringMap[strings.Mind_Edit_Delete_Archive_Tips] = "确认删除该书架？"
stringMap[strings.Mind_Edit_Delete_Bookcase_Tips] = "确认删除该书夹？"
stringMap[strings.Mind_Edit_Delete_Map_Tips] = "确认删除该导图？"
stringMap[strings.Mind_Edit_Confirm] = "确认"
stringMap[strings.Mind_Import_Map] = "导入导图"
stringMap[strings.Mind_Bookcase] = "书夹"
stringMap[strings.Added_Successfully] = "添加成功"
stringMap[strings.Message_Tips_Bookcase_Name_No_Null] = "书夹名称不能为空"

stringMap[strings.Mind_Association_Map] = "关联导图"
stringMap[strings.Mind_confirm_Association_Map] = "确定关联该导图？"
stringMap[strings.Mind_Association_Map_See] = "查看关联导图"
stringMap[strings.Mind_Association_Map_Delete] = "删除关联导图"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "关联线结构"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "关联线起点"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "关联线终点"

stringMap[strings.Share_Group_To_Storm] = "是否分享组到社区"
stringMap[strings.Apply_To_Join_Storm_Platform] = "在社区平台，其他人看到并申请加入，和有共同知识爱好者一起头脑风暴"
stringMap[strings.Pay_To_Goin] = "付费加入"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "加入时需要支付M豆"
stringMap[strings.Mbean_Can_Be_Realized] = "M豆可变现"

stringMap[strings.Mind_Visit] = "访问"
stringMap[strings.Mind_Details] = "详情"
stringMap[strings.Mind_See] = "查看"
stringMap[strings.Earn_Mbeans] = "赚取M豆"
stringMap[strings.Mind_Explain] = "说明"
stringMap[strings.Mind_Parenting] = "亲子"
stringMap[strings.Mind_Internet] = "互联网"
stringMap[strings.Mind_Psychology] = "心理学"
stringMap[strings.Mind_literature] = "文学"
stringMap[strings.Mind_Technology] = "科技"
stringMap[strings.Mind_Workplace] = "职场"
stringMap[strings.Mind_Life] = "生活"
stringMap[strings.Mind_Subject] = "学科"
stringMap[strings.Hot_Group] = "热门组"
stringMap[strings.Open_Group] = "公开组"
stringMap[strings.Mind_Group_Used] = "已使用"

stringMap[strings.Mind_Click_Select] = "单击选中"
stringMap[strings.Mind_Double_Click_Enter] = "双击进入"
stringMap[strings.Search_Shared_Groups] = "搜索共享组"
stringMap[strings.Search_Storm] = "搜索社区"
stringMap[strings.Members_Save_Free] = "会员免费保存"
stringMap[strings.Bank_Card_Account_Name] = "请输入您的银行卡户名"
stringMap[strings.Bank_Card_No] = "银行卡号"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "请输入您的银行卡号"
stringMap[strings.Branch_Sub_Branch_Information] = "分行支行信息"
stringMap[strings.Branch_Sub_Branch_Example] = "例如：杭州分行城西支行"
stringMap[strings.Application_For_Withdrawal] = "申请提现"
stringMap[strings.Withdrawal_Amount] = "提现金额"
stringMap[strings.Withdrawal] = "提现"
stringMap[strings.Current_Revenue] = "当前收益"
stringMap[strings.Recharge] = "充值"
stringMap[strings.Current_Total_MBeans] = "当前M豆总数"
stringMap[strings.Withdrawal_Status] = "提现状态"
stringMap[strings.Insufficient_Withdrawal_Amount] = "提现金额不足"
stringMap[strings.Load_More] = "加载更多"
stringMap[strings.Loading] = "加载中"
stringMap[strings.It_Is_Over] = "已经到底了"
stringMap[strings.Default_Label] = "默认标签"
stringMap[strings.Selected_Label] = "已选标签"
stringMap[strings.Current_Location] = "当前位置"
stringMap[strings.MGold_Introduction] = "M金是您通过寻简赚取"
stringMap[strings.MGold_Details] = "您的付费云组，组内有知识导图、学习资料等，用户加入支付M豆，其中有50%会转化为M金，M金大于50可提取"
stringMap[strings.Cloud_Group_Introduction] = "分享您的组，其他人可以在社区查看到"
stringMap[strings.Cloud_Group_Details] = "您的付费云组，组内有知识导图、学习资料等，用户加入支付M豆，其中有50%会转化为M金，M金大于50可提取"
stringMap[strings.Share_Storm_Introduction] = "分享您的导图到社区，让更多的人向您学习、探讨知识"
stringMap[strings.Share_Storm_Details] = "如果您开启使用M豆保存导图，其他用户变可以使用您的知识导图，你也将获得50%的M豆，M豆可以用来购买会员、加入付费组、同样可以支付使用其他人的导图。"

stringMap[strings.Linked_Successfully] = "关联成功"
stringMap[strings.Cancel_Archiving] = "取消归档"
stringMap[strings.Please_Select_Archive_Location] = "请选择归档位置"
stringMap[strings.The_Search_Result_Is_Empty] = "搜索结果为空"

stringMap[strings.Customize_A_Style] = "非会员成员，只能自定义一个风格"
stringMap[strings.Adds_Current_Guide_Style_Library] = "将当前导图风格(颜色、形状)添加到风格库中"
stringMap[strings.Trial_Version] = "试用版"
stringMap[strings.Not_Supported_At_The_Moment] = "暂不支持"
stringMap[strings.Not_Supported_At_The_Moment_This_Type_Xmind_File] = "暂不支持此版本，请导入最新版本xmind文件"
stringMap[strings.Load_File_Error] = "很抱歉，文件解析出错！"
stringMap[strings.Mind_File] = "文件"
stringMap[strings.Mind_Import] = "导入"
stringMap[strings.Importing] = "导入中"

stringMap[strings.Mind_Edit_Insert_Video] = "视频"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "拖放视频至此"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "上传视频时长不能超过60s"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "查看视频"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "删除视频"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "上传视频不能超过10MB"
stringMap[strings.Mind_Upload_Video_Restrictions] = "仅支持上传MP4格式的视频,上限120s和10M大小"
stringMap[strings.Mind_Video_Size] = "视频大小"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "导出Word"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "导出TXT"
stringMap[strings.Export_Failed] = "导出失败"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "云同步"
stringMap[strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "未同步"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_As] = "另存为"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "共享组不支持另存为， 可以先讲文件保存到【最近创作】，再保存到本地"
stringMap[strings.Add_File] = "添加文件"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "直接拖入文件，即可上传"
stringMap[strings.Supported_Imported_File_Types] = "目前支持导入的文件类型：寻简(.xjmm)、Word(仅支持.docx)、XMind、.txt、图片"
stringMap[strings.No_Permission_Do_This] = "您没有权限执行此操作"

stringMap[strings.Upgrade_To_Professional] = "升级到专业版"
stringMap[strings.Non_Members_Insert_Videos] = "非会员注册24小时后才可插入视频"
stringMap[strings.Upload_Failed] = "上传失败"
stringMap[strings.Upload_Failed_Title_Message] = "上传失败请点击确认，删除上传失败的遗留图片"

stringMap[strings.Mind_Edit_Members_Use] = "会员使用"
stringMap[strings.Invitation_Code] = "邀请码"
stringMap[strings.Student_Certification] = "学生认证"
stringMap[strings.I_Get_It] = "我会了，跳过引导"
stringMap[strings.Welcome_To_Mind] = "欢迎小主加入寻简,让我们开启思维之旅吧！"
stringMap[strings.Mind_Before] = "先"
stringMap[strings.Try_A_Map] = "一个导图试试看"
stringMap[strings.Click_Create] = "点击创建"
stringMap[strings.Click_Create_A_Mind_Map] = "点击创建一张思维导图"
stringMap[strings.Mind_About] = "关于"
stringMap[strings.See_Checking_Data] = "查看个人信息"
stringMap[strings.Modify_Checking_Data] = "修改个人信息"
stringMap[strings.Mind_Shear] = "剪切"

stringMap[strings.Mind_Style_Empty] = "未收藏或自定义风格"
stringMap[strings.Mind_Branch_Free_Layout] = "分支自由布局"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "双向结构和气泡图分支可以自由拖动布局"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "自由主题拖动吸附"

stringMap[strings.Select_A_Node] = "选中一个主题"
stringMap[strings.Create_Child_Node] = "创建子主题"
stringMap[strings.Add_A_Nice_Image_To_The_Node] = "接下来我们给子主题添加一个好看的"
stringMap[strings.Click_Icon_Panel] = "点击图标面板"
stringMap[strings.Select_An_Icon_You_Like] = "选择一个你喜欢的图标"
stringMap[strings.Replace_The_Mind_Map_With_A_New_One] = "接下来我们给思维导图更换一个新的"
stringMap[strings.Select_The_Central_Theme] = "选中中心主题"
stringMap[strings.Switch_To_Canvas_Panel] = "切换到画布面板"
stringMap[strings.Click_Layout] = "点击布局"
stringMap[strings.Choose_A_Layout_You_Like] = "选择一个你喜欢的布局"
stringMap[strings.Mind_Map_Save_Share] = "恭喜你已经成功做出了一张漂亮的思维导图！我们把它保存下来分享给小伙伴吧~"
stringMap[strings.Click_Save_Picture] = "点击保存图片"
stringMap[strings.Click_OK] = "点击确定"
stringMap[strings.Congratulations_On_Completing_The_Novice_Guide] = "恭喜你完成了新手引导~"
stringMap[strings.Upload_Succeeded] = "上传成功"

stringMap[strings.Mind_Edit_Node_Menu_Quick_Style] = "快速样式"
stringMap[strings.Mind_Extremely_Important] = "极其重要"
stringMap[strings.Mind_Important] = "重要"
stringMap[strings.Mind_Commonly] = "一般"
stringMap[strings.Mind_Ordinary] = "普通"
stringMap[strings.Member_Exclusive] = "该功能为会员专享，是否去升级会员？"
stringMap[strings.Member_Exclusive_Color_Scheme] = "该配色方案为会员专享，是否去升级会员？"
stringMap[strings.Mind_Enlarge] = "放大"
stringMap[strings.Mind_Narrow] = "缩小"
stringMap[strings.Mind_Center] = "居中"
stringMap[strings.Mind_Full_Screen] = "全屏"

stringMap[strings.Mind_Association_Node] = "连接线"
stringMap[strings.Mind_Version_Number] = "版本号"
stringMap[strings.Mind_Copy_Rights] = "Copyright ©2021上海玉数科技有限公司版权所有"

stringMap[strings.Global_Edit_Task] = "编辑任务"
stringMap[strings.Global_Detele_Task] = "删除任务"
stringMap[strings.Mind_Extremely_Urgent] = "非常紧急"
stringMap[strings.Mind_Urgent] = "紧急"
stringMap[strings.Mind_Lower] = "较低"
stringMap[strings.Mind_Task_Information] = "任务信息"
stringMap[strings.Mind_Start_Date] = "开始日期"
stringMap[strings.Mind_End_Date] = "结束日期"
stringMap[strings.Mind_person_Charge] = "执行人"
stringMap[strings.Mind_colse] = "关闭"
stringMap[strings.Mind_Enter_Names_Separated_By_Commas] = "输入姓名,多个用逗号隔开"
stringMap[strings.Mind_Please_Select_An_Start_Date] = "请选择开始日期"
stringMap[strings.Mind_Please_Select_An_End_Date] = "请选择结束日期"
stringMap[strings.Mind_The_Task_Leader_Cannot_Be_Empty] = "添加执行人"
stringMap[strings.Mind_The_End_Time_Less_Start_Time] = "结束时间不能小于开始时间"
stringMap[strings.Mind_Are_You_Sure_To_Delete_This_Task] = "是否确定删除本次任务"
stringMap[strings.Mind_Start_Later] = "后开始"
stringMap[strings.Mind_Over] = "已结束"
stringMap[strings.Mind_In_Progress_Remaining] = "进行中...剩余"
stringMap[strings.Mind_In_Progress_Time] = "进行中...用时"
stringMap[strings.Mind_level] = "完成度"
stringMap[strings.Mind_Completed] = "已完成"
stringMap[strings.Display_Mode] = "显示模式"
stringMap[strings.Default_Sort] = "默认排序"
stringMap[strings.Edit_Time] = "编辑时间"

stringMap[strings.Mind_Year] = "年"
stringMap[strings.Mind_Month] = "月"
stringMap[strings.Mind_Day] = "日"
stringMap [strings.Mind_Day_Day] = "天"
stringMap[strings.Mind_Hours] = "小时"
stringMap[strings.Mind_Minutes] = "分钟"

stringMap[strings.Mind_SubjectLine_Space] = "分支间距"
stringMap[strings.Mind_SonSubjectLine_Space] = "子分支间距"
stringMap[strings.Mind_SubjectLine_H] = "分支横向间距"
stringMap[strings.Mind_SubjectLine_Vertical_Space] = "分支竖向间距"
stringMap[strings.Mind_SonSubjectLine_H] = "子分支横向间距"
stringMap[strings.Mind_SonSubjectLine_Vertical_Space] = "子分支竖向间距"

stringMap[strings.Current_Paging] = "当前分页"
stringMap[strings.Select_Paging] = "选择分页"
stringMap[strings.Batch_Delete] = "批量删除"
stringMap[strings.New_Version_Online] = "新版本上线"
stringMap[strings.Upgrade_Now] = "立即升级"
stringMap[strings.install_Later] = "稍后安装"

stringMap[strings.Mind_Edit_Latex] = "编辑LaTeX"
stringMap[strings.Mind_Detele_Latex] = "删除LaTeX"
stringMap[strings.Mind_Are_You_Sure_To_Delete_This_LaTeX] = "是否确定删除LaTeX公式"
stringMap[strings.Mind_LaTeX_Expression] = "LaTeX表达式"
stringMap[strings.Mind_LaTeX_Formula_Display_Area] = "公式展示区"

stringMap[strings.Mind_LaTeX_Add_Template] = "添加到模板"
stringMap[strings.Mind_LaTeX_Common_Template] = "常用模板"
stringMap[strings.Mind_LaTeX_Used_Help_1] = "LaTeX功能分为四大模块,分别为"
stringMap[strings.Mind_LaTeX_Used_Help_1_1] = "公式区域-根据表达式生成对应公式图形"
stringMap[strings.Mind_LaTeX_Used_Help_1_2] = "表达式区域-输入LaTex表达式"
stringMap[strings.Mind_LaTeX_Used_Help_1_3] = "符号区域-常用各领域符号对应的字符串值"
stringMap[strings.Mind_LaTeX_Used_Help_1_4] = "常用模板-一些默认公式,可以通过'添加到模板'自定义公式,鼠标移上可删除指定的自定义公式"
stringMap[strings.Mind_LaTeX_Used_Help_2] = "必须在正确的LaTeX的语法规则下才能生成公式图形以及保存使用"
stringMap[strings.Mind_LaTeX_Used_Help_3] = "如何修改颜色?"
stringMap[strings.Mind_LaTeX_Used_Help_3_1] = "选中指定的文字区域点击风格符中的颜色即可修改文字颜色,"
stringMap[strings.Mind_LaTeX_Used_Help_3_2] = "例如对 x=a+b中的a+b进行着色,只需要选中a+b点击风格符中的颜色块,"
stringMap[strings.Mind_LaTeX_Used_Help_3_3] = "此时表达式会变成x={\color{#FF0000}a}将a改为a+b着色"
stringMap[strings.Mind_LaTeX_Quick_Title_2] = "希腊字母"
stringMap[strings.Mind_LaTeX_Quick_Title_3] = "数学"
stringMap[strings.Mind_LaTeX_Quick_Title_4] = "风格符"
stringMap[strings.Mind_LaTeX_Quick_Title_5] = "分隔符"
stringMap[strings.Mind_LaTeX_Quick_Title_6] = "可变符"
stringMap[strings.Mind_LaTeX_Quick_Title_7] = "函数"
stringMap[strings.Mind_LaTeX_Quick_Title_8] = "关系符"
stringMap[strings.Mind_LaTeX_Quick_Title_9] = "箭头符"
stringMap[strings.Mind_LaTeX_Quick_Title_10] = "杂项符"
stringMap[strings.Mind_LaTeX_Quick_Title_11] = "重音符"
stringMap[strings.Mind_LaTeX_Error] = "latex公式错误，请确保公式正确"
stringMap[strings.Line_Dashed_Line] = "线条虚线"
stringMap[strings.Border_Dashed_Line] = "边框虚线"
stringMap[strings.Rainbow_Color] = "彩虹色"
stringMap[strings.Mind_Beginning] = "始"
stringMap[strings.Mind_End] = "末"
stringMap[strings.Mind_Arrow] = "箭头"
stringMap[strings.Get_Verification_Code] = "获取验证码"
stringMap[strings.Mailbox_Login] = "邮箱登录"
stringMap[strings.Forget_Email_Password] = "忘记邮箱密码"
stringMap[strings.Mind_No_Account] = "没有账号"
stringMap[strings.Mind_To_Register] = "去注册"
stringMap[strings.Reset_Mailbox_Password] = "重置邮箱密码"
stringMap[strings.Mailbox_Registration] = "邮箱注册"
stringMap[strings.Mind_add_Resource] = "附件"
stringMap[strings.Mind_Float_Explain] = "注释"
stringMap[strings.Account_Security] = "账号安全"
stringMap[strings.Cell_Phone_Number] = "手机号"
stringMap[strings.Mind_Replace] = "更换"
stringMap[strings.Mind_Unbound] = "未绑定"
stringMap[strings.Mind_Debinding] = "去绑定"
stringMap[strings.Change_Password] = "修改密码"
stringMap[strings.Mind_Not_Set] = "未设置"
stringMap[strings.Logged_Device] = "已登录设备"
stringMap[strings.Current_Equipment] = "当前设备"
stringMap[strings.Change_Mobile_Phone_Number] = "更换手机号"
stringMap[strings.Bind_Mobile_Phone_Number] = "绑定手机号"
stringMap[strings.Replace_Mailbox] = "更换邮箱"
stringMap[strings.Bind_Mailbox] = "绑定邮箱"
stringMap[strings.Bind_Mobile_Number_Or_Email_Address] = "请绑定手机号或邮箱!"

stringMap[strings.Mind_Resource_See] ="查看附件"
stringMap[strings.Mind_Resource_Delete] ="删除附件"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "拖放文件至此"
stringMap[strings.Mind_Edit_choose_explain] = "选择文件"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "上传文件不能超过20MB"
stringMap[strings.Modification_Time] = "修改时间"
stringMap[strings.Header_Mind_Tips_Saving] = "保存中"
stringMap[strings.Mind_Not_Edited] = "未编辑,请编辑之后保存"

stringMap[strings.Keep_Original_Style_Request] = "是否保留原有样式"
stringMap[strings.Keep_Original_Style] = "保留原有"
stringMap[strings.Keep_FollowCurrent] = "跟随当前"
stringMap[strings.Export_picture_margins_from_map]  = "导图中导出图片边距"
stringMap[strings.Mind_Edit_Right_Menu_text] = "文本"
stringMap[strings.Title_Location] = "标题位置"
stringMap[strings.Wrapping_Line_Padding] = "内边距"
stringMap[strings.Mind_Edit_Menu_Custom_Width] = "自定义宽度"
stringMap[strings.Mind_Node_Overlay_Paste] = "覆盖粘贴"
stringMap[strings.Mind_Expression] = "表情"
stringMap[strings.Mind_Flag] = "旗帜"
stringMap[strings.Mind_Portrait] = "人像"
stringMap[strings.Mind_Learning] = "学术"
stringMap[strings.Mind_Social_Contact] = "社交"
stringMap[strings.Mind_Symbol] = "符号"
stringMap[strings.Mind_Month_Icon] = "月份"
stringMap[strings.Mind_Skeleton] = "骨架"

stringMap[strings.Mind_Confirm_Deleted] = "确定要删除吗"
stringMap[strings.Mind_Folder_Has] = "当前文件夹有"
stringMap[strings.Mind_Sub_File] = "个子文件"
stringMap[strings.Not_Joined_Any_Group] = "你还没有加入任何共享组"
stringMap[strings.Mind_Set_Password] = "设置密码"
stringMap[strings.Mind_Stretching] = "拉伸"
stringMap[strings.Mind_Equal_Ratio] = "等比"
stringMap[strings.Mind_Insert_Background] = "插入背景"
stringMap[strings.Mind_Size_Fit] = "尺寸适配"
stringMap[strings.Mind_Watermark_Settings] = "水印设置"
stringMap[strings.Mind_Simplification_Watermark] = "寻简水印"
stringMap[strings.Mind_Custom_Watermark] = "自定义水印"
stringMap[strings.keep_Custom_Part_Style_Unchanged] = "是否保持自定义部分样式不改变？"
stringMap[strings.Mind_Cover] = "覆盖"
stringMap[strings.Mind_Keep_Custom] = "保留自定义"
stringMap[strings.Mind_Adding_Child_Nodes_Not_Allowed] = "注释不允许添加子节点"
stringMap[strings.Mind_Information_Has_Expired] = "信息已过期请重新登录"
stringMap[strings.Mind_Fail] = "失败"
stringMap[strings.Mind_Scan_Creation] = "扫描创建"
stringMap[strings.Mind_Scan_Extraction] = "扫描提取"
stringMap[strings.Recharge_Members_Can_Get_More_Use_Times] = "充值会员可获得更多使用次数"
stringMap[strings.Mind_Go_Now] = "立即前往"
stringMap[strings.Mind_Available_Times] = "可用次数"
stringMap[strings.Mind_Edit_Title] = "标题"
stringMap[strings.Supported_Scan_File_Types] = "目前支持扫描的文件类型：.png、.jpg、.webp"

stringMap[strings.Please_Enter_Mind_Password] = "请输入4～8位密码"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "请输入密码"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "此功能是会员功能"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "已经移除文件密码"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "密码位数不够，请输入4倒8位密码"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "密码太长，请输入4倒8位密码"
stringMap[strings.Please_Enter_Mind_Password_Success] = "密码设置成功"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "密码格式错误"
stringMap[strings.Mind_Load_Data_Error] = "数据加载出错"
stringMap[strings.Please_Enter_Mind_Password_Error] = "密码错误"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "只有该导图创建者和管理员可以设置密码"

stringMap[strings.Mind_Edit_Right_Menu_Node_Full_Shape] = '填充样式'
stringMap[strings.Mind_Extremely_DrawOblique] = "纹理"
stringMap[strings.Mind_Extremely_Gradient] = "渐变"
stringMap[strings.Mind_Extremely_Shadow] = "阴影"
stringMap[strings.Create_Mind_Storage_Mode] = "云保存"
stringMap[strings.Node_Shadow_Label] = "阴影效果"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "紧促型布局"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "同级主题对齐"
stringMap[strings.Mind_Style_Node_Overlap] = "主题层叠"
stringMap[strings.Mind_Style_To_Peer] = "应用到同级"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "所有子主题"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "图片另存为"
stringMap[strings.Mind_Edit_Order_Label] = '编号'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '编号首层'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '编号前两层'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '编号前三层'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '编号前四层'
stringMap[strings.Mind_Edit_Order_Remove_Label] = '移除编号'

stringMap[strings.Mind_Edit_Open_Hidde_Title] = '显示层级数'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '显示1层'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '显示2层'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '显示3层'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '显示4层'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '显示5层'
stringMap[strings.Mind_Edit_Open_Hidde_All] = '显示所有层'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai助手-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai助手'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = '输入你想要的任何问题，Ai将给出结果'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = '请合理文明使用Ai助手'
stringMap[strings.Mind_Ai_To_Map_Label] = '加入导图'
stringMap[strings.Mind_Copy_Cuccess_Label] = '成功复制'
stringMap[strings.Mind_Ai_Input_Hint_Label] = '请输入问题，Ai将给出结果...'
stringMap[strings.Mind_Ai_Submit_Label] = '提交'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = '很抱歉，单日使用次数太多，请明天再使用'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = '今日使用次数已用完，永久会员可获得更多单日使用次数'
stringMap[strings.Mind_Ai_To_Member_Label] = '免费次数已用完，会员可获得更多单日使用次数'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI加载中'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai正在收集信息'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai正在整理优化信息'
stringMap[strings.Mind_Ai_Wait_3_Label] = '请请耐心等待，你的问题比较专业'
stringMap[strings.Mind_Ai_Wait_4_Label] = '请您耐心等待'
stringMap[strings.Mind_Ai_Wait_5_Label] = '已经在返回的道路上'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = '请以步骤方式给我'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'Ai 服务正在升级，请稍后重试'
stringMap[strings.Mind_Node_To_Card_Label] = '变为卡片'
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "发散思维-头脑风暴模型"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "灵动-自由流线模型"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "笔记、小说-梳理模型"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "全线条-清新模型"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "[父子]层级关系模型"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "便捷、快速、随手记"
stringMap[strings.My_Mind_Fish_Modol] = "因果分析模型"
stringMap[strings.My_Mind_Bridge_Modol] = "类比相似模型"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "整体和局部[总分]关系模型"
stringMap[strings.My_Mind_Radiate_Modol] = "平行放射-关系聚合模型"
stringMap[strings.My_Mind_Right_Modol] = "右侧拓展分析模型"
stringMap[strings.My_Mind_Left_Modol] = "左侧拓展分析模型"
stringMap[strings.My_Mind_Form_Modol] = "对比、罗列分块分析模型"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "具有前后事件关系模型"
stringMap[strings.My_Mind_Tree_Right_Modol] = "竖屏、顺序性、全局性模型"
stringMap[strings.My_Mind_Bottom_Modol] = "组织架构、平行知识模型"
stringMap[strings.My_Mind_Top_Modol] = "事物分析模型"
stringMap[strings.My_Mind_Bubble_Modol] = "分析事物属性、关系模型"
stringMap[strings.My_Mind_Circular_Modol] = "分类、归纳模型"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "对比共性和特性"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "竖向先后顺序事件模型"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "横向先后顺序事件模型"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "大信息量、事件模型"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "卡片模型"
stringMap[strings.Mind_Menu_Edit_Title] = "编辑标题"
stringMap[strings.Global_Text_Find] = "查找"
stringMap[strings.Global_Text_Replace] = "替换"
stringMap[strings.Global_Text_Replace_All] = "全部替换"
stringMap[strings.Global_Text_Find_Empty] = "无内容"
stringMap[strings.Global_Text_Find_Result] = "第&项, 共@项"

stringMap[strings.Mind_Fast_Style_Rainbow_Label] = "活力"
stringMap[strings.Mind_Fast_Style_Solid_Label] = "纯真"
stringMap[strings.Mind_Import_File] = "导入文件"

stringMap[strings.Mind_Add_Font_Label] = "+ 添加本地字体:ttf、woff..."
stringMap[strings.Mind_Import_Font_Web_Tips] = "请到官网下载电脑客户端，使用该功能，https://mindyushu.com"
stringMap[strings.Mind_Import_Font_Upgrade_Label] = "免费试用版只能添加1个外部字体，升级到Pro用户，可添加更多外部字体以及所有专业版功能。"

stringMap[strings.Mind_Flow_Chart_Map] = "流程图"
stringMap[strings.Mind_Shape_Corner_Text] = "流程描述"
stringMap[strings.Mind_Shape_Start_Text] = " 开始 "
stringMap[strings.Mind_Shape_End_Text] = "结束"
stringMap[strings.Mind_Shape_Determine_Text] = " 判定 "
stringMap[strings.Mind_Shape_Ellipse_3D_Text] = "数据库"
stringMap[strings.Mind_Shape_Cube_Text] = "科学"
stringMap[strings.Mind_Shape_Form_Text] = "文档"
stringMap[strings.Mind_Shape_Corner_Right_Text] = "卡片"
stringMap[strings.Mind_Shape_Book_Text] = "书本"
stringMap[strings.Mind_Shape_Text] = "文本"
module.exports = stringMap;
